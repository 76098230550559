import { DashboardStatistics } from '../../model/Doctor/DashboardStatistics'
const parseDashboardStatisticsData = (dashboardResponse) => {

    let dashboardStatistics = null
    try {

        dashboardStatistics = DashboardStatistics.create({
            totalPatientCount: dashboardResponse.data.totalPatientCount,
            grossAmountByOfflineAppointment: dashboardResponse.data.grossAmountByOfflineAppointment,
            grossAmountByOnlineAppointment: dashboardResponse.data.grossAmountByOnlineAppointment,
            grossAmountByMedicalWorkup: dashboardResponse.data.grossAmountByMedicalWorkup,
            futureAppointmentCount: dashboardResponse.data.futureAppointmentCount,
            serviceAmount:  (dashboardResponse.data.serviceAmount !== undefined) ? dashboardResponse.data.serviceAmount: 0,
            commissionAmount:  (dashboardResponse.data.commissionAmount !== undefined) ? dashboardResponse.data.commissionAmount: 0,
        })

    } catch (error) {
        console.log("error: ", error)
    }

    return dashboardStatistics;
}

export default parseDashboardStatisticsData;