import { Bar } from 'react-chartjs-2';
import { Box,  Card, CardContent, CardHeader, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

export const HastaTetkikGrafik = (props) => {
  const theme = useTheme();

  const [graphicData, setGraphicData] = useState(null)

  useEffect(() => {
    // Update the document title using the browser API
    if (props.data !== null) {
      let patientCountArray = []
      let modicalWorkupCountArray = []
      for (let i = 0; i < props.data.length; i++) {
        const tempStatistics = props.data[i];
        patientCountArray.push(tempStatistics.patientCount)
        modicalWorkupCountArray.push(tempStatistics.modicalWorkupCount)
      }

      const data = {
        datasets: [
          {
            backgroundColor: '#3F51B5',
            barPercentage: 0.5,
            barThickness: 12,
            borderRadius: 4,
            categoryPercentage: 0.5,
            data: patientCountArray,
            label: 'Hasta',
            maxBarThickness: 10
          },
          {
            backgroundColor: '#eee',
            barPercentage: 0.5,
            barThickness: 12,
            borderRadius: 4,
            categoryPercentage: 0.5,
            data: modicalWorkupCountArray,
            label: 'Tetkik',
            maxBarThickness: 10
          }
        ],
        labels: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']
      };

      setGraphicData(data)
    }
  }, [props.data]);

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    xAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    ],
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card {...props}>
      <CardHeader

        title="12 Aylık Hasta/ Tetkik Grafiği"
      />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative'
          }}
        >
          {(graphicData !== null) ?
            <Bar
              data={graphicData}
              options={options}
            /> : null}
        </Box>
      </CardContent>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        
      </Box>
    </Card>
  );
};
