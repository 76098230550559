import { types } from "mobx-state-tree"

export const DoctorPrice = types
    .model({
        id: types.maybeNull(types.optional(types.number, 0)),
        doctorId: types.maybeNull(types.optional(types.number, 0)),
        examinationTime: types.maybeNull(types.optional(types.number, 0)),
        examinationPrice: types.maybeNull(types.optional(types.number, 0)),
        memberPrice: types.maybeNull(types.optional(types.number, 0)),
        wmCommission: types.maybeNull(types.optional(types.number, 0)),
        timeBetweenExaminations: types.maybeNull(types.optional(types.number, 0)),
        bankName: types.maybeNull(types.optional(types.string, "")),
        clinicPrice:  types.maybeNull(types.optional(types.number, 0)),
        iban: types.maybeNull(types.optional(types.string, "")),
        recipientNameSurname: types.maybeNull(types.optional(types.string, "")),
        offlineExaminationPrice: types.maybeNull(types.optional(types.number, 0)),
        vacationDate: types.maybeNull(types.optional(types.Date, new Date())),
        vacationEndDate: types.maybeNull(types.optional(types.Date, new Date()))
    }).actions(self => ({

    }))
