const UserAgreement = (
	<div style={{ padding: "10px" }}>
		<h1>WHOLEMEDICS SAĞLIK ÇALIŞANI SÖZLEŞME</h1>
		İşbu Yazılım Lisans Sözleşmesi (“Sözleşme”) tarafları, Evliya Çelebi Mah. Sadi Konuralp Cad.
		IKSV Vakfı No:5/2 Beyoğlu, İstanbul adresinde mukim WholeMedics Sağlık ve Bilişim Hizmetleri
		Ticaret AŞ (bundan böyle “WHOLEMEDİCS”) ile, ayrıntılı tanımı işbu sözleşmenin TANIMLAR
		bölümünde verilen, hekim, diş hekimi, psikolog, diyetisyen gibi kısmen ya da tamamen sağlık
		hizmet sunumunda yer alma yetkisini haiz Sağlık Profesyoneli’dır (bundan böyle “Sağlık
		Profesyoneli”). İşbu Sözleşme’de WholeMedics ve Sağlık Profesyoneli ayrı ayrı “Taraf” ve
		birlikte “Taraflar” olarak anılacaktır.
		<h2>1. TANIMLAR</h2>
		İşbu Sözleşme uyarınca aşağıdaki terimler aşağıdaki anlama gelecektir: Donanım: İşbu
		Sözleşme’ye konu Yazılım’ları çalıştırmak ve Sağlık Hizmet Sunumundan istifade eden
		hasta/danışanların nitelikli kişisel verileri de dahil kişisel verilerine erişim için Sağlık
		Profesyoneli’nin kendi tek ve münhasır sorumluluğunda bulunan bilgisayar donanımı veya
		yazılım ürünleri. Lisans: Yazılım’ların işbu Sözleşme süresince geçerli ve işbu Sözleşme’nin
		şart ve koşullarına tabi olarak kullanımına ilişkin münhasır olmayan, devredilemez hak.
		Yazılım/Platform: Uzaktan Sağlık Hizmet Sunumu (MedScreenWM tescilli markası ile) ve/veya
		Muayenehane İşletimi (MİS-360WM tescilli markası ile) süreçlerine aracılık etmek üzere
		WHOLEMEDİCS ve 3. Parti iş ortakları tarafından geliştirilmiş ve mevzuat çerçevesinde ilgili
		kamu kurum ve kuruluşları tarafından onaylanmış yazılım ve platformlar ile, bunların
		uzantısı niteliğindeki tüm web siteleri, mobil uygulamalar ve bunların güncellemeleri Sağlık
		Profesyoneli: 1219 sayılı Tabâbet ve Şu’abatı San’atlarının Tarz-ı İcrâsına Dâir Kânun ek
		13. maddesi ile 663 sayılı Sağlık Bakanlığı ve Bağlı Kuruluşlarının Teşkilat ve Görevleri
		Hakkında Kanun Hükmünde Kararname 40. maddesi hükümlerine dayanılarak hazırlanmış olan
		Sağlık Meslek Mensupları ile Sağlık Hizmetlerinde Çalışan Diğer Meslek Mensuplarının İş ve
		Görev Tanımlarına Dair Yönetmelik ve Uzaktan Sağlık Hizmetlerinin Sunumu Hakkında Yönetmelik
		çerçevesinde, hekim, diş hekimi gibi doğrudan hasta/hastalık tedavi yetkisi olan Sağlık
		Meslek Mensuplarıyla, bir uzman hekimin yönlendirmesi ile ya da aynı yönetmelik kapsamında
		hastalık sayılmayan durumlarda uzman hekim yönlendirmesine gerek olmadan tedavi
		uygulayabilecek psikolog, diyetisyen, konuşma terapisti gibi sağlık hizmetlerinde çalışan
		Diğer Meslek Mensupları Kullanıcı: Yazılım ya da unsurlarını, uzaktan sağlık hizmeti ya da
		yüz yüze muayene randevusu almak üzere kullanan, ve/veya Yazılım’da yer alan içeriklerden
		faydalanan üye veya ziyaretçi gerçek kişiler.
		<h2>2. LİSANSLAMA</h2>
		2.1 Sağlık Profesyoneli tarafından bu Sözleşme’nin şart ve koşullarının kabul edilmesi
		üzerine WHOLEMEDİCS, Sağlık Profesyoneli’ne tercihlerine göre Sözleşme konusu Yazılımlar’dan
		bir kısmının ya da tümünün süreli kullanım lisansını verecektir. Lisans, bu Sözleşme
		uyarınca daha önce sonlandırılmadığı sürece yıllık olarak yenilenir. Bu Lisans uyarınca
		Yazılım’ın Sağlık Profesyoneli tarafından indirilmesi ve/veya kullanılması, bu Yazılım’la
		ilgili zaman zaman WHOLEMEDİCS tarafından değiştirilebilecek tüm şart ve koşulların kabulüne
		bağlıdır. 2.2 Sağlık Profesyoneli’ne, kanuni yetkilerine göre hizmet kapsamı otomatik olarak
		belirlenmiş bir üyelik statüsü, üye numarası ve geçici şifre temin edilir. Sağlık
		Profesyonelinden, sonrasında bu şifreyi değiştirmesi istenir. Şifrenin seçimi ve korunması
		tamamıyla kullanıcının sorumluluğundadır. WHOLEMEDICS, kullanıcı şifrelerini saklamaz ve
		şifre kullanımı ile ilgili kullanıcı hataları sebebiyle doğacak problemlerden sorumlu
		değildir. Şifresini unutan kullanıcı "Şifremi Unuttum" bölümüne yönlendirilir ve yeni
		şifresini tanımlaması için güvenlik adımlarını tamamlaması istenir. Güvenlik adımlarını
		başarıyla tamamlayan kullanıcı, yeni şifresini tanımlar ve bundan sonra bu yeni şifre
		geçerli olur. 2.3 Sağlık Profesyoneli, WHOLEMEDİCS tarafından sunulan herhangi bir sağlık
		hizmeti sunum platformunu kullanmaya başlamadan önce, sunacağı hizmetlerle ilgili Sağlık
		Bakanlığı’ndan temin edilmesi gereken faaliyet izin belgelerini ve diğer tüm izinlerini
		almakla yükümlüdür. Sağlık Profesyoneli, kişisel profesyonel kullanımı için yetkilendirilmiş
		olduğu platformlar kanalıyla başka bir kişinin sağlık hizmeti sunmasına izin veremez,
		platformu başka birine kiralayamaz, tahsis edemez. Sağlık Profesyoneli, Yazılım üzerinde
		yetki tanımlama bölümü üzerinden İş Kanunu kapsamında çalıştırdığı asistanına yetki
		verebilir. Bu yetki hasta datasına erişim, hasta datasını görüntüleme, değişiklik yapmayı
		içermez. Asistanın Yazılım ve Platform üzerinde yapacağı işlemlerden Sağlık Profesyoneli
		sorumludur. Sağlık Profesyoneli’nin asistanı ile iş ilişkisi biter bitmez, Yazılım’a ve
		Platform’a erişimi kesmek Sağlık Profesyoneli’nin sorumluluğundadır. Sağlık Profesyoneli,
		yetki tanımladığı asistanını işbu Sözleşme kapsamındaki yükümlülükler ile ilgili
		bilgilendirmekle yükümlüdür
		<h2>3. LİSANS KAPSAMI VE KULLANIM ŞARTLARI</h2>
		3.1 Sağlık Profesyoneli, Yazılım aracılığıyla temas ettiği Kullanıcı’lara, sair kanun ve
		mevzuatın kendisine verdiği yetkiler çerçevesinde hizmet verebilir. Bu çerçevede, sağlığın
		korunması amacıyla yaşam tarzına, sağlık durumuna etki eden alışkanlıklara, ya da genel
		sağlık tedbirlerine yönelik profesyonel öneriler verebilir, danışmanlık sağlayabilir, bir
		hastalık durumunun tanısına yönelik olarak Kullanıcı’ları tıbbi biçimde sorgulayabilir, ya
		da Kullanıcı’lardan belli tetkikler isteyebilir. Bu girişimleri yetersiz bulduğu takdirde
		Kullanıcı’ları yüz yüze görüşme ve muayene etmek üzere çalıştığı sağlık kuruluşlarına davet
		edilebilir ya da muayene amaçlı evde sağlık hizmeti önerilebilir. Tanı konulmuş bir tıbbi
		duruma yönelik olarak Kullanıcı’lara tedavi verebilir, reçete yazılabilir, tedavinin
		sonuçlarını ya da kronik süreçleri izleme alınabilir. 3.2 Bu şekilde sağlık
		profesyonellerince sağlanan tüm danışmanlık, tanısal girişim, tedavi ve takip hizmetleri,
		hizmeti sağlayan sağlık profesyonellerinin yasalarla tanımlanmış mesleki uygulama izinleri
		çerçevesinde sağlanacak olup, bu hizmetin ifası ya da sonuçlarıyla ilgili WHOLEMEDICS
		herhangi bir sorumluluk kabul etmez. WHOLEMEDICS’in bu anlamdaki tek sorumluluğu,
		Kullanıcı’lara hizmet verecek sağlık profesyonellerinin yasal olarak gerek duyulacak mesleki
		diplomalarının sisteme yüklenmesini ve Kullanıcı’lar tarafından görünür olmasını
		sağlamaktır. Sağlık Profesyoneli, hatalı tıbbi uygulamalara dair sair mevzuat dahil,
		Yazılım’ı kullanırken geçerli tüm ulusal, bölgesel ve yerel yasalara ve düzenlemelere daima
		uyacaktır. Aksi takdirde, doğacak tüm hukuki ve cezai yükümlülükler tamamen ve münhasıran
		Sağlık Profesyoneli’nin sorumluluğundadır. 3.3 WHOLEMEDICS, aracılık ettiği hizmetlerin
		kalitesini ve sonuca ulaşırlığını temin etmek üzere, 3. partilerle iş birliği yapabilir. Bu
		iş birlikleri, bunlarla sınırlı olmamak üzere, temelde akredite olmuş tahlil ve görüntüleme
		laboratuvarları, özel test ve tanısal girişim merkezlerini ve gıda takviyesi tedarikçilerini
		kapsamaktadır. Ayrıca WHOLEMEDICS, özel sigorta şirketleri ile anlaşmalar imzalayabilir, ve
		anlaşmalı sigorta şirketleri tarafından bedeli karşılanan hizmetler için Kullanıcı’lar adına
		provizyon işlemlerini yönetebilir. WHOLEMEDICS, sigorta şirketlerinin herhangi bir hizmete
		yönelik provizyonu onaylayacağına dair Kullanıcı’lara hiçbir teminat vermez. 3.4
		WHOLEMEDICS’in aracılık ettiği hizmetler randevu esasına dayanır. Alınacak hizmet tipi ve
		hizmetin sağlayıcısı olan sağlık profesyoneli Kullanıcı tarafından seçilebileceği gibi,
		Kullanıcı’nın şikayetleri onaylanmış algoritmalarca proses edilerek hastaya hizmet
		önerilerinde bulunulabilir ve sistem tarafından Kullanıcı’lara anlık olarak bu hizmeti
		sağlayabilecek sağlık profesyonellerinin listesi sunulabilir. Sağlık Profesyonelleri, yüz
		yüze görmekte oldukları hasta ve danışanlarına da Yazılım’ların ilgili unsurları üzerinden
		randevu verebilir. Sağlık Profesyoneli, Yazılım ve Platformlar üzerinden sağlamakta olduğu
		sağlık hizmetlerinin süresini, randevuya uygun saat dilimlerinin belirlenmesi süreçlerini
		kendisi belirler. Ajanda karışıklığı ve randevu çakışması gibi sorunları bertaraf etmek için
		WHOLEMEDICS Merkezi Ajanda opsiyonunu Sağlık Profesyonellerine sunmaktadır. Buna rağmen,
		Sağlık Profesyoneli’nin bağımsız ajanda tutması nedeniyle ortaya çıkabilecek çakışmalardan
		WHOLEMEDICS sorumlu tutulamaz. 3.5 Sağlanacak hizmetlere dair bedeller Sağlık Profesyoneli
		tarafından belirlenir. Sağlık Profesyoneli bu bedelleri gerekli gördüğü zamanlarda
		güncelleyebilir. Kullanıcı tarafından alınan randevunun kesinleşmesi için seçilen hizmete
		dair ödemenin gerçekleşmiş olması gerekir. Tahsilat WHOLEMEDICS tarafından gerçekleştirilir
		ve hizmet ifa edilene kadar ödeme WHOLEMEDICS bünyesinde tutulur. Kullanıcı vaktinde iptal
		ya da değişiklik işlemi gerçekleştirmez, randevu saatini izleyen 10 dakika içinde bağlantı
		kurmaz ve hizmet ifa edilemez ise, ücret iadesi yapılmaz. Kullanıcı, randevu saatinden 48
		saat öncesine kadar randevusunu iptal edebilir ve 8 saat öncesine kadar değiştirilebilir.
		İptal durumunda 72 saat içinde ödeme aynı kanalla iade edilir. Değiştirilen randevular,
		ajanda uygunluğuna göre en fazla 3 ay ötelenmek üzere Kullanıcı’nın seçeceği başka bir zaman
		dilimine kaydırılır. Bir randevu en fazla 2 kez ertelenebilir. Ertelenen randevulara da
		hasta tarafından katılım gerçekleşmezse ücretin tamamı ÜYE’ye ödenir ve randevu kalıcı
		olarak iptal edilir. Değiştirilmek istenen bir randevu 3 ay içerisinde yenilenmez ise iptal
		edilerek ücretin tamamı ÜYE’ye ödenir. Ücret, sistem kullanım bedeli düşüldükten sonra
		Sağlık Profesyoneli’nin kayıtlı hesabına aktarılır. Sağlık Profesyonellerinin tercihi
		doğrultusunda WHOLEMEDICS tarafından sunulan kontör sistemi de ödeme aracı olarak
		kullanılabilir. Bu durumda ÜYE tarafından yapılan ödeme ve bu ödemenin mali gereklilikleri
		Sağlık Çalışanının sorumluluğundadır. Her bir randevunun kesinleşmesi için WHOLEMEDICS’e
		ödenecek hizmet bedeli, Sağlık Çalışanı tarafından ön ödemeli olarak satın alınacak
		kontörler vasıtasıyla, Sağlık Çalışanı tarafından ÜYE’ye bildirilecek aktif kontör kodunun
		sisteme girilmesiyle gerçekleştirilecektir. 3.6 WHOLEMEDİCS Sağlık Profesyoneli’ne, kişisel
		ve mesleki bilgilerini, özgeçmişini, kurum bilgilerini, sağladığı hizmetlerin tanımını
		girebileceği ve Kullanıcı’ların doğrudan kendisi ile ilgili bu bilgilere erişebileceği bir
		bilgilendirme alanı açabilir. Ayrıca WHOLEMEDİCS, Sağlık Profesyoneli’nin sağlamakta olduğu
		hizmetleri daha detaylı sunabileceği, halkı bilgilendirici video ve görsellere yer
		verebileceği, sınırlı bir web sitesi alanı sağlayabilir. Sağlık Profesyoneli tarafından
		Platform’da oluşturulan ve Kullanıcı’ların ve Platform’u ziyaret edenlerin doğrudan
		erişimine açık şekilde yayınlanan bu ve bunun gibi içerikler bütünüyle Sağlık
		Profesyoneli’nin sorumluluğundadır. Bu içerik ile ilgili şikayet olması ya da düzenli
		denetlemeler sırasında gerçeği yansıtmayan/uygunsuz içerikle karşılaşılması durumunda,
		WHOLEMEDİCS belirli süreyle bu bilgilerin yayına kapatılması, üyeliğin askıya alınması ya da
		üyeliğin yenilenmemesi haklarını saklı tutar. 3.7 Sağlık Profesyoneli’nden herhangi bir
		şekilde sağlık hizmeti alan Kullanıcı, Sağlık Profesyoneli’ni Platform üzerinde
		puanlayabilecek ve bu puanlamaların ortalaması Kullanıcılar ve Platform’u ziyaret edenler
		tarafından açık bir şekilde görülebilecektir. Bu puanlamaların, Platform tarafından bir
		yönlendirme olarak algılanmaması için sağlık Profesyonelleri Soyadı alfabetik sırasına göre
		dizilecek ve puanlama yapan kişi sayısı belirtilmeyecektir. Aynı amaçla, Kullanıcı’ların,
		aldıkları sağlık hizmeti çerçevesinde Sağlık Profesyonellerini açık metinlerle
		değerlendirmelerine müsaade edilmeyecektir. Bu kurumsal ilke, Sağlık Profesyoneli tarafından
		kabul edilmektedir. 3.8 WHOLEMEDİCS, sistemlerinin kullanıcısı olan Sağlık Profesyonelleri
		ile sağlık hizmeti alan Kullanıcı’ların sisteme giriş yapılan kişisel verileri ile nitelikli
		kişisel verileri ve sağlık verilerinin gerektiği şekilde korunması için, bilgi güvenliği
		standartları ve prosedürleri gereğince alınması gereken tüm teknik ve idari güvenlik
		tedbirlerini, teknolojik imkânlar da göz önünde bulundurularak muhtemel riske uygun bir
		düzeyde ve mevzuatın gerektirdiği asgari standartlarda sağlanmaktadır. Ancak bu durum,
		sağlık verilerinin işlenmesi ile ilgili Sağlık Profesyoneli’nin Kişisel Verilerin
		Korunmasına İlişkin Kanun ve sair mevzuat gereği olan yükümlülüklerinin yerini almaz. Sağlık
		Profesyoneli, mevzuat gereği yükümlü olduğu ve VERBİS sistemine tanımladığı işlemekte olduğu
		kişisel veri ve nitelikli kişisel verilerle ilgili tüm tedbirleri aynı şekilde gözetmek
		zorundadır. WHOLEMEDİCS, saklamakta olduğu kişisel sağlık verilerine erişimi mevzuata uygun
		şekilde ve veri sahibinin iznine tabi olarak ilgili partilerle paylaşmaktadır. Bu kapsamda
		Sağlık Profesyoneli, sair mevzuat çerçevesinde kişisel sağlık verilerine erişiminin izin
		iptalleri çerçevesinde kısıtlanabileceğini, kendisine ait hasta kayıt verilerine mahkemeler
		ve yasal zorunluluk gereği bu verileri talep etmeye yetkili olan kamu kurum veya
		kuruluşlarınca erişilebileceğini peşinen kabul eder. 3.9 WHOLEMEDİCS, karar tamamen
		kendisine ait olmak üzere, zaman zaman Yazılım’ların iyileştirilmesi ve veriminin
		artırılması için yazılım güncellemeleri ve yeni hizmet sürümleri yayınlayabilir. Aynı
		amaçla, önceden haber vererek bakım hizmetleri amacıyla sunulan hizmeti kesintiye
		uğratabilir.
		<h2>4. LİSANS KULLANIMINA İLİŞKİN ÖZEL KOŞULLAR</h2>
		4.1. Sağlık Profesyoneli, Yazılım’ı kullanmaya başlamadan önce verdiği kişisel ve diğer sair
		bilgilerin kanunlar önünde doğru olduğunu ve bu bilgileri güncel tutacağını, WHOLEMEDİCS’in
		bu bilgilerin gerçeğe aykırılığı nedeniyle uğrayacağı tüm zararları aynen ve derhal tazmin
		edeceğini beyan ve taahhüt eder. Sağlık Profesyoneli’nin WHOLEMEDİCS’e verdiği söz konusu
		bilgilerin doğru ya da güncel olmadığının tespiti halinde, WHOLEMEDİCS’in işbu Sözleşme’de
		düzenlenen diğer haklarının yanı sıra işbu Sözleşme’yi derhal fesih hakkı saklıdır. 4.2.
		Sağlık Profesyoneli, Yazılım’a erişirken ve Yazılım’ı kullanırken kullandığı bilgisayar,
		telefon, tablet vb. tüm elektronik cihazların ve donanımların, internet ve altyapısının
		siber güvenliğinden bizzat sorumlu olduğunu Yazılım’ı etkileyecek herhangi bir saldırının
		önlenmesi için gerekli önlemleri alacağını ve herhangi bir siber saldırı halinde derhal
		WHOLEMEDİCS’e bilgi vereceğini kabul, beyan ve taahhüt eder. 4.3. Sağlık Profesyoneli,
		Yazılım’a erişim sağlarken kullandığı şifresini başka kişi ya da kuruluşlarla
		paylaşmayacağını, bizzat kendisi kullanacağını ve bu maddeye aykırılık sebebiyle doğabilecek
		tüm sorumluluk ile üçüncü kişiler veya yetkili merciler tarafından WHOLEMEDİCS’e karşı ileri
		sürülebilecek tüm iddia ve taleplere karşı, WHOLEMEDİCS’i ari tutacağını kabul, beyan ve
		taahhüt eder. 4.4. Sağlık Profesyoneli, Yazılım’ı hiçbir şekilde ve koşulda kamu düzenini
		bozucu, genel ahlaka aykırı, başkalarını rahatsız ve taciz edici şekilde, yasalara aykırı
		bir amaç için, başkalarının fikri ve telif haklarına tecavüz edecek şekilde kullanmayacağını
		ve başkalarının Yazılım’ı kullanmasını önleyici veya zorlaştırıcı faaliyet davranışlarda
		bulunmayacağını kabul, beyan ve taahhüt eder. 4.5. Sağlık Profesyoneli tarafından beyan
		edilen, yazılan, yüklenen, kullanılan fikir ve içerikler tamamen Sağlık Profesyoneli’nin
		sorumluluğundadır. Sağlık Profesyoneli tarafından beyan edilen, yazılan, yüklenen,
		kullanılan fikir ve içerikler nedeniyle üçüncü kişilerin ve Sağlık Profesyoneli’nin
		uğrayabileceği zararlardan dolayı WHOLEMEDİCS’in herhangi bir sorumluluğu bulunmamaktadır.
		WHOLEMEDİCS, Sağlık Profesyoneli’ne ait içeriklere ilişkin denetim yükümlülüğü bulunmamakla
		birlikte bu içeriklerin mevzuata aykırı olduğunun, Yazılım/Platform koşullarına aykırı
		olduğunun tespit edilmesi veya idari/adli yaptırım kararı verilmesi halinde WHOLEMEDİCS
		içerikleri kaldırma hakkına sahiptir. Sağlık Profesyoneli’ne ait içerikler, WHOLEMEDİCS
		tarafından Platform’da ve WHOLEMEDİCS sosyal medya hesaplarında paylaşılabilir. 4.6. Sağlık
		Profesyoneli, Yazılım’ı kullanmasından dolayı uğrayabileceği herhangi bir zarar yüzünden
		WHOLEMEDİCS’den tazminat talep etmemeyi peşinen kabul etmiştir. 4.7. Kullanıcı’ya sağlık
		hizmeti sunmaya başlamadan önce ve sunulacak olan sağlık hizmetinin niteliğine uygun düştüğü
		ölçüde Kullanıcı’yı bilgilendirme sorumluluğu Sağlık Profesyoneline ait olup bu konuda
		WHOLEMEDİCS herhangi bir sorumluluk üstlenmemektedir. 4.8. Sağlık Profesyoneli’nin İşbu
		Sözleşme’yi herhangi bir biçimde ihlal etmesi halinde Sağlık Profesyoneli ihlal nedeniyle
		cezai ve hukuki olarak tek başına sorumlu olup, WHOLEMEDİCS’i bu ihlallerin hukuki ve cezai
		sonuçlarından ari tutacak ve WHOLEMEDİCS’in herhangi bir zarara uğraması halinde bu zararı
		derhal tazmin edecektir. 4.9. WHOLEMEDİCS her zaman tek taraflı olarak zaruri hallerde
		Sağlık Profesyoneli’nin Lisans’ını iptal etme/silme, Sağlık Profesyoneli’nin Yazılım’a
		erişimini engelleme/süreli veya süresiz olarak durdurma hakkını saklı tutar. Sağlık
		Profesyoneli, bu durumu ve böyle bir durumda WHOLEMEDİCS’in hiçbir sorumluluğu olmadığını
		peşinen kabul eder.
		<h2>5. MÜLKİYET VE FİKRİ MÜLKİYET HAKLARI</h2>
		5.1. Sağlık Profesyoneli, Yazılım, Yazılım Güncellemeleri ve ilişkili tüm telif hakkı,
		ticari sır, patent, ticari marka ve diğer fikri ve sınai mülkiyet hakları dahil olmak üzere
		Yazılım için sahip olma ve tüm mülkiyet haklarının halen WHOLEMEDİCS’e ait olduğunu kabul
		eder. Yazılımın WHOLEMEDİCS tarafından sağlanan herhangi bir biçimdeki fiziksel kopyaları
		WHOLEMEDİCS mülkiyetinde kalır ve bu kopyaların Lisans süresince Sağlık Profesyoneli’ne
		ödünç verildiği kabul edilecektir. 5.2. Sağlık Profesyoneli, Yazılım’da bulunan fikirlerin
		ve bunların ifadelerinin WHOLEMEDİCS’in hem tescilli bilgisi hem de ticari sırları olduğunu
		kabul eder. Sağlık Profesyoneli, Yazılım’ın değiştirilmesi, uyarlanması, dönüştürülmesi,
		parçalanması, kaynak koda dönüştürme, tersine mühendisliği veya diğer bir şekle
		dönüştürülmesine; tamamen veya kısmen herhangi bir üçüncü bir tarafa ifşa edilmesi,
		kopyalanması, görüntülenmesi, ödünç verilmesi, yayınlanması veya devrine; ve WHOLEMEDİCS’in
		önceden yazılı izni olmadan Yazılım’ın türev eserlerinin oluşturulmasına neden olacak
		faaliyetlerde bulunmayacaktır. Sağlık Profesyoneli, Yazılım’ı korumak ve Yazılım’a erişimi
		olan hiç kimseni bu Sözleşme’yi ihlal eden herhangi bir işlem yapmasını engellemek için
		WHOLEMEDİCS tarafından makul bir şekilde istenebilecek diğer önlemleri de alacaktır. 5.3.
		Sağlık Profesyoneli, WHOLEMEDİCS’in adını, ticari unvanını, logosunu vb. WHOLEMEDİCS’e ait
		işaret ve materyalleri WHOLEMEDİCS’in önceden yazılı onayını almadan ticari/pazarlama
		amaçları dahil hiçbir amaçla ve koşulda kullanamaz.
		<h2>6. GARANTİ REDDİ</h2>
		6.1. Yazılım WHOLEMEDİCS tarafından, Sağlık Profesyoneli’ne herhangi bir garanti veya ihlal
		etmeme garantisi ve satılabilirlik veya belirli bir amaca uygunluk dahil olmak üzere açık
		veya zımni, yazılı veya sözel herhangi bir garanti olmadan “olduğu gibi” sağlanmaktadır.
		6.2. WHOLEMEDİCS, Yazılım’ın kurulumu ve çalıştırılmasının kesintisiz veya hatasız olacağını
		veya Yazılım’daki kusurların mutlak şekilde düzeltileceğini garanti etmemektedir. 6.3.
		Sağlık Profesyoneli Yazılım’ın kurulumu ve kullanımı için tüm gerekli ekipmanın (donanım,
		üçüncü taraf yazılımı, internet erişimi, vb.) tüm maliyetini, bu tür kullanım nedeniyle veya
		bağlantılı olarak oluşan herhangi bir bakım veya onarım gideri dahil olmak üzere
		karşılayacaktır. 6.4. WHOLEMEDİCS’in hiçbir çalışanı veya temsilcisinin bu Sözleşme’de
		bulunanlardan daha geniş kapsamlı veya farklı beyanlar, garantiler veya sözleşmeleri kabul
		etmeye yetkisi yoktur.
		<h2>7. VERİ KORUMA</h2>
		7.1. Taraflar, işbu Sözleşme kapsamında elde edeceği tek başına veya başka bilgilerle
		birlikte kimliği belirli veya belirlenebilir bir gerçek kişiyi tanımlayan her türlü veriyi
		(“Kişisel Veri”), başta 6698 sayılı Kişisel Verilerin Korunması Kanunu ve hukuki dayanağını
		ondan olan ikincil mevzuat ile Kişisel Verileri Koruma Kurulu kararları (“Veri Koruma
		Mevzuatı”) olmak üzere yalnızca ilgili mevzuat hükümlerine uygun olarak toplamayı ve
		işlemeyi ve bu Kişisel Verileri işbu Sözleşme’de yer alan yükümlülüklerin yerine getirilmesi
		haricinde başka herhangi bir amaç için kullanmamayı kabul, beyan ve taahhüt eder. 7.2.
		Sağlık Profesyoneli, kendisi tarafından Yazılım’a girilmiş, oluşturulmuş, üretilmiş veya
		kaydedilmiş, herhangi bir Kullanıcı’ya ait kişisel sağlık verileri dahil her türlü kişisel
		verinin, Yazılım vasıtasıyla ya da basılı olarak yetkisiz kişilerin erişimine kapatılması
		amacıyla, sorumlu olduğu sağlık tesisi bünyesinde makul derecede güvenlik tedbirleri
		almaktan ve başta Veri Koruma Mevzuatı olmak üzere bu verilerin yürürlükteki mevzuata uygun
		olarak tutulmasını ve gizliliğini sağlamaktan sorumludur. Sağlık Profesyoneli, bu verilerle
		ilgili herhangi bir güvenlik ihlali (yetkisiz erişim ve ifşa gibi fakat bunlarla sınırlı
		olmaksızın) durumunda, derhal WHOLEMEDİCS’i ve ilgili kişileri bu durumdan haberdar
		edeceğini kabul, beyan ve taahhüt eder. 7.3. Sağlık Profesyoneli, WHOLEMEDİCS Yazılım ve
		Platform’ları aracılığıyla kaydettiği ve işlediği kişisel sağlık verileri dahil Kullanıcı’ya
		ait olan her türlü kişisel veriyi, gerektiğinde Mahkemeler ve yasal zorunluluk gereği bu
		verileri talep etmeye yetkili olan tüm kamu kurum veya kuruluşlarının incelemesine ve
		erişimine açmak, Kullanıcı’nıntalebi üzerine bunları silmek veya anonimleştirmek ve diğer
		ilgili tedbirleri almakla yükümlüdür. Sağlık Profesyoneli, daha sonraki bir hukuki süreç
		için kanıt niteliğinde saklamak üzere makul süreyle, Kullanıcı’yla ilgili kendisinin tutmuş
		olduğu muayene notlarının bir kopyasını veri gizliliğine tam uyum içerisinde olmak ve makul
		süre dolduğunda silmek kaydıyla saklayabilir. 7.4. Sağlık Profesyoneli Veri Koruma maddesi
		kapsamındaki yükümlülüklerinin ihlali sebebiyle WHOLEMEDİCS’in uğrayacağı zararlardan
		sorumludur ve bu zararları derhal ve WHOLEMEDİCS’in ilk talebiyle tazmin edecektir. İşbu
		yükümlülükler süresizdir ve Sözleşme süresinin sona ermesinden veya Sözleşme’nin
		feshedilmesinden sonra dahi devam eder.
		<h2>8. GİZLİLİK</h2>
		8.1. Sağlık Profesyoneli, bu Sözleşme’nin yürürlük tarihinden itibaren, bu Sözleşme ile
		bağlantılı olarak Yazılım’la ilgili her türlü bilgi dâhil olmak üzere öğrendiği/edindiği her
		türlü bilgilerin ve belgelerin (birlikte “Gizli Bilgi”) gizliliğini sağlayacak ve gizli
		tutacak ve bu tür bilgileri, bu Sözleşme kapsamındaki yükümlülüklerinin yerine getirilmesi
		veya yasalarca gerekli kılınması dışında kullanmayacak ve hiçbir üçüncü şahsa
		açıklamayacaktır. 8.2. Sağlık Profesyoneli, Gizli Bilgi’nin her türlü ihlalinin
		onarılamayacak hasara mal olacağını, bu hasarın parasal zararlarla telafi edilemeyeceğini ve
		bu bağlamda, WHOLEMEDİCS’in, her türlü gizlilik ihlaline veya tehdidine karşılık ihtiyati
		tedbir almaya hakkı olacağını ve WHOLEMEDİCS’in zararlarını tazmin edeceğini kabul eder.
		8.3. Bu Sözleşme’nin feshi/sona ermesi durumunda, Sağlık Profesyoneli, WHOLEMEDİCS’e, alınan
		ve kendi zilyetliğinde veya kontrolünde olan her türlü ve tüm Gizli Bilgileri (bunların her
		türlü kopyası veya reprodüksiyonu dâhil) ilgili yasal düzenlemeler çerçevesinde iade edecek
		ve böyle her türlü Gizli Bilgi kullanımını durduracaktır ve eğer WHOLEMEDİCS bunların
		imhasını isterse bu bilgiler imha edilecek ve bu imha işlemi ve bunun kanıtı yazılı olarak
		Sağlık Profesyoneli tarafından sağlanacaktır. 8.4. Gizli Bilginin herhangi bir şekilde
		yetkisiz olarak herhangi bir üçüncü tarafa açıklanması durumunda Sağlık Profesyoneli
		WHOLEMEDİCS’i derhal bilgilendireceklerdir. 8.5. İşbu Sözleşme hükümleri ihlal edilmeksizin
		kamuya mal olmuş bilgiler, başka kaynaklardan temin edilebilecek nitelikteki veriler ve
		kanun ya da düzenlemeler ya da verilmiş olan bir mahkeme kararı ya da idari emir gereğince
		açıklanması gereken bilgiler işbu madde kapsamında değerlendirilmez.
		<h2>9. ÖDEME VE FATURALANDIRMA</h2>
		9.1. Yazılım’lara dair lisans kullanım ücretleri, Sözleşme’nin yürürlüğe girdiği tarih
		itibarıyla Sağlık Profesyoneli’nden yıllık olarak tek seferde tahsil edilir. WHOLEMEDİCS,
		her bir Yazılım’ın yıllık lisans ücretinde günün şartlarına, hizmet içeriğinin ve
		kapasitesinin geliştirilmesine bağlı olarak ve kampanya dönemlerinde değişiklik yapma
		hakkını saklı tutar. 9.2. Kullanıcı’ların WHOLEMEDİCS Yazılım ve Platform’ları aracılığıyla
		Sağlık Profesyoneli’nden aldıkları hizmetlerin tahsilatı, işbu Sözleşme Madde 3.5’te tarif
		edildiği şekliyle WHOLEMEDİCS tarafından gerçekleştirilir. Kredi kartıyla yapılan
		ödemelerde, aracı kurum komisyon oranları ve %5’lik WHOLEMEDİCS Hizmet Bedeli kesildikten
		sonra bakiye Sağlık Profesyoneli’nin sistemde tanımlı hesabına aracı kurum tarafından
		hizmetin icrasını takip eden gün aktarılır. Sağlık Profesyoneli, Kullanıcı’ya sunduğu hizmet
		karşılığında elde ettiği ödemelere ilişkin faturayı/makbuzu düzenlemekten sorumludur.
		eFatura ya da eSMM, hizmet gerçekleştikten sonra en fazla 5 gün içerisinde ilgili kişiye
		gönderilecektir. 9.3 Sağlık Profesyoneli, Platform üzerinden başka Sağlık Profesyonelleri
		ile iş birliği gerçekleştirebilir. Bu durumda hizmete dair ödeme, her bir Sağlık
		Profesyoneli’nin ortaklaşa kararlaştırdıkları hak ediş oranına göre pay edilerek hesaplarına
		aracı kurum tarafından hizmetin icrasını takip eden gün aktarılır. Her Sağlık Profesyoneli,
		kendi hak edişine dair eFatura ya da eSMM’yi hizmet gerçekleştikten sonra en fazla 5 gün
		içerisinde ilgili kişiye göndermekle yükümlüdür. 9.4 Kesinleşmiş bir randevunun herhangi bir
		sebeple Sağlık Profesyoneli tarafından iptal edilmesi ya da randevu saatinden itibaren 15
		dakika içinde başlatılmaması durumunda Sağlık Profesyoneli’ne herhangi bir ödeme
		yapılmayacaktır. Kullanıcı tarafından yapılan ödemenin tamamı Kullanıcı’ya iade edilecektir.
		9.5 WHOLEMEDİCS, Platform’ları vasıtasıyla üretmiş olduğu gelirin bir bölümünü, Adil Gelir
		Paylaşımı İlkesi gereği, üyesi Sağlık Profesyonelleri ile paylaşabilir. Bu durumda, her ayın
		sonunda Sağlık Profesyoneli’ne gönderilecek olan mali bilgilendirme doğrultusunda Sağlık
		Profesyoneli tarafından kesilecek olan eFatura ya da eSMM’yi müteakip ödeme Sağlık
		Profesyoneli’nin sistemde kayıtlı banka hesabına aktarılacaktır. WHOLEMEDİCS, düzenleyeceği
		faturaları Sağlık Profesyoneli’nin cari hesabına işleme ve mevcut borç bakiyesini
		netleştirme ve mahsup etme hakkını haizdir. Sağlık Profesyoneli, WHOLEMEDİCS’in talep ettiği
		dönemlerde yazılı hesap mutabakatı yapmayı kabul ve taahhüt eder. 9.6 Tüm hizmet hak ediş ve
		gelir paylaşımları doğrultusunda, sair mevzuat kapsamında eFatura ya da eSMM ile
		belgelendirme, tahakkuk ve vergi beyanı sorumluluğu Sağlık Profesyoneli’ne aittir. Sağlık
		Profesyoneli, belgelendirme yükümlülüğünü vaktinde yerine getirmemesi sonucu WHOLEMEDİCS’in
		muhatap olacağı her türlü ceza, tazminat ve kaybın kendisine rücu ettirileceğini ve bunu
		ihtara gerek kalmaksızın tümüyle tazmin edeceğini kabul beyan ve taahhüt eder. 9.7
		WHOLEMEDİCS, Yazılım ve Platform’larının kullanımını yaygınlaştırmak amacıyla Kullanıcı’lara
		dönük kampanyalar düzenleme hakkını saklı tutar. Sağlık Profesyoneli, WHOLEMEDİCS tarafından
		düzenlenen ve hizmet bedellerinin %10’una kadar olan kampanya indirimlerine otomatik olarak
		katılmak üzere WHOLEMEDİCS’i münhasıran yetkilendirdiğini, bu kampanyaların zaman, süre ve
		kapsamını belirlemede WHOLEMEDİCS’in tam yetkili olduğunu kabul beyan ve taahhüt eder.
		Bunların dışında Sağlık Profesyoneli, rekabet uyumu açısından WHOLEMEDİCS tarafından
		onaylanmak üzere, münferiden kampanyalar düzenleyebilir. Münferiden düzenlenmiş
		kampanyalarda WHOLEMEDİCS hizmet ücreti sistemde tanımlı orijinal fiyatlar üzerinden
		hesaplanır. 9.8. Ödemelere dair hesaplamalar yapılırken WHOLEMEDİCS veri tabanındaki
		kayıtlar esas alınacak ve Hukuk Muhakemeleri Kanunu’nun 193. maddesi uyarınca kesin delil
		olarak kabul edilecektir.
		<h2>10. SONLANDIRMA</h2>
		10.1. Sağlık Profesyoneli’nin işbu sözleşmedeki herhangi bir şart ve koşulu ihlali, mücbir
		sebepler ya da tarafların işbu sözleşmeyi yenilememe kararı dahil, işbu Sözleşme herhangi
		bir sebeple sona ererse lisans kullanım hakkı otomatik olarak sonlanacaktır. Bu Sözleşme’nin
		sonlanması üzerine Sağlık Profesyoneli, cari hesap kayıtlarına göre varsa ödemesi gereken
		tüm miktarları ödeyecek ve Yazılım’a erişimi sonlandırılacaktır. 10.2. İşbu Sözleşme
		WHOLEMEDİCS tarafından herhangi bir zamanda ve herhangi bir sebebe bağlı kalınmaksızın
		derhal ve tazminatsız olarak feshedilebilir. Bu durumda, Sağlık Profesyoneli’nin peşinen
		ödediği fakat kullanmadığı döneme dair miktarlar hesaplanır ve 7 iş günü içerisinde Sağlık
		Profesyoneli’ne iade faturası düzenlenmesi suretiyle ödenir.
		<h2>11. MÜCBİR SEBEP</h2>
		Mücbir sebepler; Sözleşme’nin ifasını imkansız kılan yasal mevzuat değişiklikleri, doğal
		afet, salgın hastalık, savaş olayları da dahil olmak üzere Taraflarca öngörülmesi ve
		önlenmesi mümkün olmayan ve işbu Sözleşme konusu hak ve yükümlülüklerin yerine getirilmesini
		engelleyen, Taraflardan herhangi birinin, kendi ihmalinden kaynaklanmayan ve kontrolü
		dışındaki bir nedenden kaynaklanan olaylardır. Taraflar Mücbir Sebep dolayısı ile işbu
		Sözleşme’den doğan yükümlülüklerini yerine getirememelerinden sorumlu tutulamayacaktır.
		Mücbir Sebep’in meydana gelmesi sonrasında derhal ve en geç 2 (iki) gün içerisinde diğer
		Taraf yazılı bildirim yapılarak durumdan haberdar edilecektir.
		<h2>12. DEVİR</h2>
		Sağlık Profesyoneli, WHOLEMEDİCS’in önceden yazılı izni olmaksızın bu Sözleşme kapsamındaki
		herhangi bir hak ve yükümlülüğünü başkasına devredemez, aktaramaz veya alt lisansını
		veremez. WHOLEMEDİCS’in işbu Sözleşme’den doğan hak ve yükümlülüklerini, Sağlık
		Profesyoneli’nin yazılı izni aranmaksızın grup şirketlerine ve/veya ortağı bulunduğu başka
		bir kuruluşa ve/veya üçüncü kişilere ve/veya kendisinden ayrılarak başka bir tüzel kişilik
		altında şirket kuran bölümlerine ve/veya yatırım alması/ortaklık vb. sebeplerle kontrolünün
		değişmesi halinde, tüm bunlarla sınırlı olmaksızın, Sağlık Profesyoneli’nin herhangi bir ön
		yazılı iznini almaksızın, tüzel kişiliği farklı dahi olsa diğer bütün üçüncü şahıslara
		herhangi bir tazminat ödeme yükümlülüğü olmaksızın devretme hakkı saklıdır.
		<h2>13. BÖLÜNEBİLİRLİK</h2>
		Bu Sözleşme’nin herhangi bir hükmünün yürürlüğe konamaz olması veya geçersizliği, kalan
		hükümlerin yürürlükte ve geçerli olmasını etkilemeyecektir.
		<h2>14. SÖZLEŞME BÜTÜNLÜĞÜ</h2>
		Bu Sözleşme, zaman zaman WHOLEMEDİCS tarafından değiştirildiği şekliyle, Yazılım’la ilgili
		olarak Sağlık Profesyoneli ve WHOLEMEDİCS arasındaki anlaşmanın tamamını oluşturur. Bu
		Sözleşme’nin koşulları açısından hiçbir feragat, rıza, ekleme veya değiştirme yazılı olarak
		yapılıp WHOLEMEDİCS yetkili temsilcileri tarafından imzalanmadıkça WHOLEMEDİCS’i bağlayıcı
		nitelik taşımaz.
		<h2>15. TEBLİGAT</h2>
		Taraflar, Platform’da yer alan adres ve elektronik adreslerini tebligat adresi olarak kabul
		ve beyan ederler. Yapılacak adres değişiklikleri diğer Tarafa yazılı olarak bildirilmediği
		takdirde, bu adreslere 7201 sayılı Tebligat Kanunu hükümleri uyarınca yapılacak her türlü
		tebligat geçerli bir tebliğin sonuçlarını doğuracaktır.
		<h2>16. UYGULANACAK HUKUK VE YETKİLİ MAHKEME</h2>
		İşbu Sözleşme, Türk hukukuna tabi olacak ve Türk hukukuna göre yorumlanacaktır. Sözleşme’nin
		akdi ve uygulanmasından doğan tüm uyuşmazlıklar ya da ihtilaflar, münhasıran İstanbul Merkez
		(Çağlayan) Mahkemeleri’nde ve İcra Daireleri’nde çözümlenecektir.
		<h2>17. DAMGA VERGİSİ</h2>
		İşbu Sözleşme’nin imzalanmasından damga vergisi doğması halinde, tümü WHOLEMEDİCS tarafından
		ödenecektir.
		<h2>GEÇİCİ EK MADDE.1</h2>
		Doktorlar, Diyetisyenler ve Psikologlar için 01.05.2024 itibariyle başlatılan “Lansman
		Kampanyası” nedeniyle Yazılımın USBS işlevlerini yerine getiren tüm fonksiyonlarını içeren
		MedScreenWM lisansı 01.05.2027 tarihine kadar 3 yıl süreyle ücretsiz olacaktır. Takip eden
		yıllarda sağlık çalışanının platform üzerindeki performansına bağlı olarak ücretsiz dönemin
		devamıyla ilgili kararı WHOLEMEDİCS AŞ. yönetimi verecek ve bu kararını sözleşme bitiş
		tarihinden 60 gün önce sağlık çalışanına bildirecektir. Lansman süresinde sağlık
		çalışanlarının uzaktan “online” olarak gördüğü hastalar/danışanlar için platforma ödeyeceği
		komisyon oranı %10 olacaktır. Lansman kampanyası 31.12.2024 tarihine kadar devam edecek ve
		bu tarihe kadar sisteme katılan tüm sağlık çalışanları için, katıldıkları tarihten itibaren
		3 yıl boyunca ücretsiz lisans kullanımı geçerli olacaktır. İşbu 17 (onyedi) madde ve 1 (bir)
		ek maddeden ibaret Sözleşme, Sağlık Profesyoneli tarafından her hükmü okunup, imzalanarak ya
		da elektronik ortamda onaylanarak, Sağlık Profesyoneli’nin faaliyet izin belgesini
		WHOLEMEDİCS’e iletmesiyle ve her durumda imzalanmasını/onaylanmasını takiben en geç 15 gün
		içerisinde yürürlüğe girmiş kabul edilecektir.
	</div>
);

export default UserAgreement;
