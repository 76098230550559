import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import { containerStyle } from '../../utils/export-style';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Card, CardActions, CardContent, CardHeader, Tooltip } from '@mui/material';

import { LeftMenu } from '../../components/common/LeftMenu';

import { Box, Container, Grid } from '@mui/material';
import { ToplamHastaSayisi } from '../../components/doktor/dashboard/ToplamHastaSayisi';
import { AylikMuayeneCirosu } from '../../components/doktor/dashboard/AylikMuayeneCirosu';
import { AylikTetkikCirosu } from '../../components/doktor/dashboard/AylikTetkikCirosu';
import { BekleyenRezervasyon } from '../../components/doktor/dashboard/BekleyenRezervasyon';
import { MuayeneTetkikCiroOranGrafik } from '../../components/doktor/dashboard/MuayeneTetkikCiroOranGrafik';

import { HiVideoCamera } from 'react-icons/hi'

import getDashboardStatistics from '../../utils/api/Dashboard/GetDashboardStatistics'
import getAppointmentList from '../../utils/api/Doctor/Appointment/GetAppointmentList';
import dayjs from 'dayjs';

class DashboardPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dashboardStatics: null,
            todayAppointmentList: []
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/panel/kullanici-girisi");
        } else {

            let getDashboardStatisticsResponse = await getDashboardStatistics(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id)

            if (getDashboardStatisticsResponse !== undefined && getDashboardStatisticsResponse !== null) {
                //console.log("getDashboardStatisticsResponse: ", getDashboardStatisticsResponse)
                if (getDashboardStatisticsResponse.status === 200) {
                    this.setState({
                        dashboardStatics: getDashboardStatisticsResponse.data
                    })
                } else if (getDashboardStatisticsResponse.status === 401) {
                    this.props.store.setUserLogout()
                    window.location.reload()
                }
            }

            const today = new Date();
            const gmtOffset = today.getTimezoneOffset() / 60;
            const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0 - gmtOffset, 0, 0);
            const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23 - gmtOffset, 59, 59);

            let getAppointmentListTodayResponse = await getAppointmentList(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, startDate.toISOString(), endDate.toISOString())
            //console.log("getAppointmentListTodayResponse: ", getAppointmentListTodayResponse)
            if (getAppointmentListTodayResponse !== undefined && getAppointmentListTodayResponse !== null) {
                if (getAppointmentListTodayResponse.status === 200) {
                    if (getAppointmentListTodayResponse.data !== null) {
                        let tempRandevuListesi = getAppointmentListTodayResponse.data.reverse()
                        this.setState({ todayAppointmentList: tempRandevuListesi })
                    }

                } else if (getAppointmentListTodayResponse.status === 401) {
                    this.props.store.setUserLogout()
                    window.location.reload()
                }
            }
        }
    }

    startVideoCall = async (appointment) => {

        this.props.history.push("/panel/gorusme/" + appointment.appointmentId + "/" + appointment.patientId)

        /*let startVideoCallResponse = await putForStartVideoCall(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, appointment.appointmentId)
        console.log("startVideoCallResponse: ", startVideoCallResponse)*/
    }

    onClickTumRandevuBilgileri = () => {
        this.props.history.push("/panel/randevularim")
    }

    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage={"Ana Sayfa"} />
                <Box component="main" sx={{
                    ...containerStyle,
                    flexGrow: 1,
                    py: 8
                }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={3} sm={6} xl={3} xs={12}>
                                <ToplamHastaSayisi sx={{ height: '100%' }} data={(this.state.dashboardStatics !== null) ? this.state.dashboardStatics.totalPatientCount : 0} />
                            </Grid>
                            <Grid item xl={3} lg={3} sm={6} xs={12}>
                                <AylikMuayeneCirosu data={(this.state.dashboardStatics !== null) ? 
                                    (this.state.dashboardStatics.grossAmountByOfflineAppointment
                                        + this.state.dashboardStatics.grossAmountByOnlineAppointment) : 0} usertype={this.props.store.userType}/>
                            </Grid>
                            <Grid item xl={3} lg={3} sm={6} xs={12}>
                                <AylikTetkikCirosu data={(this.state.dashboardStatics !== null) ? this.state.dashboardStatics.grossAmountByMedicalWorkup : 0}
                                usertype={this.props.store.userType} />
                            </Grid>
                            <Grid item xl={3} lg={3} sm={6} xs={12}>
                                <BekleyenRezervasyon sx={{ height: '100%' }}
                                    data={(this.state.dashboardStatics !== null) ? this.state.dashboardStatics.futureAppointmentCount : 0} />
                            </Grid>
                            <Grid item lg={(this.props.store.userType !== 1) ? 12 : 8} md={12} xl={(this.props.store.userType !== 1) ? 12 : 9} xs={12} >
                                <Card style={{ height: '100%' }}>
                                    <CardHeader title="Bugünkü Randevularınız" style={{ paddingBottom: "10px" }} />
                                    <CardContent style={{ paddingTop: "0", height: 'calc(100% - 130px)' }}>
                                        <TableContainer style={{ marginTop: "0px" }}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>RANDEVU TARİH/SAAT</TableCell>
                                                        <TableCell>ADI SOYADI</TableCell>
                                                        <TableCell>RANDEVU TİPİ</TableCell>
                                                        <TableCell>HİZMET</TableCell>
                                                        <TableCell>RANDEVU DURUM</TableCell>
                                                        <TableCell>İŞLEMLER</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.todayAppointmentList.map((appointment, idx) => (
                                                        <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                            <TableCell component="th" scope="row">
                                                                {dayjs(new Date(appointment.appointmentDate)).format("DD.MM.YYYY HH:mm")}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {appointment.title}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {(appointment.appointmentType === 2) ? "Yüzyüze" : "Online"}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {(appointment.appointmentType === 1) ? "Muayene" : "Hizmet ismi gelecek"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {(appointment.status === 0 || appointment.status === 1) ? "Bekliyor" : (appointment.status === 2) ? "Görüşme Başladı" : (appointment.status === 3) ? "Görüşme Tamamlandı" : "Diğer"}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
						                                        {this.props.store.userType !== 3 && (
                                                                    <Tooltip title="Görüşme Odasına Gir">
                                                                        <Button variant={"contained"} onClick={() => this.startVideoCall(appointment)}
                                                                        /*disabled={(isBefore(appointment.appointmentDate, new Date()) ? true : false)}*/
                                                                        >
                                                                            <HiVideoCamera style={{ fontSize: "20px" }} />
                                                                        </Button>
                                                                    </Tooltip>)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                    <CardActions>
                                        <Button onClick={this.onClickTumRandevuBilgileri} style={{ marginLeft: "auto" }}>
                                            Tüm Randevu Listesi Görüntüle
                                        </Button>
                                    </CardActions>
                                </Card>

                            </Grid>
                            <Grid item lg={4} md={6} xl={3} xs={12} hidden={(this.props.store.userType !== 1) ? true : false}>
                                <MuayeneTetkikCiroOranGrafik sx={{ height: '100%' }}
                                    data={(this.state.dashboardStatics !== null) ? [this.state.dashboardStatics.grossAmountByOfflineAppointment + this.state.dashboardStatics.grossAmountByOnlineAppointment, this.state.dashboardStatics.grossAmountByMedicalWorkup, this.state.dashboardStatics.commissionAmount, this.state.dashboardStatics.serviceAmount] : []} />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(DashboardPage));
