import React, { useState, useEffect, useRef } from 'react';
import { observer, inject } from "mobx-react"

import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

import DownloadIcon from '@mui/icons-material/Download';

import getAppointmentChat from '../../../utils/api/Doctor/Appointment/Chat/GetAppointmentChat'
import FilePreview from '../../common/FilePreview';
import { Link } from '@mui/material';

export const ChatView = inject("store")(observer((props) => {

    const messagesEndRef = useRef(null)
    const chatListSize = useRef(0)

    const [chatList, setChatList] = useState([])

    useEffect(() => {
        // Update the document title using the browser API
        getChatListReq();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getChatListReq = async () => {

        let chatListGetResponse = await getAppointmentChat(props.store.baseUrl, props.store.token,
            props.store.doctor[0].id, props.appointmentId)

        if (chatListGetResponse !== null && chatListGetResponse !== undefined) {
            if (chatListGetResponse.status === 200) {
                setChatList(chatListGetResponse.data.reverse())

                if (chatListSize.current !== chatListGetResponse.data.length) {
                    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
                }

                chatListSize.current = chatListGetResponse.data.length
            }
        }
    }

    const onFileReadError = () => {

    }

    return (
        <>
            <div className="video-chat-container" style={{ background: "#eee", padding: "5px" }}>
                <List>
                    {chatList.map((chat, idx) => (
                        (chat.ownerId === chat.doctorId) ?
                            (
                                (chat.messageType === 1) ?
                                    <ListItem key={idx}>
                                        <Grid container style={{
                                            background: "#2196f3", color: "#fff",
                                            padding: "5px", paddingLeft: "20px", borderRadius: "10px", width: "50%"
                                        }}>
                                            <Grid item xs={12}>
                                                <ListItemText align="left" primary={chat.message}></ListItemText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle2' style={{ fontSize: "12px" }}>
                                                    {dayjs(new Date(chat.createdDate)).format('YYYY-MM-DD')}
                                                    <b> {dayjs(new Date(chat.createdDate)).format('HH:mm')}</b></Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    :
                                    <ListItem key={idx}>
                                        <Grid container style={{
                                            background: "#2196f3", color: "#fff",
                                            padding: "5px", paddingLeft: "20px", borderRadius: "10px", width: "50%"
                                        }}>
                                            <Grid item xs={12}>
                                                <FilePreview style={{ width: "100%" }}
                                                    type={"file"}
                                                    url={props.store.baseUrl + chat.message}
                                                    onError={onFileReadError}
                                                />
                                                <Tooltip title="Dosyayı İndir">
                                                    <IconButton component={Link} href={props.store.baseUrl + chat.message}
                                                        target={"_blank"}
                                                        style={{ float: "right" }}>
                                                        <DownloadIcon style={{ color: "#fff" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle2' style={{ fontSize: "12px", textAlign: "right" }}>
                                                    {dayjs(new Date(chat.createdDate)).format('YYYY-MM-DD')}
                                                    <b> {dayjs(new Date(chat.createdDate)).format('HH:mm')}</b></Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>)
                            :
                            (chat.messageType === 1) ?
                                <ListItem key={idx} style={{ width: "100%" }}>
                                    <Grid container style={{
                                        background: "#fff",
                                        padding: "5px", paddingRight: "20px", borderRadius: "10px", border: "1px solid #eee", width: "50%", marginLeft: "50%"
                                    }}>
                                        <Grid item xs={12}>
                                            <ListItemText align="right" primary={chat.message}></ListItemText>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle2' style={{ fontSize: "12px", textAlign: "right" }}>
                                                {dayjs(new Date(chat.createdDate)).format('YYYY-MM-DD')}
                                                <b> {dayjs(new Date(chat.createdDate)).format('HH:mm')}</b></Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem> :
                                <ListItem key={idx} style={{ width: "100%" }}>
                                    <Grid container style={{
                                        background: "#fff",
                                        padding: "5px", paddingRight: "20px", borderRadius: "10px", border: "1px solid #eee", width: "50%", marginLeft: "50%"
                                    }}>
                                        <Grid item xs={12}>
                                            <FilePreview style={{ width: "100%" }}
                                                type={"file"}
                                                url={props.store.baseUrl + chat.message}
                                                onError={onFileReadError}
                                            />
                                            <Tooltip title="Dosyayı İndir">
                                                <IconButton component={Link} href={props.store.baseUrl + chat.message}
                                                    target={"_blank"}
                                                    style={{ float: "right" }}>
                                                    <DownloadIcon style={{ color: "#2196f3" }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle2' style={{ fontSize: "12px", textAlign: "right" }}>
                                                {dayjs(new Date(chat.createdDate)).format('YYYY-MM-DD')}
                                                <b> {dayjs(new Date(chat.createdDate)).format('HH:mm')}</b></Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                    ))
                    }

                </List>
                <div ref={messagesEndRef} />
            </div>
        </>
    )
}))