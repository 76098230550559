import { DoctorTitle } from '../../model/Doctor/DoctorTitle'

const parseDoctorTitleData = (inputDoctorTitle) => {
    //console.log("inputDoctorTitle: ", inputDoctorTitle)
    let doctorTitle = null
    try {

        doctorTitle = DoctorTitle.create({
            titleId: (inputDoctorTitle.titleId !== null) ? inputDoctorTitle.titleId : 0,
            titleName: (inputDoctorTitle.titleName !== null) ? inputDoctorTitle.titleName : ""
        })
        
    } catch (error) {
        console.log("error: ", error)
    }
    
    return doctorTitle;
} 

export default parseDoctorTitleData;

