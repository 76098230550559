import { types } from "mobx-state-tree"

export const DoctorSetting = types
    .model({
        id: types.maybeNull(types.optional(types.number, 0)),
        doctorId: types.maybeNull(types.optional(types.number, 0)),
        settingsId: types.maybeNull(types.optional(types.number, 0)),
        settingsName: types.maybeNull(types.optional(types.string, "")),
        isActive: types.maybeNull(types.optional(types.boolean, false)),
    }).actions(self => ({

    }))
