import axios from "axios";

const deleteClinicExpense = async (baseUrl, token, doctorId, clinicid, expenseId) => {

    //doctor/{doctorid}/clinic/{clinicid}/room/{roomid}

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let deleteResponse = null

    await axios.delete(baseUrl + "/doctor/" + doctorId + "/clinic/" + clinicid + "/expense/"+ expenseId, config)
        .then((res) => {
            // console.log("res.data.data: ", res.data.data)
            deleteResponse = {
                status: res.data.status,
                data: res.data.data
            }

            // console.log("deleteResponse: ", deleteResponse)
        }).catch((error) => {
            if (error.response !== undefined) {
                deleteResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return deleteResponse
}

export default deleteClinicExpense;