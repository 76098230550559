import { types } from "mobx-state-tree";

export const Appointment = types
	.model({
		id: types.optional(types.number, 0),
		appointmentId: types.optional(types.integer, 0),
		doctorId: types.optional(types.number, 0),
		patientId: types.optional(types.number, 0),
		title: types.optional(types.string, ""),
		description: types.optional(types.string, ""),
		videoUrl: types.optional(types.string, ""),
		status: types.optional(types.number, 0),
		appointmentType: types.optional(types.number, 0),
		appointmentDate: types.optional(types.Date, new Date()),
		appointmentEndDate: types.optional(types.Date, new Date()),
		/*patientBirthDate: types.optional(types.Date, new Date()),
        patientGender: types.optional(types.number, 0),
        patientWeight: types.optional(types.number, 0),
        patientHeight: types.optional(types.number, 0),
        patientName: types.optional(types.string, ""),
        patientSurname: types.optional(types.string, ""),*/
		roomId: types.optional(types.number, 0),
		clinicId: types.optional(types.number, 0),
		priceOffline: types.optional(types.number, 0),
		productsOffline: types.optional(types.array(types.number), []),
		examinationPrice: types.optional(types.number, 0),
		consultDoctorId: types.optional(types.number, 0),
		duration: types.optional(types.number, 0),
		startDate: types.optional(types.Date, new Date("0001-01-01")),
		expireDate: types.optional(types.Date, new Date("0001-01-01")),
		barcode: types.optional(types.string, ""),
		noSms: types.optional(types.boolean, false),
		coupon: types.optional(types.string, ""),
		sysTakip: types.optional(types.string, ""),
	})
	.actions((self) => ({}));

export const Payment = types
	.model({
		title: types.optional(types.string, ""),
		date: types.optional(types.Date, new Date("0001-01-01")),
		examinationPrice: types.optional(types.number, 0),
		priceOffline: types.optional(types.number, 0),
		paid: types.optional(types.integer, 0),
		type: types.optional(types.string, ""),
		price: types.optional(types.integer, 0),
		discount: types.optional(types.integer, 0),
		note: types.optional(types.string, ""),
		complete: types.optional(types.boolean, true),
	})
	.actions((self) => ({}));

export const PaymentDetail = types
	.model({
		paymentType: types.optional(types.string, ""),
		price: types.optional(types.integer, 0),
		note: types.optional(types.string, ""),
	})
	.actions((self) => ({}));
