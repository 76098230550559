import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import { containerStyle } from '../../utils/export-style';

import { Box, Container, Grid, Typography } from '@mui/material';

import { LeftMenu } from '../../components/common/LeftMenu';

import CampaignIcon from '@mui/icons-material/Campaign';

class StatisticsPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dashboardStatics: null,
            passedAppointmentList: [],
            futureAppointmentList: [],
            type: null
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/panel/kullanici-girisi");
        } 
    }

    onLogout = () => {
        this.props.store.setUserLogout()
        window.location.reload()
    }

    onHandleProfileMenuOpen = (event) => {
        //setProfileMenuDisplay(true)
        //setProfileMenuTarget(event.currentTarget)
        this.setState({
            profileMenuDisplay: true,
            profileMenuTarget: event.currentTarget
        })
    }

    onClickProfileMenuClose = () => {
        //setProfileMenuDisplay(false)
        //setProfileMenuTarget(null)
        this.setState({
            profileMenuDisplay: false,
            profileMenuTarget: null
        })
    }

    render() {
        return (
            <div className="container mps-container">
                <LeftMenu activePage={"İstatistikler"} />
                <Box component="main" sx={{
                    ...containerStyle,
                    flexGrow: 1,
                    py: 8
                }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} xl={12} xs={12} style={{textAlign: "center"}}>
                            <CampaignIcon color="primary" style={{fontSize: "50px"}}/>
                                <Typography variant="h5" color="primary">
                                        Pazar ve İşletme İstatistikleri Çok Yakında!
                                </Typography>
                                <img src="/assets/images/dashboard.png" alt="dashboardimage" style={{ width: "100%", borderRadius: "30px", marginTop: "30px" }}></img>

                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(StatisticsPage));