import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"
import { toJS } from 'mobx';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { IMaskInput } from 'react-imask';
import OutlinedInput from '@mui/material/OutlinedInput';

import getIlListesi from '../../../utils/api/Common/GetIlListesi'
import getIlceListesi from '../../../utils/api/Common/GetIlceListesi'
import getMahalleListesi from '../../../utils/api/Common/GetMahalleListesi'
import putDoctor from '../../../utils/api/Doctor/PutDoctor';
import getDoctorLanguages from '../../../utils/api/Common/GetDoctorLanguages'

import InfoIcon from '@mui/icons-material/Info';

import UploadKase from '../../upload/UploadKase';
import { Button } from '@mui/material';
import TextEditorCommon from '../../common/TextEditorCommon';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const newAddress = { address: "", cityId: 0, districtId: 0, neighbourhoodId: 0 };

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(#00)000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

export const ProfileMenu = inject("store")(observer((props) => {

    const [ilListesi, setIlListesi] = useState([])
    const [ilceListesi, setIlceListesi] = useState([])
    const [mahalleListesi, setMahalleListesi] = useState([])
    const [languages, setLanguages] = useState([])
    const [selectedLanguages, setSelectedLanguages] = useState([])

    useEffect(() => {
        // Update the document title using the browser API
        getIlListesiReq()
        getLanguagesReq()
        if (props.store.doctor[0] !== undefined) {
            if (props.store.doctor[0].address !== null) {
                getIlceListesiReq(props.store.doctor[0].address.cityId)
                getMahalleListesiReq(props.store.doctor[0].address.districtId)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getIlListesiReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getIlListesiResponse = await getIlListesi(props.store.baseUrl, props.store.token)
        //console.log("getIlListesiResponse: ", getIlListesiResponse)
        if (getIlListesiResponse !== null && getIlListesiResponse !== undefined) {
            if (getIlListesiResponse.status === 200) {
                setIlListesi(getIlListesiResponse.result)
            } else if (getIlListesiResponse.status === 401) {
                props.store.setUserLogout()
                window.location.reload()
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const getIlceListesiReq = async (ilId) => {
        props.store.setLoadingBarStatus(false)

        let getIlceListesiResponse = await getIlceListesi(props.store.baseUrl, props.store.token, ilId)
        //console.log("getIlceListesiResponse: ", getIlceListesiResponse)
        if (getIlceListesiResponse !== null && getIlceListesiResponse !== undefined) {
            if (getIlceListesiResponse.status === 200) {
                setIlceListesi(getIlceListesiResponse.result)
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const getMahalleListesiReq = async (districtId) => {
        props.store.setLoadingBarStatus(false)

        let getMahalleListesiResponse = await getMahalleListesi(props.store.baseUrl, props.store.token, districtId)
        //console.log("getMahalleListesiResponse: ", getMahalleListesiResponse)
        if (getMahalleListesiResponse !== null && getMahalleListesiResponse !== undefined) {
            if (getMahalleListesiResponse.status === 200) {
                setMahalleListesi(getMahalleListesiResponse.result)
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const getLanguagesReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getLanguageResponse = await getDoctorLanguages(props.store.baseUrl, props.store.token)
        if (getLanguageResponse !== null && getLanguageResponse !== undefined) {
            if (getLanguageResponse.status === 200) {
                //console.log("getLanguageResponse: ", getLanguageResponse)
                setLanguages(getLanguageResponse.data)
                let languageListResponse = getLanguageResponse.data

                let doctorLanguages = []
                for (let index = 0; index < languageListResponse.length; index++) {
                    const languageTemp = languageListResponse[index];

                    if(props.store.doctor[0].langIds !== undefined) {
                        if (props.store.doctor[0].langIds.includes(languageTemp.langId)) {
                            //console.log("languageTemp.langId:", languageTemp.langId)
                            doctorLanguages.push(languageTemp.langName)
                        }
                    }

                }
                //console.log("doctorLanguages: ", doctorLanguages)
                setSelectedLanguages(doctorLanguages)
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    const onChangeSelectIlMenu = async (event) => {
        await getIlceListesiReq(event.target.value)
        let doktor = toJS(props.store.doctor[0])
        if (!doktor.address) {
            doktor.address = newAddress;
        }
        doktor.address.cityId = Number(event.target.value)
        props.store.setDoctor(doktor)
    }

    const onChangeSelectIlceMenu = async (event) => {
        await getMahalleListesiReq(event.target.value)
        let doktor = toJS(props.store.doctor[0])
        doktor.address.districtId = Number(event.target.value)
        props.store.setDoctor(doktor)
    }

    const onChangeSelectMahalleMenu = (event) => {
        let doktor = toJS(props.store.doctor[0])
        doktor.address.neighbourhoodId = Number(event.target.value)
        props.store.setDoctor(doktor)
    }

    /*const onChangeTextField = (event) => {
        let doktor = toJS(props.store.doctor[0])
        doktor[event.target.name] = event.target.value

        props.store.setDoctor(doktor)
    }*/

    const onChangeAddressTextField = (event) => {
        let doktor = toJS(props.store.doctor[0])
        doktor.address.address = event.target.value
        props.store.setDoctor(doktor)
    }

    const onChangeOzetTanitim = (event) => {
        let doktor = toJS(props.store.doctor[0])
        doktor.shortDesc = event.target.value
        props.store.setDoctor(doktor)
    }

    const onChangeGenelTanitim = (event) => {
        //console.log("event: ", event)
        let doktor = toJS(props.store.doctor[0])
        doktor.longDesc = event
        props.store.setDoctor(doktor)
    }

    const onClickUpdateDoctorInfo = async () => {
        props.store.setLoadingBarStatus(false)
        //console.log("doktor: ", props.store.doctor[0])
        let updatedDoktor = toJS(props.store.doctor[0])
        /*updatedDoktor.shortDesc = ""
        updatedDoktor.longDesc = ""
        updatedDoktor.kase = ""*/

        let putDoctorResponse = await putDoctor(props.store.baseUrl, props.store.token, props.store.doctor[0].id, updatedDoktor)
        //console.log("putDoctorResponse: ", putDoctorResponse)
        if (putDoctorResponse != null && putDoctorResponse !== undefined) {
            if (putDoctorResponse.status === 200) {
                props.store.setMessage("Bilgiler başarıyla güncellendi", true, "success")
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const handleLanguageChange = (event) => {
        // console.log("selected languages: ", event.target.value)
        const {
            target: { value },
        } = event;
        setSelectedLanguages(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

        let languagesArray = typeof value === 'string' ? value.split(',') : value

        let doctorLangIds = []
        for (let index = 0; index < languages.length; index++) {
            const language = languages[index];
            if (languagesArray.includes(language.langName)) {
                doctorLangIds.push(language.langId)
            }
        }
        //console.log("doctorLangIds: ", doctorLangIds)
        let doktor = toJS(props.store.doctor[0])
        doktor.langIds = doctorLangIds
        props.store.setDoctor(doktor)
    }

    // const encryptWithAES = (text) => {
    //     const passphrase = '123';
    //     return CryptoJS.AES.encrypt(text, passphrase).toString();
    // };

    // const decryptWithAES = (ciphertext) => {
    //     const passphrase = '123';
    //     const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);

    //     let originalText = ""
    //     try {
    //         originalText = bytes.toString(CryptoJS.enc.Utf8);
    //     } catch (error) {
    //         //console.log("error: ", error)
    //     }
        
    //     return originalText;
    // };

    return (
        <div className="container-xxl" style={{ marginTop: "0px" }}>
            <Card style={{ marginTop: "8px" }}>
                <CardContent>
                    <Typography color="secondary" variant="h5" style={{ fontWeight: "bold" }}>Profilim</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={6} style={{ padding: "40px" }}>
                            <TextField id="outlined-basic" disabled label="TC Kimlik No" variant="outlined" value={(props.store.doctor[0] !== undefined) ? props.store.doctor[0].tckno : ""} style={{ width: "100%" }} />
                            <TextField id="outlined-basic" disabled label="Adı" variant="outlined" value={(props.store.doctor[0] !== undefined) ? props.store.doctor[0].name : ""} style={{ width: "100%", marginTop: "15px" }} />
                            <TextField id="outlined-basic" disabled label="Soyadı" variant="outlined" value={(props.store.doctor[0] !== undefined) ? props.store.doctor[0].surname : ""} style={{ width: "100%", marginTop: "15px" }} />
                            <FormControl fullWidth style={{ marginTop: "10px" }} >
                                <TextField id="outlined-basic" variant="outlined" name="mobilePhone" disabled
                                    value={(props.store.doctor[0] !== undefined) ? props.store.doctor[0].mobilePhone.toString() : ""}
                                    type='text'
                                    InputProps={{
                                        inputComponent: TextMaskCustom,
                                    }}
                                    label="Cep Telefon Numarası" />
                            </FormControl>

                            <TextField id="outlined-basic" disabled label="E-posta" variant="outlined" value={(props.store.doctor[0] !== undefined) ? props.store.doctor[0].email : ""} style={{ width: "100%", marginTop: "15px" }} />
                            <TextField id="outlined-basic" disabled label="Unvan" variant="outlined" value={(props.store.doctor[0] !== undefined) ? (props.store.doctor[0].title !== null) ? props.store.doctor[0].title.titleName : "" : ""} style={{ width: "100%", marginTop: "15px" }} />
                            <TextField disabled id="outlined-basic" label="Diploma No" variant="outlined" value={(props.store.doctor[0] !== undefined) ? (props.store.doctor[0].diploma !== null) ? props.store.doctor[0].diploma.diplomaNo : "" : ""} style={{ width: "100%", marginTop: "15px" }} />

                            <Typography variant='body1' style={{ fontWeight: "bold", marginTop: "20px" }}>Kaşe Görseli Yükleme</Typography>
                            <UploadKase fileType={2} />
                            <Typography variant='body2' style={{ marginTop: "15px", fontWeight: "bold" }}>Kaşe: </Typography>
                            {(props.store.doctor[0] !== undefined) ? 
                                <img alt="kase" src={props.store.baseUrl + props.store.doctor[0].kase} style={{ width: "250px" }}></img> : ""}
                        </Grid>
                        <Grid item xs={6} style={{ padding: "40px" }}>
                            <FormControl fullWidth>
                                <InputLabel id="city-select-label">İl Bilgisi</InputLabel>
                                <Select labelId="city-select-label" label="İl Bilgisi"
                                    value={(props.store.doctor[0] !== undefined) ? (props.store.doctor[0].address !== null) ? props.store.doctor[0].address.cityId : 0 : 0} id="city-select" style={{ background: "#fff" }}
                                    onChange={onChangeSelectIlMenu} MenuProps={MenuProps}>
                                    <MenuItem key={"emptyil"} value={0}>İl Seçiniz</MenuItem>
                                    {ilListesi.map((il, idx) => (
                                        <MenuItem key={idx} value={il.cityId}>{il.cityName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
                                <InputLabel id="city-select-label">İlçe Bilgisi</InputLabel>
                                <Select labelId="city-select-label" label="İlçe Bilgisi"
                                    value={(props.store.doctor[0] !== undefined) ? (props.store.doctor[0].address !== null) ? props.store.doctor[0].address.districtId : 0 : 0} id="city-select" style={{ background: "#fff" }}
                                    onChange={onChangeSelectIlceMenu} MenuProps={MenuProps}>
                                    <MenuItem key={"emptyil"} value={0}>İlçe Seçiniz</MenuItem>
                                    {ilceListesi.map((ilce, idx) => (
                                        <MenuItem key={idx} value={ilce.districtId}>{ilce.districtName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
                                <InputLabel id="city-select-label">Mahalle Bilgisi</InputLabel>
                                <Select labelId="city-select-label" label="Mahalle Bilgisi"
                                    value={(props.store.doctor[0] !== undefined) ? (props.store.doctor[0].address !== null) ? props.store.doctor[0].address.neighbourhoodId : 0 : 0} id="city-select" style={{ background: "#fff" }}
                                    onChange={onChangeSelectMahalleMenu} MenuProps={MenuProps}>
                                    <MenuItem key={"emptyil"} value={0}>Mahalle Seçiniz</MenuItem>
                                    {mahalleListesi.map((mahalle, idx) => (
                                        <MenuItem key={idx} value={mahalle.neighbourhoodId}>{mahalle?.neighbourhoodName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField name="" id="outlined-basic" label="Adres" variant="outlined" value={(props.store.doctor[0] !== undefined) ? (props.store.doctor[0].address !== null) ? props.store.doctor[0].address.address : "" : ""}
                                style={{ width: "100%", marginTop: "15px" }} onChange={onChangeAddressTextField} />

                            <FormControl sx={{ width: "100%", marginTop: "15px" }}>
                                <InputLabel id="demo-multiple-name-label">Hizmet Verebileceğiniz Diller</InputLabel>
                                <Select labelId="demo-multiple-name-label"
                                    id="demo-multiple-name" multiple value={selectedLanguages}
                                    onChange={handleLanguageChange}
                                    input={<OutlinedInput label="Hizmet Verebileceğiniz Diller" />}
                                    MenuProps={MenuProps}>
                                    {languages.map((language, idx) => (
                                        <MenuItem
                                            key={idx}
                                            value={language.langName}>
                                            {language.langName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <InfoIcon color="primary" style={{ marginTop: "15px" }} />
                            <Typography variant='body2'>
                                Kısa tanıtım, Sağlıkçım uygulamasında danışanlara arama panelinde, kısa tanıtım cümlesi olara gösterilecektir. Danışan detay bilgisine bakmak
                                istediğinde detaylı tanıtım için Özgeçmiş alanında yazdığınız bilgiler danışanlara gösterilecektir.
                            </Typography>
                            <TextField name="" id="outlined-basic" label="Kısa Tanıtım" variant="outlined" value={(props.store.doctor[0] !== undefined) ? (props.store.doctor[0].shortDesc !== null) ? props.store.doctor[0].shortDesc : "" : ""}
                                style={{ width: "100%", marginTop: "15px" }} onChange={onChangeOzetTanitim} />


                            <Typography variant='body1' style={{ marginTop: "25px", fontWeight: "bold" }}>Özgeçmiş</Typography>
                            <TextEditorCommon onTextFieldChange={onChangeGenelTanitim} value={(props.store.doctor[0] !== undefined) ? props.store.doctor[0].longDesc : ""} />

                            <Button variant="contained" color="primary" onClick={onClickUpdateDoctorInfo} style={{ marginTop: "15px", float: "right" }}>
                                Güncelle
                            </Button>
                        </Grid>
                        <Grid item xs={6} style={{ padding: "30px", paddingTop: "0" }}>

                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}))