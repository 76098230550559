import { types } from "mobx-state-tree"

export const Medicine = types
    .model({
        patientId: types.optional(types.number, 0),
        appointmentId: types.optional(types.number, 0),
        doctorId: types.optional(types.number, 0),
        medicineName: types.optional(types.string, ""),
        medicinePeriod: types.optional(types.string, ""),
        medicineDose: types.optional(types.string, ""),
        medicineNumber: types.optional(types.number, 0)
    }).actions(self => ({
    }))

/*
    {
        "medicineId": 0,
        "medicinePeriod": "string",
        "diagnosisId": 0,
        "count": 0,
        "dose": "string",
        "usage": 0
    }
*/