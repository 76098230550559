import { types } from "mobx-state-tree"

export const PatientMedicalInfo = types
    .model({
        medicalInfoTypeName: types.optional(types.string, ""),
        medicalInfoTypeId: types.optional(types.number, 0),
        medicalInfoId: types.optional(types.number, 0),
        description: types.optional(types.string, "")
    }).actions(self => ({

    })) 