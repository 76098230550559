import axios from "axios";
import parsePatientData from '../../parsers/PatientDataParser'

const getDoctorPatientInfo = async (baseUrl, token, doctorId, patientId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + "/doctor/" + doctorId + "/patient/" + patientId, config)
        .then((res) => {
            getResponse = {
                status: res.data.status,
                data: (res.data !== null) ? parsePatientData(res.data.data, patientId) : null
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return getResponse
}

export default getDoctorPatientInfo;