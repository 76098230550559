import { Doctor } from '../../model/Doctor/Doctor'

import parseAddressData from './AddressParser'
import parseDoctorTitleData from './DoctorTitleParser'
import parseDoctorDiplomaData from './DoctorDiplomaParser'
import parseDoctorProfessionalData from './DoctorProfessionalParser'
import parseDoctorDepartmantData from './DoctorDepartmentParser'
import parseDoctorUniversityData from './DoctorUniversityParser'
import parseClinicData from './DoctorClinicParser'

const parseDoctorData = (doctorDataResponse) => {

    let doctor = null
    try {

       //console.log("doctorDataResponse: ", doctorDataResponse.data)

        doctor = Doctor.create({
            id: doctorDataResponse.data.id,
            name: doctorDataResponse.data.name,
            surname: doctorDataResponse.data.surname,
            email: doctorDataResponse.data.email,
            tckno: doctorDataResponse.data.tckno,
            mobilePhone: doctorDataResponse.data.mobilePhone,
            isActive: doctorDataResponse.data.isActive,
            isApproved: doctorDataResponse.data.isApproved,
            avatar: doctorDataResponse.data.avatar,
            userType: doctorDataResponse.data.userType,
            roles: doctorDataResponse.data.roles,
            address: parseAddressData(doctorDataResponse.data.address),
            title: parseDoctorTitleData(doctorDataResponse.data.title),
            diploma: parseDoctorDiplomaData(doctorDataResponse.data.diploma),
            professional: parseDoctorProfessionalData(doctorDataResponse.data.professional),
            departments: parseDoctorDepartmantData(doctorDataResponse.data.department),
            univercity: parseDoctorUniversityData(doctorDataResponse.data.univercity),
            shortDesc: (doctorDataResponse.data.shortDesc !== null) ? doctorDataResponse.data.shortDesc : "",
            longDesc: (doctorDataResponse.data.longDesc !== null) ? doctorDataResponse.data.longDesc : "" ,
            kase: doctorDataResponse.data.kase,
            langIds: doctorDataResponse.data.langIds,
            clinic: parseClinicData(doctorDataResponse.data.clinic),
        })

    } catch (error) {
        console.log("error: ", error)
    }

    return doctor;
}

export default parseDoctorData;

