import { Patient } from '../../model/Patient'
import { PatientMedicalInfo } from '../../model/PatientMedicalInfo'

const parsePatientData = (patientResponse) => {

    let patient = null
    try {

        let medicalInfosArray = patientResponse.medicalInfos

        let medicalInfosArrayFixed = []
        if(medicalInfosArray !== null && medicalInfosArray !== undefined){
            for (let index = 0; index < medicalInfosArray.length; index++) {
                const medicalInfosTemp = medicalInfosArray[index] ;

                let fixedMedicalInfo = PatientMedicalInfo.create({
                    medicalInfoTypeName: (medicalInfosTemp.medicalInfoTypeName !== null) ? medicalInfosTemp.medicalInfoTypeName: "",
                    medicalInfoTypeId: (medicalInfosTemp.medicalInfoTypeId !== null) ? medicalInfosTemp.medicalInfoTypeId: 0,
                    medicalInfoId: (medicalInfosTemp.medicalInfoId !== null) ? medicalInfosTemp.medicalInfoId: 0,
                    description:  (medicalInfosTemp.description !== null) ? medicalInfosTemp.description: "",
                })
                medicalInfosArrayFixed.push(fixedMedicalInfo)
            }
        }

        patient =  Patient.create({
            id: patientResponse.id,
            doctorId: patientResponse.doctorId,
            patientId: patientResponse.patientId,
            name: patientResponse.name,
            surname: patientResponse.surname,
            email: patientResponse.email,
            tckno: patientResponse.tckno,
            mobilePhone: patientResponse.mobilePhone,
            label: patientResponse.name + " " + patientResponse.surname,
            birthDate: (patientResponse.birthDate !== null) ? new Date(patientResponse.birthDate): new Date(),
            gender: patientResponse.gender,
            medicalInfos: medicalInfosArrayFixed,
            height: patientResponse.height,
            weight: patientResponse.weight,
            bloodGroup: patientResponse.bloodGroup,
            isActive: patientResponse.isActive
        })
        
    } catch (error) {
        console.log("error: ", error)
    }
    
    return patient;
} 

export default parsePatientData;