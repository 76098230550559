const drawerWidthMobile = 50;
const drawerWidth = 240;
export const containerStyle = {
	width: {
		xs: `calc(100% - ${drawerWidthMobile + 10}px)`,
		md: `calc(100% - ${drawerWidth + 10}px)`,
	},
	marginLeft: {
		xs: `${drawerWidthMobile + 10}px`,
		md: `${drawerWidth + 10}px`,
	},
	marginTop: "30px",
};
