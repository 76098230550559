import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import { containerStyle } from '../../utils/export-style';

import { LeftMenu } from '../../components/common/LeftMenu';
import { Box, Container, Grid } from '@mui/material';
import { RandevuListesiMenu } from '../../components/doktor/randevu/RandevuListesiMenu';

import getAppointmentListByDate from '../../utils/api/Doctor/Appointment/GetAppoinmentListByDate'
import dayjs from 'dayjs';

class RandevularimPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            passedAppointmentList: null,
            paymentWaitingAppointmentList: null,
            futureAppointmentList: null
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/panel/kullanici-girisi");
        } else {
            this.props.store.setLoadingBarStatus(true)

            //let startDate = dayjs(new Date()).format().split("+")[0].split("T")[0] + "T00:00:01"
            //let endDate = dayjs(this.getLastDayOfYear(new Date().getFullYear())).format().split("+")[0].split("T")[0] + "T23:59:59"

            let startDate = dayjs(new Date()).subtract(1, 'year').format().split("+")[0].split("T")[0] + "T00:00:01"
            let endDate = dayjs(new Date()).add(1, 'year').format().split("+")[0].split("T")[0] + "T23:59:59"

            let getAppointmentListTodayResponse = await getAppointmentListByDate(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, startDate, endDate)
            //console.log("getAppointmentListTodayResponse: ", getAppointmentListTodayResponse)
            if (getAppointmentListTodayResponse !== undefined && getAppointmentListTodayResponse !== null) {
                if (getAppointmentListTodayResponse.status === 200) {

                    let appointmentList = getAppointmentListTodayResponse.data.reverse();

                    let passedAppointmentList = []
                    let futureAppointmentList = []
                    let paymentWaitingList = []

                    for (let index = 0; index < appointmentList.length; index++) {
                        const appointmentTemp = appointmentList[index];
                        //console.log("appointmentTemp: ", appointmentTemp.status)

                        if (dayjs().isAfter(dayjs(appointmentTemp.appointmentDate).add(30, "minute"))) {
                            //if (appointmentTemp.status > 2 && appointmentTemp.status < 8) {
                            passedAppointmentList.push(appointmentTemp)
                            /*} else if (appointmentTemp.status === 9) {
                                paymentWaitingList.push(appointmentTemp)
                            }*/
                        } else {
                            if (appointmentTemp.status > 2 && appointmentTemp.status < 8) {
                                passedAppointmentList.push(appointmentTemp)
                            } else if (appointmentTemp.status === 9) {
                                paymentWaitingList.push(appointmentTemp)
                            } else {
                                futureAppointmentList.push(appointmentTemp)
                            }
                        }
                    }

                    //console.log("paymentWaitingList: ", paymentWaitingList)

                    this.setState({
                        passedAppointmentList: passedAppointmentList,
                        futureAppointmentList: futureAppointmentList,
                        paymentWaitingAppointmentList: paymentWaitingList
                    })
                }else if (getAppointmentListTodayResponse.status === 401) {
                    this.props.store.setUserLogout()
                    window.location.reload()
                }
            }
            this.props.store.setLoadingBarStatus(true)
        }
    }

    getLastDayOfYear(year) {
        return new Date(year, 11, 31);
    }

    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage={""} />
                <Box component="main" sx={{
                    ...containerStyle,
                    flexGrow: 1,
                    py: 8
                }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                                <RandevuListesiMenu passedAppointmentList={this.state.passedAppointmentList}
                                 futureAppointmentList={this.state.futureAppointmentList} paymentWaitingAppointmentList={this.state.paymentWaitingAppointmentList}
                                 history={this.props.history} />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(RandevularimPage));
