import React, { useState, useEffect, useRef } from 'react';
import { observer, inject } from "mobx-react"

import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';

import postAppointmentChat from '../../../utils/api/Doctor/Appointment/Chat/PostAppointmentChat'
import getAppointmentChat from '../../../utils/api/Doctor/Appointment/Chat/GetAppointmentChat'
import UploadAppointmentFile from '../../upload/UploadAppointmentFile';
import FilePreview from '../../common/FilePreview';
import { Link } from '@mui/material';

export const Chat = inject("store")(observer((props) => {

    const messagesEndRef = useRef(null)
    const chatListSize = useRef(0)

    const [chatList, setChatList] = useState([])
    const [chatItem, setChatItem] = useState("")
    const [uploadMenuDisplay, setUploadMenuDisplay] = useState(false)

    useEffect(() => {
        // Update the document title using the browser API
        getChatListReq();

        //eslint-disable-next-line
        setInterval(() => {
            getChatListReq();
            //messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
        }, 5000)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.store.fileMessageUrl !== "") {
            sendFileChatItem(props.store.fileMessageUrl, 2)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.store.fileMessageUrl]);

    const onChangeChatItemTextInput = (event) => {
        setChatItem(event.target.value)
    }

    const onKeyPressHandler = (target) => {
        if (target.charCode === 13) {
            addChatItem(1)
        }
    }

    const getChatListReq = async () => {

        let chatListGetResponse = await getAppointmentChat(props.store.baseUrl, props.store.token,
            props.store.doctor[0].id, props.appointmentId)

        if (chatListGetResponse !== null && chatListGetResponse !== undefined) {
            if (chatListGetResponse.status === 200) {
                setChatList(chatListGetResponse.data.reverse())

                if (chatListSize.current !== chatListGetResponse.data.length) {
                    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
                }

                chatListSize.current = chatListGetResponse.data.length
            }
        }
    }

    const sendFileChatItem = async (fileUrl, messageType) => {
        let chatObject = {
            patientId: props.patientId,
            message: fileUrl,
            messageType: messageType
        }

        let chatCreateItemResponse = await postAppointmentChat(props.store.baseUrl, props.store.token,
            props.store.doctor[0].id, props.appointmentId, chatObject)

        if (chatCreateItemResponse !== null && chatCreateItemResponse !== undefined) {
            if (chatCreateItemResponse.status === 200) {
                await getChatListReq()
                props.store.setFileMessageUrl("")
            }
        }
    }

    const addChatItem = async (messageType) => {
        let chatObject = {
            patientId: props.patientId,
            message: chatItem,
            messageType: messageType
        }

        let chatCreateItemResponse = await postAppointmentChat(props.store.baseUrl, props.store.token,
            props.store.doctor[0].id, props.appointmentId, chatObject)

        if (chatCreateItemResponse !== null && chatCreateItemResponse !== undefined) {
            if (chatCreateItemResponse.status === 200) {
                await getChatListReq()
                setChatItem("")
                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }

    const onClickOpenFileUploadDialog = () => {
        setUploadMenuDisplay(true)
    }

    const onClickCloseFileDialogMenu = () => {
        setUploadMenuDisplay(false)
    }

    const onFileReadError = () => {

    }

    return (
        <>
            <div className="video-chat-container" style={{ background: "#eee", padding: "5px" }}>
                <List>
                    {chatList.map((chat, idx) => (
                        (chat.ownerId === chat.doctorId) ?
                            (
                                (chat.messageType === 1) ?
                                    <ListItem key={idx}>
                                        <Grid container className='chat-message' style={{
                                            background: "#2196f3", color: "#fff",
                                            padding: "5px", paddingLeft: "20px", borderRadius: "10px", width: "50%"
                                        }}>
                                            <Grid item xs={12}>
                                                <ListItemText align="left" primary={chat.message}></ListItemText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle2' style={{ fontSize: "12px" }}>
                                                    {dayjs(new Date(chat.createdDate)).format('YYYY-MM-DD')}
                                                    <b> {dayjs(new Date(chat.createdDate)).format('HH:mm')}</b></Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    :
                                    <ListItem key={idx}>
                                        <Grid container style={{
                                            background: "#2196f3", color: "#fff",
                                            padding: "5px", paddingLeft: "20px", borderRadius: "10px", width: "50%"
                                        }}>
                                            <Grid item xs={12}>
                                                <FilePreview style={{ width: "100%" }}
                                                    type={"file"}
                                                    url={props.store.baseUrl + chat.message}
                                                    onError={onFileReadError}
                                                />
                                                <Tooltip title="Dosyayı İndir">
                                                    <IconButton component={Link} href={props.store.baseUrl + chat.message}
                                                        target={"_blank"}
                                                        style={{ float: "right" }}>
                                                        <DownloadIcon style={{ color: "#fff" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle2' style={{ fontSize: "12px", textAlign: "right" }}>
                                                    {dayjs(new Date(chat.createdDate)).format('YYYY-MM-DD')}
                                                    <b> {dayjs(new Date(chat.createdDate)).format('HH:mm')}</b></Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>)
                            :
                            (chat.messageType === 1) ?
                                <ListItem key={idx} style={{ width: "100%" }}>
                                    <Grid container style={{
                                        background: "#fff",
                                        padding: "5px", paddingRight: "20px", borderRadius: "10px", border: "1px solid #eee", width: "50%", marginLeft: "50%"
                                    }}>
                                        <Grid item xs={12}>
                                            <ListItemText align="right" primary={chat.message}></ListItemText>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle2' style={{ fontSize: "12px", textAlign: "right" }}>
                                                {dayjs(new Date(chat.createdDate)).format('YYYY-MM-DD')}
                                                <b> {dayjs(new Date(chat.createdDate)).format('HH:mm')}</b></Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem> :
                                <ListItem key={idx} style={{ width: "100%" }}>
                                    <Grid container style={{
                                        background: "#fff",
                                        padding: "5px", paddingRight: "20px", borderRadius: "10px", border: "1px solid #eee", width: "50%", marginLeft: "50%"
                                    }}>
                                        <Grid item xs={12}>
                                            <FilePreview style={{ width: "100%" }}
                                                type={"file"}
                                                url={props.store.baseUrl + chat.message}
                                                onError={onFileReadError}
                                            />
                                            <Tooltip title="Dosyayı İndir">
                                                <IconButton component={Link} href={props.store.baseUrl + chat.message}
                                                    target={"_blank"}
                                                    style={{ float: "right" }}>
                                                    <DownloadIcon style={{ color: "#2196f3" }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle2' style={{ fontSize: "12px", textAlign: "right" }}>
                                                {dayjs(new Date(chat.createdDate)).format('YYYY-MM-DD')}
                                                <b> {dayjs(new Date(chat.createdDate)).format('HH:mm')}</b></Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                    ))
                    }

                </List>
                <div ref={messagesEndRef} />
            </div>

            <Grid container spacing={0} style={{ width: "100%", marginTop: "10px" }}>
                <Grid item xs={10}>
                    <TextField label="Mesaj yaz" style={{ width: "100%", borderRadius: "0" }} size="small"
                        value={chatItem} onChange={onChangeChatItemTextInput} onKeyPress={onKeyPressHandler}></TextField>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title="Dosya Gönder">
                        <IconButton onClick={onClickOpenFileUploadDialog}>
                            <AttachFileIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title="Mesaj Gönder">
                        <IconButton onClick={() => addChatItem(1)}>
                            <SendIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <Dialog open={uploadMenuDisplay} onClose={onClickCloseFileDialogMenu} fullWidth={true} maxWidth="sm">
                <AppBar sx={{ position: 'relative', background: "#2196f3" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClickCloseFileDialogMenu}
                            aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Dosya Gönder
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Typography variant='body2'>Buradan görüşme yaptığınız hastaya dosya gönderebilirsiniz</Typography>
                    <UploadAppointmentFile appointmentId={props.appointmentId} patientId={props.patientId} fileType={1} />
                </DialogContent>
            </Dialog>
        </>
    )
}))