import axios from "axios";

const postGoruntulemeTalep = async (baseUrl, token, doctorId, appointmentId, imageTalep) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    await axios.post(baseUrl + `/doctor/` + doctorId + "/appointment/" + appointmentId + "/image", imageTalep, config)
        .then((res) => {
            putResponse = {
                status: res.status,
                result: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    result:  error.response.status
                }
            }
        });
    return putResponse
}

export default postGoruntulemeTalep;