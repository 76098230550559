import React, { useState, useEffect } from 'react'
import { observer, inject } from "mobx-react"
import { toJS } from 'mobx';

import dayjs from 'dayjs';

import { Button, Grid, MenuItem, TextField, Tooltip, Typography, IconButton, Tabs, Tab } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import DialogContent from '@mui/material/DialogContent';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

//import { Appointment } from '../../../model/Appointment';
//import { Patient } from '../../../model/Patient';
//import { Prescription } from '../../../model/Doctor/Prescription';

import getMedicineList from '../../../utils/api/Doctor/GetMedicineList'
import putPrescription from '../../../utils/api/Doctor/Appointment/Prescription/PutPrescription'
import getErecete from '../../../utils/api/Doctor/Integration/GetErecete'
import getMedicineUsageList from '../../../utils/api/Doctor/Appointment/Prescription/GetMedicineUsageList'
import eczanemLogo from './eczanem.png'
import { professionName } from '../../../utils/Roles'

export const ReceteMenu = inject("store")(observer((props) => {

    //const [appointment, setAppointment] = useState(Appointment.create({}))
    //const [patient, setPatient] = useState(Patient.create({}))
    // const [prescription, setPrescription] = useState(Prescription.create({}))
    const [receteMedicineList, setReceteMedicineList] = useState([])
    const [receteSupplementList, setReceteSupplementList] = useState([])

    const [activeSuggestion, setActiveSuggesstions] = useState(0)
    const [filteredSuggestions, setFilteredSuggestions] = useState([])
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [userInput, setUserInput] = useState("")
    const [barcode, setBarcode] = useState("")

    //const [receteDialogMenuDisplay, setReceteDialogMenuDisplay] = useState(false)

    const [medicineId, setMedicineId] = useState(0)
    //const [medicineName, setMedicineName] = useState("")
    const [medicineKutu, setMedicineKutu] = useState(0)
    const [medicineDoz1, setMedicineDoz1] = useState(0)
    const [medicineDoz2, setMedicineDoz2] = useState(0)
    const [medicinePeriyot, setMedicinePeriyot] = useState(0)
    const [medicinePeriyotType, setMedicinePeriyotType] = useState(0)
    const [medicineKullanimSekli, setMedicineKullanimSekli] = useState(0)
    const [medicineKullanimYorum, setMedicineKullanimYorum] = useState("")

    const [ilacKullanimSekli, setIlacKullanimSekli] = useState([])
    const [receteIsExist, setReceteIsExist] = useState(false)
    const [medicineType, setMedicineType] = useState(props.store.permissions.includes(5) ? 1 : 2)

    const [value, setValue] = useState(0)

    useEffect(() => {
        // Update the document title using the browser API
        //setReceteDialogMenuDisplay(props.display)
        getMedicineUsageListReq()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        //console.log("props.prescription: ", props.prescription)

        if (props.prescription !== null) {
            let medicineList = []
            let supplementList = []
            for (let index = 0; index < props.prescription.length; index++) {
                const prescription = props.prescription[index];
                if (prescription.prescriptionType === 1) {
                    medicineList.push(prescription)
                } else {
                    supplementList.push(prescription)
                }

            }

            setReceteMedicineList(medicineList)
            setReceteSupplementList(supplementList)
        }

    }, [props.prescription])

    const getMedicineUsageListReq = async () => {
        let ilacKullanimSekliResponse = await getMedicineUsageList(props.store.baseUrl, props.store.token)
        //console.log("ilacKullanimSekliResponse: ", ilacKullanimSekliResponse)
        if (ilacKullanimSekliResponse !== null && ilacKullanimSekliResponse !== undefined) {
            if (ilacKullanimSekliResponse.status === 200) {
                setIlacKullanimSekli(ilacKullanimSekliResponse.data)
            }
        }

        if (props.prescription !== null) {
            setReceteIsExist(true)
        }
    }

    const onChangeMedicineTextInput = (event) => {

        /*if (event.target.name === "medicineName") {
            setMedicineName(event.target.value)

        } else */
        if (event.target.name === "medicineKutu") {
            setMedicineKutu(event.target.value)
        } else if (event.target.name === "medicineDoz1") {
            setMedicineDoz1(event.target.value)

        } else if (event.target.name === "medicineDoz2") {
            setMedicineDoz2(event.target.value)
        } else if (event.target.name === "medicinePeriyot") {
            setMedicinePeriyot(event.target.value)
        } else if (event.target.name === "medicinePeriyotType") {
            setMedicinePeriyotType(event.target.value)
        } else if (event.target.name === "usage") {
            setMedicineKullanimYorum(event.target.value)
        }

    }

    const addMedicine = () => {
        const canDiagnose = props.store.permissions.includes(2);
        const canMedicine = props.store.permissions.includes(5);
        const canSupplement = props.store.permissions.includes(6);
        if (!canDiagnose || props.diagnosis.diagnosisId !== 0) {
            let medicinePeriyotTypeValue = (medicinePeriyotType === 0) ? "Gün" : (medicinePeriyotType === 1) ? "Hafta" : "Ay"
            let prescription = {
                medicineId: medicineId,
                medicineName: userInput,
                medicinePeriod: medicinePeriyot + " " + medicinePeriyotTypeValue,
                diagnosisId: canDiagnose ? props.diagnosis.diagnosisId : 0,
                count: Number(medicineKutu),
                dose: medicineDoz1 + "x" + medicineDoz2,
                usage: medicineKullanimSekli,
                usageComment: medicineKullanimYorum,
                appointmentid: Number(props.appointmentId),
                barcode: barcode
            }
            if (canMedicine && medicineType === 1) {
                setReceteMedicineList([prescription, ...receteMedicineList])
                resetMedicineInfo();
            } else if (canSupplement && medicineType === 2) {
                setReceteSupplementList([prescription, ...receteSupplementList])
                resetMedicineInfo();
            }
        } else {
            props.store.setMessage("Tanı bilgisi kaydedilmeden reçete oluşturamazsınız.", true, "error")
        }

    }

    const resetMedicineInfo = () => {
        setUserInput("")
        setMedicineKutu(0)
        setMedicineDoz1(0)
        setMedicineDoz2(0)
        setMedicinePeriyot(0)
        setMedicinePeriyotType(0)
        setMedicineKullanimSekli(0)
        setMedicineKullanimYorum("")
        setFilteredSuggestions([])
    }

    const removeMedicine = (index) => {
        //console.log("index: ", index)

        if (medicineType === 1) {
            setReceteMedicineList(receteMedicineList.filter((_, i) => i !== index))
        } else {
            setReceteSupplementList(receteSupplementList.filter((_, i) => i !== index))
        }
    }

    const onChange = async (e) => {
        const userInput = e.target.value;
        setUserInput(e.target.value)

        //console.log("userInput: ", userInput)
        if (userInput.length > 2) {
            let medicineListResponse = await getMedicineList(props.store.baseUrl, props.store.token, props.store.doctor[0].id, medicineType, userInput)
            //console.log("medicineListResponse: ", medicineListResponse)
            let tempMedicineList = medicineListResponse.data

            setFilteredSuggestions(tempMedicineList)

        } else {
            setFilteredSuggestions([])
        }

        setActiveSuggesstions(0)
        setShowSuggestions(true)
    }

    const onClickSelectMedicine = e => {
        //console.log("e.currentTarget.innerText: ", e)
        setActiveSuggesstions(0)
        setFilteredSuggestions([])
        setShowSuggestions(false)
        setUserInput(e.medicineName)
        setBarcode(e.barcode)
        setMedicineId(e.medicineId)
    };

    /*const suggestionsListComponent = () => {
        if (showSuggestions && userInput !== "") {
            if (filteredSuggestions.length) {
                return (
                    <List className="suggestions-medicine">
                        {filteredSuggestions.map((suggestion, index) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <ListItem className={className} key={suggestion.medicineId} onClick={() => onClickSelectMedicine(suggestion)} >
                                    <ListItemButton>
                                        <Typography variant="body2">{suggestion.medicineName}</Typography>
                                    </ListItemButton>
                                </ListItem>)

                        })}
                    </List>
                );
            } else {
                return (
                    <div className="no-suggestions">
                        <em>Kayıt bulunamadı</em>
                    </div>
                )
            }
        }
    }*/

    const onClickSaveRecete = async () => {
        props.store.setLoadingBarStatus(false)

        let prescriptionList = []

        let createMedicineRecete = false
        if (receteMedicineList.length > 0) {
            prescriptionList = toJS(receteMedicineList)
            for (let index = 0; index < prescriptionList.length; index++) {
                const prescriptionTemp = prescriptionList[index];
                //console.log("prescriptionTemp: ", prescriptionTemp)
                delete prescriptionTemp.medicineName
                delete prescriptionTemp.appointmentid
                delete prescriptionTemp.barcode
                prescriptionTemp.prescriptionType = 1
            }
        }

        //console.log("receteMedicineList: ", receteMedicineList)
        //console.log("receteSupplementList: ", receteSupplementList)

        if (receteSupplementList.length > 0) {
            let supplementList = toJS(receteSupplementList)

            for (let index = 0; index < supplementList.length; index++) {
                const supplementTemp = supplementList[index];
                //console.log("prescriptionTemp: ", prescriptionTemp)
                delete supplementTemp.medicineName
                delete supplementTemp.appointmentid
                delete supplementTemp.barcode
                supplementTemp.prescriptionType = 6
                
                prescriptionList.push(supplementTemp)
            }
        }

        if (prescriptionList !== null) {
            let createReceteResponse = await putPrescription(props.store.baseUrl, props.store.token, props.store.doctor[0].id,
                props.appointmentId, props.patient.patientId, prescriptionList)

            if (createReceteResponse !== null && createReceteResponse !== undefined) {
                if (createReceteResponse.status === 200) {
                    //props.store.setMessage("Reçete Başarıyla Oluşturuldu.", true, "success")
                    createMedicineRecete = true
                } else {
                    props.store.setMessage("Reçete oluşturulurken hata oluştu.", true, "error")
                }
            }
        }

        if (createMedicineRecete) {
            //setReceteKayitDurum(true)
            setReceteIsExist(true)
        }

        props.store.setLoadingBarStatus(true)
    }

    const onClickUpdateRecete = async () => {
        let prescriptionList = [...toJS(receteMedicineList)]

        let fixedPrescriptionList = []
        if (receteMedicineList.length > 0) {
            for (let index = 0; index < prescriptionList.length; index++) {
                const prescriptionTemp = JSON.parse(JSON.stringify(prescriptionList[index]));
                prescriptionTemp.diagnosisId = props.diagnosis.diagnosisId
                delete prescriptionTemp.medicineName
                delete prescriptionTemp.appointmentid
                delete prescriptionTemp.barcode

                delete prescriptionTemp.atcCode
                delete prescriptionTemp.atcName
                delete prescriptionTemp.diagnosisCode
                delete prescriptionTemp.diagnosisMainCode
                delete prescriptionTemp.diagnosisName
                delete prescriptionTemp.doctorName
                delete prescriptionTemp.doctorSurname
                delete prescriptionTemp.firmName
                delete prescriptionTemp.patientName
                delete prescriptionTemp.patientSurname

                prescriptionTemp.prescriptionType = 1

                fixedPrescriptionList.push(prescriptionTemp)
            }
        }

        if (receteSupplementList.length > 0) {
            let supplementList = toJS(receteSupplementList)

            for (let index = 0; index < supplementList.length; index++) {
                const supplementTemp = supplementList[index];
                //console.log("prescriptionTemp: ", prescriptionTemp)
                delete supplementTemp.medicineName
                delete supplementTemp.appointmentid
                delete supplementTemp.barcode
                supplementTemp.prescriptionType = 6

                fixedPrescriptionList.push(supplementTemp)
            }
        }

        if (fixedPrescriptionList !== null) {
            let createReceteResponse = await putPrescription(props.store.baseUrl, props.store.token, props.store.doctor[0].id,
                props.appointmentId, props.patient.patientId, fixedPrescriptionList)

            if (createReceteResponse !== null && createReceteResponse !== undefined) {
                if (createReceteResponse.status === 200) {
                    props.store.setMessage("Reçete başarıyla güncellendi", true, "success")
                } else {
                    props.store.setMessage("Reçete oluşturulurken hata oluştu.", true, "error")
                }
            }
        }

        //console.log("prescriptionList: ", fixedPrescriptionList)
    }

    const onClickEreceteButton = async () => {
        props.store.setLoadingBarStatus(false)

        let eReceteCreateResponse = await getErecete(props.store.baseUrl, props.store.token, props.store.doctor[0].id, props.appointmentId, props.patient.patientId)
        if (eReceteCreateResponse !== null && eReceteCreateResponse !== undefined) {
            //console.log("eReceteCreateResponse: ", eReceteCreateResponse)
            if (eReceteCreateResponse.status === 200) {
                window.open(eReceteCreateResponse.data, '_blank');
            } else {
                props.store.setMessage("E-reçete ekranına yönlendirilirken hata oluştu.", true, "error")
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    const closeReceteMenuDialog = () => {
        props.store.setReceteDialogMenuDisplay(false)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
        if (newValue === 0) {
            setMedicineType(1)
            setUserInput("")
            setMedicineDoz1(0)
            setMedicineDoz2(0)
            setMedicinePeriyot(0)
            setMedicinePeriyotType(0)
            setMedicineKutu(0)
            setMedicineKullanimSekli(0)
            setMedicineKullanimYorum("")

        } else {
            setMedicineType(2)
            setUserInput("")
            setMedicineDoz1(0)
            setMedicineDoz2(0)
            setMedicinePeriyot(0)
            setMedicinePeriyotType(0)
            setMedicineKutu(0)
            setMedicineKullanimSekli(0)
            setMedicineKullanimYorum("")
        }
    }

    return (
        <>
            <Dialog fullWidth={true} maxWidth={"lg"} open={props.store.receteDialogMenuDisplay} fullScreen>
                <AppBar sx={{ position: 'relative', background: "#2196f3" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={closeReceteMenuDialog}
                            aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Reçete
                        </Typography>
                        <Button color="success" variant='contained' disabled={(receteMedicineList.length > 0) ? false : true}
                            style={{ marginRight: "10px" }} onClick={onClickEreceteButton}>
                            E-REÇETE SAYFASINA GİT
                        </Button>

                        {(props.prescription === null && receteIsExist === false) ?
                            <Button color="info" variant='contained' onClick={onClickSaveRecete}
                                style={{ float: "right" }}>  REÇETE OLUŞTUR</Button>
                            : <Button color="info" variant='contained' onClick={onClickUpdateRecete}
                                style={{ float: "right" }}>  REÇETEYİ GÜNCELLE </Button>
                        }

                    </Toolbar>
                </AppBar>
                <DialogContent>

                    <Grid container style={{ width: "100%", border: "1px solid gray", padding: "10px" }}>
                        <Grid item xs={4}>
                            <Grid container spacing={1} >
                                <Grid item xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: "bold" }}>
                                        {`${professionName(props.store.doctorType)} Bilgileri`} </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Adı Soyadı:</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant='body2'>{(props.store.doctor[0] !== undefined) ?
                                        ((props.store.doctor[0].title !== null) ? props.store.doctor[0].title.titleName : "")
                                        + " " +
                                        ((props.store.doctor[0].professional !== null) ? props.store.doctor[0].professional.professionalName : "")
                                        + " " + props.store.doctor[0].name + " " + props.store.doctor[0].surname : ""}</Typography>
                                </Grid>
                                {props.store.doctor[0]?.address && <>
                                    <Grid item xs={5}>
                                        <Typography variant='body2' style={{ fontWeight: "bold" }}>İletişim Bilgileri:</Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant='body2'>{(props.store.doctor[0] !== undefined) ?
                                            props.store.doctor[0]?.address?.neighbourhoodName + " " +
                                            props.store.doctor[0]?.address?.address + " " + props.store.doctor[0].address?.districtName + "/" +
                                            props.store.doctor[0]?.address?.cityName : ""} <br />
                                            {(props.store.doctor[0] !== undefined) ? props.store.doctor[0].mobilePhone : ""} <br />
                                            {(props.store.doctor[0] !== undefined) ? props.store.doctor[0].email : ""}</Typography>
                                    </Grid>
                                </>}
                                <Grid item xs={5}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Uzmanlık Bilgisi:</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant='body2'>{(props.store.doctor[0] !== undefined) ? (props.store.doctor[0].professional !== null) ? props.store.doctor[0].professional.professionalName : "" : ""}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Diploma No:</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant='body2'>{(props.store.doctor[0] !== undefined) ? (props.store.doctor[0].diploma !== null) ? props.store.doctor[0].diploma.diplomaNo : "" : ""}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "center" }}>
                            <img alt="kase" src={props.store.baseUrl + props.store.doctor[0]?.kase} style={{ width: "250px" }}></img>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: "bold" }}>Hasta Bilgileri</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>T.C. No:</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant='body2'>{(props.patient.id !== 0) ? props.patient.tckno : ""}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Hasta Adı Soyadı:</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant='body2'>{(props.patient.id !== 0) ? props.patient.name + " " + props.patient.surname : ""}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Doğum Tarihi:</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    {
                                        <Typography variant='body2'>{(props.patient.id !== 0) ? dayjs(new Date(props.patient.birthDate)).format("DD.MM.YYYY") : ""}</Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} style={{ width: "100%", marginTop: "15px", border: "1px solid gray", padding: "10px" }}>
                        <Grid item xs={12}>
                            <Typography variant='body2'><b>Tanı: </b>
                                {(props.diagnosis.diagnosisId !== 0) ?
                                    (props.diagnosis.diagnosisType === 3) ? "(Ön Tanı) " + props.diagnosis.diagnosisCode + " " + props.diagnosis.diagnosisName + " " + props.diagnosis.diagnosisDesc :
                                        "(Kesin Tanı) " + props.diagnosis.diagnosisCode + " " + props.diagnosis.diagnosisName
                                    : ""}</Typography>
                            <Typography variant='body2'>
                                <b>Açıklama:</b> {props.diagnosis.diagnosisDesc}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={0} style={{ width: "100%", marginTop: "15px", border: "1px solid gray", padding: "10px" }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            {(props.store.permissions.includes(5)) ? <Tab label="İLAÇ" /> : false}
                            <Tab label="GIDA TAKVİYESİ" />
                        </Tabs>
                        <Grid item xs={12} style={{ marginTop: "15px" }}>
                            <Typography variant='body2'><b>İlaçlar: </b></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} style={{ width: "100%", padding: "25px", background: "#f3f4f6", margin: "0" }}>
                                <Grid item xs={4}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>{(medicineType === 1) ? "İlaç Adı" : "Gıda Takviyesi Adı"}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Doz1 x</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Doz2</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Kullanım Periyotu</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Kutu</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Kullanım Şekli</Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField onChange={onChange} value={userInput} style={{ width: "100%", background: "#fff" }} />
                                    {(showSuggestions && userInput) ?
                                        (filteredSuggestions.length > 0) ?
                                            <List className="suggestions-medicine">
                                                {filteredSuggestions.map((suggestion, index) => {
                                                    let className;
                                                    // Flag the active suggestion with a class
                                                    if (index === activeSuggestion) {
                                                        className = "suggestion-active";
                                                    }
                                                    return (
                                                        <ListItem className={className} key={suggestion.medicineId} onClick={() => onClickSelectMedicine(suggestion)} >
                                                            <ListItemButton>
                                                                <Typography variant="body2" style={{ marginInlineEnd: '8px' }}>{suggestion.medicineName}</Typography>
                                                                {suggestion.link !== null && <img alt="eczanem.saglikcim.net" src={eczanemLogo} style={{ width: '42px', height: '42px', marginInlineStart: 'auto' }} />}
                                                            </ListItemButton>
                                                        </ListItem>)
                                                })}
                                            </List>
                                            : <div className="no-suggestions">
                                                <em>Kayıt bulunamadı</em>
                                            </div> : null
                                    }
                                </Grid>

                                <Grid item xs={1}>
                                    <TextField name="medicineDoz1" value={medicineDoz1} variant="outlined"
                                        style={{ width: "100%", background: "#fff", borderRadius: "9px" }} onChange={onChangeMedicineTextInput} />
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField name="medicineDoz2" value={medicineDoz2} variant="outlined"
                                        style={{ width: "100%", background: "#fff", borderRadius: "9px" }} onChange={onChangeMedicineTextInput} />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField name="medicinePeriyot" value={medicinePeriyot} variant="outlined"
                                        style={{ width: "50%", background: "#fff", borderRadius: "9px" }} onChange={onChangeMedicineTextInput} />
                                    <TextField
                                        select name="medicinePeriyotType"

                                        value={medicinePeriyotType}
                                        onChange={onChangeMedicineTextInput}
                                        style={{ width: "50%", background: "#fff", borderRadius: "9px" }} >
                                        <MenuItem key="gun" value={0}>
                                            Gün
                                        </MenuItem>
                                        <MenuItem key="hafta" value={1}>
                                            Hafta
                                        </MenuItem>
                                        <MenuItem key="ay" value={2}>
                                            Ay
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField name="medicineKutu" value={medicineKutu} variant="outlined"
                                        style={{ width: "100%", background: "#fff", borderRadius: "9px" }} onChange={onChangeMedicineTextInput} />
                                </Grid>

                                <Grid item xs={2}>
                                    <TextField name="usage" value={medicineKullanimYorum} variant="outlined" inputProps={{ maxLength: 50 }}
                                        style={{ width: "100%", background: "#fff", borderRadius: "9px" }} onChange={onChangeMedicineTextInput} />                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip title="İlaç Ekle">
                                        <Button color="primary" style={{ height: "50px", float: "right" }} variant="contained" onClick={addMedicine}>
                                            <AddIcon />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer style={{ display: (medicineType === 1) ? "block" : "none" }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" style={{ paddingLeft: "50px" }}>İLAÇ ADI</TableCell>
                                            <TableCell align="left">DOZ</TableCell>
                                            <TableCell align="left">PERİYOT</TableCell>
                                            <TableCell align="left">KUTU</TableCell>
                                            <TableCell align="left">KULLANIM ŞEKLİ</TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(receteMedicineList !== null) ? receteMedicineList.map((medicine, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell align="left" style={{ paddingLeft: "50px", borderBottom: "1px solid #d2d2d2" }}>
                                                    <Typography>
                                                        {medicine.medicineName}<br />
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left" style={{ borderBottom: "1px solid #d2d2d2" }}>
                                                    <Typography>{medicine.dose}</Typography>
                                                </TableCell>
                                                <TableCell align="left" style={{ borderBottom: "1px solid #d2d2d2" }}>
                                                    <Typography>{medicine.medicinePeriod} </Typography>
                                                </TableCell>
                                                <TableCell align="left" style={{ borderBottom: "1px solid #d2d2d2" }}>
                                                    <Typography>{medicine.count}</Typography>
                                                </TableCell>
                                                <TableCell align="left" style={{ borderBottom: "1px solid #d2d2d2" }}>
                                                    {medicine.usageComment ? <Typography>{medicine.usageComment}</Typography> : 
                                                        ilacKullanimSekli.map((kullanimSekli) => (
                                                            (medicine.usage === Number(kullanimSekli.usageId)) ? kullanimSekli.usageName : ""
                                                        ))
                                                    }
                                                </TableCell>
                                                <TableCell align="left" style={{ borderBottom: "1px solid #d2d2d2" }}>
                                                    <Tooltip title="İlacı Listeden Kaldır">
                                                        <Button color="error" style={{ height: "50px", marginTop: "10px" }} variant="contained" onClick={() => removeMedicine(idx)}>
                                                            <CloseIcon />
                                                        </Button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        )) : null
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer style={{ display: (medicineType === 2) ? "block" : "none" }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" style={{ paddingLeft: "50px" }}>GIDA TAKVİYESİ ADI</TableCell>
                                            <TableCell align="left">DOZ</TableCell>
                                            <TableCell align="left">PERİYOT</TableCell>
                                            <TableCell align="left">KUTU</TableCell>
                                            <TableCell align="left">KULLANIM ŞEKLİ</TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(receteSupplementList !== null) ? receteSupplementList.map((medicine, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell align="left" style={{ paddingLeft: "50px", borderBottom: "1px solid #d2d2d2" }}>
                                                    <Typography>
                                                        {medicine.medicineName}<br />
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left" style={{ borderBottom: "1px solid #d2d2d2" }}>
                                                    <Typography>{medicine.dose}</Typography>
                                                </TableCell>
                                                <TableCell align="left" style={{ borderBottom: "1px solid #d2d2d2" }}>
                                                    <Typography>{medicine.medicinePeriod} </Typography>
                                                </TableCell>
                                                <TableCell align="left" style={{ borderBottom: "1px solid #d2d2d2" }}>
                                                    <Typography>{medicine.count}</Typography>
                                                </TableCell>
                                                <TableCell align="left" style={{ borderBottom: "1px solid #d2d2d2" }}>
                                                    {medicine.usageComment ? <Typography>{medicine.usageComment}</Typography> : 
                                                        ilacKullanimSekli.map((kullanimSekli) => (
                                                            (medicine.usage === Number(kullanimSekli.usageId)) ? kullanimSekli.usageName : ""
                                                        ))
                                                    }
                                                </TableCell>
                                                <TableCell align="left" style={{ borderBottom: "1px solid #d2d2d2" }}>
                                                    <Tooltip title="İlacı Listeden Kaldır">
                                                        <Button color="error" style={{ height: "50px", marginTop: "10px" }} variant="contained" onClick={() => removeMedicine(idx)}>
                                                            <CloseIcon />
                                                        </Button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        )) : null
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>

        </>
    )
}))