import { types } from "mobx-state-tree";

const Address = types.model({
    cityId: types.optional(types.number, 0),
    districtId: types.optional(types.number, 0),
    neighbourhoodId: types.optional(types.number, 0),
    address: types.optional(types.string, "")
});

export const Clinic = types.model({
    clinicId: types.optional(types.number, 0),
    doctorId: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
    address: types.optional(Address, {})
}).actions(self => ({

}));
