import { types } from "mobx-state-tree"
import { PatientMedicalInfo } from "./PatientMedicalInfo"

export const Patient = types
    .model({
        id: types.maybeNull(types.optional(types.number, 0)),
        doctorId: types.maybeNull(types.optional(types.number, 0)),
        patientId: types.maybeNull(types.optional(types.number, 0)),
        name: types.maybeNull(types.optional(types.string,"")),
        surname: types.maybeNull(types.optional(types.string, "")),
        email: types.maybeNull(types.optional(types.string, "")),
        tckno: types.maybeNull(types.optional(types.number, 0)),
        mobilePhone: types.maybeNull(types.optional(types.number, 0)),
        label: types.maybeNull(types.optional(types.string, "")),
        birthDate: types.maybeNull(types.optional(types.Date, new Date())),
        gender: types.maybeNull(types.optional(types.number, 0)),
        medicalInfos: types.maybeNull(types.optional(types.array(PatientMedicalInfo), [])),
        height: types.maybeNull(types.optional(types.number, 0)),
        weight: types.maybeNull(types.optional(types.number, 0)),
        bloodGroup: types.maybeNull(types.optional(types.number, 0)),
        isActive: types.maybeNull(types.optional(types.boolean, false))
    }).actions(self => ({

    }))