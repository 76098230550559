import React, { useState } from "react";
import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import CalendarMonth from "@mui/icons-material/CalendarMonth";

export const TakvimBox = (props) => {
	const [isCalendar, setIsCalendar] = useState(false);

	const handleCalendar = () => {
		props.handleiscalendar(!isCalendar);
		setIsCalendar(!isCalendar);
	};

	return (
		<Card onClick={handleCalendar} sx={{ height: "100%", cursor: "pointer" }} {...props}>
			<CardContent>
				<Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
					<Grid item xs={9}>
						<Typography color="textPrimary" variant="h4">
							{isCalendar ? "Tablo Görünümüne Geç" : "Takvim Görünümüne Geç"}
						</Typography>
					</Grid>
					<Grid item xs={3}>
						<Avatar
							sx={{
								backgroundColor: "#f50057",
								height: 56,
								width: 56,
								marginInlineStart: "auto",
							}}
						>
							<CalendarMonth />
						</Avatar>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
