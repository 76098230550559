
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React from 'react'
import { observer, inject } from "mobx-react"

import { Button, IconButton } from '@mui/material';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import './styles.css'

const MenuBar = ({ editor }) => {
    if (!editor) {
        return null
    }

    return (
        <>
            <IconButton size="small"
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .toggleBold()
                        .run()
                }
                className={editor.isActive('bold') ? 'is-active' : ''}
            >
                <FormatBoldIcon />
            </IconButton>
            <IconButton size="small"
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .toggleItalic()
                        .run()
                }
                className={editor.isActive('italic') ? 'is-active' : ''}
            >
                <FormatItalicIcon />
            </IconButton>
            {
                /**
                 <button
                onClick={() => editor.chain().focus().toggleStrike().run()}
                disabled={
                  !editor.can()
                    .chain()
                    .focus()
                    .toggleStrike()
                    .run()
                }
                className={editor.isActive('strike') ? 'is-active' : ''}
              >
                strike
              </button>
                

                <IconButton
                    onClick={() => editor.chain().focus().toggleCode().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleCode()
                            .run()
                    }
                    className={editor.isActive('code') ? 'is-active' : ''}
                >
                    <FormatClearIcon />
                </IconButton> 
                 <button onClick={() => editor.chain().focus().clearNodes().run()}>
                    clear nodes
                </button>

                <button
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'is-active' : ''}
            >
                bullet list
            </button>
            <button
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'is-active' : ''}
            >
                ordered list
            </button>
            <button
                onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                className={editor.isActive('codeBlock') ? 'is-active' : ''}
            >
                code block
            </button>
            <button
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}
            >
                blockquote
            </button>

             <button onClick={() => editor.chain().focus().setHardBreak().run()}>
                hard break
            </button>

             <IconButton onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                <HorizontalRuleIcon />
            </IconButton>

            <IconButton style={{ fontSize: "16px" }} size="small"
                onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}>
                Başlık 4
            </IconButton>
                */
            }
            <IconButton onClick={() => editor.chain().focus().unsetAllMarks().run()}>
                <FormatClearIcon />
            </IconButton>

            <IconButton style={{ fontSize: "16px" }} size="small"
                onClick={() => editor.chain().focus().setParagraph().run()} className={editor.isActive('paragraph') ? 'is-active' : ''}>
                <LocalParkingIcon />
            </IconButton>

            <Button style={{fontSize: "14px", color: "gray"}} size="small"
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''} >
                Başlık 1
            </Button>
            <Button size="small"
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                style={{fontSize: "14px", color: "gray"}}>
                Başlık 2
            </Button>
            <Button style={{fontSize: "14px", color: "gray"}} size="small"
                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}>
                Başlık 3
            </Button>
            <IconButton
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'is-active' : ''}
            >
                <FormatListBulletedIcon/>
            </IconButton>
            <IconButton onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'is-active' : ''}>
                <FormatListNumberedIcon />
            </IconButton>

            <IconButton onClick={() => editor.chain().focus().undo().run()} disabled={
                !editor.can()
                    .chain()
                    .focus()
                    .undo()
                    .run()
            } >
                <UndoIcon />
            </IconButton>
            <IconButton
                onClick={() => editor.chain().focus().redo().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .redo()
                        .run()
                }
            >
                <RedoIcon />
            </IconButton>
        </>
    )
}

const TextEditorPrivateNote = inject("store")(observer((props) => {

    const editor = useEditor({
        extensions: [
            StarterKit,
        ],
        content: props.store.selectedDoctorPatientPrivateNote,
        onUpdate: ({ editor }) => {
            const html = editor.getHTML()
            props.store.setSelectedDoctorPatientPrivateNote(html)
            // send the content to an API here
        },
    })

    return (
        <div>
            <MenuBar editor={editor} />
            <EditorContent editor={editor} />
        </div>
    )
}))

export default TextEditorPrivateNote
