import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";

import { DataGrid } from "@mui/x-data-grid";
import { trTR } from "@mui/x-data-grid/locales";
import { Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Paper sx={{ p: 3 }} style={{ height: "calc(100vh - 200px)" }}>
					{children}
				</Paper>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

export const KuponListesi = inject("store")(
	observer((props) => {
		const [activeData, setActiveData] = useState([]);
		const [expiredData, setExpiredData] = useState([]);
		const [tabValue, setTabValue] = useState(0);

		useEffect(() => {
			if (props.active.length > 0) {
				setActiveData(props.active);
			}
			if (props.expired.length > 0) {
				setExpiredData(props.expired);
			}
		}, [props.active, props.expired]);

		const activeColumns = [
			{
				field: "id",
				headerName: "#",
				flex: 1,
				sortable: false,
				renderCell: (id) => activeData.findIndex((data) => data === id.row) + 1,
			},
			{
				field: "couponCode",
				headerName: "Kupon Kodu",
				flex: 4,
				sortable: false,
			},
			{
				field: "createdDate",
				headerName: "Oluşturma Tarihi",
				flex: 4,
				renderCell: (dateString) => {
					const date = new Date(dateString.value);
					return date.toLocaleString("tr-TR");
				},
			},
		];

		const expiredColumns = [
			{
				field: "id",
				headerName: "#",
				flex: 1,
				sortable: false,
				renderCell: (id) => expiredData.findIndex((data) => data === id.row) + 1,
			},
			{
				field: "couponCode",
				headerName: "Kupon Kodu",
				flex: 3,
				sortable: false,
			},
			{
				field: "createdDate",
				headerName: "Oluşturma Tarihi",
				flex: 3,
				renderCell: (dateString) => {
					const date = new Date(dateString.value);
					return date.toLocaleString("tr-TR");
				},
			},
			{
				field: "dateUsed",
				headerName: "Kullanma Tarihi",
				flex: 3,
				renderCell: (dateString) => {
					const date = new Date(dateString.value);
					return date.toLocaleString("tr-TR");
				},
			},
		];

		const handleKuponTabChange = (event, newValue) => {
			setTabValue(newValue);
		};

		return (
			<>
				<Grid container spacing={3}>
					<Grid item xs={8}>
						<Typography color="secondary" variant="h5" style={{ fontWeight: "bold" }}>
							Kuponlarım
						</Typography>
					</Grid>
					{/* <Grid item xs={4}>
						<Button
							variant="contained"
							color="primary"
							style={{ float: "right" }}
							onClick={handleAddConsultantDoctorMenuOpen}
						>
							<IoPersonAddSharp style={{ marginRight: "10px" }} /> Kupon Satın Al
						</Button>
					</Grid> */}
				</Grid>

				<Tabs value={tabValue} onChange={handleKuponTabChange} aria-label="tabs">
					<Tab label="AKTİF KUPONLAR" />
					<Tab label="KULLANILAN KUPONLAR" />
				</Tabs>

				<TabPanel value={tabValue} index={0}>
					<DataGrid
						rows={activeData}
						columns={activeColumns}
						disableSelectionOnClick
						localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
						color="primary"
						style={{ border: "0" }}
					/>
				</TabPanel>

				<TabPanel value={tabValue} index={1}>
					<DataGrid
						rows={expiredData}
						columns={expiredColumns}
						disableSelectionOnClick
						localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
						color="primary"
						style={{ border: "0" }}
					/>
				</TabPanel>
			</>
		);
	})
);
