import axios from "axios";

import parseAppointmentData from '../../../parsers/AppointmentDataParser'

const getAppointmentList = async (baseUrl, token, doctorId, startDate = null, endDate = null) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null
    let constUrl = "";

    if (startDate || endDate) {
        constUrl = baseUrl + "/doctor/" + doctorId + "/appointment/?" + (startDate ? endDate ? "s=" + startDate + "&e=" + endDate : "s=" + startDate : "e=" + endDate) 
    } else {
        constUrl = baseUrl + "/doctor/" + doctorId + "/appointment"
    }

    await axios.get(constUrl, config)
        .then((res) => {
            let appointmentList = res.data.data

            let fixedAppointmentList = []
            for (let index = 0; index < appointmentList.length; index++) {
                const tempAppointment = appointmentList[index];
                fixedAppointmentList.push(parseAppointmentData(tempAppointment))
            }
            getResponse = {
                status: res.data.status,
                data: fixedAppointmentList
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return getResponse
}

export default getAppointmentList;