import * as React from "react";
import { observer, inject } from "mobx-react";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MapissoAlert from "./MapissoAlert";

import { FaRegUserCircle, FaCog, FaRegUser } from "react-icons/fa";

import { FiLogOut } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";

import { Avatar, Fab, IconButton, LinearProgress, Link, Tooltip } from "@mui/material";

export const TopBarMenu = inject("store")(
	observer((props) => {
		const [profileMenuDisplay, setProfileMenuDisplay] = React.useState(false);
		const [profileMenuTarget, setProfileMenuTarget] = React.useState(null);

		const onHandleProfileMenuOpen = (event) => {
			setProfileMenuDisplay(true);
			setProfileMenuTarget(event.currentTarget);
		};

		const onClickProfileMenuClose = () => {
			setProfileMenuDisplay(false);
			setProfileMenuTarget(null);
		};

		const onLogout = () => {
			props.store.setUserLogout();
			window.location.reload();
		};

		return (
			<>
				<Box sx={{ display: "flex" }}>
					<AppBar position="fixed" sx={{ width: "100%" }}>
						<Toolbar key="topMenu">
							<a href="/panel">
								<img
									src="/assets/images/wholemedic-logo.png"
									alt="wholemedics"
									style={{ width: "200px", marginRight: "50px" }}
								/>
							</a>

							<Typography color="secondary" style={{ fontWeight: "bold" }}>
								{props.meeting ? "Görüşme Paneli" : "Sağlık Çalışanı Paneli"}
							</Typography>

							{!props.meeting && (
								<>
									<Tooltip title="Ayarlar" placement="bottom">
										<IconButton
											aria-label="profil"
											size="medium"
											className="profile-menu"
											style={{ marginRight: "45px", boxShadow: "none" }}
											href="/panel/ayarlar"
											component={Link}
										>
											<FaCog />
										</IconButton>
									</Tooltip>

									<Tooltip title="Kullanıcı Menüsü" placement="bottom">
										<Fab
											variant="extended"
											className="profile-menu"
											color="primary"
											onClick={(event) => onHandleProfileMenuOpen(event)}
											style={{ paddingLeft: "5px" }}
										>
											{props.store.doctor[0]?.avatar === null ? (
												<FaRegUser
													style={{ fontSize: "25px", marginRight: "5px" }}
												/>
											) : (
												<Avatar
													alt="Remy Sharp"
													src={
														props.store.baseUrl +
														props.store.doctor[0]?.avatar
													}
													style={{ marginRight: "5px" }}
												/>
											)}
											{(props.store.doctor[0]?.name !== undefined
												? props.store.doctor[0]?.name
												: "") +
												" " +
												(props.store.doctor[0]?.surname !== undefined
													? props.store.doctor[0]?.surname
													: "")}
											<IoIosArrowDown
												style={{ fontSize: "15px", marginLeft: "5px" }}
											/>
										</Fab>
									</Tooltip>
								</>
							)}
							{props.meeting ? (
								!props.hideLeave && (
									<MenuItem
										onClick={() => onLogout()}
										style={{
											padding: "13px",
											marginLeft: "auto",
										}}
									>
										<FiLogOut
											style={{
												color: "#D14343",
												fontSize: "20px",
												marginRight: "5px",
											}}
										/>
										<Typography
											variant="body1"
											style={{ fontWeight: "600", color: "#D14343" }}
										>
											Çıkış Yap
										</Typography>
									</MenuItem>
								)
							) : (
								<Menu
									id="profile-menu"
									anchorEl={profileMenuTarget}
									open={profileMenuDisplay}
									onClose={onClickProfileMenuClose}
									style={{ marginTop: "15px" }}
								>
									<MenuItem
										href="/panel/ayarlar"
										component={Link}
										style={{
											width: "175px",
											textAlign: "center",
											padding: "13px",
										}}
									>
										<FaCog style={{ fontSize: "20px", marginRight: "5px" }} />{" "}
										<Typography variant="body1" style={{ fontWeight: "400" }}>
											Ayarlar
										</Typography>
									</MenuItem>
									<MenuItem
										href="/panel/profilim"
										component={Link}
										style={{
											width: "175px",
											textAlign: "center",
											padding: "13px",
										}}
									>
										<FaRegUserCircle
											style={{ fontSize: "20px", marginRight: "5px" }}
										/>{" "}
										<Typography variant="body1" style={{ fontWeight: "400" }}>
											Profilim
										</Typography>
									</MenuItem>
									{!props.hideLeave && (
										<MenuItem
											onClick={() => onLogout()}
											style={{
												width: "175px",
												textAlign: "center",
												padding: "13px",
											}}
										>
											<FiLogOut
												style={{
													color: "#D14343",
													fontSize: "20px",
													marginRight: "5px",
												}}
											/>
											<Typography
												variant="body1"
												style={{ fontWeight: "600", color: "#D14343" }}
											>
												Çıkış Yap
											</Typography>
										</MenuItem>
									)}
								</Menu>
							)}
						</Toolbar>
						<div
							style={{
								position: "absolute",
								height: "5px",
								width: "100%",
								zIndex: "5000",
							}}
						>
							<LinearProgress
								style={{ display: props.store.loadingBarStatus ? "none" : "block" }}
								color="primary"
							/>
						</div>
					</AppBar>

					<MapissoAlert />
				</Box>
			</>
		);
	})
);
