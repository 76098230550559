const parseClinicData = (inputClinic) => {
    let clinic = null;
    try {
        clinic = {
            clinicId: inputClinic.clinicId || 0,
            doctorId: inputClinic.doctorId || 0,
            name: inputClinic.name || "",
            address: {
                cityId: inputClinic.address.cityId || 0,
                districtId: inputClinic.address.districtId || 0,
                neighbourhoodId: inputClinic.address.neighbourhoodId || 0,
                address: inputClinic.address.address || ""
            }
        };
    } catch (error) {
        console.log("Error parsing clinic data: ", error);
    }
    return clinic;
};

export default parseClinicData;
