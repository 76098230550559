import axios from "axios";

const getGoruntulemeProductList = async (baseUrl, token, doctorId, firmId, categoryId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + `/doctor/`+ doctorId + "/firm/" + firmId + "/imagecategory/" + categoryId + "/image", config)
        .then((res) => {
            getResponse = {
                status: res.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return getResponse
}

export default getGoruntulemeProductList;