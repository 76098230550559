import React, { useEffect, useState } from 'react';
import { observer, inject } from "mobx-react"
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import DeleteIcon from '@mui/icons-material/Delete'

import dayjs from 'dayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { NumericFormat } from 'react-number-format';

import getAccountingExpense from '../../../utils/api/Doctor/Accounting/GetAccountingExpense';
import getAccountingIncome from '../../../utils/api/Doctor/Accounting/GetAccountingIncome';
import {getAccountingExp} from '../../../utils/api/Doctor/Accounting/Accounting';
import { DataGrid } from '@mui/x-data-grid';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};



export const MuhasebeMenu = inject("store")(observer((props) => {

    const [tabValue, setTabValue] = useState(0)

    const [startFilterDate, setStartFilterDate] = useState(new Date());
    const [endFilterDate, setEndFilterDate] = useState(new Date())

    const [incomeList, setIncomeList] = useState([])
    const [backupIncomeList, setBackupIncomeList] = useState([])
    const [expenseList, setExpenseList] = useState([])
    const [backupExpenseList, setBackupExpenseList] = useState([])
    const [accountList, setAccountList] = useState([])

    const [totalIncome, setTotalIncome] = useState(0)
    const [totalExpense, setTotalExpense] = useState(0)

    const [filterOptions, setFilterOptions] = useState([])

    const [selectedFilter, setSelectedFilter] = useState(-99)

    const monthArray = [
        "OCAK", "ŞUBAT", "MART", "NİSAN", "MAYIS", "HAZİRAN", "TEMMUZ", "AĞUSTOS", "EYLÜL", "EKİM", "KASIM", "ARALIK"
    ]

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        // Update the document title using the browser API
        getIncomeList()
        getExpenseList()
        getAccountList()
        createFilterList()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createFilterList = () => {

        let month = dayjs().month()
        let firstMonth = dayjs().subtract(1, 'month').month()
        let secondMonth = dayjs().subtract(2, 'month').month()
        let tirthMonth = dayjs().subtract(3, 'month').month()

        let filter0 = {
            id: 0,
            monthValue: month,
            monthName: monthArray[month].toLocaleUpperCase(),
            year: dayjs().year()
        }

        let filter1 = {
            id: 1,
            monthValue: firstMonth,
            monthName: monthArray[firstMonth].toLocaleUpperCase(),
            year: dayjs().subtract(1, 'month').year()
        }

        let filter2 = {
            id: 2,
            monthValue: secondMonth,
            monthName: monthArray[secondMonth].toLocaleUpperCase(),
            year: dayjs().subtract(2, 'month').year()
        }

        let filter3 = {
            id: 3,
            monthValue: tirthMonth,
            monthName: monthArray[tirthMonth].toLocaleUpperCase(),
            year: dayjs().subtract(3, 'month').year()
        }

        let filter4 = {
            id: -1,
            monthValue: 0,
            monthName: "GEÇEN YIL",
            year: dayjs().subtract(1, 'year').year()
        }

        let filter5 = {
            id: -2,
            monthValue: 0,
            monthName: "BU YIL",
            year: dayjs().year()
        }
        let filterList = [filter0, filter1, filter2, filter3, filter4, filter5]
        setFilterOptions(filterList)
        //console.log("filterList: ", filterList)
    }

    const handleStartDateChange = (newValue) => {
        setStartFilterDate(newValue);
    };

    const handleEndDateChange = (newValue) => {
        setEndFilterDate(newValue)
    }

    const onClickDeleteFilter = () => {
        setStartFilterDate(new Date())
        setEndFilterDate(new Date())
        setExpenseList(backupExpenseList)
        setIncomeList(backupIncomeList)

        let totalIncomeValue = 0
        for (let index = 0; index < backupIncomeList.length; index++) {
            const income = backupIncomeList[index];
            totalIncomeValue = totalIncomeValue + income.paymentAmount
        }
        setTotalIncome(totalIncomeValue)

        let totalExpenseValue = 0
        for (let index = 0; index < backupExpenseList.length; index++) {
            const expense = backupExpenseList[index];
            totalExpenseValue = totalExpenseValue + expense.expenseAmount
        }
        setTotalExpense(totalExpenseValue)
    }

    const getIncomeList = async () => {
        props.store.setLoadingBarStatus(false)
        let getIncomeListResponse = await getAccountingIncome(props.store.baseUrl, props.store.token, props.store.doctor[0].id)
        //console.log("getIncomeListResponse: ", getIncomeListResponse)
        if (getIncomeListResponse.data !== null && getIncomeListResponse.data !== undefined) {
            if (getIncomeListResponse.status === 200) {
                let incomeList = getIncomeListResponse.data
                setIncomeList(incomeList)
                setBackupIncomeList(incomeList)
                let totalIncomeValue = 0
                for (let index = 0; index < incomeList.length; index++) {
                    const income = incomeList[index];
                    totalIncomeValue = totalIncomeValue + income.paymentAmount
                }
                setTotalIncome(totalIncomeValue)
            } else if (getIncomeListResponse.status === 401) {
                props.store.setUserLogout()
                window.location.reload()
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const getExpenseList = async () => {
        props.store.setLoadingBarStatus(false)

        let getExpenseListResponse = await getAccountingExpense(props.store.baseUrl, props.store.token, props.store.doctor[0].id)
        //console.log("getExpenseListResponse: ", getExpenseListResponse)
        if (getExpenseListResponse !== null && getExpenseListResponse !== undefined) {
            if (getExpenseListResponse.status === 200) {
                let expenselist = getExpenseListResponse.data
                setExpenseList(expenselist)
                setBackupExpenseList(expenselist)
                let totalExpenseValue = 0
                for (let index = 0; index < expenselist.length; index++) {
                    const expense = expenselist[index];
                    totalExpenseValue = totalExpenseValue + expense.expenseAmount
                }
                setTotalExpense(totalExpenseValue)
            } else if (getExpenseListResponse.status === 401) {
                props.store.setUserLogout()
                window.location.reload()
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const getAccountList = async () => {
        props.store.setLoadingBarStatus(false)

        let getAccountingExpenseListResponse = await getAccountingExp(props.store.baseUrl, props.store.token, props.store.doctor[0].id)
        // console.log("getAccountingExpenseListResponse: ", getAccountingExpenseListResponse)
        if (getAccountingExpenseListResponse !== null && getAccountingExpenseListResponse !== undefined) {
            if (getAccountingExpenseListResponse.status === 200) {
                let expenselist = getAccountingExpenseListResponse.data;
                expenselist.forEach(item => {
                    item.totalPrice = item.examinationPrice + item.productPrice
                });
                setAccountList(expenselist)
            } else if (getAccountingExpenseListResponse.status === 401) {
                props.store.setUserLogout()
                window.location.reload()
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const incomeColumns = [
        /*{ field: 'paymentId', headerName: 'ID', flex: 1 },*/
        {
            field: 'paymentDate', headerName: 'GELİR TARİH', flex: 1,
            headerClassName: 'mps-datagrid-column',
            renderCell: (params) => (
                <>{dayjs(new Date(params.value)).format("DD.MM.YYYY HH:mm")}</>
            )
        },
        { field: 'paymentTypeName', headerName: 'GELİR TİPİ', flex: 1, headerClassName: 'mps-datagrid-column' },
        {
            field: 'paymentAmount', headerName: 'GELİR MİKTARI', flex: 1, headerClassName: 'mps-datagrid-column',
            renderCell: (params) => (
                <NumericFormat value={params.value} allowLeadingZeros thousandSeparator="," suffix={' TL'}
                    style={{ border: "0" }} />
            )
        }
    ];

    const expenseColumns = [
        {
            field: 'expenseDate', headerName: 'GİDER TARİH', flex: 1,  headerClassName: 'mps-datagrid-column',
            renderCell: (params) => (
                <>{dayjs(new Date(params.value)).format("DD.MM.YYYY HH:mm")}</>
            )
        },
        { field: 'expenseTypeName', headerName: 'GİDER TİPİ', flex: 1,  headerClassName: 'mps-datagrid-column' },
        {
            field: 'expenseAmount', headerName: 'GİDER MİKTARI', flex: 1, headerClassName: 'mps-datagrid-column',
            renderCell: (params) => (
                <NumericFormat value={params.value} allowLeadingZeros thousandSeparator="," suffix={' TL'}
                    style={{ border: "0" }} />
            )
        }
    ];

    const accountColumns = [
        { field: 'title', headerName: 'İSİM SOYİSİM', flex: 1,  headerClassName: 'mps-datagrid-column' },
        { field: 'examinationPrice', headerName: 'TOPLAM MUAYENE ÜCRETİ', flex: 1,  headerClassName: 'mps-datagrid-column',
        renderCell: (params) => (
            <NumericFormat value={params.value} allowLeadingZeros thousandSeparator="," suffix={' TL'}
                style={{ border: "0" }} />
        ) },
        { field: 'productPrice', headerName: 'TOPLAM HİZMET ÜCRETİ', flex: 1, headerClassName: 'mps-datagrid-column',
            renderCell: (params) => (
                <NumericFormat value={params.value} allowLeadingZeros thousandSeparator="," suffix={' TL'}
                    style={{ border: "0" }} />
            )
        },
        { field: 'totalPrice', headerName: 'TOPLAM ÜCRET', flex: 1, headerClassName: 'mps-datagrid-column',
            renderCell: (params) => (
                <NumericFormat value={params.value} allowLeadingZeros thousandSeparator="," suffix={' TL'}
                    style={{ border: "0" }} />
            )
        }
    ];

    const handleChangeFilterSelectChange = (event) => {
        setSelectedFilter(event.target.value)

        let startDate = null
        let endDate = null

        if (event.target.value >= 0) {
            let filterValue = filterOptions.filter(filter => filter.id === event.target.value)[0]
            let filterDate = dayjs().set("date", 1)
            filterDate = dayjs(filterDate).set("month", filterValue.monthValue)
            filterDate = dayjs(filterDate).set("year", filterValue.year)
            startDate = filterDate.startOf('month')
            endDate = filterDate.endOf('month')
            setStartFilterDate(startDate)
            setEndFilterDate(endDate)
        } else if (event.target.value === -1) {

            startDate = dayjs().subtract(1, 'year').startOf('year')
            endDate = dayjs().subtract(1, 'year').endOf('year')
            setStartFilterDate(startDate)
            setEndFilterDate(endDate)
        } else if (event.target.value === -2) {
            startDate = dayjs().startOf('year')
            endDate = dayjs()
            setStartFilterDate(startDate)
            setEndFilterDate(endDate)
        }

        //console.log("start date: ", startDate)
        //console.log(" end date: ", endDate)

        if (tabValue === 0) {
            let filteredIncomeList = backupIncomeList.filter(filter => dayjs(filter.paymentDate).isAfter(startDate) && dayjs(filter.paymentDate).isBefore(endDate))
            setIncomeList(filteredIncomeList)
            //console.log("......", filteredIncomeList)
            let totalIncomeValue = 0
            for (let index = 0; index < filteredIncomeList.length; index++) {
                const income = filteredIncomeList[index];
                totalIncomeValue = totalIncomeValue + income.paymentAmount
            }
            //console.log("totalIncomeValue: ", totalIncomeValue)
            setTotalIncome(totalIncomeValue)
        } else if (tabValue === 1) {
            let filteredExpenseList = backupExpenseList.filter(filter => dayjs(filter.expenseDate).isAfter(startDate) && dayjs(filter.expenseDate).isBefore(endDate))
            setExpenseList(filteredExpenseList)
            //console.log("------.", filteredExpenseList)
            let totalExpenseValue = 0
            for (let index = 0; index < filteredExpenseList.length; index++) {
                const expense = filteredExpenseList[index];
                totalExpenseValue = totalExpenseValue + expense.expenseAmount
            }
            setTotalExpense(totalExpenseValue)
        } else {

        }
    }

    return (
        <div className="container-xxl" style={{ marginTop: "0px" }}>
            <Card style={{ marginTop: "8px", height: "calc(100vh - 150px)" }}>
                <CardHeader title="Muhasebe Ekranı" style={{ paddingBottom: "0", paddingTop: "15px" }}>
                </CardHeader>
                <CardContent style={{ height: "calc(100vh - 250px)", padding: "0px", paddingLeft: "10px", paddingRight: "10px" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: "20px" }}>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="GELİR EKRANI" />
                            <Tab label="GİDER EKRANI" />
                            <Tab label="HASTA CARİLERİ" />
                        </Tabs>

                        <div style={{ marginTop: "15px", marginBottom: "15px", display: (tabValue === 0 || tabValue === 1) ? "block" : "none" }}>
                            <FormControl style={{ width: "250px", background: "#e0e0e0", borderRadius: "10px", marginRight: "10px" }}>
                                <Select id="demo-simple-select" variant="outlined" labelId="demo-simple-select-label" value={selectedFilter}
                                    onChange={handleChangeFilterSelectChange} >
                                    <MenuItem key={"seciniz"} value={-99} disabled>Düzenleme Tarihi</MenuItem>
                                    {filterOptions.map((filter, idx) => (
                                        <MenuItem key={idx} value={filter.id}>{(filter.id >= 0) ? filter.monthName + " " + filter.year : filter.monthName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} >
                                <DesktopDatePicker
                                    label="Başlangıç Zamanı"
                                    inputFormat="DD/MM/YYYY"
                                    value={startFilterDate}
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => <TextField {...params} style={{ marginRight: "10px" }} />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DesktopDatePicker
                                    label="Bitiş Zamanı"
                                    inputFormat="DD/MM/YYYY"
                                    value={endFilterDate}
                                    onChange={handleEndDateChange}
                                    renderInput={(params) => <TextField {...params} style={{ marginRight: "10px" }} />} />
                            </LocalizationProvider>

                            <Button color="primary" variant="contained" style={{ float: "right", height: "55px", background: "#e0e0e0", color: "#000" }}
                                onClick={onClickDeleteFilter}>
                                <DeleteIcon /> Temizle
                            </Button>
                        </div>
                    </Box>

                    <Box hidden={(tabValue !== 0) ? true : false} style={{ padding: "0", height: "calc(100% - 130px)" }}>
                        <DataGrid
                            rows={incomeList}
                            columns={incomeColumns}
                            rowsPerPageOptions={[10]}
                            disableSelectionOnClick
                            getRowId={(row) => row?.paymentId}

                        />
                    </Box>

                    <Box hidden={(tabValue !== 1) ? true : false} style={{ padding: "0", height: "calc(100% - 130px)" }}>
                        <DataGrid
                            rows={expenseList}
                            columns={expenseColumns}
                            rowsPerPageOptions={[10]}
                            disableSelectionOnClick
                            getRowId={(row) => row?.expenseId}
                        />

                    </Box>

                    <Box hidden={(tabValue !== 2) ? true : false} style={{ padding: "0", height: "calc(100% - 130px)" }}>
                        <DataGrid
                            rows={accountList}
                            columns={accountColumns}
                            rowsPerPageOptions={[10]}
                            disableSelectionOnClick
                            getRowId={(row) => row?.patientId}
                        />

                    </Box>
                </CardContent>
                {<Typography style={{ marginLeft: "15px", marginTop: "20px", display: (tabValue === 0 || tabValue === 1) ? "block" : "none" }}>
                    <b>TOPLAM:</b>
                    {(tabValue === 0) ? <NumericFormat value={totalIncome} allowLeadingZeros thousandSeparator="," suffix={' TL'}
                        style={{ border: "0", width: "auto", fontSize: "16px" }} /> : <NumericFormat value={totalExpense} allowLeadingZeros thousandSeparator="," suffix={' TL'}
                            style={{ border: "0" }} />}
                </Typography>}
            </Card>
        </div>
    )
}))