import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	Typography,
	FormControl,
	FormControlLabel,
	FormLabel,
	RadioGroup,
	Radio,
	Box,
	Container,
	Grid,
} from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";

import { Button, Card, CardContent, CardHeader } from "@mui/material";

import { TopBarMenu } from "../../components/common/TopBarMenu";

import getClinic from "../../utils/api/Doctor/Clinic/GetClinic";
import getClinicEmployee from "../../utils/api/Doctor/Clinic/GetClinicEmployee";
import deleteClinicEmployee from "../../utils/api/Doctor/Clinic/DeleteClinicEmployee";
import postClinic from "../../utils/api/Doctor/Clinic/PostClinic";
import postClinicEmployee from "../../utils/api/Doctor/Clinic/PostClinicEmployee";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";

export const PersonelPage = inject("store")(
	observer((props) => {
		const [clinic, setClinic] = useState({});
		const [employeeList, setEmployeeList] = useState([]);
		const [clinicDialog, setClinicDialog] = useState(false);
		const [personelDialog, setPersonelDialog] = useState(false);
		const [employee, setEmployee] = useState({
			tckno: 0,
			name: "",
			surname: "",
			email: "",
			mobilePhone: 0,
			birthDate: new Date(),
			gender: 0,
			userType: 3,
			doctorId: props.store.doctor[0].id,
			avatar: "",
		});
		const [birimIsmi, setBirimIsmi] = useState("");
		const address = {
			cityId: 99,
			districtId: 99,
			neighbourhoodId: 99,
			address: "Görüşmeci",
		};

		useEffect(() => {
			if (!props.store.isLogin) {
				props.store.setLoadingBarStatus(true);
				props.history.push("/panel/kullanici-girisi");
			} else {
				getClinicRequest();
			}
		}, []);

		useEffect(() => {
			if (clinic) {
				getKlinikCalisanListesi(clinic.clinicId);
			}
		}, [clinic]);

		const getClinicRequest = async () => {
			let clinicResponse = await getClinic(props.store.baseUrl, props.store.token, props.store.doctor[0].id);
			//console.log("clinicResponse: ", clinicResponse)
			if (clinicResponse !== null && clinicResponse !== undefined) {
				if (clinicResponse.status === 200) {
					return setClinic(clinicResponse.data);
				} else if (clinicResponse.status === 401) {
					props.store.setUserLogout();
					window.location.reload();
				}
				setClinic(null);
			}
		};

		const handleNewClinicDialog = () => {
			setClinicDialog(!clinicDialog);
		};

		const handlePersonelDialog = () => {
			setPersonelDialog(!personelDialog);
		};

		const getKlinikCalisanListesi = async (klinikId) => {
			props.store.setLoadingBarStatus(false);

			let getKlinikEmployeeResponse = await getClinicEmployee(props.store.baseUrl, props.store.token, props.store.doctor[0].id, klinikId);
			if (getKlinikEmployeeResponse !== null && getKlinikEmployeeResponse !== undefined) {
				if (getKlinikEmployeeResponse.status === 200) {
					if (getKlinikEmployeeResponse.data !== null) {
						setEmployeeList(getKlinikEmployeeResponse.data);
					} else {
						setEmployeeList([]);
					}
				}
			}
			props.store.setLoadingBarStatus(true);
		};

		const clinicPost = async () => {
			props.store.setLoadingBarStatus(false);

			let klinikData = {
				doctorId: props.store.doctor[0].id,
				name: birimIsmi,
				address,
			};
			let klinikCreateResponse = await postClinic(props.store.baseUrl, props.store.token, props.store.doctor[0].id, klinikData);
			if (klinikCreateResponse !== undefined && klinikCreateResponse !== null) {
				if (klinikCreateResponse.status === 200) {
					props.store.setMessage("Klinik oluşturma  başarıyla tamamlandı.", true, "success");
					handleNewClinicDialog();
					await getClinicRequest();
				} else {
					props.store.setMessage(klinikCreateResponse.data.message, true, "error");
				}
			} else {
				props.store.setMessage("Klinik oluşturulurken hata oluştu.", true, "error");
			}
			props.store.setLoadingBarStatus(true);
		};

		const onClickPersonelGuncelle = (inputEmployee) => {
			setEmployee(inputEmployee);
			handlePersonelDialog();
		};

		const onClickPersonelEkle = async () => {
			if (employee.tckno === 0 || employee.name === "" || employee.surname === "" || employee.mobilePhone === 0 || employee.birthDate === null || employee.gender === 0 || employee.email === 0)
				return props.store.setMessage("Lütfen tüm alanları doldurunuz.", true, "error");
			employee.mobilePhone = employee.mobilePhone.replace(/[\(\)]/g, "").replace("-", "");
			let employeeCreateResponse = await postClinicEmployee(props.store.baseUrl, props.store.token, props.store.doctor[0].id, employee);
			if (employeeCreateResponse !== null && employeeCreateResponse !== undefined) {
				if (employeeCreateResponse.status === 200) {
					props.store.setMessage("Personeli başarıyla oluşturuldu.", true, "success");
					await getKlinikCalisanListesi(clinic.clinicId);
				} else {
					props.store.setMessage(employeeCreateResponse.data.message, true, "error");
				}
			} else {
				props.store.setMessage("Personel oluşturulurken hata oluştu.", true, "error");
			}
		};

		const deleteKlinikCalisani = async (doctorId) => {
			props.store.setLoadingBarStatus(false);

			let deleteKlinikEmployeeResponse = await deleteClinicEmployee(props.store.baseUrl, props.store.token, props.store.doctor[0].id, doctorId);
			if (deleteKlinikEmployeeResponse !== null && deleteKlinikEmployeeResponse !== undefined) {
				if (deleteKlinikEmployeeResponse.status === 200) {
					props.store.setMessage("Klinik çalışanı silme işlemi başarıyla tamamlandı.", true, "success");
					await getKlinikCalisanListesi(clinic.clinicId);
				} else {
					props.store.setMessage(deleteKlinikEmployeeResponse.data.message, true, "error");
				}
			} else {
				props.store.setMessage("Klinik çalışanı silinirken hata oluştu.", true, "error");
			}
			props.store.setLoadingBarStatus(true);
		};

		const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
			const { onChange, ...other } = props;
			return (
				<IMaskInput
					{...other}
					mask="(#00)000-0000"
					definitions={{
						"#": /[1-9]/,
					}}
					inputRef={ref}
					onAccept={(value) => onChange({ target: { name: props.name, value } })}
					overwrite
				/>
			);
		});

		TextMaskCustom.propTypes = {
			name: PropTypes.string.isRequired,
			onChange: PropTypes.func.isRequired,
		};

		return (
			<div className="container mps-container">
				<TopBarMenu meeting />
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						pt: 9,
					}}
				>
					<Container maxWidth={false}>
						<Grid container spacing={3}>
							<Grid item style={{ height: "100%", width: "100%" }}>
								<Card>
									{/* <CardHeader title={(clinic?.name ?? "") + " Personel Yönetimi"} /> */}
									<CardHeader title="Personel Yönetimi" />
									{clinic ? (
										<CardContent>
											<Button variant="contained" color="primary" onClick={handlePersonelDialog} style={{ marginLeft: "10px" }}>
												Yeni Personel Ekle
											</Button>
										</CardContent>
									) : (
										<CardContent>
											Aktif birimizin bulunmamaktadır.
											<Button variant="contained" color="primary" onClick={handleNewClinicDialog} style={{ marginLeft: "10px" }}>
												Yeni Birim Oluştur
											</Button>
										</CardContent>
									)}
									<CardContent style={{ paddingTop: "0", height: "100%" }}>
										<TableContainer style={{ marginTop: "0px" }}>
											<Table sx={{ minWidth: 650 }} aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell>ADI</TableCell>
														<TableCell>SOYADI</TableCell>
														<TableCell>GÖREVİ</TableCell>
														<TableCell>E-POSTA ADRESİ</TableCell>
														<TableCell>İŞLEMLER</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{employeeList.map((employee, idx) => (
														<TableRow key={idx}>
															<TableCell>
																<Typography variant="body2">{employee.name}</Typography>
															</TableCell>
															<TableCell>
																<Typography variant="body2">{employee.surname}</Typography>
															</TableCell>
															<TableCell>
																<Typography variant="body2">{employee.userType === 1 ? "Süpervizör" : "Görüşmeci"}</Typography>
															</TableCell>
															<TableCell>
																<Typography variant="body2">{employee.email}</Typography>
															</TableCell>
															<TableCell>
																{employee.userType !== 1 ? (
																	props.store.doctor[0] !== undefined ? (
																		employee.doctorId === props.store.doctor[0].id ? null : (
																			<Button
																				variant="contained"
																				color="warning"
																				onClick={() => onClickPersonelGuncelle(employee)}
																				style={{
																					marginRight: "10px",
																				}}
																			>
																				Güncelle
																			</Button>
																		)
																	) : null
																) : null}
																{props.store.doctor[0] !== undefined ? (
																	employee.doctorId === props.store.doctor[0].id ? null : (
																		<Button variant="contained" color="error" onClick={() => deleteKlinikCalisani(employee.doctorId)}>
																			Sil
																		</Button>
																	)
																) : null}
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</Container>
				</Box>
				<Dialog fullWidth={true} maxWidth={"sm"} open={clinicDialog} onClose={handleNewClinicDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{"Birim Oluşturma Menüsü"}</DialogTitle>
					<DialogContent style={{ padding: "30px" }}>
						<TextField value={birimIsmi} variant="outlined" label="Birim İsmi" autoFocus onChange={(e) => setBirimIsmi(e.target.value)} style={{ width: "100%" }}></TextField>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleNewClinicDialog}>Kapat</Button>
						<Button color="primary" variant="contained" onClick={clinicPost}>
							Birimi Kaydet
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog fullWidth={true} maxWidth={"sm"} open={personelDialog} onClose={handlePersonelDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{"Personel Ekle"}</DialogTitle>
					<DialogContent style={{ padding: "30px", paddingTop: "0" }}>
						<TextField
							name="tckno"
							value={employee.tckno}
							label="T.C. Kimlik Numarası*"
							style={{ width: "100%", marginTop: "10px" }}
							onChange={(e) => setEmployee({ ...employee, tckno: e.target.value })}
						/>
						<TextField name="name" value={employee.name} label="Adı*" style={{ width: "100%", marginTop: "10px" }} onChange={(e) => setEmployee({ ...employee, name: e.target.value })} />
						<TextField
							name="surname"
							value={employee.surname}
							label="Soyadı*"
							style={{ width: "100%", marginTop: "10px" }}
							onChange={(e) => setEmployee({ ...employee, surname: e.target.value })}
						/>
						<TextField
							name="email"
							value={employee.email}
							label="E-posta*"
							style={{ width: "100%", marginTop: "10px" }}
							onChange={(e) => setEmployee({ ...employee, email: e.target.value })}
						/>

						<TextField
							id="outlined-basic"
							variant="outlined"
							name="mobilePhone"
							value={employee.mobilePhone}
							type="text"
							style={{
								width: "100%",
								marginBottom: "10px",
								borderRadius: "10px",
								marginTop: "10px",
							}}
							label="Cep Telefonu Numarası*"
							onChange={(e) => setEmployee({ ...employee, mobilePhone: e.target.value })}
						/>

						<FormControl fullWidth style={{ marginTop: "20px" }}>
							<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"tr"} style={{ width: "calc(100% - 100px)" }}>
								<DatePicker
									label="Personel Doğum Tarihi*"
									value={employee.birthDate}
									onChange={(newValue) => setEmployee({ ...employee, birthDate: newValue })}
									renderInput={(params) => <TextField {...params} />}
									resizable
								/>
							</LocalizationProvider>
						</FormControl>
						<FormControl style={{ marginTop: "10px" }}>
							<FormLabel id="demo-row-radio-buttons-group-label">Cinsiyet*</FormLabel>
							<RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
								<FormControlLabel value="erkek" control={<Radio onChange={() => setEmployee({ ...employee, gender: 1 })} />} label="Erkek" />
								<FormControlLabel value="kadın" control={<Radio onChange={() => setEmployee({ ...employee, gender: 2 })} />} label="Kadın" />
							</RadioGroup>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button onClick={handlePersonelDialog}>Kapat</Button>
						<Button color="primary" variant="contained" onClick={onClickPersonelEkle}>
							Personel Ekle
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	})
);
