import axios from "axios";

const putPrivateNote = async (baseUrl, token, doctorId, patientId, note) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    await axios.put(baseUrl + `/doctor/` + doctorId + "/patient/" + patientId + "/private-note", note, config)
        .then((res) => {
            putResponse = {
                status: res.status,
                result: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    result:  error.response.status
                }
            }
        });
    return putResponse
}

export default putPrivateNote;