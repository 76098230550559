import { DoctorProfessional } from '../../model/Doctor/DoctorProfessional'

const parseDoctorProfessionalData = (inputDoctorProfessional) => {
    //console.log("inputDoctorProfessional: ", inputDoctorProfessional)
    let doctorProfessional = null
    try {

        doctorProfessional = DoctorProfessional.create({
            professionalId: (inputDoctorProfessional.professionalId !== null) ?  inputDoctorProfessional.professionalId : 0,
            professionalName: (inputDoctorProfessional.professionalName !== null) ? inputDoctorProfessional.professionalName : ""
        })
        
    } catch (error) {
        console.log("error: ", error)
    }
    
    return doctorProfessional;
} 

export default parseDoctorProfessionalData;

