import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"
import { toJS } from 'mobx';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { Dialog, DialogContent, DialogTitle, DialogActions, TableCell, TableHead, TableRow, Table, TableBody } from '@mui/material';
import MaskedInput from 'react-text-mask'
import { Button, Chip, FormControl, FormHelperText, MenuItem } from '@mui/material';
import { Stack } from '@mui/system';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { DoctorAllSettings } from '../../../model/Doctor/DoctorAllSettings';
import putDoctorSettings from '../../../utils/api/Doctor/PutDoctorSettings'
import { getDoctorPromos, insertPromo, deletePromo } from '../../../utils/api/Doctor/Promos';

import PromoCard from '../../common/PromoCard';

export const DoktorAyarlarMenu = inject("store")(observer((props) => {

    const [settings, setSettings] = useState(DoctorAllSettings.create({}))
    const viziteSuresiArray = [10, 20, 30, 40, 50, 60, 70, 80, 90]
    const [startHours, setStartHours] = useState(["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"])
    const [endHours, setEndHours] = useState(["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"])
    const [promoList, setPromoList] = useState([])
    const [newPromo, setNewPromo] = useState({ count: 0, discount: 0, description: "" })
    const [newPromoWindow, setNewPromoWindow] = useState(false)

    useEffect(() => {
        // Update the document title using the browser API
        //console.log("props.data: ", toJS(props.data))
        if (props.data !== null) {
            setSettings(props.data)
            getPromoList();
        }
    }, [props.data]);

    const handleEmailBildirimChange = (event) => {

        let doctorSettings = settings.doctorSettings[0]
        doctorSettings.isActive = event.target.checked

        let newDoctorSettings = settings.doctorSettings
        newDoctorSettings[0] = doctorSettings

        setSettings({ ...settings, doctorSettings: newDoctorSettings })

    }

    const handleOfflineGorusmeChange = (event) => {
        let doctorSettings = settings.doctorSettings[3]
        doctorSettings.isActive = event.target.checked

        let newDoctorSettings = settings.doctorSettings
        newDoctorSettings[3] = doctorSettings

        setSettings({ ...settings, doctorSettings: newDoctorSettings })
    }

    const onChangeDateSwitch = (event) => {

        let pazartesiSettings = settings.doctorWorkingDays[Number(event.target.name)]
        pazartesiSettings.isActive = event.target.checked

        let newDoctorWorkingDays = settings.doctorWorkingDays
        newDoctorWorkingDays[Number(event.target.name)] = pazartesiSettings

        setSettings({ ...settings, doctorWorkingDays: newDoctorWorkingDays })

    }

    const handleRemoveWorkingHour = (workingDay, workingDayIndex, hour, hourIndex) => {
        let selectedWorkday = settings.doctorWorkingDays[workingDayIndex]
        let hourArray = selectedWorkday.hourList
        hourArray.splice(hourIndex, 1)

        let newDoctorWorkingDays = settings.doctorWorkingDays
        newDoctorWorkingDays[workingDayIndex] = selectedWorkday

        setSettings({ ...settings, doctorWorkingDays: newDoctorWorkingDays })

    }

    const handleViziteSuresiChange = (event) => {
        let selectedPriceInfo = settings.doctorPrice
        let newSelectedPriceInfo = selectedPriceInfo
        newSelectedPriceInfo.examinationTime = event.target.value
        setSettings({ ...settings, doctorPrice: newSelectedPriceInfo })
    }

    const handleViziteUcretiChange = (event) => {
        let selectedPriceInfo = settings.doctorPrice
        let newSelectedPriceInfo = selectedPriceInfo
        newSelectedPriceInfo.examinationPrice = Number(event.target.value)
        setSettings({ ...settings, doctorPrice: newSelectedPriceInfo })
    }

    const handleOfflineViziteUcretiChange = (event) => {
        let selectedPriceInfo = settings.doctorPrice
        let newSelectedPriceInfo = selectedPriceInfo
        newSelectedPriceInfo.offlineExaminationPrice = Number(event.target.value)
        setSettings({ ...settings, doctorPrice: newSelectedPriceInfo })
    }

    const handleViziteDinlenmeSuresiChange = (event) => {
        let selectedPriceInfo = settings.doctorPrice
        let newSelectedPriceInfo = selectedPriceInfo
        newSelectedPriceInfo.timeBetweenExaminations = Number(event.target.value)
        setSettings({ ...settings, doctorPrice: newSelectedPriceInfo })
    }

    const onChangeStartHour = (event) => {
        let startHourIndex = Number(event.target.name);
        const startHoursNew = startHours.map((c, i) => {
            if (i === startHourIndex) {
                // Increment the clicked counter
                return event.target.value;
            } else {
                // The rest haven't changed
                return c;
            }
        });
        setStartHours(startHoursNew)
    }

    const onChangeEndHour = (event) => {
        let endHourIndex = Number(event.target.name);

        const endHoursNew = endHours.map((c, i) => {
            if (i === endHourIndex) {
                // Increment the clicked counter
                return event.target.value;
            } else {
                // The rest haven't changed
                return c;
            }
        });

        setEndHours(endHoursNew)
    }

    const onClickAddWorkingHour = (dayIndex) => {
        const testPattern = /^\d{2}:\s?\d{2}$/;
        const [hours1, minutes1] = startHours[dayIndex].split(":");
        const [hours2, minutes2] = endHours[dayIndex].split(":");
        const date1 = new Date(0, 0, 0, hours1, minutes1);
        const date2 = new Date(0, 0, 0, hours2, minutes2);
        
        if (!testPattern.test(startHours[dayIndex]) || !testPattern.test(endHours[dayIndex]) || Number(hours1) > 24 || Number(hours2) > 24  || Number(minutes1) > 59 || Number(minutes2) > 59) {
            return props.store.setMessage(`Saat bilgisi hatalı: ${startHours[dayIndex]} - ${endHours[dayIndex]}`, true, "error")
        }
        else if ((date2 <= date1)) {
            return props.store.setMessage(`Saat aralığı hatalı: ${startHours[dayIndex]} - ${endHours[dayIndex]}`, true, "error")
        }
        let selectedWorkday = settings.doctorWorkingDays[dayIndex]
        let hourArray = selectedWorkday.hourList
        if (hourArray !== null) {
            hourArray.push(startHours[dayIndex] + "-" + endHours[dayIndex])
        } else {
            hourArray = []
            hourArray.push(startHours[dayIndex] + "-" + endHours[dayIndex])
        }

        selectedWorkday.hourList = hourArray
        let newDoctorWorkingDays = settings.doctorWorkingDays
        newDoctorWorkingDays[dayIndex] = selectedWorkday

        setSettings({ ...settings, doctorWorkingDays: newDoctorWorkingDays })
    }

    const onClickSettingsGuncelle = async () => {
        if (settings.doctorPrice.vacationDate || settings.doctorPrice.vacationEndDate) {
            const vacationDate = settings.doctorPrice.vacationDate && getCorrectDate(settings.doctorPrice.vacationDate);
            const vacationEndDate = settings.doctorPrice.vacationEndDate && getCorrectDate(settings.doctorPrice.vacationEndDate);
            if (vacationDate && vacationEndDate) {
                if (new Date(vacationDate) > new Date(vacationEndDate)) {
                    return props.store.setMessage("Randevu kapatırken bitiş tarihi, başlangıç tarihinden daha geç olmalıdır.", true, "error")
                }
            }
            else {
                return props.store.setMessage("Randevu kapatırken tarihlerin ikisi de dolu veya boş olmalıdır.", true, "error")
            }
        }

        props.store.setLoadingBarStatus(false)
        settings.doctorWorkingDays.forEach((days)=>{
            if (days.isActive && (!days.hourList || days.hourList?.length < 1))
            days.isActive = false;
        })

        let updateDoctorSettingsResponse = await putDoctorSettings(props.store.baseUrl, props.store.token, settings.doctorId, settings)
        if (updateDoctorSettingsResponse !== undefined && updateDoctorSettingsResponse !== null) {
            if (updateDoctorSettingsResponse.status === 200) {
                props.store.setMessage("Ayarlarınız başarıyla güncellendi.", true, "success")
            } else {
                props.store.setMessage("Ayarlarınız güncellenirken hata oluştu", true, "error")
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    const handleBankNameChange = (event) => {
        let doctorPrice = toJS(settings.doctorPrice)
        doctorPrice.bankName = event.target.value
        setSettings({ ...settings, doctorPrice: doctorPrice })
    }

    const handleRecipientNameSurnameChange = (event) => {
        let doctorPrice = toJS(settings.doctorPrice)
        doctorPrice.recipientNameSurname = event.target.value
        setSettings({ ...settings, doctorPrice: doctorPrice })
    }

    const handleIbanChange = (event) => {
        let doctorPrice = toJS(settings.doctorPrice)
        doctorPrice.iban = event.target.value
        setSettings({ ...settings, doctorPrice: doctorPrice })
    }

    const handleVacationChange = (event) => {
        let doctorPrice = toJS(settings.doctorPrice);
        doctorPrice.vacationDate = new Date(getCorrectDate(event));
        setSettings({ ...settings, doctorPrice: doctorPrice })
    }

    const handleVacationEndChange = (event) => {
        let doctorPrice = toJS(settings.doctorPrice);
        doctorPrice.vacationEndDate = new Date(getCorrectDate(event));
        setSettings({ ...settings, doctorPrice: doctorPrice })
    }

    const resetVacation = () => {
        let doctorPrice = toJS(settings.doctorPrice);
        doctorPrice.vacationDate = null;
        doctorPrice.vacationEndDate = null;
        setSettings({ ...settings, doctorPrice: doctorPrice })
    }

    const getCorrectDate = (event) => {
        const date = dayjs(event).valueOf();
        return new Date(Date.UTC(new Date(date).getFullYear(), new Date(date).getMonth(), new Date(date).getDate())).toUTCString();
    }

    const getPromoList = async () => {
        props.store.setLoadingBarStatus(false)
        let getPromoListRes = await getDoctorPromos(props.store.baseUrl, props.store.token, props.store.doctor[0].id)
        if (getPromoListRes !== null && getPromoListRes !== undefined) {
            if (getPromoListRes.status === 200) {
                if (getPromoListRes.data !== null) {
                    setPromoList(getPromoListRes.data)
                } else {
                    setPromoList([])
                }
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const insertNewPromo = async () => {
        props.store.setLoadingBarStatus(false)
        let insertPromoRes = await insertPromo(props.store.baseUrl, props.store.token, props.store.doctor[0].id, newPromo)
        if (insertPromoRes !== null && insertPromoRes !== undefined) {
            if (insertPromoRes.status === 200) {
                props.store.setMessage("Paket başarıyla eklendi.", true, "success")
                getPromoList();
                closeNewPromoWindow();
            } else {
                props.store.setMessage("Paket eklenirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Paket eklenirken hata oluştu.", true, "error")
        }
        props.store.setLoadingBarStatus(true)
    }

    const delPromo = async (promoId) => {
        props.store.setLoadingBarStatus(false)
        let deletePromoRes = await deletePromo(props.store.baseUrl, props.store.token, props.store.doctor[0].id, promoId)
        if (deletePromoRes !== null && deletePromoRes !== undefined) {
            if (deletePromoRes.status === 200) {
                props.store.setMessage("Paket başarıyla silindi.", true, "success")
                getPromoList()
            } else {
                props.store.setMessage("Paket silinirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Paket silinirken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const closeNewPromoWindow = () => {
        setNewPromo({ count: 0, discount: 0 })
        setNewPromoWindow(false)
    }

    const onChangePromo = (event) => {
        if (event.target.name === "promoCount") {
            setNewPromo({...newPromo, count: event.target.value})
        }
        else if (event.target.name === "promoDiscount" && event.target.value <= 100 && event.target.value >= 0) {
            setNewPromo({...newPromo, discount: event.target.value})
        }
        else if (event.target.name === "promoDesc") {
            setNewPromo({...newPromo, description: event.target.value})
        }
    }

    return (
        <div className="container-xxl" style={{ marginTop: "0px" }}>
            <Card style={{ marginTop: "8px", height: "auto" }}>
                <CardContent style={{ height: "auto" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Typography variant='body1' style={{ fontWeight: "bold" }}>Bildirim Onay Bilgileri</Typography>
                            <Typography variant='subtitle2' style={{ fontWeight: "100" }} >Wholemedics platformunu kullanmak için verilmesi gereken izinler buradan ulaşabilirsiniz</Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(settings.doctorSettings[0] !== undefined) ? settings.doctorSettings[0].isActive : false}
                                        onChange={handleEmailBildirimChange}
                                        name="emailBildirimSettings"
                                        color="primary"
                                    />
                                }
                                label={(settings.doctorSettings[0] !== undefined) ? settings.doctorSettings[0].settingsName : ""}
                            />
                            

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(settings.doctorSettings[3] !== undefined) ? settings.doctorSettings[3].isActive : false}
                                        onChange={handleOfflineGorusmeChange}
                                        name="emailBildirimSettings"
                                        color="primary"
                                    />
                                }
                                label={(settings.doctorSettings[3] !== undefined) ? settings.doctorSettings[3].settingsName : ""}
                            />

                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant='body1' style={{ fontWeight: "bold" }}>Çalışma Saati Bilgileri</Typography>
                            <Typography variant='subtitle2' style={{ fontWeight: "100", marginBottom: "10px" }} >Bu ekrandan Wholemedics platformundan online hizmet vereceğiniz gün ve saat bilgilerini görüntüleyebilir ve güncelleyebilirsiniz.</Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch name={"0"} checked={(settings.doctorWorkingDays[0] !== undefined) ? settings.doctorWorkingDays[0].isActive : false} onChange={onChangeDateSwitch} />} label="Pazartesi" style={{ marginBottom: "8px" }} />
                                        <FormControlLabel control={<Switch name={"1"} checked={(settings.doctorWorkingDays[1] !== undefined) ? settings.doctorWorkingDays[1].isActive : false} onChange={onChangeDateSwitch} />} label="Salı" style={{ marginBottom: "8px" }} />
                                        <FormControlLabel control={<Switch name={"2"} checked={(settings.doctorWorkingDays[2] !== undefined) ? settings.doctorWorkingDays[2].isActive : false} onChange={onChangeDateSwitch} />} label="Çarşamba" style={{ marginBottom: "8px" }} />
                                        <FormControlLabel control={<Switch name={"3"} checked={(settings.doctorWorkingDays[3] !== undefined) ? settings.doctorWorkingDays[3].isActive : false} onChange={onChangeDateSwitch} />} label="Perşembe" style={{ marginBottom: "8px" }} />
                                        <FormControlLabel control={<Switch name={"4"} checked={(settings.doctorWorkingDays[4] !== undefined) ? settings.doctorWorkingDays[4].isActive : false} onChange={onChangeDateSwitch} />} label="Cuma" style={{ marginBottom: "8px" }} />
                                        <FormControlLabel control={<Switch name={"5"} checked={(settings.doctorWorkingDays[5] !== undefined) ? settings.doctorWorkingDays[5].isActive : false} onChange={onChangeDateSwitch} />} label="Cumartesi" style={{ marginBottom: "8px" }} />
                                        <FormControlLabel control={<Switch name={"6"} checked={(settings.doctorWorkingDays[6] !== undefined) ? settings.doctorWorkingDays[6].isActive : false} onChange={onChangeDateSwitch} />} label="Pazar" />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={2}>
                                    <MaskedInput name="0" disabled={(settings.doctorWorkingDays[0] !== undefined) ? !settings.doctorWorkingDays[0].isActive : false} mask={[/[0-9]/, /\d/, ':', /\d/, /\d/]}
                                        onChange={onChangeStartHour}
                                        value={startHours[0]} style={{ width: "100%", height: "37px", border: "1px solid #eaeaea", borderRadius: "5px", marginBottom: "9px", padding: "10px" }} />
                                    <MaskedInput name="1" disabled={(settings.doctorWorkingDays[1] !== undefined) ? !settings.doctorWorkingDays[1].isActive : false} mask={[/[0-9]/, /\d/, ':', /\d/, /\d/]}
                                        onChange={onChangeStartHour}
                                        value={startHours[1]} style={{ width: "100%", height: "37px", border: "1px solid #eaeaea", borderRadius: "5px", marginBottom: "9px", padding: "10px" }} />
                                    <MaskedInput name="2" disabled={(settings.doctorWorkingDays[2] !== undefined) ? !settings.doctorWorkingDays[2].isActive : false} mask={[/[0-9]/, /\d/, ':', /\d/, /\d/]}
                                        onChange={onChangeStartHour}
                                        value={startHours[2]} style={{ width: "100%", height: "37px", border: "1px solid #eaeaea", borderRadius: "5px", marginBottom: "9px", padding: "10px" }} />
                                    <MaskedInput name="3" disabled={(settings.doctorWorkingDays[3] !== undefined) ? !settings.doctorWorkingDays[3].isActive : false} mask={[/[0-9]/, /\d/, ':', /\d/, /\d/]}
                                        onChange={onChangeStartHour}
                                        value={startHours[3]} style={{ width: "100%", height: "37px", border: "1px solid #eaeaea", borderRadius: "5px", marginBottom: "9px", padding: "10px" }} />
                                    <MaskedInput name="4" disabled={(settings.doctorWorkingDays[4] !== undefined) ? !settings.doctorWorkingDays[4].isActive : false} mask={[/[0-9]/, /\d/, ':', /\d/, /\d/]}
                                        onChange={onChangeStartHour}
                                        value={startHours[4]} style={{ width: "100%", height: "37px", border: "1px solid #eaeaea", borderRadius: "5px", marginBottom: "9px", padding: "10px" }} />
                                    <MaskedInput name="5" disabled={(settings.doctorWorkingDays[5] !== undefined) ? !settings.doctorWorkingDays[5].isActive : false} mask={[/[0-9]/, /\d/, ':', /\d/, /\d/]}
                                        onChange={onChangeStartHour}
                                        value={startHours[5]} style={{ width: "100%", height: "37px", border: "1px solid #eaeaea", borderRadius: "5px", marginBottom: "9px", padding: "10px" }} />
                                    <MaskedInput name="6" disabled={(settings.doctorWorkingDays[6] !== undefined) ? !settings.doctorWorkingDays[6].isActive : false} mask={[/[0-9]/, /\d/, ':', /\d/, /\d/]}
                                        onChange={onChangeStartHour}
                                        value={startHours[6]} style={{ width: "100%", height: "37px", border: "1px solid #eaeaea", borderRadius: "5px", marginBottom: "9px", padding: "10px" }} />
                                </Grid>
                                <Grid item xs={2}>
                                    <MaskedInput name="0" disabled={(settings.doctorWorkingDays[0] !== undefined) ? !settings.doctorWorkingDays[0].isActive : false} mask={[/[0-9]/, /\d/, ':', ' ', /\d/, /\d/]}
                                        onChange={onChangeEndHour}
                                        value={endHours[0]} style={{ width: "100%", height: "37px", border: "1px solid #eaeaea", borderRadius: "5px", marginBottom: "9px", padding: "10px" }} />
                                    <MaskedInput name="1" disabled={(settings.doctorWorkingDays[1] !== undefined) ? !settings.doctorWorkingDays[1].isActive : false} mask={[/[0-9]/, /\d/, ':', ' ', /\d/, /\d/]}
                                        onChange={onChangeEndHour}
                                        value={endHours[1]} style={{ width: "100%", height: "37px", border: "1px solid #eaeaea", borderRadius: "5px", marginBottom: "9px", padding: "10px" }} />
                                    <MaskedInput name="2" disabled={(settings.doctorWorkingDays[2] !== undefined) ? !settings.doctorWorkingDays[2].isActive : false} mask={[/[0-9]/, /\d/, ':', ' ', /\d/, /\d/]}
                                        onChange={onChangeEndHour}
                                        value={endHours[2]} style={{ width: "100%", height: "37px", border: "1px solid #eaeaea", borderRadius: "5px", marginBottom: "9px", padding: "10px" }} />
                                    <MaskedInput name="3" disabled={(settings.doctorWorkingDays[3] !== undefined) ? !settings.doctorWorkingDays[3].isActive : false} mask={[/[0-9]/, /\d/, ':', ' ', /\d/, /\d/]}
                                        onChange={onChangeEndHour}
                                        value={endHours[3]} style={{ width: "100%", height: "37px", border: "1px solid #eaeaea", borderRadius: "5px", marginBottom: "9px", padding: "10px" }} />
                                    <MaskedInput name="4" disabled={(settings.doctorWorkingDays[4] !== undefined) ? !settings.doctorWorkingDays[4].isActive : false} mask={[/[0-9]/, /\d/, ':', ' ', /\d/, /\d/]}
                                        onChange={onChangeEndHour}
                                        value={endHours[4]} style={{ width: "100%", height: "37px", border: "1px solid #eaeaea", borderRadius: "5px", marginBottom: "9px", padding: "10px" }} />
                                    <MaskedInput name="5" disabled={(settings.doctorWorkingDays[5] !== undefined) ? !settings.doctorWorkingDays[5].isActive : false} mask={[/[0-9]/, /\d/, ':', ' ', /\d/, /\d/]}
                                        onChange={onChangeEndHour}
                                        value={endHours[5]} style={{ width: "100%", height: "37px", border: "1px solid #eaeaea", borderRadius: "5px", marginBottom: "9px", padding: "10px" }} />
                                    <MaskedInput name="6" disabled={(settings.doctorWorkingDays[6] !== undefined) ? !settings.doctorWorkingDays[6].isActive : false} mask={[/[0-9]/, /\d/, ':', ' ', /\d/, /\d/]}
                                        onChange={onChangeEndHour}
                                        value={endHours[6]} style={{ width: "100%", height: "37px", border: "1px solid #eaeaea", borderRadius: "5px", marginBottom: "9px", padding: "10px" }} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button variant="contained" style={{ marginBottom: "5px" }} disabled={(settings.doctorWorkingDays[0] !== undefined) ? !settings.doctorWorkingDays[0].isActive : false} onClick={() => onClickAddWorkingHour(0)}>Ekle</Button><br />
                                    <Button variant="contained" style={{ marginBottom: "5px" }} disabled={(settings.doctorWorkingDays[1] !== undefined) ? !settings.doctorWorkingDays[1].isActive : false} onClick={() => onClickAddWorkingHour(1)}>Ekle</Button><br />
                                    <Button variant="contained" style={{ marginBottom: "5px" }} disabled={(settings.doctorWorkingDays[2] !== undefined) ? !settings.doctorWorkingDays[2].isActive : false} onClick={() => onClickAddWorkingHour(2)}>Ekle</Button><br />
                                    <Button variant="contained" style={{ marginBottom: "5px" }} disabled={(settings.doctorWorkingDays[3] !== undefined) ? !settings.doctorWorkingDays[3].isActive : false} onClick={() => onClickAddWorkingHour(3)}>Ekle</Button><br />
                                    <Button variant="contained" style={{ marginBottom: "5px" }} disabled={(settings.doctorWorkingDays[4] !== undefined) ? !settings.doctorWorkingDays[4].isActive : false} onClick={() => onClickAddWorkingHour(4)}>Ekle</Button><br />
                                    <Button variant="contained" style={{ marginBottom: "5px" }} disabled={(settings.doctorWorkingDays[5] !== undefined) ? !settings.doctorWorkingDays[5].isActive : false} onClick={() => onClickAddWorkingHour(5)}>Ekle</Button><br />
                                    <Button variant="contained" style={{ marginBottom: "5px" }} disabled={(settings.doctorWorkingDays[6] !== undefined) ? !settings.doctorWorkingDays[6].isActive : false} onClick={() => onClickAddWorkingHour(6)}>Ekle</Button>
                                </Grid>
                                <Grid item xs={5}>
                                    <div style={{ marginLeft: "15px", overflowX: "auto", width: "100%", height: "100%" }}>
                                        {(settings.doctorWorkingDays !== undefined && settings.doctorWorkingDays !== null) ?
                                            settings.doctorWorkingDays.map((doctorWorkingDay, idx) => (
                                                <Stack key={idx} direction="row" spacing={1}>
                                                    {(doctorWorkingDay.hourList !== undefined && doctorWorkingDay.hourList !== null) ?
                                                        (doctorWorkingDay.hourList.length > 0) ? doctorWorkingDay.hourList.map((hour, idx2) => (
                                                            <Chip key={idx2} label={hour} onDelete={() => handleRemoveWorkingHour(doctorWorkingDay, idx, hour, idx2)}
                                                                style={{ marginLeft: "10px", marginBottom: "14px" }} />
                                                        )) : <Chip label="" style={{ marginLeft: "10px", marginBottom: "14px", background: "transparent" }}></Chip> :
                                                        <Chip label="" style={{ marginLeft: "10px", marginBottom: "14px", background: "transparent" }}></Chip>}
                                                </Stack>
                                            )) : <Chip label="" style={{ marginLeft: "10px", marginBottom: "14px", background: "transparent" }}></Chip>}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography variant='body1' style={{ fontWeight: "bold" }}>Randevu Kapatma</Typography>
                    <Grid container spacing={3} style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} style={{ width: "calc(100% - 100px)" }}>
                                    <DatePicker
                                        label="İlk Günü Seç"
                                        value={settings.doctorPrice.vacationDate}
                                        disablePast
                                        onChange={handleVacationChange}
                                        renderInput={(params) => <TextField {...params} />} resizable
                                        timezone="Europe/Greenwich"
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} style={{ width: "calc(100% - 100px)" }}>
                                    <DatePicker
                                        label="Son Günü Seç"
                                        value={settings.doctorPrice.vacationEndDate}
                                        disablePast
                                        onChange={handleVacationEndChange}
                                        renderInput={(params) => <TextField {...params} />} resizable
                                        timezone="Europe/Greenwich"
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" onClick={resetVacation} style={{ height: "56px" }}>
                                İzin Gününü Sıfırla
                            </Button>
                        </Grid>
                    </Grid>
                    <Typography variant='body1' style={{ fontWeight: "bold" }}>Banka Hesap Bilgileri</Typography>

                    <Grid container spacing={3} style={{ marginTop: "10px" }}>
                        <Grid item xs={6}>
                            <TextField
                                id="outlined-select-currency"
                                label="Banka Adı"
                                value={(settings.doctorPrice.bankName)}
                                onChange={handleBankNameChange}
                                style={{ width: "100%" }} required>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="outlined-select-currency"
                                label="Hesap Adı"
                                value={(settings.doctorPrice.recipientNameSurname)}
                                onChange={handleRecipientNameSurnameChange}
                                style={{ width: "100%" }} required>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-select-currency"
                                label="IBAN"
                                value={(settings.doctorPrice.iban)}
                                onChange={handleIbanChange}
                                style={{ width: "100%" }} required>
                            </TextField>
                        </Grid>
                    </Grid>
                    {props.store.userType === 1 && settings?.doctorPrice?.examinationPrice > 0 && <>
                        <Typography variant='body1' style={{ fontWeight: "bold", marginTop: "15px" }}>Paket Listesi</Typography>
                        {promoList.length > 0 && <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>RANDEVU ADETİ</TableCell>
                                    <TableCell>İNDİRİM YÜZDESİ</TableCell>
                                    <TableCell>RANDEVU ÜCRETİ</TableCell>
                                    <TableCell>AÇIKLAMA</TableCell>
                                    <TableCell>İŞLEMLER</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {promoList.map((promo, idx) => (
                                    <TableRow key={idx} >
                                        <TableCell>
                                            <Typography variant="body2"> {promo.count}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2"> {promo.discount}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2"> {promo.priceAfterDiscount / promo.count}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2"> {promo.description}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Button variant='contained' color="error" onClick={() => delPromo(promo.promoId)}>
                                                Sil
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>}
                        <Button variant='contained' onClick={() => setNewPromoWindow(true)}>
                            İndirim Paketi Ekle
                        </Button>
                    </>}

                    <Typography variant='body1' style={{ fontWeight: "bold", marginTop: "15px" }}>Vizite Ücret ve Süre Bilgileri</Typography>

                    <Grid container spacing={3} style={{ marginTop: "10px" }}>
                        <Grid item xs={3}>
                            <TextField
                                id="outlined-select-currency"
                                select required
                                label="Vizite Süresi"
                                value={(settings.doctorPrice.examinationTime)}
                                onChange={handleViziteSuresiChange}
                                helperText="Hastalar ile yapılacak görüşme süresini buradan belirleyebilirsiniz">
                                {viziteSuresiArray.map((viziteSuresi, idx) => (
                                    <MenuItem key={idx} value={viziteSuresi}>
                                        {viziteSuresi}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined">
                                <TextField
                                    id="outlined-adornment-weight"
                                    label="Online Vizite Ücreti (KDV dahil) TL"
                                    type='number' required
                                    value={settings.doctorPrice.examinationPrice}
                                    onChange={handleViziteUcretiChange}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="outlined-weight-helper-text">Hastalar ile yapılacak görüşme fiyatını KDV dahil olarak buradan belirleyebilirsiniz.</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined">
                                <TextField
                                    id="outlined-adornment-weight"
                                    label="Yüzyüze Vizite Ücreti (KDV dahil) TL"
                                    type='number' required
                                    value={settings.doctorPrice.offlineExaminationPrice}
                                    onChange={handleOfflineViziteUcretiChange}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="outlined-weight-helper-text">Hastalar ile yapılacak görüşme fiyatını KDV dahil olarak buradan belirleyebilirsiniz.</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl variant="outlined">
                                <TextField
                                    id="outlined-adornment-weight"
                                    label="Viziteler arası Dinlenme Süresi" required
                                    value={settings.doctorPrice.timeBetweenExaminations}
                                    onChange={handleViziteDinlenmeSuresiChange}
                                    type='number'
                                />
                                <FormHelperText id="outlined-weight-helper-text">Hastalar ile yapılacak görüşmeler arasındaki dinlenme süresi bilgisi.</FormHelperText>
                            </FormControl>

                        </Grid>
                    </Grid>
                    <Button variant="contained" onClick={onClickSettingsGuncelle} style={{ marginTop: "10px", float: "right", marginBottom: "10px" }}>
                        Güncelle
                    </Button>
                </CardContent>
            </Card>
            <Dialog
                maxWidth={"sm"}
                open={newPromoWindow}
                onClose={closeNewPromoWindow}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    Yeni İndirim Paketi Ekle
                </DialogTitle>
                <DialogContent style={{ padding: "10px" }}>
                    <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                        <Grid sx={{marginLeft: "10px"}} item xs={12}>
                            Vizite ücretinin değiştiği durumda indirim oranı sabit tutulacak ve paket ücreti yeniden hesaplanacaktır.
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="promoCount" type='number' value={newPromo.count} variant="outlined" label="Paketteki randevu adeti"
                                onChange={onChangePromo} style={{ width: "100%" }}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="promoDiscount" type='number' value={newPromo.discount} variant="outlined" label="İndirim yüzdesi (%)"
                                onChange={onChangePromo} style={{ width: "100%" }}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="promoDesc" type='text' value={newPromo.description} variant="outlined" label="Açıklama (Opsiyonel)"
                                onChange={onChangePromo} style={{ width: "100%" }} inputProps={{ maxLength: 50 }}/>
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%', marginLeft: "10px", marginRight: "10px" }}>
                            Örnek:
                            <PromoCard promo={newPromo} appoPrice={settings?.doctorPrice?.examinationPrice} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeNewPromoWindow}>Kapat</Button>
                    <Button variant='contained' onClick={insertNewPromo}>Ekle</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}))