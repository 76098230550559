import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";

import { Grid, TextField, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import getDiagnosisList from "../../utils/api/Doctor/Diagnosis/GetDiagnosisList";

require("./AutoCompleteStyle.css");

export const AutocompleteTani = inject("store")(
	observer((props) => {
		const [activeSuggestion, setActiveSuggestion] = useState(0);
		const [filteredSuggestions, setFilteredSuggestions] = useState([]);
		const [showSuggestions, setShowSuggestions] = useState(false);
		const [userInput, setUserInput] = useState("");
		const [suggestionsListComponent, setSuggestionsListComponent] = useState(null);

		useEffect(() => {
			// Update the document title using the browser API
			if (props.diagnosisId !== 0) {
				setUserInput(props.store.selectedDiagnosisName);
			}
			createSuggestionsListComponent();
		}, []);

		useEffect(() => {
			if (props.diagnosisId !== 0) {
				setUserInput(props.store.selectedDiagnosisName);
			} else {
				setUserInput("");
			}
		}, [props.diagnosisId]);

		useEffect(() => {
			createSuggestionsListComponent();
		}, [filteredSuggestions]);

		const onChange = async (e) => {
			setUserInput(e.target.value);
			if (e.target.value.length > 2) {
				let taniListResponse = await getDiagnosisList(
					props.store.baseUrl,
					props.store.token,
					props.store.doctor[0].id,
					e.target.value
				);
				let tempTaniList = taniListResponse.data;
				let taniListFixed = [];
				for (let index = 0; index < tempTaniList.length; index++) {
					const tempTani = tempTaniList[index];
					tempTani.label = tempTani.code + " " + tempTani.diagnosisName;
					taniListFixed.push(tempTani);
				}
				setFilteredSuggestions(taniListFixed);
			} else {
				setFilteredSuggestions([]);
			}

			setActiveSuggestion(0);
			setShowSuggestions(true);
		};

		const onClickSelectSuggestion = (e) => {
			setActiveSuggestion(0);
			setFilteredSuggestions([]);
			setShowSuggestions(false);
			setUserInput(e.code + " - " + e.diagnosisName);
			props.store.setSelectedDiagnosisId(e.diagnosisId);
			props.store.setSelectedDiagnosisName(e.code + " - " + e.diagnosisName);
		};

		const onKeyDown = (e) => {
			// User pressed the enter key
			if (e.keyCode === 13) {
				setActiveSuggestion(0);
				setShowSuggestions(false);
				setUserInput(filteredSuggestions[activeSuggestion]);
			}
			// User pressed the up arrow
			else if (e.keyCode === 38) {
				if (activeSuggestion === 0) {
					return;
				}
				setActiveSuggestion(activeSuggestion - 1);
			}
			// User pressed the down arrow
			else if (e.keyCode === 40) {
				if (activeSuggestion - 1 === filteredSuggestions.length) {
					return;
				}
				setActiveSuggestion(activeSuggestion + 1);
			}
		};

		const createSuggestionsListComponent = () => {
			let suggestionsListComponent;
			if (showSuggestions && userInput !== "") {
				if (filteredSuggestions.length) {
					suggestionsListComponent = (
						<List className="suggestions">
							{filteredSuggestions.map((suggestion, index) => {
								let className;
								// Flag the active suggestion with a class
								if (index === activeSuggestion) {
									className = "suggestion-active";
								}
								return (
									<ListItem
										className={className}
										key={suggestion.diagnosisId}
										onClick={() => onClickSelectSuggestion(suggestion)}
									>
										<ListItemButton>
											<Typography variant="body2">
												<b>{suggestion.code}</b> -{" "}
												{suggestion.diagnosisName}
											</Typography>
										</ListItemButton>
									</ListItem>
								);
							})}
						</List>
					);
				} else {
					suggestionsListComponent = (
						<div className="no-suggestions">
							<em>En az 3 karekter girmeniz gerekmektedir.</em>
						</div>
					);
				}
			}
			setSuggestionsListComponent(suggestionsListComponent);
		};

		return (
			<>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<Typography variant="body2" style={{ marginBottom: "10px" }}>
							*Tanı bilgileri ICD10 kod listesinden gelmektedir.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							onChange={onChange}
							onKeyDown={onKeyDown}
							value={userInput}
							style={{ width: "100%" }}
							inputProps={{
								autocomplete: "new-password",
							}}
							label="Tanı Bilgisi"
						/>
						{suggestionsListComponent}
					</Grid>
				</Grid>
			</>
		);
	})
);
