import axios from "axios";

const putClinicProduct = async (baseUrl, token, doctorId, clinicId, product) => {

    const config = { 
        headers: { Authorization: `Bearer ${token}` }
    };

    let postResponse = null

    await axios.put(baseUrl + "/doctor/" + doctorId + "/clinic/" + clinicId +  "/product", product, config)
        .then((res) => {
            postResponse = {
                status: res.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                postResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return postResponse
}

export default putClinicProduct;