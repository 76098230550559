import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import PeopleIcon from "@mui/icons-material/PeopleOutlined";

export const ToplamHastaSayisi = (props) => (
	<Card sx={{ height: "100%" }} {...props}>
		<CardContent>
			<Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
				<Grid item>
					<Typography color="textSecondary" gutterBottom variant="overline">
						{props.text ?? "TOPLAM HASTA SAYISI"}
					</Typography>
					<Typography color="textPrimary" variant="h4">
						{props.data}
					</Typography>
				</Grid>
				<Grid item>
					<Avatar sx={{ backgroundColor: "primary.main", height: 56, width: 56 }}>
						<PeopleIcon />
					</Avatar>
				</Grid>
			</Grid>
		</CardContent>
	</Card>
);
