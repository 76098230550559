import axios from "axios";

const postAppointmentComplaint = async (baseUrl, token, doctorId, appointmentId, complaint) => {

    const config = {
        headers: { Authorization: `Bearer ${token}`,
                   "Content-Type": "multipart/form-data" 
                 }
    };

    var bodyFormData = new FormData();
    bodyFormData.append('complaint', complaint);

    let putResponse = null

    await axios.post(baseUrl + "/doctor/" + doctorId + "/appointment/" + appointmentId + "/complaint", bodyFormData, config)
        .then((res) => {
            putResponse = {
                status: res.data.status,
                data: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return putResponse
}

export default postAppointmentComplaint;