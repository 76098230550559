import React, { useState, useEffect, useRef } from "react";
import { observer, inject } from "mobx-react";
import dayjs from "dayjs";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";
import {
	Dialog,
	DialogActions,
	Divider,
	Link,
	TextField,
	DialogTitle,
	InputLabel,
	MenuItem,
	Select,
	FormControl,
	TableRow,
	TableHead,
	TableContainer,
	TablePagination,
	TableCell,
} from "@mui/material";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { trTR } from "@mui/x-data-grid/locales";
import { Patient } from "../../../model/Patient";
import { toJS } from "mobx";

import BadgeIcon from "@mui/icons-material/Badge";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CurrencyLiraIcon from "@mui/icons-material/CurrencyLira";
import RequestPageIcon from "@mui/icons-material/RequestPage";

import getAppointmentListByPatientId from "../../../utils/api/Doctor/Appointment/GetAppointmentListByPatientId";
import getPrescription from "../../../utils/api/Doctor/Appointment/Prescription/GetPrescriptionInfo";
import getReport from "../../../utils/api/Doctor/Appointment/Report/GetReport";
import getAppointmentFileList from "../../../utils/api/Doctor/Appointment/GetAppointmentFileList";
import getAppointmentInsuranceForm from "../../../utils/api/Doctor/Appointment/InsuranceForm/GetAppointmentInsuranceForm";
import getAppointmentDiagnosis from "../../../utils/api/Doctor/Appointment/Diagnosis/GetAppointmentDiagnosis";
import postDoctorPrivateNote from "../../../utils/api/Doctor/Appointment/DoctorPrivateNote/PostAppointmentPrivateNote";
import getAppointmentPrivateNote from "../../../utils/api/Doctor/Appointment/DoctorPrivateNote/GetAppointmentPrivateNote";
import getAppointmentNote from "../../../utils/api/Doctor/Appointment/Note/GetAppointmentNote";
import getAppointmentActions from "../../../utils/api/Doctor/Appointment/GetAppointmentActions";
import getPatientFileList from "../../../utils/api/Doctor/GetPatientFileList";
import getPrivateNote from "../../../utils/api/Doctor/PrivateNote/GetPrivateNote";
import putPrivateNote from "../../../utils/api/Doctor/PrivateNote/PutPrivateNote";
import getClinic from "../../../utils/api/Doctor/Clinic/GetClinic";
import { getPatientPaymentList, getAppointmentPaymentDetail, postPatientPayment } from "../../../utils/api/Doctor/Accounting/Accounting";

import DashboardInfoBox from "../dashboard/DashboardInfoBox";
import PaymentsIcon from "@mui/icons-material/Payments";
import PaymentIcon from "@mui/icons-material/Payment";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

import { Appointment, Payment } from "../../../model/Appointment";
import { ChatView } from "../chat/ChatView";
import UploadAppointmentFile from "../../upload/UploadAppointmentFile";
import TextEditor from "../../common/TextEditor";
import TextEditorPrivateNote from "../../common/TextEditorPrivateNote";
import { DekontBilgi } from "../muhasebe/DekontBilgi";
import { useReactToPrint } from "react-to-print";
import { professionName } from "../../../utils/Roles";
import { PrintButton } from "../../common/PrintButton";
import { HiOutlinePrinter } from "react-icons/hi";

import { RecetePage } from "../../../pages/doctor/RecetePage";

const CryptoJS = require("crypto-js");

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export const HastaBilgiMenu = inject("store")(
	observer((props) => {
		const [patient, setPatient] = useState(Patient.create({}));
		const [expanded, setExpanded] = useState(false);
		const [doktorHastaListesi, setDoktorHastaListesi] = useState([]);
		const [clinicId, setClinicId] = useState(null);

		const [kronikHastalikListesi, setKronikHastalikListesi] = useState(null);
		const [alerjiListesi, setAlerjiListesi] = useState([]);
		/*const [ilacListesi, setIlacListesi] = useState([])*/
		const [ameliyatListesi, setAmeliyatListesi] = useState([]);
		const [yakinKronikHastaListesi, setYakinKronikHastaListesi] = useState([]);

		const [randevuBilgiMenuDisplay, setRandevuBilgiMenuDisplay] = useState(false);
		const [selectedRandevu, setSelectedRandevu] = useState(Appointment.create({}));
		const [receteBilgi, setReceteBilgi] = useState(null);
		const [tani, setTani] = useState(null);
		const [note, setNote] = useState(null);
		const [actions, setActions] = useState(null);
		const [raporBilgi, setRaporBilgi] = useState(null);
		const [sigortaFormu, setSigortaFormu] = useState(null);

		const [appointmentFileList, setAppointmentFileList] = useState([]);
		const [patientFileList, setPatientFileList] = useState([]);

		const [uploadDisplay, setUploadDisplay] = useState(false);
		const [showPaymentMenu, setShowPaymentMenu] = useState(false);

		const [showPaymentDetail, setShowPaymentDetail] = useState(false);

		const [paymentList, setPaymentList] = useState(null);
		const [paymentSummary, setPaymentSummary] = useState({ total: 0, paid: 0, remain: 0 });
		const [paymentDetail, setPaymentDetail] = useState(Payment.create({}));
		const [paymentPrice, setPaymentPrice] = useState(null);
		const [appointmentPaymentDetail, setAppointmentPaymentDetail] = useState(null);
		const [paymentDiscount, setPaymentDiscount] = useState(0);
		const [paymentNote, setPaymentNote] = useState("");
		const [paymentCompleted, setPaymentCompleted] = useState("full");
		const [paymentType, setPaymentType] = useState("");

		const [paymentPage, setPaymentPage] = useState(0);

		const profName = professionName(props.store.userType);

		const fileTypeList = useRef([
			{ id: 0, name: "Belirsiz", show: false },
			{ id: 1, name: `${profName} Diploması`, show: false },
			{ id: 2, name: `${profName} Kaşesi`, show: false },
			{ id: 13, name: "Hasta Anamnez", show: true },
			{ id: 14, name: "Hasta Reçete", show: true },
			{ id: 15, name: "Hasta Rapor", show: true },
			{ id: 3, name: "Hasta Tahlil", show: true },
			{ id: 4, name: "Klinik", show: false },
			{ id: 5, name: "Bilgisayarlı Tomografi", show: true },
			{ id: 6, name: "Ultrason", show: true },
			{ id: 7, name: "Renkli Doppler Ultrason", show: true },
			{ id: 8, name: "Düz Grafi", show: true },
			{ id: 9, name: `${profName} Avatar`, show: false },
			{ id: 10, name: "Özel Grafiler", show: true },
			{ id: 11, name: "Manyetik Rezonans", show: true },
			{ id: 12, name: "Hasta Fotoğrafı", show: true },
		]);
		const [selectedFileType, setSelectedFileType] = useState(0);
		const canDiagnose = props.store.permissions.includes(2);
		const canExamination = props.store.permissions.includes(3);
		const canReport = props.store.permissions.includes(4);
		const canPrescription = props.store.permissions.includes(5) || props.store.permissions.includes(6);
		const canHistory = props.store.permissions.includes(7);

		/*const [selectedFileType, setSelectedFileType] = useState(0)*/

		const handleChange = (panel) => (event, isExpanded) => {
			setExpanded(isExpanded ? panel : false);
		};

		const componentRef = useRef(null);
		const handlePrint = useReactToPrint({
			content: () => componentRef.current,
		});

		useEffect(() => {
			// Update the document title using the browser API
			if (props.data !== null) {
				setPatient(props.data);
				if (props.store.userType !== 4) {
					getPatientAppointmentListReq(props.patientId);
				}
				getPatientFileListReq(props.patientId);
				getPatientPrivateNoteReq(props.patientId);
				getPatientPayment(props.patientId);

				//console.log("patientData: ", props.data)
				let medicalInfoResultArray = props.data.medicalInfos;

				if (canHistory) {
					let kronikHastalikArray = null;
					let alerjiArray = null;
					let ameliyatArray = null;
					let yakinKronikHastalikArray = null;
					let ilacArray = null;
					for (let index = 0; index < medicalInfoResultArray.length; index++) {
						const tempMedicalInfoTemp = medicalInfoResultArray[index];
						//kronik hastalıklar
						if (tempMedicalInfoTemp.medicalInfoTypeId === 3) {
							kronikHastalikArray = [];
							kronikHastalikArray.push(tempMedicalInfoTemp);
							//alerji
						} else if (tempMedicalInfoTemp.medicalInfoTypeId === 10) {
							alerjiArray = [];
							alerjiArray.push(tempMedicalInfoTemp);
							//ameliyat
						} else if (tempMedicalInfoTemp.medicalInfoTypeId === 9) {
							ameliyatArray = [];
							ameliyatArray.push(tempMedicalInfoTemp);
							//yakinların kronik hasta listesi
						} else if (tempMedicalInfoTemp.medicalInfoTypeId === 11) {
							yakinKronikHastalikArray = [];
							yakinKronikHastalikArray.push(tempMedicalInfoTemp);
							//ilac
						} else if (tempMedicalInfoTemp.medicalInfoTypeId === 8) {
							ilacArray = [];
							ilacArray.push(tempMedicalInfoTemp);
							//yakinların kronik hasta listesi
						}
					}

					setKronikHastalikListesi(kronikHastalikArray);
					setAlerjiListesi(alerjiArray);
					setAmeliyatListesi(ameliyatArray);
					setYakinKronikHastaListesi(yakinKronikHastalikArray);
					//setIlacListesi(ilacArray)
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.data]);

		const getPatientAppointmentListReq = async (patientId) => {
			props.store.setLoadingBarStatus(false);

			let getAppointmentListResponse = await getAppointmentListByPatientId(props.store.baseUrl, props.store.token, props.store.doctor[0].id, patientId);
			//console.log("getAppointmentListResponse: ", getAppointmentListResponse)
			if (getAppointmentListResponse !== null && setDoktorHastaListesi !== undefined) {
				if (getAppointmentListResponse.status === 200) {
					setDoktorHastaListesi(getAppointmentListResponse.data.reverse());
				}
			}

			props.store.setLoadingBarStatus(true);
		};

		const columns = [
			{ field: "appointmentTitle", headerName: "Randevu Bilgisi", flex: 1 },
			{ field: "appointmentDescription", headerName: "Randevu Açıklama", flex: 1 },
			{
				field: "appointmentDate",
				headerName: "Randevu Tarih Saat",
				flex: 1,
				renderCell: (params) => <>{dayjs(new Date(params.value)).format("DD.MM.YYYY HH:mm")}</>,
			},
			{
				field: "appointmentId",
				headerName: "",
				flex: 1,
				renderCell: (params) => (
					<>
						<Button color="primary" variant="contained" onClick={() => onClickOpenRandevuMenu(params.row)}>
							<InfoIcon style={{ fontSize: "20px", marginRight: "5px" }} /> Randevu Bilgisi
						</Button>
					</>
				),
			},
		];

		const onClickOpenRandevuMenu = async (appointmentData) => {
			//console.log("appointmentData: ", appointmentData)
			let appointmentDataObject = toJS(appointmentData);
			appointmentDataObject.title = appointmentDataObject.appointmentTitle;
			appointmentDataObject.description = appointmentDataObject.appointmentDescription;
			//delete appointmentDataObject.appointmentTitle
			//delete appointmentDataObject.appointmentDescription
			await getAppointmentDiagnosisReq(appointmentDataObject.appointmentId);
			await getAppointmentPrescriptionReq(appointmentDataObject.appointmentId);
			await getAppointmentReportListReq(appointmentDataObject.appointmentId);
			await getDoctorAppointmentFileListReq(appointmentDataObject.appointmentId);
			await getAppointmentInsuranceFormReq(appointmentDataObject.appointmentId);
			if (canHistory) {
				await getDoctorSpecialNoteReq(appointmentDataObject.appointmentId);
				await getDoctorNoteReq(appointmentDataObject.appointmentId);
			}
			await getAppoActions(appointmentDataObject.appointmentId);

			setSelectedRandevu(appointmentData);
			setRandevuBilgiMenuDisplay(true);
		};

		const getAppointmentDiagnosisReq = async (appointmentId) => {
			let appointmentDiagnosisResponse = await getAppointmentDiagnosis(props.store.baseUrl, props.store.token, props.store.doctor[0].id, appointmentId);
			if (appointmentDiagnosisResponse !== null && appointmentDiagnosisResponse !== undefined) {
				if (appointmentDiagnosisResponse.status === 200) {
					if (appointmentDiagnosisResponse.data !== null) {
						//console.log("appointmentDiagnosisResponse: ", appointmentDiagnosisResponse)
						setTani(appointmentDiagnosisResponse.data[0]);
					}
				}
			}
		};

		const getAppointmentPrescriptionReq = async (appointmentId) => {
			let patientPrescriptionResponse = await getPrescription(props.store.baseUrl, props.store.token, props.store.doctor[0].id, appointmentId, patient.patientId);
			if (patientPrescriptionResponse !== null && patientPrescriptionResponse !== undefined) {
				if (patientPrescriptionResponse.status === 200) {
					if (patientPrescriptionResponse.data !== null) {
						if (patientPrescriptionResponse.data.length > 0) {
							setReceteBilgi(patientPrescriptionResponse.data);
							setTani({
								diagnosisMainCode: patientPrescriptionResponse.data[0].diagnosisMainCode,
								diagnosisCode: patientPrescriptionResponse.data[0].diagnosisCode,
								diagnosisName: patientPrescriptionResponse.data[0].diagnosisName,
							});
						}
					}
				}
			}
		};

		const getAppointmentReportListReq = async (appointmentId) => {
			let reportListResponse = await getReport(props.store.baseUrl, props.store.token, props.store.doctor[0].id, appointmentId);
			if (reportListResponse !== null && reportListResponse !== undefined) {
				if (reportListResponse.status === 200) {
					//console.log("reportListResponse: ", reportListResponse.data)
					setRaporBilgi(reportListResponse.data);
				}
			}
		};

		const getAppointmentInsuranceFormReq = async (appointmentId) => {
			let insuranceFormResponse = await getAppointmentInsuranceForm(props.store.baseUrl, props.store.token, props.store.doctor[0].id, appointmentId);
			if (insuranceFormResponse !== null && insuranceFormResponse !== undefined) {
				if (insuranceFormResponse.status === 200) {
					//console.log("insuranceFormResponse: ", insuranceFormResponse.data)
					setSigortaFormu(insuranceFormResponse.data);
				}
			}
		};

		const getDoctorAppointmentFileListReq = async (appointmentId) => {
			props.store.setLoadingBarStatus(false);

			let appointmentFileListResponse = await getAppointmentFileList(props.store.baseUrl, props.store.token, props.store.doctor[0].id, appointmentId);

			if (appointmentFileListResponse !== null && appointmentFileListResponse !== undefined) {
				if (appointmentFileListResponse.status === 200) {
					//console.log("appointmentFileListResponse: ", appointmentFileListResponse)
					let fileList = appointmentFileListResponse.data;
					for (let i = 0; i < fileList.length; i++) {
						const fileTemp = fileList[i];
						const fileTempName = fileTemp.fileName.split("/")[fileTemp.fileName.split("/").length - 1];
						fileTemp.name = fileTempName;
					}
					//console.log("fileList: ", fileList)
					setAppointmentFileList(appointmentFileListResponse.data);
				}
			}

			props.store.setLoadingBarStatus(true);
		};

		const onClickRandevuMenuClose = () => {
			setRandevuBilgiMenuDisplay(false);
		};

		const saveDoctorSpecialNote = async () => {
			props.store.setLoadingBarStatus(false);

			//console.log("props.store.selectedDoctorNote: ", props.store.selectedDoctorNote)

			let encrypt = encryptWithAES(props.store.selectedDoctorNote);

			//console.log("selectedRandevu: ", selectedRandevu)

			let doctorSpecialNoteCreateResponse = await postDoctorPrivateNote(props.store.baseUrl, props.store.token, props.store.doctor[0].id, selectedRandevu.appointmentId, encrypt);

			if (doctorSpecialNoteCreateResponse !== null && doctorSpecialNoteCreateResponse !== undefined) {
				if (doctorSpecialNoteCreateResponse.status === 200) {
					props.store.setMessage("Özel notlar kaydedildi.", true, "success");
				} else {
					props.store.setMessage("Özel notlar kaydedilirken hata oluştu.", true, "error");
				}
			} else {
				props.store.setMessage("Özel notlar kaydedilirken hata oluştu.", true, "error");
			}

			props.store.setLoadingBarStatus(true);
		};

		const getDoctorSpecialNoteReq = async (appointmentId) => {
			props.store.setLoadingBarStatus(false);

			let doctorSpecialNoteResponse = await getAppointmentPrivateNote(props.store.baseUrl, props.store.token, props.store.doctor[0].id, appointmentId);
			//console.log("doctorSpecialNoteResponse: ", doctorSpecialNoteResponse)

			if (doctorSpecialNoteResponse !== null && doctorSpecialNoteResponse !== undefined) {
				if (doctorSpecialNoteResponse.status === 200) {
					if (doctorSpecialNoteResponse.data.length > 0) {
						let doctorNote = decryptWithAES(doctorSpecialNoteResponse.data[0].doctorNote);
						//console.log("doctorNote: ", doctorNote)
						props.store.setSelectedDoctorNote(doctorNote);
					} else {
						props.store.setSelectedDoctorNote("");
					}
				} else {
					props.store.setSelectedDoctorNote("");
				}
			} else {
				props.store.setSelectedDoctorNote("");
			}
			props.store.setLoadingBarStatus(true);
		};

		const getDoctorNoteReq = async (appointmentId) => {
			props.store.setLoadingBarStatus(false);

			let doctorNoteResponse = await getAppointmentNote(props.store.baseUrl, props.store.token, props.store.doctor[0].id, appointmentId);
			//console.log("doctorNoteResponse: ", doctorNoteResponse)

			if (doctorNoteResponse !== null && doctorNoteResponse !== undefined) {
				if (doctorNoteResponse.status === 200) {
					if (doctorNoteResponse.data !== null) {
						setNote(doctorNoteResponse.data);
					} else {
						setNote("");
					}
				} else {
					setNote("");
				}
			} else {
				setNote("");
			}
			props.store.setLoadingBarStatus(true);
		};

		const getAppoActions = async (appointmentId) => {
			props.store.setLoadingBarStatus(false);

			let getAppointmentActionsResponse = await getAppointmentActions(props.store.baseUrl, props.store.token, props.store.doctor[0].id, appointmentId);

			if (getAppointmentActionsResponse !== null && getAppointmentActionsResponse !== undefined) {
				if (getAppointmentActionsResponse.status === 200) {
					if (getAppointmentActionsResponse.data !== null) {
						setActions(getAppointmentActionsResponse.data);
					} else {
						setActions("");
					}
				} else {
					setActions("");
				}
			} else {
				setActions("");
			}
			props.store.setLoadingBarStatus(true);
		};

		const encryptWithAES = (text) => {
			const passphrase = "123";
			return CryptoJS.AES.encrypt(text, passphrase).toString();
		};

		const decryptWithAES = (ciphertext) => {
			if (ciphertext !== null) {
				const passphrase = "123";
				const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
				const originalText = bytes.toString(CryptoJS.enc.Utf8);
				return originalText;
			} else {
				return "";
			}
		};

		const getPatientFileListReq = async (patientId) => {
			props.store.setLoadingBarStatus(false);

			let getPatientFileResponse = await getPatientFileList(props.store.baseUrl, props.store.token, props.store.doctor[0].id, patientId);
			//console.log("getPatientFileResponse: ", getPatientFileResponse)

			if (getPatientFileResponse !== null && getPatientFileResponse !== undefined) {
				if (getPatientFileResponse.status === 200) {
					let fileList = getPatientFileResponse.data;
					for (let i = 0; i < fileList.length; i++) {
						const fileTemp = fileList[i];
						const fileTempName = fileTemp.fileName.split("/")[fileTemp.fileName.split("/").length - 1];
						fileTemp.name = fileTempName;
					}

					setPatientFileList(fileList);
				}
			}
			props.store.setLoadingBarStatus(true);
		};

		const getPatientPrivateNoteReq = async (patientId) => {
			props.store.setLoadingBarStatus(false);

			let getPatientPrivateResponse = await getPrivateNote(props.store.baseUrl, props.store.token, props.store.doctor[0].id, patientId);

			if (getPatientPrivateResponse !== null && getPatientPrivateResponse !== undefined) {
				if (getPatientPrivateResponse.status === 200) {
					//console.log("getPatientPrivateResponse.data: ", getPatientPrivateResponse.data)
					if (getPatientPrivateResponse.data !== null) {
						let privateNoteEncrypted = getPatientPrivateResponse.data.note;
						props.store.setSelectedDoctorPatientPrivateNote(decryptWithAES(privateNoteEncrypted));
					} else {
						props.store.setSelectedDoctorPatientPrivateNote("");
					}
				}
			}
			props.store.setLoadingBarStatus(true);
		};

		const getPatientPayment = async (patientId) => {
			props.store.setLoadingBarStatus(false);
			const getclinicid = await getKlinikBilgisiReq();
			setClinicId(getclinicid);

			let getPatientPayment = await getPatientPaymentList(props.store.baseUrl, props.store.token, props.store.doctor[0].id, getclinicid, patientId);

			if (getPatientPayment !== null && getPatientPayment !== undefined) {
				if (getPatientPayment.status === 200) {
					if (getPatientPayment.data !== null) {
						setPaymentList(getPatientPayment.data);
						let [total, paid, remain] = [0, 0, 0];
						getPatientPayment.data.forEach((payment) => {
							total = payment.examinationPrice + payment.priceOffline + total;
							paid = payment.totalPaid + paid;
							if (!payment.completed) {
								remain = payment.examinationPrice + payment.priceOffline - payment.totalPaid + remain;
							}
						});
						setPaymentSummary({ total: total, paid: paid, remain: remain });
					}
				}
			}
			props.store.setLoadingBarStatus(true);
		};

		const getAppointmentDetail = async (appointmentId) => {
			props.store.setLoadingBarStatus(false);
			let getAppointmentPaymentList = await getAppointmentPaymentDetail(props.store.baseUrl, props.store.token, props.store.doctor[0].id, clinicId, appointmentId);
			if (getAppointmentPaymentList !== null && getAppointmentPaymentList !== undefined) {
				if (getAppointmentPaymentList.status === 200) {
					if (getAppointmentPaymentList.data !== null) {
						setAppointmentPaymentDetail(getAppointmentPaymentList.data);
					}
				}
			}
			props.store.setLoadingBarStatus(true);
		};

		const saveDoctorPatientSpecialNote = async () => {
			props.store.setLoadingBarStatus(false);

			let privateNote = {
				doctorId: props.store.doctor[0].id,
				patientId: patient.patientId,
				note: encryptWithAES(props.store.selectedDoctorPatientPrivateNote),
			};

			let privateNoteCreateResponse = await putPrivateNote(props.store.baseUrl, props.store.token, props.store.doctor[0].id, patient.patientId, privateNote);

			if (privateNoteCreateResponse !== null && privateNoteCreateResponse !== undefined) {
				if (privateNoteCreateResponse.status === 200) {
					props.store.setMessage("Hasta ile ilgili özel not başarıyla kaydedildi.", true, "success");
					//props.store.setSelectedDoctorPatientPrivateNote("")
				} else {
					props.store.setMessage("Hasta ile ilgili özel not kaydedilirken hata oluştu.", true, "success");
				}
			} else {
				props.store.setMessage("Hasta ile ilgili özel not kaydedilirken hata oluştu.", true, "success");
			}
			props.store.setLoadingBarStatus(true);
		};

		const onClickGeriDon = () => {
			props.history.push("/panel/hasta");
		};

		const getBloodGroupName = (bloodGroupValue) => {
			return bloodGroupValue === 1
				? "A RH +"
				: bloodGroupValue === 2
				? "A RH -"
				: bloodGroupValue === 3
				? "B RH +"
				: bloodGroupValue === 4
				? "B RH -"
				: bloodGroupValue === 5
				? "AB RH +"
				: bloodGroupValue === 6
				? "AB RH -"
				: bloodGroupValue === 7
				? "0 RH +"
				: bloodGroupValue === 8
				? "0 RH -"
				: "Belirsiz";
		};

		const onClickOpenUploadMenu = () => {
			setUploadDisplay(true);
		};

		const onClickCloseUploadMenu = () => {
			setUploadDisplay(false);
		};

		const onChangeFileTypeSelect = (event) => {
			setSelectedFileType(event.target.value);
		};

		const setPatientPayment = (data) => {
			const payment = paymentList.find((payment) => payment.appointmentId === Number(data.target.dataset.id));
			setPaymentDetail(payment);
			setPaymentPrice((payment?.examinationPrice ?? 0) + payment.priceOffline - payment.totalPaid);
			handleShowPayment();
		};

		const handleShowPayment = () => {
			if (showPaymentMenu) {
				setPaymentPrice(null);
				setPaymentNote("");
				setPaymentCompleted("full");
				setPaymentType("");
				setPaymentDiscount(null);
			}
			setShowPaymentMenu(!showPaymentMenu);
		};

		const handleShowPaymentDetail = () => {
			if (showPaymentDetail) {
				setAppointmentPaymentDetail(null);
			}
			setShowPaymentDetail(!showPaymentDetail);
		};

		const handleAppointmentPaymentDetail = (data) => {
			getAppointmentDetail(Number(data.target.dataset.id));
			handleShowPaymentDetail();
		};

		const handlePrintButton = async (data) => {
			props.store.setLoadingBarStatus(false);
			let getAppointmentPaymentList = await getAppointmentPaymentDetail(props.store.baseUrl, props.store.token, props.store.doctor[0].id, clinicId, Number(data.target.dataset.id));
			if (getAppointmentPaymentList !== null && getAppointmentPaymentList !== undefined) {
				if (getAppointmentPaymentList.status === 200) {
					if (getAppointmentPaymentList.data !== null) {
						setAppointmentPaymentDetail(getAppointmentPaymentList.data);
						handlePrint();
					}
				}
			}
			props.store.setLoadingBarStatus(true);
		};

		const onChangePaymentTextInput = (event) => {
			const totalPrice = (paymentDetail?.examinationPrice ?? 0) + (paymentDetail.priceOffline ?? 0) - paymentDetail.totalPaid;
			if (event.target.name === "paymentPrice") {
				if (Number(event.target.value) > 0) {
					setPaymentPrice(Number(event.target.value));
					setPaymentDiscount((100 - (Number(event.target.value) / totalPrice) * 100).toFixed(2));
				} else if (event.target.value === "") {
					setPaymentPrice(0);
					setPaymentDiscount(0);
				}
			} else if (event.target.name === "paymentDiscount") {
				if (Number(event.target.value) >= 0 && Number(event.target.value) < 100) {
					setPaymentDiscount(Number(event.target.value).toFixed(2));
					setPaymentPrice(totalPrice - (totalPrice * Number(event.target.value)) / 100);
				}
			} else if (event.target.name === "paymentNote") {
				setPaymentNote(event.target.value);
			} else if (event.target.name === "paymentCompleted") {
				setPaymentCompleted(event.target.value);
			} else if (event.target.name === "paymentType") {
				setPaymentType(event.target.value);
			}
		};

		const submitPayment = async (data) => {
			props.store.setLoadingBarStatus(false);
			const postPayment = {
				appointmentid: Number(data.target.dataset.id),
				clinicid: clinicId,
				patientid: props.patientId,
				price: paymentCompleted === "close" ? 0 : paymentPrice,
				note: paymentNote,
				type: paymentType,
				completed:
					paymentCompleted === "full" || paymentCompleted === "close" || (paymentDetail?.examinationPrice ?? 0) + paymentDetail.priceOffline === paymentDetail.totalPaid + paymentPrice,
			};

			let postPatientPaymentResponse = await postPatientPayment(props.store.baseUrl, props.store.token, props.store.doctor[0].id, postPayment);
			if (postPatientPaymentResponse !== null && postPatientPaymentResponse !== undefined) {
				if (postPatientPaymentResponse.status === 200) {
					props.store.setMessage("Ödeme kaydı başarılı bir şekilde gerçekleştirildi.", true, "success");
					let paymentList = await getPatientPayment(postPayment.patientid);
					handleShowPayment();
					if (paymentList !== undefined && paymentList !== null) {
						if (paymentList.status === 200) {
						} else if (paymentList.status === 401) {
							this.props.store.setUserLogout();
							window.location.reload();
						}
					} else {
						this.props.store.setMessage("Ödeme listesi getirilirken hata oluştu.", true, "error");
					}
				} else {
					props.store.setMessage(postPatientPaymentResponse.data.message, true, "error");
				}
			} else {
				props.store.setMessage("Ödeme kaydedilirken hata oluştu.", true, "error");
			}
			props.store.setLoadingBarStatus(true);
		};

		const getKlinikBilgisiReq = async () => {
			let clinicId = null;
			let getClinicResponse = await getClinic(props.store.baseUrl, props.store.token, props.store.doctor[0].id);
			if (getClinicResponse !== null && getClinicResponse !== undefined) {
				if (getClinicResponse.status === 200) {
					if (getClinicResponse.data !== null) {
						clinicId = getClinicResponse.data.clinicId;
					}
				}
			}
			return clinicId;
		};

		const handleChangePage = (event, newPage) => {
			setPaymentPage(newPage);
		};

		return (
			<div className="container-xxl" style={{ marginTop: "0px" }}>
				<Card style={{ marginTop: "8px", height: "auto" }}>
					<CardContent style={{ height: "auto", padding: "0px" }}>
						<Grid container spacing={3}>
							<Grid item xs={3} style={{ boxShadow: "0px 0px 10px 0px rgb(209, 209, 209)", height: "100vh", padding: "40px", paddingRight: "10px" }}>
								<Typography color="secondary" variant="h5" style={{ fontWeight: "bold" }}>
									Hasta Dosyası
								</Typography>
								<BadgeIcon style={{ fontSize: "100px", color: "#aeaeae" }} />
								<Typography variant="body1" style={{ marginBottom: "10px" }}>
									<b>T.C. No:</b> {patient.tckno.toString().substr(0, 3) + "********"}
								</Typography>
								<Typography variant="body1" style={{ marginBottom: "10px" }}>
									<b>Adı:</b> {patient.name}
								</Typography>
								<Typography variant="body1" style={{ marginBottom: "10px" }}>
									<b>Soyadı:</b> {patient.surname}
								</Typography>
								<Typography variant="body1" style={{ marginBottom: "10px" }}>
									<b>Telefon Numarası:</b> {patient.mobilePhone}
								</Typography>
								<Typography variant="body1" style={{ marginBottom: "10px" }}>
									<b>Cinsiyet:</b> {patient.gender === 1 ? "Erkek" : patient.gender === 2 ? "Kadın" : "Belirsiz"}
								</Typography>
								<Typography variant="body1" style={{ marginBottom: "10px" }}>
									<b>Yaşı:</b> {dayjs(patient.birthDate).year() > 1900 ? dayjs(new Date()).diff(patient.birthDate, "year") : "-"}
								</Typography>

								<Button onClick={onClickOpenUploadMenu} color="primary" variant="contained" style={{ float: "right" }}>
									<UploadFileIcon style={{ marginRight: "10px" }} /> Dosya Yükle
								</Button>
							</Grid>
							<Grid item xs={9} style={{ paddingLeft: "5px" }}>
								<div style={{ height: "60px" }}>
									<Button color="primary" variant="contained" onClick={onClickGeriDon} style={{ marginTop: "10px", marginRight: "10px", float: "right" }}>
										<ArrowLeftIcon /> Geri Dön
									</Button>
								</div>
								{props.store.userType !== 4 && (
									<Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} style={{ background: expanded === "panel1" ? "#f4f4f4" : "#fff" }}>
										<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
											<Typography sx={{ width: "33%", flexShrink: 0 }} style={{ fontWeight: "bold" }}>
												Görüşme Bilgileri
											</Typography>
											<Typography sx={{ color: "text.secondary" }}>Seçtiğiniz hasta ile yaptığınız görüşmelerin listesi</Typography>
										</AccordionSummary>
										<AccordionDetails style={{ height: "400px" }}>
											<DataGrid
												rows={doktorHastaListesi !== null ? doktorHastaListesi : []}
												columns={columns}
												pageSize={5}
												rowsPerPageOptions={[5]}
												disableSelectionOnClick
												localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
												color="primary"
												style={{ border: "0" }}
											/>
										</AccordionDetails>
									</Accordion>
								)}
								<Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")} style={{ background: expanded === "panel3" ? "#f4f4f4" : "#fff" }}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
										<Typography sx={{ width: "33%", flexShrink: 0 }} style={{ fontWeight: "bold" }}>
											Hasta Dosya Bilgileri
										</Typography>
										<Typography sx={{ color: "text.secondary" }}>Seçtiğiniz hasta ile yaptığınız görüşmelerin esnasında yüklenen dosyaların listesi</Typography>
									</AccordionSummary>
									<AccordionDetails style={{ height: "400px", maxHeight: "400px", overflowY: "auto" }}>
										<TableContainer>
											<Table aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell>KATEGORİ</TableCell>
														<TableCell>DOSYA ADI</TableCell>
														<TableCell>YÜKLENME TARİHİ</TableCell>
														<TableCell>İŞLEMLER</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{patientFileList !== null ? (
														patientFileList.map((file, idx) => (
															<TableRow key={idx}>
																<TableCell>{fileTypeList.current.map((fileType, idx) => (fileType.id === file.fileType ? fileType.name : null))}</TableCell>
																<TableCell>{file?.name}</TableCell>
																<TableCell>{dayjs(file?.createdDate).format("DD.MM.YYYY HH:mm")}</TableCell>
																<TableCell>
																	<Button component={Link} href={props.store.baseUrl + file?.fileName} target="_blank" variant="contained" color="primary">
																		<InfoIcon style={{ marginRight: "10px" }} /> Görüntüle
																	</Button>
																</TableCell>
															</TableRow>
														))
													) : (
														<TableRow>
															<TableCell>
																<Typography variant="body2">Bu görüşmede kayıt altına alınmış bir dosya bulunmamaktadır</Typography>
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</TableContainer>
									</AccordionDetails>
								</Accordion>
								<Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")} style={{ background: expanded === "panel2" ? "#f4f4f4" : "#fff" }}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
										<Typography sx={{ width: "33%", flexShrink: 0 }} style={{ fontWeight: "bold" }}>
											Medikal Bilgileri
										</Typography>
										<Typography sx={{ color: "text.secondary" }}>Seçtiğiniz hastaya ait medikal bilgiler</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid container>
											<Grid item xs={6}>
												<Typography variant="body2" style={{ fontWeight: "bold" }}>
													Fiziksel Özellikler
												</Typography>

												<Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
													<Chip key={"boy"} label={"Boy: " + patient.height + " cm"} color="primary" style={{ color: "#fff" }} />
												</Stack>

												<Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
													<Chip key={"kilo"} label={"Kilo: " + patient.weight + " kg"} color="primary" style={{ color: "#fff" }} />
												</Stack>

												<Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
													<Chip key={"kan-grubu"} label={"Kan Grubu: " + getBloodGroupName(patient.bloodGroup)} color="primary" style={{ color: "#fff" }} />
												</Stack>

												<Typography variant="body2" style={{ fontWeight: "bold", marginTop: "10px" }}>
													Ameliyat
												</Typography>
												<Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
													{ameliyatListesi !== null ? (
														ameliyatListesi.length > 0 ? (
															ameliyatListesi.map((ameliyat, idx) => <Chip key={idx} label={ameliyat.description} color="primary" style={{ color: "#fff" }} />)
														) : (
															<Chip key={"yok"} label="Hasta tarafından beyan edilmiş ameliyat bilgisi bulunmamaktadır" color="primary" style={{ color: "#fff" }} />
														)
													) : (
														<Chip key={"yok"} label="Hasta tarafından beyan edilmiş ameliyat bilgisi bulunmamaktadır" color="primary" style={{ color: "#fff" }} />
													)}
												</Stack>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="body2" style={{ fontWeight: "bold", marginTop: "10px" }}>
													Kronik Hastalıklar
												</Typography>
												<Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
													{kronikHastalikListesi !== null ? (
														kronikHastalikListesi.map((kronikHastalik, idx) => (
															<Chip key={idx} label={kronikHastalik.description} color="primary" style={{ color: "#fff" }} />
														))
													) : (
														<Chip key={"yok"} label="Hasta tarafından beyan edilmiş kronik hastalık bilgisi bulunmamaktadır" color="primary" style={{ color: "#fff" }} />
													)}
												</Stack>

												<Typography variant="body2" style={{ fontWeight: "bold", marginTop: "10px" }}>
													Alerjiler
												</Typography>
												<Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
													{alerjiListesi !== null ? (
														alerjiListesi.map((alerji, idx) => <Chip key={idx} label={alerji.description} color="primary" style={{ color: "#fff" }} />)
													) : (
														<Chip key={"yok"} label="Hasta tarafından beyan edilmiş alerji bilgisi bulunmamaktadır" color="primary" style={{ color: "#fff" }} />
													)}{" "}
												</Stack>

												<Typography variant="body2" style={{ fontWeight: "bold", marginTop: "10px" }}>
													Hasta Yakınında Bulunan Kronik Hastalıklar
												</Typography>
												<Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
													{yakinKronikHastaListesi !== null ? (
														yakinKronikHastaListesi.length > 0 ? (
															yakinKronikHastaListesi.map((yakinHastalik, idx) => (
																<Chip key={idx} label={yakinHastalik.description} color="primary" style={{ color: "#fff" }} />
															))
														) : (
															<Chip
																key={"yok"}
																label="Hasta tarafından beyan edilmiş yakınlara ait kronik hasta bilgisi bulunmamaktadır"
																color="primary"
																style={{ color: "#fff" }}
															/>
														)
													) : (
														<Chip
															key={"yok"}
															label="Hasta tarafından beyan edilmiş yakınlara ait kronik hasta bilgisi bulunmamaktadır"
															color="primary"
															style={{ color: "#fff" }}
														/>
													)}
												</Stack>
											</Grid>
										</Grid>

										{/*<Typography variant='body2' style={{ fontWeight: "bold", marginTop: "10px" }}>İlaçlar</Typography>
                                    <Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
                                        {(ilacListesi !== null) ? (ilacListesi.length > 0) ? ilacListesi.map((ilac, idx) => (
                                            <Chip key={idx} label={ilac.description} color="primary" style={{ color: "#fff" }} />
                                        )) : <Typography>İlgili hastaya ilişkin sistemde kayıtlı düzenli kullandığı ilaç bilgisi bulunmamaktadır.</Typography>
                                            : <Typography>İlgili hastaya ilişkin sistemde kayıtlı düzenli kullandığı ilaç bilgisi bulunmamaktadır.</Typography>}
                                        </Stack>
                                    */}
									</AccordionDetails>
								</Accordion>
								<Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")} style={{ background: expanded === "panel5" ? "#f4f4f4" : "#fff" }}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
										<Typography sx={{ width: "33%", flexShrink: 0 }} style={{ fontWeight: "bold" }}>
											Notlarım
										</Typography>
										<Typography sx={{ color: "text.secondary" }}>
											Hastanız ile ilgili özel notlarınızI buradan kaydedebilirsiniz. Bu alanda kaydettiğiniz hiçbir bilgi hastaya gösterilmez
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<TextEditorPrivateNote />
										<Button variant="contained" color="primary" onClick={saveDoctorPatientSpecialNote} style={{ float: "right", marginTop: "5px", marginBottom: "5px" }}>
											Kaydet
										</Button>
									</AccordionDetails>
								</Accordion>
								<Accordion expanded={expanded === "panel6"} onChange={handleChange("panel6")} style={{ background: expanded === "panel6" ? "#f4f4f4" : "#fff" }}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6bh-content" id="panel6bh-header">
										<Typography sx={{ width: "33%", flexShrink: 0 }} style={{ fontWeight: "bold" }}>
											Hasta Cari Bilgileri
										</Typography>
										<Typography sx={{ color: "text.secondary" }}>Seçtiğiniz hastanın randevu geçmişi, muayene ücretleri ve ödemeleri</Typography>
									</AccordionSummary>
									<AccordionDetails style={{ height: "500px", maxHeight: "500px", overflowY: "auto" }}>
										<TableContainer>
											<Table aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell>#</TableCell>
														<TableCell>Randevu Tarihi</TableCell>
														<TableCell>Kalan Ücret(TL)</TableCell>
														<TableCell>Durum</TableCell>
														<TableCell>İşlemler</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{paymentList !== null ? (
														paymentList.slice(paymentPage * 5, paymentPage * 5 + 5).map((payment, idx) => (
															<TableRow key={idx}>
																<TableCell>{paymentList.length - idx}</TableCell>
																<TableCell>{!!payment?.appointmentDate && dayjs(payment?.appointmentDate).format("DD.MM.YYYY HH:mm")}</TableCell>
																<TableCell>{payment?.completed ? undefined : (payment?.examinationPrice ?? 0) + payment?.priceOffline - payment?.totalPaid}</TableCell>
																<TableCell>{payment?.completed ? "Ödendi" : "Ödeme Bekliyor"}</TableCell>
																<TableCell>
																	<Button
																		color="primary"
																		variant="contained"
																		data-id={payment?.appointmentId}
																		disabled={!payment?.appointmentId}
																		onClick={handleAppointmentPaymentDetail}
																	>
																		<InfoIcon style={{ fontSize: "20px", marginRight: "5px" }} /> Detay
																	</Button>
																	{!payment?.completed && (
																		<Button
																			style={{ marginLeft: "10px" }}
																			disabled={!payment?.appointmentId}
																			data-id={payment?.appointmentId}
																			color="primary"
																			variant="contained"
																			onClick={setPatientPayment}
																		>
																			<CurrencyLiraIcon style={{ fontSize: "20px", marginRight: "5px" }} /> Ödeme
																		</Button>
																	)}
																	<Button
																		style={{ marginLeft: "10px" }}
																		color="success"
																		data-id={payment?.appointmentId}
																		variant="contained"
																		onClick={handlePrintButton}
																	>
																		<RequestPageIcon style={{ fontSize: "20px", marginRight: "5px" }} />
																		Dekont
																	</Button>
																</TableCell>
															</TableRow>
														))
													) : (
														<TableRow>
															<TableCell>
																<Typography variant="body2">Kişiye ait geçmiş ödeme bilgisi bulunmamaktadır.</Typography>
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</TableContainer>
										{paymentList !== null && (
											<TablePagination
												component="div"
												count={paymentList?.length}
												pagesize={5}
												rowsPerPage={5}
												rowsPerPageOptions={[5]}
												onPageChange={handleChangePage}
												page={paymentPage}
											/>
										)}
									</AccordionDetails>
								</Accordion>
								{(!!paymentSummary.total || !!paymentSummary.paid || !!paymentSummary.remain) && (
									<Grid container spacing={3}>
										<Grid item md={4} xs={6}>
											<DashboardInfoBox sx={{ height: "100%" }} data={paymentSummary.total} text="TOPLAM İŞLEM TUTARI" color="#CECE5A" image={<PaymentIcon />} />
										</Grid>
										<Grid item md={4} xs={6}>
											<DashboardInfoBox sx={{ height: "100%" }} data={paymentSummary.paid} text="ÖDENEN TUTAR" color="#22A699" image={<CreditScoreIcon />} />
										</Grid>
										<Grid item md={4} xs={6}>
											<DashboardInfoBox sx={{ height: "100%" }} data={paymentSummary.remain} text="KALAN BAKİYE" color="#FD8D14" image={<PaymentsIcon />} />
										</Grid>
									</Grid>
								)}
							</Grid>
						</Grid>
					</CardContent>
				</Card>
				<Dialog fullScreen open={randevuBilgiMenuDisplay} /*onClose={closeReceteMenuDialog}*/>
					<AppBar sx={{ position: "relative", background: "#2196f3" }}>
						<Toolbar>
							<IconButton onClick={onClickRandevuMenuClose} edge="start" color="inherit" aria-label="close">
								<CloseIcon />
							</IconButton>
							<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
								Seçilen Randevu Bilgisi
							</Typography>
						</Toolbar>
					</AppBar>
					<DialogContent>
						<Grid container spacing={3}>
							<Grid item xs={8}>
								<Typography variant="body2" style={{ fontWeight: "bold" }}>
									Hasta Bilgisi
								</Typography>
								<TableContainer>
									<Table aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>HASTA ADI SOYADI</TableCell>
												<TableCell>HASTA YAŞ</TableCell>
												<TableCell>HASTA CİNSİYET</TableCell>
												<TableCell>HASTA BOY</TableCell>
												<TableCell>HASTA KİLO</TableCell>
												<TableCell>RANDEVU TARİH SAAT</TableCell>
												<TableCell>RANDEVU BİLGİ</TableCell>
												<TableCell>RANDEVU AÇIKLAMA</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell>{selectedRandevu.patientName + " " + selectedRandevu.patientSurname}</TableCell>
												<TableCell>{dayjs(selectedRandevu.patientBirthDate).year() > 1900 ? dayjs(new Date()).diff(selectedRandevu.patientBirthDate, "year") : "-"}</TableCell>
												<TableCell>{selectedRandevu.patientGender === 1 ? "Erkek" : selectedRandevu.patientGender === 2 ? "Kadın" : "Belirsiz"}</TableCell>
												<TableCell>{selectedRandevu.patientHeight + " cm"}</TableCell>
												<TableCell>{selectedRandevu.patientWeight + " kg"}</TableCell>
												<TableCell>{dayjs(new Date(selectedRandevu.appointmentDate)).format("DD.MM.YYYY hh:mm")}</TableCell>
												<TableCell>{selectedRandevu.title}</TableCell>
												<TableCell>{selectedRandevu.description}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>

								<Divider style={{ marginTop: "15px", marginBottom: "15px" }} />

								{canHistory && (
									<>
										<Typography variant="body2" style={{ fontWeight: "bold" }}>
											Notlar
										</Typography>
										<TableContainer>
											<Table aria-label="simple table">
												<TableBody>
													{!!note?.complaint ? (
														<TableRow>
															<TableCell>Hasta Şikayeti ve Öyküsü: {note?.complaint}</TableCell>
														</TableRow>
													) : (
														<TableRow>
															<TableCell>
																<Typography variant="body2">Bu görüşmede kayıt altına alınmış bir hasta şikayeti bulunmamaktadır</Typography>
															</TableCell>
														</TableRow>
													)}
													{!!note?.symptom ? (
														<TableRow>
															<TableCell>Semptom ve Bulgular: {note?.symptom}</TableCell>
														</TableRow>
													) : (
														<TableRow>
															<TableCell>
																<Typography variant="body2">Bu görüşmede kayıt altına alınmış bir semptom veya bulgu bulunmamaktadır</Typography>
															</TableCell>
														</TableRow>
													)}
													{!!note?.history ? (
														<TableRow>
															<TableCell>Hasta Geçmiş Bilgileri: {note?.history}</TableCell>
														</TableRow>
													) : (
														<TableRow>
															<TableCell>
																<Typography variant="body2">Bu görüşmede kayıt altına alınmış bir geçmiş bilgisi bulunmamaktadır</Typography>
															</TableCell>
														</TableRow>
													)}
													{!!note?.familyIllness ? (
														<TableRow>
															<TableCell>Hasta Geçmiş Bilgileri: {note?.familyIllness}</TableCell>
														</TableRow>
													) : (
														<TableRow>
															<TableCell>
																<Typography variant="body2">Bu görüşmede kayıt altına alınmış bir hasta yakını geçmişi bilgisi bulunmamaktadır</Typography>
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</TableContainer>
									</>
								)}

								{ameliyatListesi?.length > 0 && (
									<>
										<Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
										<Typography variant="body2" style={{ fontWeight: "bold", marginTop: "10px" }}>
											Ameliyat
										</Typography>
										<Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
											{ameliyatListesi !== null ? (
												ameliyatListesi.length > 0 ? (
													ameliyatListesi.map((ameliyat, idx) => <Chip key={idx} label={ameliyat.description} color="primary" style={{ color: "#fff" }} />)
												) : (
													<Chip key={"yok"} label="Hasta tarafından beyan edilmiş ameliyat bilgisi bulunmamaktadır" color="primary" style={{ color: "#fff" }} />
												)
											) : (
												<Chip key={"yok"} label="Hasta tarafından beyan edilmiş ameliyat bilgisi bulunmamaktadır" color="primary" style={{ color: "#fff" }} />
											)}
										</Stack>
									</>
								)}

								{kronikHastalikListesi?.length > 0 && (
									<>
										<Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
										<Typography variant="body2" style={{ fontWeight: "bold", marginTop: "10px" }}>
											Kronik Hastalıklar
										</Typography>
										<Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
											{kronikHastalikListesi !== null ? (
												kronikHastalikListesi.map((kronikHastalik, idx) => <Chip key={idx} label={kronikHastalik.description} color="primary" style={{ color: "#fff" }} />)
											) : (
												<Chip key={"yok"} label="Hasta tarafından beyan edilmiş kronik hastalık bilgisi bulunmamaktadır" color="primary" style={{ color: "#fff" }} />
											)}
										</Stack>
									</>
								)}

								{alerjiListesi?.length > 0 && (
									<>
										<Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
										<Typography variant="body2" style={{ fontWeight: "bold", marginTop: "10px" }}>
											Alerjiler
										</Typography>
										<Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
											{alerjiListesi !== null ? (
												alerjiListesi.map((alerji, idx) => <Chip key={idx} label={alerji.description} color="primary" style={{ color: "#fff" }} />)
											) : (
												<Chip key={"yok"} label="Hasta tarafından beyan edilmiş alerji bilgisi bulunmamaktadır" color="primary" style={{ color: "#fff" }} />
											)}{" "}
										</Stack>
									</>
								)}

								{yakinKronikHastaListesi?.length > 0 && (
									<>
										<Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
										<Typography variant="body2" style={{ fontWeight: "bold", marginTop: "10px" }}>
											Hasta Yakınında Bulunan Kronik Hastalıklar
										</Typography>
										<Stack direction="row" spacing={1} style={{ marginTop: "15px" }}>
											{yakinKronikHastaListesi !== null ? (
												yakinKronikHastaListesi.length > 0 ? (
													yakinKronikHastaListesi.map((yakinHastalik, idx) => <Chip key={idx} label={yakinHastalik.description} color="primary" style={{ color: "#fff" }} />)
												) : (
													<Chip
														key={"yok"}
														label="Hasta tarafından beyan edilmiş yakınlara ait kronik hasta bilgisi bulunmamaktadır"
														color="primary"
														style={{ color: "#fff" }}
													/>
												)
											) : (
												<Chip key={"yok"} label="Hasta tarafından beyan edilmiş yakınlara ait kronik hasta bilgisi bulunmamaktadır" color="primary" style={{ color: "#fff" }} />
											)}
										</Stack>
									</>
								)}

								<Divider style={{ marginTop: "15px", marginBottom: "15px" }} />

								{canDiagnose && (
									<>
										<Typography variant="body2" style={{ fontWeight: "bold" }}>
											Tanı Bilgisi
										</Typography>
										<TableContainer>
											<Table aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell>TANI ANA KODU</TableCell>
														<TableCell>TANI KODU</TableCell>
														<TableCell>TANI İSMİ</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{tani !== null ? (
														<TableRow>
															<TableCell>{tani !== null ? tani.diagnosisMainCode : ""}</TableCell>
															<TableCell>{tani !== null ? tani.diagnosisCode : "-"}</TableCell>
															<TableCell>{tani !== null ? tani.diagnosisName : ""}</TableCell>
														</TableRow>
													) : (
														<TableRow>
															<TableCell>
																<Typography variant="body2">Bu görüşmede kayıt altına alınmış bir tanı bulunmamaktadır</Typography>
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</TableContainer>
										<Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
									</>
								)}
								{canHistory && (
									<>
										<Typography variant="body2" style={{ fontWeight: "bold" }}>
											Tedavi Bilgisi
										</Typography>
										<TableContainer>
											<Table aria-label="simple table">
												<TableBody>
													{!!actions?.treatment || actions?.actions?.length > 0 ? (
														<>
															<TableRow>
																<TableCell>
																	{actions.actions?.map((action) => (
																		<Chip key={action.actionTypeId} label={action.actionName} color="primary" style={{ color: "#fff", marginInlineEnd: "5px" }} />
																	))}
																</TableCell>
															</TableRow>
															{!!actions.treatment && (
																<TableRow>
																	<TableCell>{actions.treatment}</TableCell>
																</TableRow>
															)}
														</>
													) : (
														<TableRow>
															<TableCell>
																<Typography variant="body2">Bu görüşmede kayıt altına alınmış bir tedavi bulunmamaktadır</Typography>
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</TableContainer>

										<Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
									</>
								)}

								{canPrescription && (
									<>
										<Typography variant="body2" style={{ fontWeight: "bold" }}>
											Reçete Bilgisi
										</Typography>
										<TableContainer>
											<Table aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell>İLAÇ ADI</TableCell>
														<TableCell>PERİYOT</TableCell>
														<TableCell>DOZ</TableCell>
														<TableCell>ADET</TableCell>
														<TableCell>
															<PrintButton content={<RecetePage patient={patient} appointment={selectedRandevu} recete={receteBilgi} tani={tani} />}>
																<HiOutlinePrinter
																	style={{
																		marginInlineStart: "5px",
																		fontSize: "20px",
																		color: "#03a9f4",
																		cursor: "pointer",
																	}}
																/>
															</PrintButton>
															<PrintButton pdf content={<RecetePage patient={patient} appointment={selectedRandevu} recete={receteBilgi} tani={tani} />}>
																<HiOutlinePrinter
																	style={{
																		marginInlineStart: "5px",
																		fontSize: "20px",
																		color: "#03a9f4",
																		cursor: "pointer",
																	}}
																/>
															</PrintButton>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{receteBilgi !== null ? (
														receteBilgi.map((recete, idx) => (
															<TableRow key={idx}>
																<TableCell>{recete !== null ? recete.medicineName : "-"}</TableCell>
																<TableCell>{recete !== null ? recete.medicinePeriod : ""}</TableCell>
																<TableCell>{recete !== null ? recete.dose : ""}</TableCell>
																<TableCell>{recete !== null ? recete.count : ""}</TableCell>
															</TableRow>
														))
													) : (
														<TableRow>
															<TableCell>
																<Typography variant="body2">Bu görüşmede kayıt altına alınmış bir reçete bulunmamaktadır</Typography>
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</TableContainer>
										<Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
									</>
								)}

								{canReport && (
									<>
										<Typography variant="body2" style={{ fontWeight: "bold" }}>
											Rapor Bilgisi
										</Typography>
										<TableContainer>
											<Table aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell>RAPOR AÇIKLAMA</TableCell>
														<TableCell>RAPOR TİP</TableCell>
														<TableCell>RAPOR TARİHİ</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{raporBilgi !== null ? (
														<TableRow key={"rapor"}>
															<TableCell>{raporBilgi?.reportDecision}</TableCell>
															<TableCell>
																{raporBilgi?.reportType === 3 ? "İş Görmezlik Raporu" : raporBilgi?.reportType === 2 ? "Durum Raporu" : "Doğum Raporu"}
															</TableCell>
															<TableCell>
																{dayjs(new Date(raporBilgi?.reportStartDate)).format("DD.MM.YYYY") +
																	" - " +
																	dayjs(new Date(raporBilgi?.reportEndDate)).format("DD.MM.YYYY")}
															</TableCell>
														</TableRow>
													) : (
														<TableRow>
															<TableCell>
																<Typography variant="body2">Bu görüşmede kayıt altına alınmış bir rapor bulunmamaktadır</Typography>
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</TableContainer>
										<Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
									</>
								)}

								<Typography variant="body2" style={{ fontWeight: "bold" }}>
									Sigorta Formu Bilgisi
								</Typography>
								<TableContainer style={{ width: "100%" }}>
									<Table aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>RANDEVU ZAMANI</TableCell>
												<TableCell>HASTA ADI SOYADI</TableCell>
												{canDiagnose && <TableCell>TANI BİLGİSİ</TableCell>}
											</TableRow>
										</TableHead>
										<TableBody>
											{sigortaFormu !== null ? (
												<TableRow>
													<TableCell>{dayjs(sigortaFormu?.appointmentDate).format("DD.MM.YYYY HH:mm")}</TableCell>
													<TableCell>{sigortaFormu?.patientName + " " + sigortaFormu?.patientSurname}</TableCell>
													{canDiagnose && <TableCell>{sigortaFormu?.diagnosis}</TableCell>}
												</TableRow>
											) : (
												<TableRow>
													<TableCell>
														<Typography variant="body2">Bu görüşmede kayıt altına alınmış bir sigorta bilgi formu bulunmamaktadır</Typography>
													</TableCell>
												</TableRow>
											)}
										</TableBody>
									</Table>
								</TableContainer>

								<Divider style={{ marginTop: "15px", marginBottom: "15px" }} />

								{canExamination && (
									<>
										<Typography variant="body2" style={{ fontWeight: "bold", marginTop: "10px" }}>
											Tetkik İstek Bilgisi
										</Typography>
										<TableContainer>
											<Table aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell>TETKİK İSMİ</TableCell>
														<TableCell>TETKİK KATEGORİ</TableCell>
														<TableCell>TETKİK TARİH</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow>
														<TableCell>
															<Typography variant="body2">Bu görüşmede kayıt altına alınmış bir tetkik talebi bulunmamaktadır</Typography>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>

										<Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
									</>
								)}

								<Typography variant="body2" style={{ fontWeight: "bold", marginTop: "15px" }}>
									Görüşme Dosya Listesi
								</Typography>
								<TableContainer>
									<Table aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>DOSYA ID</TableCell>
												<TableCell>DOSYA ADI</TableCell>
												<TableCell>YÜKLENME TARİHİ</TableCell>
												<TableCell>İŞLEMLER</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{appointmentFileList !== null ? (
												appointmentFileList.map((file, idx) => (
													<TableRow key={idx}>
														<TableCell>{file?.fileId}</TableCell>
														<TableCell>{file?.name}</TableCell>
														<TableCell>{dayjs(file?.createdDate).format("DD.MM.YYYY HH:mm")}</TableCell>
														<TableCell>
															<Button component={Link} href={props.store.baseUrl + file?.fileName} target="_blank">
																<InfoIcon /> Görüntüle
															</Button>
														</TableCell>
													</TableRow>
												))
											) : (
												<TableRow>
													<TableCell>
														<Typography variant="body2">Bu görüşmede kayıt altına alınmış bir dosya bulunmamaktadır</Typography>
													</TableCell>
												</TableRow>
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
							<Grid item xs={4}>
								<Typography variant="body2" style={{ fontWeight: "bold" }}>
									Görüşme Mesajlaşma İçeriği
								</Typography>
								<ChatView appointmentId={selectedRandevu.appointmentId} patientId={props.patientId} />
								<br />
								<Typography variant="body2" style={{ fontWeight: "bold" }}>
									Özel Not
								</Typography>
								<TextEditor />
								<Button variant="contained" color="primary" onClick={saveDoctorSpecialNote} style={{ float: "right", marginTop: "5px", marginBottom: "5px" }}>
									Kaydet
								</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
				<Dialog fullWidth maxWidth="sm" open={uploadDisplay} onClose={onClickCloseUploadMenu}>
					<AppBar sx={{ position: "relative", background: "#2196f3" }}>
						<Toolbar>
							<IconButton onClick={onClickCloseUploadMenu} edge="start" color="inherit" aria-label="close">
								<CloseIcon />
							</IconButton>
							<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
								Hasta için Dosya Yükle
							</Typography>
						</Toolbar>
					</AppBar>
					<DialogContent>
						<Typography>
							<b>Bilgilendirme:</b> Dosya tipini seçtikten sonra sisteme yükleyeceğiniz dosyayı seçiniz. Dosyayı seçtikten sonra <b>Dosya Yükle</b> butonuna tıklayarak sisteme dosya
							yükleyebilirsiniz.
						</Typography>
						<FormControl fullWidth style={{ marginTop: "20px", marginBottom: "10px" }}>
							<InputLabel id="city-select-label">Dosya Tipi</InputLabel>
							<Select
								labelId="city-select-label"
								label="Dosya Kategorisi"
								value={selectedFileType}
								id="city-select"
								style={{ background: "#fff" }}
								onChange={onChangeFileTypeSelect}
								MenuProps={MenuProps}
							>
								<MenuItem key={"dosyatipi"} value={0}>
									Dosya tipi seçiniz
								</MenuItem>
								{fileTypeList.current.map((fileType, idx) =>
									fileType.show ? (
										<MenuItem key={idx} value={fileType.id}>
											{fileType.name}
										</MenuItem>
									) : null
								)}
							</Select>
						</FormControl>
						<UploadAppointmentFile appointmentId={0} patientId={patient.patientId} fileType={selectedFileType} onUploadSuccess={() => getPatientFileListReq(props.patientId)} />
					</DialogContent>
				</Dialog>

				<Dialog fullWidth={true} maxWidth={"md"} open={showPaymentMenu} onClose={handleShowPayment} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{"Hasta İçin Ödeme Al"}</DialogTitle>
					<DialogContent style={{ padding: "10px" }}>
						<RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue={"full"} name="paymentCompleted" onChange={onChangePaymentTextInput}>
							<FormControlLabel value={"full"} control={<Radio />} label="Tamamını Öde" />
							<FormControlLabel value={"part"} control={<Radio />} label="Bir Kısmını Öde" />
							<FormControlLabel value={"close"} control={<Radio />} label="Randevudan Ödeme Alma" />
						</RadioGroup>
						<TextField name="paymentTitle" value={paymentDetail?.title} variant="outlined" label="Hasta Adı" style={{ width: "50%", marginTop: "15px" }} disabled />
						<TextField
							name="paymentDate"
							value={paymentDetail?.appointmentDate && dayjs(paymentDetail?.appointmentDate).format("DD.MM.YYYY HH:mm")}
							variant="outlined"
							label="Randevu Tarihi"
							style={{ width: "50%", marginTop: "15px" }}
							disabled
						/>
						<TextField
							name="paymentExaminationPrice"
							value={paymentDetail?.examinationPrice ?? 0}
							variant="outlined"
							label="Yüzyüze Muayene Ücreti (TL)"
							style={paymentDetail?.priceOffline > 0 ? { width: "50%", marginTop: "15px" } : { width: "100%", marginTop: "15px" }}
							disabled
						/>
						{paymentDetail?.priceOffline > 0 && (
							<TextField
								name="paymentPriceOffline"
								value={paymentDetail?.priceOffline}
								variant="outlined"
								label="Hizmet Ücreti (TL)"
								style={{ width: "50%", marginTop: "15px" }}
								disabled
							/>
						)}
						{paymentDetail?.priceOffline > 0 && (
							<TextField
								name="paymentTotalPrice"
								value={(paymentDetail?.examinationPrice ?? 0) + (paymentDetail?.priceOffline ?? 0)}
								variant="outlined"
								label="Genel Toplam Ücreti (TL)"
								style={{ width: "100%", marginTop: "15px" }}
								disabled
							/>
						)}
						{paymentDetail?.totalPaid > 0 && (
							<TextField
								type="number"
								name="paymentPaid"
								value={paymentDetail?.totalPaid}
								variant="outlined"
								label="Önceden Ödenmiş Ücret (TL)"
								style={{ width: "100%", marginTop: "15px" }}
								disabled
							/>
						)}
						{paymentCompleted !== "close" && (
							<TextField
								type="number"
								inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
								name="paymentPrice"
								value={paymentPrice}
								variant="outlined"
								label="Ödenecek Ücret (TL)"
								onChange={onChangePaymentTextInput}
								style={paymentCompleted === "full" ? { width: "50%", marginTop: "15px" } : { width: "100%", marginTop: "15px" }}
								InputLabelProps={{ shrink: true }}
							/>
						)}
						{paymentCompleted === "full" && (
							<TextField
								type="number"
								inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
								name="paymentDiscount"
								value={paymentDiscount < 0 ? 0 : paymentDiscount}
								variant="outlined"
								label="İndirim Oranı (Yüzde)"
								onChange={onChangePaymentTextInput}
								style={{ width: "50%", marginTop: "15px" }}
								InputLabelProps={{ shrink: true }}
							/>
						)}
						<FormControl style={{ width: "100%", marginTop: "15px" }}>
							<InputLabel id="payment-type-label">Ödeme Tipi</InputLabel>
							<Select
								labelId="payment-type-label"
								label="Dosya Kategorisi"
								name="paymentType"
								value={paymentType}
								id="payment-type"
								style={{ background: "#fff" }}
								onChange={onChangePaymentTextInput}
								MenuProps={MenuProps}
							>
								<MenuItem value="Nakit">Nakit</MenuItem>
								<MenuItem value="Kredi Kartı">Kredi Kartı</MenuItem>
								<MenuItem value="Havale">Havale</MenuItem>
								<MenuItem value="Diğer">Diğer</MenuItem>
							</Select>
						</FormControl>
						<TextField
							multiline
							rows={2}
							name="paymentNote"
							value={paymentNote}
							variant="outlined"
							label="Ödeme Notu"
							onChange={onChangePaymentTextInput}
							style={{ width: "100%", marginTop: "15px" }}
							inputProps={{ maxLength: 200 }}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleShowPayment}>Kapat</Button>
						<Button color="primary" variant="contained" data-id={paymentDetail?.appointmentId} onClick={submitPayment}>
							Ödemeyi Kaydet
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog fullWidth={true} maxWidth={"md"} open={showPaymentDetail} onClose={handleShowPaymentDetail} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{"Ödeme Geçmişi"}</DialogTitle>
					<DialogContent>
						<Grid container>
							<Grid item>
								<Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
								<TableContainer style={{ width: "100%" }}>
									<Table aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>Kayıt Tarihi</TableCell>
												<TableCell>Ödeme Öncesi Borç</TableCell>
												<TableCell>Ödenen Borç</TableCell>
												<TableCell>Ödeme Tipi</TableCell>
												<TableCell>Durum</TableCell>
												<TableCell>Kaydı Yapan Kişi</TableCell>
												<TableCell>Not</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{appointmentPaymentDetail !== null &&
												appointmentPaymentDetail.map((pay, idx) => {
													let currentPrice = (pay?.examinationPrice ?? 0) + pay?.priceOffline;
													for (let index = 0; index < idx; index++) {
														currentPrice = currentPrice - appointmentPaymentDetail[index].price;
													}
													return (
														<TableRow key={idx} style={pay.completed ? { backgroundColor: "#84ff8c" } : { backgroundColor: "#f99595" }}>
															<TableCell>{dayjs(pay?.paymentDate).isBefore(dayjs(1971)) ? undefined : dayjs(pay?.paymentDate).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
															<TableCell>{currentPrice}</TableCell>
															<TableCell>{pay?.price}</TableCell>
															<TableCell>{pay?.type}</TableCell>
															<TableCell>{pay?.completed ? "Ödeme Tamamlandı" : "Ödeme Bekliyor"}</TableCell>
															<TableCell>{(pay?.name ?? "") + " " + (pay?.surname ?? "")}</TableCell>
															<TableCell>{pay?.note}</TableCell>
														</TableRow>
													);
												})}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
				<span style={{ display: "none" }}>
					<div ref={componentRef} style={{ margin: "10px" }}>
						<DekontBilgi store={props.store} patient={patient} data={appointmentPaymentDetail} />
					</div>
				</span>
			</div>
		);
	})
);
