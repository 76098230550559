import { types } from "mobx-state-tree"

export const Prescription = types
    .model({
        medicineId: types.optional(types.number, 0),
        medicineName: types.optional(types.string, ""),
        medicinePeriod: types.optional(types.string, ""),
        diagnosisId: types.optional(types.number, 0),
        count: types.optional(types.number, 0),
        dose: types.optional(types.string, ""),
        usage: types.optional(types.number, 0),
        prescriptionType: types.optional(types.number, 0)
    }).actions(self => ({
    }))
