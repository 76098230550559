import axios from "axios";

const FindPatient = async (baseUrl, token, doctorId, patienttc) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + "/doctor/" + doctorId + "/find/" + patienttc, config)
        .then((res) => {
            getResponse = {
                status: res.data.status,
                data: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return getResponse
}

export default FindPatient;