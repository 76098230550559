import axios from "axios";

export const getAccountingExp = async (baseUrl, token, doctorId) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let getResponse = null;

	await axios
		.get(baseUrl + `/doctor/` + doctorId + "/accounting/patient", config)
		.then((res) => {
			getResponse = {
				status: res.data.status,
				data: res.data.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};

export const getPatientPaymentList = async (baseUrl, token, doctorId, clinicId, patientId) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let getResponse = null;

	await axios
		.get(
			baseUrl + `/doctor/` + doctorId + "/accounting/" + clinicId + "/patient/" + patientId,
			config
		)
		.then((res) => {
			getResponse = {
				status: res.data.status,
				data: res.data.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};

export const getAppointmentPaymentDetail = async (baseUrl, token, doctorId, clinicId, appointmentId) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let getResponse = null;

	await axios
		.get(
			baseUrl + `/doctor/` + doctorId + "/accounting/" + clinicId + "/appointment/" + appointmentId,
			config
		)
		.then((res) => {
			getResponse = {
				status: res.data.status,
				data: res.data.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};

export const postPatientPayment = async (baseUrl, token, doctorId, postPayment) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let postResponse = null

    await axios.post(baseUrl + `/doctor/` + doctorId + "/accounting/" + postPayment.clinicid + "/payment", postPayment, config)
        .then((res) => {
            postResponse = {
                status: res.data.status,
                data: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                postResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return postResponse
}
