import axios from "axios";
import parseUserData from '../../../parsers/UserDataParser'

const userLoginWithSmsCode = async (baseUrl, tcKimlikNo, password, code) => {

    let userResponse = null

    const body = {
        tckno: Number(tcKimlikNo),
        password: password,
        code: code
    }

    await axios.put(baseUrl + `/doctor/login`, body)
        .then((res) => {
            if(res.data.status === 200){
                userResponse = {
                    status: res.data.status,
                    data: parseUserData(res.data)
                }
            }else if(res.data.status === 401){
                userResponse = {
                    status: res.data.status,
                    data: res.data.message
                }
            }
            
        }).catch((error) => {
            //console.log("error: ", error)
            if (error.response !== undefined) {
                userResponse = {
                    status: error.response.status
                }
            } 
        });

    return userResponse
}

export default userLoginWithSmsCode;
