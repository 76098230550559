import { types } from "mobx-state-tree"

export const Expense = types
    .model({
        expenseId: types.optional(types.number, 0),
        clinicId: types.optional(types.number, 0),
        expenseAmount: types.optional(types.number, 0),
        expenseName: types.optional(types.string, ""),
        categoryId: types.optional(types.number, 0),
        expenseDate: types.optional(types.Date, new Date()),
        fileUrl: types.optional(types.string, "")
    }).actions(self => ({
    }))