import { types } from "mobx-state-tree"

export const Diagnosis = types
    .model({
        diagnosisId: types.optional(types.number,0),
        patientId: types.optional(types.number, 0),
        doctorId: types.optional(types.number, 0),
        appointmentId: types.optional(types.number, 0),
        diagnosisDesc: types.optional(types.string, ""),
        diagnosisDate: types.optional(types.Date, new Date()),
        diagnosisType: types.optional(types.number, 0),
    }).actions(self => ({

    }))