import { DoctorDepartment } from '../../model/Doctor/DoctorDepartment'

const parseDoctorDepartmantData = (inputDoctorDepartmant) => {
    //console.log("inputDoctorDepartmant: ", inputDoctorDepartmant)
    let doctorDepartment = null
    try {
        doctorDepartment = DoctorDepartment.create({
            id: (inputDoctorDepartmant.departmentId !== null) ? inputDoctorDepartmant.departmentId: 0,
            name: (inputDoctorDepartmant.name !== null) ? inputDoctorDepartmant.name: ""
        })
        
    } catch (error) {
        console.log("error: ", error)
    }
    
    return doctorDepartment;
} 

export default parseDoctorDepartmantData;

