import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"

import dayjs from 'dayjs';

import { Button, Grid, TextField, Typography, IconButton, LinearProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { DatePicker } from '@mui/x-date-pickers';

import 'dayjs/locale/tr';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import getEStatusReport from '../../../utils/api/Doctor/Integration/GetEStatusReport'
import getEBirthReport from '../../../utils/api/Doctor/Integration/GetEBirthReport'
import postReport from '../../../utils/api/Doctor/Appointment/Report/PostReport'

export const RaporMenu = inject("store")(observer((props) => {

    const [reportType, setReportType] = useState(0)
    const [raporKarar, setRaporKarar] = useState("")
    const [reportStartDate, setReportStartDate] = useState(new Date())
    const [reportEndDate, setReportEndDate] = useState(new Date())
    const [raporExist, setRaporExist] = useState(false)
    const [eRaporYonlendirmeDisplay, setERaporYonlendirmeDisplay] = useState(false)

    useEffect(() => {
        // Update the document title using the browser API
        //   
        if(props.rapor !== null){
            //console.log("props.rapor.reportType: ", props.rapor.reportType)
            setReportType(props.rapor.reportType)
            setRaporKarar(props.rapor.reportDecision)
            setReportStartDate(props.rapor.reportStartDate)
            setReportEndDate(props.rapor.reportEndDate)
            setRaporExist(true)
        }
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rapor]);

    const onChangeRaporKararTextField = (event) => {
        setRaporKarar(event.target.value)
    }

    const onChangeReportTip = (event) => {
        //console.log("event: ", event)
        if (event.target.name === "dogum") {
            setReportType(1)
        } else if (event.target.name === "durum") {
            setReportType(2)
        } else {
            setReportType(3)
        }
    }

    const onClickRaporMenuClose = () => {
        props.store.setRaporDialogMenuDisplay(false)
    }

    const onChangeRaporBaslangicTarih = (event) => {
        setReportStartDate(event)
    }

    const onChangeRaporBitisTarih = (event) => {
        setReportEndDate(event)
    }

    const onClickCloseEraporDialogMenu = () => {
        setERaporYonlendirmeDisplay(false)
    }

    const onClickRaporKaydet = async () => {
        props.store.setLoadingBarStatus(false)

        //console.log("props.patient: ",props.patient)

        let report = {
            patientId: props.patient.patientId,
            reportType: reportType,
            reportDesc: "",
            reportDecision: raporKarar,
            reportStartDate: reportStartDate,
            reportEndDate: reportEndDate
        }

        let raporKaydetResponse = await postReport(props.store.baseUrl, props.store.token,
            props.store.doctor[0].id, props.appointmentId, report)

        if (raporKaydetResponse !== null && raporKaydetResponse !== undefined) {
            if (raporKaydetResponse.status === 200) {
                setERaporYonlendirmeDisplay(true)
                setRaporExist(true)
            } else {
                props.store.setMessage("Rapor kaydedilirken hata oluştu", true, "error")
            }
        }
        props.store.setLoadingBarStatus(true)

    }

    const onClickEraporButton = async () => {
        props.store.setLoadingBarStatus(false)

        let eRaporCreateResponse = null;

        if (reportType === 1 || reportType === 3) {
            eRaporCreateResponse = await getEStatusReport(props.store.baseUrl, props.store.token, props.store.doctor[0].id, props.appointmentId, props.patient.patientId);
        } else {
            eRaporCreateResponse = await getEBirthReport(props.store.baseUrl, props.store.token, props.store.doctor[0].id, props.appointmentId, props.patient.patientId);
        }

        if (eRaporCreateResponse !== null && eRaporCreateResponse !== undefined) {
            //console.log("eRaporCreateResponse: ", eRaporCreateResponse)
            if (eRaporCreateResponse.status === 200) {
                window.open(eRaporCreateResponse.data, '_blank');
            } else {
                props.store.setMessage("E-rapor ekranına yönlendirilirken hata oluştu.", true, "error")
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    // 

    return (
        <>
            <Dialog fullScreen open={props.store.raporDialogMenuDisplay} /*onClose={closeReceteMenuDialog}*/>
                <AppBar sx={{ position: 'relative', background: "#2196f3" }}>
                    <Toolbar>
                        <IconButton onClick={onClickRaporMenuClose}
                            edge="start"
                            color="inherit"
                            aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Rapor
                        </Typography>

                        <Button color='success' variant='contained' disabled={(!raporExist || props.rapor !== null) ? false : true} style={{ float: "right", marginRight: "10px" }} onClick={onClickEraporButton}>
                            E-RAPOR SAYFASINA GİT
                        </Button>

                        {(props.rapor === null && raporExist === false) ?
                            <Button color="info" variant='contained' onClick={onClickRaporKaydet} autoFocus
                                style={{ float: "right" }}>RAPOR OLUŞTUR</Button>
                            :
                            <Button color="info" variant='contained' autoFocus onClick={onClickRaporKaydet}
                                style={{ float: "right" }}>RAPOR GÜNCELLE</Button>

                        }

                    </Toolbar>
                    <div style={{ position: "absolute", height: "5px", width: "100%", zIndex: "5000" }}>
                        <LinearProgress style={{ display: (props.store.loadingBarStatus) ? "none" : "block" }} color="primary" />
                    </div>
                </AppBar>
                <DialogContent>

                    <Grid container style={{ width: "100%", border: "1px solid gray", padding: "10px" }}>
                        <Grid item xs={4}>
                            <Grid container spacing={1} >
                                <Grid item xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: "bold" }}>Doktor Bilgileri</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Adı Soyadı:</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant='body2'>{(props.store.doctor[0] !== undefined) ?
                                        ((props.store.doctor[0].title !== null) ? props.store.doctor[0].title.titleName : "")
                                        + " " +
                                        ((props.store.doctor[0].professional !== null) ? props.store.doctor[0].professional.professionalName : "")
                                        + " " + props.store.doctor[0].name + " " + props.store.doctor[0].surname : ""}</Typography>
                                </Grid>
                                {props.store.doctor[0]?.address && <>
                                    <Grid item xs={5}>
                                        <Typography variant='body2' style={{ fontWeight: "bold" }}>İletişim Bilgileri:</Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant='body2'>{(props.store.doctor[0] !== undefined) ?
                                            props.store.doctor[0]?.address?.neighbourhoodName + " " +
                                            props.store.doctor[0]?.address?.address + " " + props.store.doctor[0].address?.districtName + "/" +
                                            props.store.doctor[0]?.address?.cityName : ""} <br />
                                            {(props.store.doctor[0] !== undefined) ? props.store.doctor[0].mobilePhone : ""} <br />
                                            {(props.store.doctor[0] !== undefined) ? props.store.doctor[0].email : ""}</Typography>
                                    </Grid>
                                </>}
                                <Grid item xs={5}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Uzmanlık Bilgisi:</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant='body2'>{(props.store.doctor[0] !== undefined) ? (props.store.doctor[0].professional !== null) ? props.store.doctor[0].professional.professionalName : "" : ""}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Diploma No:</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant='body2'>{(props.store.doctor[0] !== undefined) ? (props.store.doctor[0].diploma !== null) ? props.store.doctor[0].diploma.diplomaNo : "" : ""}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "center" }}>
                            {
                                (props.store.doctor[0] !== undefined) ? 
                                <img alt="kase" src={props.store.baseUrl + props.store.doctor[0].kase} style={{ width: "250px" }}></img> : ""
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: "bold" }}>Hasta Bilgileri</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>T.C. No:</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant='body2'>{(props.patient.id !== 0) ? props.patient.tckno : ""}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Hasta Adı Soyadı:</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant='body2'>{(props.patient.id !== 0) ? props.patient.name + " " + props.patient.surname : ""}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Doğum Tarihi:</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    {
                                        <Typography variant='body2'>{(props.patient.id !== 0) ? dayjs(new Date(props.patient.birthDate)).format("DD.MM.YYYY") : ""}</Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container style={{ width: "100%", border: "1px solid gray", padding: "10px", marginTop: "15px" }}>
                        <Grid item xs={12}>
                            <Typography><b>Şikayet ve Bulgular</b></Typography>
                            <Typography variant='body1'>Şikayet: {props.complaint} <br />  Bulgular: {props.symptom}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography><b>Tanı: </b></Typography>
                            <Typography variant='body1'>

                                {(props.diagnosis.diagnosisId !== 0) ?
                                    (props.diagnosis.diagnosisType === 3) ? "Ön Tanı: " + props.diagnosis.diagnosisCode + ", " + props.diagnosis.diagnosisName + ", " + props.diagnosis.diagnosisDesc
                                        : "Kesin Tanı: " + props.diagnosis.diagnosisCode + " " + props.diagnosis.diagnosisName + " " + props.diagnosis.diagnosisDesc
                                    : ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography><b>Tedavi: </b></Typography>
                            <Typography variant='body1'>
                                {
                                    (props.tedavi !== null) ? props.tedavi.map((tedavi, idx) => (
                                        (tedavi.actionTypeId === 1) ?
                                            "Reçete oluşturuldu. " : (tedavi.actionTypeId === 2) ?
                                                "Rapor oluşturuldu. " : (tedavi.actionTypeId === 3) ?
                                                    "Tetkik talebi oluşturuldu. " : (tedavi.actionTypeId === 4) ?
                                                        "Konsültasyon istendi. " : (tedavi.actionTypeId === 5) ?
                                                            "Tekrar randevu oluşturuldu." : ""
                                    )) : null


                                }<br />
                                {
                                    props.tedaviAciklama
                                }
                            </Typography>
                        </Grid>

                    </Grid>

                    <Grid container spacing={2} style={{ width: "100%", border: "1px solid gray", padding: "10px", margin: "0", marginTop: "15px" }}>
                        <Grid item xs={6}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: "#000", fontWeight: "bold" }}>Rapor Tipi</FormLabel>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group"
                                    value={reportType} onChange={onChangeReportTip}>
                                    <FormControlLabel name="dogum" value={1} control={<Radio />} label="Doğum Raporu" />
                                    <FormControlLabel name="durum" value={2} control={<Radio />} label="Durum Raporu" />
                                    <FormControlLabel name="isgoremezlik" value={3} control={<Radio />} label="İş Göremezlik Raporu" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}> 
                            <FormControl fullWidth style={{ marginTop: "15px", marginBottom: "15px" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} style={{ width: "calc(100% - 100px)" }}>
                                    <DatePicker label="Rapor Başlangıç Tarihi" value={reportStartDate}
                                        onChange={(newValue) => onChangeRaporBaslangicTarih(newValue)}
                                        renderInput={(params) => <TextField {...params} />} resizable
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth style={{ marginTop: "15px", marginBottom: "15px" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} style={{ width: "calc(100% - 100px)" }}>
                                    <DatePicker label="Rapor Bitiş Tarihi" value={reportEndDate}
                                        onChange={(newValue) => onChangeRaporBitisTarih(newValue)}
                                        renderInput={(params) => <TextField {...params} />} resizable
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>

                            <Typography><b>Rapor Kararı: </b></Typography>
                            <TextField style={{ width: "100%", marginTop: "10px" }} value={raporKarar} multiline rows={4}
                                onChange={onChangeRaporKararTextField}></TextField>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>

            <Dialog open={eRaporYonlendirmeDisplay}>
                <DialogContent>
                    <Grid container style={{ width: "100%" }}>
                        <Grid item xs={1}>
                            <CheckCircleOutlineIcon color="success" style={{ fontSize: "50px" }} />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant="body1" style={{ margin: "10px" }}>Tebrikler, raporunuz başarıyla kaydedildi.</Typography>
                        </Grid>
                        <Grid item xs={12}>

                            <Button color='primary' variant='contained' style={{ float: "right", marginRight: "10px" }} onClick={onClickCloseEraporDialogMenu}>
                                KAPAT
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}))