import { Note } from '../../model/Doctor/Note'

const parseNoteData = (noteResponse) => {

    let note = null
    try {

        note = Note.create({
            noteId: (noteResponse.id !== null) ? noteResponse.id: 0,
            patientId: (noteResponse.patientId !== null) ? noteResponse.patientId: 0,
            doctorId: (noteResponse.doctorId !== null) ? noteResponse.doctorId: 0,
            appointmentId: (noteResponse.appointmentId !== null) ? noteResponse.appointmentId: 0,
            documentUrl: (noteResponse.documentUrl !== null) ? noteResponse.documentUrl: "",
            complaint: (noteResponse.complaint !== null) ? noteResponse.complaint: "",
            history: (noteResponse.history !== null) ? noteResponse.history: "",
            symptom: (noteResponse.symptom !== null) ? noteResponse.symptom: "",
            alcohol: (noteResponse.alcohol !== null) ? noteResponse.alcohol: 0,
            smooking: (noteResponse.smooking !== null) ? noteResponse.smooking: 0,
            familyIllness: (noteResponse.familyIllness !== null) ? noteResponse.familyIllness: "",
        })

    } catch (error) {
        console.log("error: ", error)
    }

    return note;
}

export default parseNoteData;