import axios from "axios";

const getVideoUrl = async (baseUrl, url) => {
    let videoUrl = null

    await axios.get(baseUrl + `/video_url/${url}`)
        .then((res) => {
            if(res.data.status === 200){
                videoUrl = {
                    status: res.status,
                    data: res.data.data
                }
            }else {
                videoUrl = {
                    status: res.data.status,
                    data: res.data.message
                }
            }
            
        }).catch((error) => {
            if (error.response !== undefined) {
                videoUrl = {
                    status: error.response.status
                }
            } 
        });
    return videoUrl
}

export default getVideoUrl;
