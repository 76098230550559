import axios from "axios";

const postAppointmentFamilyillness = async (baseUrl, token, doctorId, appointmentId, familyillness) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    var bodyFormData = new FormData();
    bodyFormData.append('familyIllness', familyillness);

    await axios.post(baseUrl + "/doctor/" + doctorId + "/appointment/" + appointmentId + "/familyillness", bodyFormData, config)
        .then((res) => {
            putResponse = {
                status: res.data.status,
                data: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return putResponse
}

export default postAppointmentFamilyillness;