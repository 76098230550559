import { types } from 'mobx-state-tree'

import { User } from '../model/User'
import { Doctor } from '../model/Doctor/Doctor'
import { Patient } from '../model/Patient'
import { Appointment } from '../model/Appointment'
import { Prescription } from '../model/Doctor/Prescription'
import { userPermissions } from '../utils/Roles'

export const WholeMedicsStore = types
    .model("WholeMedicsStore", {
        baseUrl: types.string,
        isLogin: types.boolean,
        darkMode: types.boolean,
        token: types.string,
        messageInfo: types.string,
        messageDisplay: types.boolean,
        messageType: types.string,
        loadingBarStatus: types.optional(types.boolean, false),
        user: types.optional(types.array(User), []),
        doctor: types.optional(types.array(Doctor), []),
        doctorType: types.optional(types.number, 0),
        permissions: types.optional(types.array(types.number), []),
        userType: types.optional(types.number, 0),
        selectedPatient: types.optional(types.array(Patient), []),
        randevuListesi: types.optional(types.array(Appointment), []),
        receteDialogMenuDisplay: types.optional(types.boolean, false),
        raporDialogMenuDisplay: types.optional(types.boolean, false),
        tetkikDialogMenuDisplay: types.optional(types.boolean, false),
        receteInfo: types.optional(types.array(Prescription), []),
        sigortaBilgiFormuMenuDisplay: types.optional(types.boolean, false),
        selectedDiagnosisId: types.optional(types.number, 0),
        selectedDiagnosisName: types.optional(types.string, ""),
        fileMessageUrl: types.optional(types.string, ""),
        activeConsultReqSize: types.optional(types.number, 0),
        selectedDoctorNote: types.optional(types.string, ""),
        selectedDoctorPatientPrivateNote: types.optional(types.string, ""),
        goruntulemeDialogMenuDisplay: types.optional(types.boolean, false),
        doctorAssistantAccount: types.optional(types.array(Doctor), [])
    }).actions(self => ({
        setUserLogged() {
            self.isLogin = true
        },
        setUserLogout() {
            self.isLogin = false
            self.token = ""
            self.user = []
            self.loadingBarStatus = true
            self.doctor = []
            self.doctorAssistantAccount = []
            self.selectedPatient = []
            self.randevuListesi = []
            self.doctorType = 0;
        },
        setDarkLightMode(inputMode) {
            self.darkMode = inputMode
        },
        setToken: (function (token) {
            self.token = token
        }),
        setDoctorType: (function (type){
            self.doctorType = type
            self.permissions = userPermissions(type)
        }),
        setUserType: (function(userType){
            self.userType = userType
        }),
        setMessage: (function (message, display, messageType) {
            self.messageInfo = message
            self.messageDisplay = display
            self.messageType = (messageType === undefined) ? "info" : messageType
        }),
        setLoadingBarStatus(loadingBar) {
            self.loadingBarStatus = loadingBar
        },
        setUser: (function (user) {
            self.user = []
            self.user.push(user)
        }),
        setDoctor: (function(inputDoctor){
            self.doctor = []
            self.doctor.push(inputDoctor)
        }),
        setDoctorAssistantAccount: (function(assistantDoctor){
            self.doctorAssistantAccount = []
            self.doctorAssistantAccount.push(assistantDoctor)
        }),
        setSelectedPatient: (function(){
            self.selectedPatient = []
        }),
        setRandevuListesi: (function(inputRandevuListesi){
            self.randevuListesi = []
            self.randevuListesi = inputRandevuListesi
        }),
        setReceteDialogMenuDisplay: (function(inputDisplay){
            self.receteDialogMenuDisplay = inputDisplay
        }),
        setReceteInfo: (function (inputReceteList){
            self.receteInfo = inputReceteList
        }),
        setRaporDialogMenuDisplay: (function(inputDisplay){
            self.raporDialogMenuDisplay = inputDisplay
        }),
        setSigortaBilgiFormuMenuDisplay: (function (inputDisplay){
            self.sigortaBilgiFormuMenuDisplay = inputDisplay
        }),
        setSelectedDiagnosisId: (function(inputDiagnosisId){
            self.selectedDiagnosisId = inputDiagnosisId
        }),
        setSelectedDiagnosisName: (function(inputDiagnosisName){
            self.selectedDiagnosisName = inputDiagnosisName
        }),
        setTetkikDialogMenuDisplay: (function(inputDisplay){
            self.tetkikDialogMenuDisplay = inputDisplay
        }),
        setFileMessageUrl: (function(inputUrl){
            self.fileMessageUrl = inputUrl
        }),
        setActiveConsultReqSize: (function(inputNumber){
            self.activeConsultReqSize = inputNumber
        }),
        setSelectedDoctorNote: (function(inputDoctorNote){
            self.selectedDoctorNote = inputDoctorNote
        }),
        setSelectedDoctorPatientPrivateNote: (function(inputDoctorPrivateNote){
            self.selectedDoctorPatientPrivateNote = inputDoctorPrivateNote
        }),
        setGoruntulemeDialogMenuDisplay: (function(inputDisplay){
            self.goruntulemeDialogMenuDisplay = inputDisplay
        })
    }))