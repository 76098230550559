import { types } from "mobx-state-tree"

export const Employee = types
    .model({
        name: types.maybeNull(types.optional(types.string, "")),
        surname: types.maybeNull(types.optional(types.string, "")),
        email: types.maybeNull(types.optional(types.string, "")),
        tckno: types.maybeNull(types.optional(types.number, 0)),
        mobilePhone: types.maybeNull(types.optional(types.number, 0)),
        gender: types.maybeNull(types.optional(types.number, 0)),
        userType: types.maybeNull(types.optional(types.number, 0)),
        birthDate: types.maybeNull(types.optional(types.Date, new Date())),
        doctorId: types.maybeNull(types.optional(types.number, 0)),
        avatar: types.maybeNull(types.optional(types.string, ""))
    }).actions(self => ({

    }))