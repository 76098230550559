import * as React from "react";
import { observer, inject } from "mobx-react";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";

import MapissoAlert from "./MapissoAlert";

import getUserLogout from "../../utils/api/Doctor/Auth/UserLogOut";

import { FaRegUserCircle, FaCog, FaRegUser } from "react-icons/fa";

import { FiInbox, FiUsers, FiLogOut } from "react-icons/fi";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { IoIosArrowDown } from "react-icons/io";
import { FaUserMd, FaChartBar, FaBriefcaseMedical } from "react-icons/fa";
import { RiCoupon3Line } from "react-icons/ri";
import CircleIcon from "@mui/icons-material/Circle";

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import { Avatar, Fab, IconButton, LinearProgress, Link, Tooltip } from "@mui/material";

const drawerWidth = 240;
const drawerWidthMobile = 50;

export const LeftMenu = inject("store")(
	observer((props) => {
		const theme = useTheme();

		const [profileMenuDisplay, setProfileMenuDisplay] = React.useState(false);
		const [profileMenuTarget, setProfileMenuTarget] = React.useState(null);

		const pages = [
			{
				name: "Ana Sayfa",
				url: "/panel",
				icon: (
					<FiInbox
						color="primary"
						style={{ fontSize: "18px", color: theme.palette.primary.main }}
					/>
				),
			},
			{
				name: "Hastalar",
				url: "/panel/hasta",
				icon: <FiUsers style={{ fontSize: "18px", color: theme.palette.primary.main }} />,
			},
			{
				name: "Ajandam",
				url: "/panel/ajanda",
				icon: (
					<IoCalendarNumberOutline
						style={{ fontSize: "18px", color: theme.palette.primary.main }}
					/>
				),
			},
			{
				name: "Randevularım",
				url: "/panel/randevularim",
				icon: <FaBriefcaseMedical  style={{ fontSize: "18px", color: theme.palette.primary.main }} />,
			},
			{
				name: "Konsültanlarım",
				url: "/panel/konsultant",
				icon: <FaUserMd style={{ fontSize: "18px", color: theme.palette.primary.main }} />,
			},
			{
				name: "Muhasebe",
				url: "/panel/muhasebe",
				icon: (
					<AccountBalanceWalletIcon
						style={{ fontSize: "18px", color: theme.palette.primary.main }}
					/>
				),
			},
			{
				name: "Mis360",
				url: "/panel/klinik",
				icon: (
					<HiOutlineOfficeBuilding
						style={{ fontSize: "18px", color: theme.palette.primary.main }}
					/>
				),
			},
			{
				name: "İstatistikler",
				url: "/panel/istatistikler",
				icon: (
					<FaChartBar style={{ fontSize: "18px", color: theme.palette.primary.main }} />
				),
			},
			{
				name: "Kuponlarım",
				url: "/panel/kuponlar",
				icon: (
					<RiCoupon3Line
						style={{ fontSize: "18px", color: theme.palette.primary.main }}
					/>
				),
			},
		];

		const onHandleProfileMenuOpen = (event) => {
			setProfileMenuDisplay(true);
			setProfileMenuTarget(event.currentTarget);
		};

		const onClickProfileMenuClose = () => {
			setProfileMenuDisplay(false);
			setProfileMenuTarget(null);
		};

		const onLogout = async () => {
			props.store.setLoadingBarStatus(false);
			await getUserLogout(props.store.baseUrl, props.store.token);
			props.store.setUserLogout();
			props.store.setLoadingBarStatus(true);
			window.location.reload();
		};

		return (
			<>
				<Box sx={{ display: "flex" }}>
					<AppBar position="fixed" sx={{ width: "100%" }}>
						<Toolbar key="topMenu" sx={{ minHeight: "64px" }}>
							<a href="/panel">
								<img
									src="/assets/images/wholemedic-logo.png"
									alt="wholemedics"
									style={{ width: "200px", marginRight: "50px" }}
								/>
							</a>

							<Typography
								color="secondary"
								style={{ fontWeight: "bold" }}
								sx={{ display: { xs: "none", md: "block" } }}
							>
								Sağlık Çalışanı Paneli
							</Typography>

							<Tooltip title="Ayarlar" placement="bottom">
								<IconButton
									size="medium"
									className="profile-menu"
									style={{ marginRight: "45px", boxShadow: "none" }}
									href="/panel/ayarlar"
									component={Link}
								>
									<FaCog />
								</IconButton>
							</Tooltip>

							<Tooltip title="Kullanıcı Menüsü" placement="bottom">
								<Fab
									variant="extended"
									className="profile-menu"
									color="primary"
									onClick={(event) => onHandleProfileMenuOpen(event)}
									style={{ paddingLeft: "5px" }}
								>
									{props.store.doctor[0]?.avatar === null ? (
										<FaRegUser
											style={{ fontSize: "25px", marginRight: "5px" }}
										/>
									) : (
										<Avatar
											alt={
												(props.store.doctor[0]?.name !== undefined
													? props.store.doctor[0]?.name
													: "") +
												" " +
												(props.store.doctor[0]?.surname !== undefined
													? props.store.doctor[0]?.surname
													: "")
											}
											src={
												props.store.baseUrl + props.store.doctor[0]?.avatar
											}
											style={{ marginRight: "5px" }}
										/>
									)}
									<Box
										sx={{
											display: { xs: "none", md: "flex" },
											flexDirection: "column",
											alignItems: "start",
										}}
									>
										<span>
											{(props.store.doctor[0]?.name !== undefined
												? props.store.doctor[0]?.name
												: "") +
												" " +
												(props.store.doctor[0]?.surname !== undefined
													? props.store.doctor[0]?.surname
													: "")}
										</span>
										{props.store.doctor[0]?.clinic && (
											<span style={{ fontSize: "11px" }}>
												{props.store.doctor[0]?.clinic.name}
											</span>
										)}
									</Box>
									<IoIosArrowDown
										style={{ fontSize: "15px", marginLeft: "5px" }}
									/>
								</Fab>
							</Tooltip>

							<Menu
								id="profile-menu"
								anchorEl={profileMenuTarget}
								open={profileMenuDisplay}
								onClose={onClickProfileMenuClose}
								style={{ marginTop: "15px" }}
							>
								<MenuItem
									href="/panel/ayarlar"
									component={Link}
									style={{ width: "175px", textAlign: "center", padding: "13px" }}
								>
									<FaCog style={{ fontSize: "18px", marginRight: "5px" }} />{" "}
									<Typography variant="body1" style={{ fontWeight: "400" }}>
										Ayarlar
									</Typography>
								</MenuItem>
								<MenuItem
									href="/panel/profilim"
									component={Link}
									style={{ width: "175px", textAlign: "center", padding: "13px" }}
								>
									<FaRegUserCircle
										style={{ fontSize: "18px", marginRight: "5px" }}
									/>{" "}
									<Typography variant="body1" style={{ fontWeight: "400" }}>
										Profilim
									</Typography>
								</MenuItem>
								<MenuItem
									onClick={() => onLogout()}
									style={{ width: "175px", textAlign: "center", padding: "13px" }}
								>
									<FiLogOut
										style={{
											color: "#D14343",
											fontSize: "18px",
											marginRight: "5px",
										}}
									/>
									<Typography
										variant="body1"
										style={{ fontWeight: "600", color: "#D14343" }}
									>
										Çıkış Yap
									</Typography>
								</MenuItem>
							</Menu>
						</Toolbar>
						<div
							style={{
								position: "absolute",
								height: "5px",
								width: "100%",
								zIndex: "5000",
							}}
						>
							<LinearProgress
								style={{ display: props.store.loadingBarStatus ? "none" : "block" }}
								color="primary"
							/>
						</div>
					</AppBar>
					<Drawer
						sx={{
							width: { xs: drawerWidthMobile, md: drawerWidth },
							flexShrink: 0,
							"& .MuiDrawer-paper": {
								width: { xs: drawerWidthMobile, md: drawerWidth },
								boxSizing: "border-box",
							},
						}}
						variant="permanent"
						anchor="left"
					>
						<Toolbar key="leftMenu" sx={{ minHeight: "64px" }}></Toolbar>
						<List disablePadding>
							{pages.map((page, index) =>
								props.activePage === page.name ? (
									page.name === "Konsültanlarım" ? (
										props.store.userType === 1 ? (
											<Link key={index} href={page.url}>
												<ListItem
													disableGutters
													disablePadding
													style={{ background: "rgba(0, 0, 0, 0.1)" }}
												>
													<ListItemButton>
														<ListItemIcon>{page.icon}</ListItemIcon>
														<Typography
															variant="body1"
															style={{
																paddingTop: "4px",
																paddingBottom: "4px",
															}}
														>
															{page.name}
															<Badge
																badgeContent={
																	props.store.activeConsultReqSize
																}
																color="success"
															>
																<CircleIcon
																	style={{
																		fontSize: "10px",
																		marginTop: "10px",
																		opacity: 0,
																	}}
																/>
															</Badge>
														</Typography>
													</ListItemButton>
												</ListItem>
											</Link>
										) : null
									) : page.name === "Mis360" ? (
										<Link key={index} href={page.url}>
											<ListItem
												disableGutters
												disablePadding
												style={{ background: "rgba(0, 0, 0, 0.1)" }}
											>
												<ListItemButton>
													<ListItemIcon>{page.icon}</ListItemIcon>
													<img
														src="/assets/images/mis360.png"
														style={{ width: "70px" }}
														alt="mis360"
													></img>
												</ListItemButton>
											</ListItem>
										</Link>
									) : page.name === "Muhasebe" ? (
										props.store.userType !== 1 ? null : (
											<Link key={index} href={page.url}>
												<ListItem
													disableGutters
													disablePadding
													style={{ background: "rgba(0, 0, 0, 0.1)" }}
												>
													<ListItemButton>
														<ListItemIcon>{page.icon}</ListItemIcon>
														<Typography variant="body1">
															{page.name}
														</Typography>
													</ListItemButton>
												</ListItem>
											</Link>
										)
									) : (
										<Link key={index} href={page.url}>
											<ListItem
												disableGutters
												disablePadding
												style={{ background: "rgba(0, 0, 0, 0.1)" }}
											>
												<ListItemButton>
													<ListItemIcon>{page.icon}</ListItemIcon>
													<Typography variant="body1">
														{page.name}
													</Typography>
												</ListItemButton>
											</ListItem>
										</Link>
									)
								) : page.name === "Konsültanlarım" ? (
									props.store.userType === 1 ? (
										<Link key={index} href={page.url}>
											<ListItem disableGutters disablePadding>
												<ListItemButton>
													<ListItemIcon>{page.icon}</ListItemIcon>
													<Typography
														variant="body1"
														style={{
															paddingTop: "4px",
															paddingBottom: "4px",
														}}
													>
														{page.name}
														<Badge
															badgeContent={
																props.store.activeConsultReqSize
															}
															color="success"
														>
															<CircleIcon
																style={{
																	fontSize: "10px",
																	marginTop: "10px",
																	opacity: 0,
																}}
															/>
														</Badge>
													</Typography>
												</ListItemButton>
											</ListItem>
										</Link>
									) : null
								) : page.name === "Mis360" ? (
									<Link key={index} href={page.url}>
										<ListItem disableGutters disablePadding>
											<ListItemButton>
												<ListItemIcon>{page.icon}</ListItemIcon>
												<img
													src="/assets/images/mis360.png"
													style={{ width: "70px" }}
													alt="mis360"
												></img>
											</ListItemButton>
										</ListItem>
									</Link>
								) : page.name === "Muhasebe" ? (
									props.store.userType !== 1 ? null : (
										<Link key={index} href={page.url}>
											<ListItem disableGutters disablePadding>
												<ListItemButton>
													<ListItemIcon>{page.icon}</ListItemIcon>
													<Typography variant="body1">
														{page.name}
													</Typography>
												</ListItemButton>
											</ListItem>
										</Link>
									)
								) : (
									<Link key={index} href={page.url}>
										<ListItem disableGutters disablePadding>
											<ListItemButton>
												<ListItemIcon>{page.icon}</ListItemIcon>
												<Typography variant="body1">{page.name}</Typography>
											</ListItemButton>
										</ListItem>
									</Link>
								)
							)}
						</List>
					</Drawer>
					<MapissoAlert />
				</Box>
			</>
		);
	})
);
