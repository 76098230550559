import axios from "axios";

const postClinicRoom = async (baseUrl, token, doctorId, clinicId, clinicRoomBody) => {

    const config = { 
        headers: { Authorization: `Bearer ${token}` }
    };

    let postResponse = null


    await axios.post(baseUrl + "/doctor/" + doctorId + "/clinic/" + clinicId +  "/room", clinicRoomBody, config)
        .then((res) => {
            postResponse = {
                status: res.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                postResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return postResponse
}

export default postClinicRoom;