import axios from "axios";
import parsePatientData from '../../parsers/PatientDataParser'

const getDoctorPatients = async (baseUrl, token, doctorId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + "/doctor/" + doctorId + "/patient", config)
        .then((res) => {
            let patientsList = []
            for (let i = 0; i < res.data.data.length; i++) {
                let tempPatient = res.data.data[i];
                if (tempPatient !== null) {
                    patientsList.push(parsePatientData(tempPatient))
                }
            }
            getResponse = {
                status: res.data.status,
                data: patientsList
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return getResponse
}

export default getDoctorPatients;