import axios from "axios";

const enabizRequest = async (baseUrl, token, doctorId, appointment) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    await axios.post(baseUrl + "/doctor/" + doctorId + "/appointment/" + appointment + "/e-government", {}, config)
        .then((res) => {
            putResponse = {
                status: res.data.status,
                data: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return putResponse
}

export default enabizRequest;