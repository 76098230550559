import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import dayjs from "dayjs";

import { Button, Card, CardContent, Container, Grid, Typography, IconButton, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { ClosePageComponent } from "../../components/common/ClosePageComponent";
import { TopBarMenu } from "../../components/common/TopBarMenu";
import { FaVideo, FaUserMd, FaRegCopy, FaPhone } from "react-icons/fa";
import { getMeeting, upsertMeeting, inviteMeeting } from "../../utils/api/Dashboard/Meeting";
import MapissoAlert from "../../components/common/MapissoAlert";

class MeetingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			meeting: undefined,
			gorusmeOncesiBilgilendirmeDisplay: false,
			acceptedAllBilgilendirme: false,
			videoUrl: "",
			meetingStatusDialog: false,
			showInvite: false,
		};
	}
	componentDidMount = async () => {
		if (!this.props.store.isLogin) {
			this.props.store.setLoadingBarStatus(true);
			this.props.history.push("/panel/kullanici-girisi");
		} else {
			this.props.store.setLoadingBarStatus(true);

			window.addEventListener("beforeunload", (e) => {
				e.preventDefault();
			});

			if (this.props.store.doctor[0] !== undefined) {
				let meetingReq = await getMeeting(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, this.props.match.params.id);

				if (meetingReq !== null && meetingReq !== undefined) {
					if (meetingReq.status === 200) {
						if (meetingReq.data.data.videoUrl)
							this.setState({
								meeting: meetingReq.data.data,
							});
						else return this.props.store.setMessage("Görüşme bulunamadı", true, "error");
					} else {
						this.props.store.setUserLogout();
						this.props.history.push("/panel/kullanici-girisi");
					}
				}
				this.props.store.setLoadingBarStatus(true);
			}
		}
	};

	startVideoProcess = async () => {
		if (this.state.meeting.endDate) {
			return this.props.store.setMessage("Görüşme sona erdi", true, "error");
		}
		this.setState({
			gorusmeOncesiBilgilendirmeDisplay: true,
		});
	};

	onAcceptedGorusmeOncesiBilgilendirme = async () => {
		this.setState({
			acceptedAllBilgilendirme: true,
			gorusmeOncesiBilgilendirmeDisplay: false,
		});

		this.props.store.setLoadingBarStatus(false);

		const utcDiff = new Date().getTimezoneOffset() * -1;
		const currentDate = new Date();
		currentDate.setMinutes(currentDate.getMinutes() + utcDiff);
		const newMeeting = { ...this.state.meeting, isStarted: true, meetingDate: currentDate };
		const videoLink = this.state.meeting.videoUrl;

		if (this.state.meeting.isStarted) {
			this.setState({
				videoUrl: videoLink,
			});
		} else {
			const upsertMeetingReq = await upsertMeeting(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, newMeeting);
			if (upsertMeetingReq !== undefined && upsertMeetingReq !== null) {
				if (upsertMeetingReq.status === 200) {
					this.setState({
						videoUrl: videoLink,
						meeting: upsertMeetingReq.data.data,
					});
				} else {
					this.props.store.setMessage("Görüşme işlemi sırasında hata oluştu", true, "error");
				}
			}
		}

		this.props.store.setLoadingBarStatus(true);
	};

	endMeeting = async (setStatus) => {
		this.props.store.setLoadingBarStatus(false);

		const utcDiff = new Date().getTimezoneOffset() * -1;
		const currentDate = new Date();
		currentDate.setMinutes(currentDate.getMinutes() + utcDiff);
		let newMeeting;
		if (setStatus) {
			const status = Number(document.querySelector("#meeting-status+input")?.value) ?? 0;
			newMeeting = { ...this.state.meeting, endDate: currentDate, status };
		} else {
			newMeeting = { ...this.state.meeting, endDate: currentDate };
		}

		const upsertMeetingReq = await upsertMeeting(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, newMeeting);

		if (upsertMeetingReq !== undefined && upsertMeetingReq !== null) {
			if (upsertMeetingReq.status === 200) {
				this.props.history.push("/panel");
			} else {
				this.props.store.setMessage("Görüşme işlemi sırasında hata oluştu", true, "error");
			}
		}
		this.props.store.setLoadingBarStatus(true);
	};

	endMeetingHandler = () => {
		window.removeEventListener("beforeunload", (e) => {
			e.preventDefault();
		});
		this.setState({
			meetingStatusDialog: true,
		});
	};

	copyInviteLink = (link) => {
		navigator.clipboard.writeText(link);
		this.props.store.setMessage("Davet adresi kopyalandı", true, "success");
		this.handleInviteDialog();
	};

	handleInviteDialog = () => {
		this.setState({
			showInvite: !this.state.showInvite,
		});
	};

	inviteNumber = async () => {
		const phone = document.getElementById("mobilePhone").value;
		if (Number(phone)?.toString().startsWith("5") && Number(phone).toString().length === 10) {
			const sendInviteReq = await inviteMeeting(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, { mobilePhone: phone, meetingId: this.props.match.params.id });

			if (sendInviteReq !== undefined && sendInviteReq !== null) {
				if (sendInviteReq.status === 200) {
					this.props.store.setMessage("Davet gönderildi", true, "success");
					document.getElementById("mobilePhone").value = "";
				} else {
					this.props.store.setMessage("Davet sırasında sorun oluştu", true, "error");
				}
			} else this.props.store.setMessage("Davet sırasında sorun oluştu", true, "error");
		} else {
			this.props.store.setMessage("Geçersiz numara", true, "error");
		}
	};

	render() {
		const inviteLink = window.location.origin + "/meet/" + this.state.meeting?.videoUrl?.split("/")[3]?.split("?")[0];
		return (
			<div className="container mps-container">
				<MapissoAlert />
				<TopBarMenu meeting hideLeave />
				<ClosePageComponent />
				<Container maxWidth={false} style={{ paddingTop: "80px" }}>
					<Grid container spacing={3}>
						<Grid item lg={12} md={12} xl={12} xs={12}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Card
										style={{
											height: "100%",
											borderRadius: "10px",
											marginTop: "0px",
										}}
									>
										{this.state.meeting && (
											<CardContent style={{ height: "auto", padding: "10px" }}>
												<Grid container spacing={1} alignItems={"center"} justifyContent={"flex-end"}>
													<Grid
														item
														style={{
															marginInlineEnd: "auto",
														}}
													>
														<Typography
															variant="body2"
															style={{
																fontSize: "20px",
															}}
														>
															<b>{this.state.meeting?.fullName}</b>
														</Typography>
													</Grid>
													<Grid item>
														<Typography variant="body2">
															<b>Oturum bilgileri: </b>
															{`${dayjs(this.state.meeting?.meetingDate).format("DD.MM.YYYY hh:mm")} - ${this.state.meeting?.duration} dk`}
														</Typography>
													</Grid>
													{/* <Grid item>
														<Button
															variant="contained"
															style={{ marginInlineStart: "10px" }}
															onClick={this.handleInviteDialog}
														>
															Davet Et
														</Button>
													</Grid> */}
													{this.state.videoUrl && (
														<Grid item>
															<Button variant="contained" color="error" onClick={this.endMeetingHandler}>
																Görüşmeyi Bitir
															</Button>
														</Grid>
													)}
												</Grid>
											</CardContent>
										)}
									</Card>
								</Grid>
								<Grid item xs={12} style={{ paddingTop: "0px" }}>
									<Card
										style={{
											height: "calc(100vh - 260px)",
											borderRadius: "10px",
											marginTop: "20px",
										}}
									>
										<CardContent style={{ padding: "0", height: "100%" }}>
											{this.state.videoUrl !== "" ? (
												<iframe
													allow="camera;microphone;display-capture;fullscreen"
													id="meeting-iframe"
													src={this.state.videoUrl}
													title="Görüşme Ekranı"
													style={{
														height: "100%",
														width: "100%",
														border: "0",
													}}
													onLoad={this.onLoadIframe}
												/>
											) : (
												<div className="session-start-screen">
													<div className="doctor-image">
														<FaUserMd
															style={{
																fontSize: "200px",
																color: "#2196f3",
																borderRadius: "150px",
																border: "7px solid #2196f3",
																marginBottom: "15px",
															}}
														/>
													</div>

													{this.state.meeting &&
														(this.state.meeting.endDate ? (
															<Button variant="contained" color="error" onClick={() => this.props.history.push("/panel")}>
																Görüşme Sona Erdi
															</Button>
														) : (
															<Button
																variant="contained"
																color="primary"
																onClick={this.startVideoProcess}
																disabled={this.state.appointment?.appointmentType === 2 ? true : false}
															>
																<FaVideo
																	style={{
																		fontSize: "24px",
																		marginRight: "15px",
																	}}
																/>{" "}
																Görüşmeyi Başlat
															</Button>
														))}
												</div>
											)}
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
				<Dialog open={this.state.gorusmeOncesiBilgilendirmeDisplay}>
					<DialogTitle id="alert-dialog-title">
						<Typography variant="body1" style={{ fontWeight: "bold" }}>
							Bilgilendirme
						</Typography>
					</DialogTitle>
					<DialogContent>
						<Typography>
							Değerli kullanıcımız, hizmetin yerine getirilebilmesi için <b>kamera ve mikrofon erişim izni </b>gerekmektedir. Yapacağınız görüşmeyi karşı tarafa bildirerek istediğiniz
							anda sonlandırabilirsiniz.
						</Typography>
						<Typography>
							Karşı taraf ile yapacağınız görüşmede <b>gerektiğinde ses kaydı yapılabilmektedir.</b>
						</Typography>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" onClick={this.onAcceptedGorusmeOncesiBilgilendirme}>
							Devam Et
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.showInvite} onClose={this.handleInviteDialog}>
					<DialogTitle id="alert-dialog-title">
						<Typography variant="body1" style={{ fontWeight: "bold" }}>
							Davet
						</Typography>
						<IconButton
							aria-label="close"
							onClick={this.handleInviteDialog}
							sx={{
								position: "absolute",
								right: 8,
								top: 8,
							}}
						>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<Grid container>
							<Grid item xs={12}>
								<Typography style={{ textAlign: "center" }}>
									<b>Davet Adresi: </b>
									{inviteLink}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Button variant="contained" style={{ width: "100%" }} onClick={() => this.copyInviteLink(inviteLink)}>
									Davet Adresini Kopyala
									<FaRegCopy style={{ marginInlineStart: "5px" }} />
								</Button>
							</Grid>
							<Grid item xs={12}>
								<TextField id="mobilePhone" name="mobilePhone" variant="outlined" label="Telefon Numarası" style={{ width: "100%", marginTop: "15px" }}></TextField>
							</Grid>
							<Grid item xs={12}>
								<Button variant="contained" style={{ width: "100%", marginTop: "15px" }} onClick={this.inviteNumber}>
									Davet Gönder
									<FaPhone style={{ marginInlineStart: "5px" }} />
								</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
				<Dialog open={this.state.meetingStatusDialog} onClose={() => this.endMeeting(false)}>
					<DialogTitle id="alert-dialog-title">
						<Typography variant="body1" style={{ fontWeight: "bold" }}>
							Oturum Sonlandırılacak
						</Typography>
						<IconButton
							aria-label="close"
							onClick={() => this.endMeeting(false)}
							sx={{
								position: "absolute",
								right: 8,
								top: 8,
							}}
						>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent  sx={{ minWidth: "400px" }} >
						<Grid container>
							<Grid item xs={12}>
								<FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
									<InputLabel id="meeting-status-label">Görüşme Durumu</InputLabel>
									<Select defaultValue={0} labelId="meeting-status-label" id="meeting-status" label="Görüşme Durumu">
										<MenuItem value={1}>Tamamlandı</MenuItem>
										<MenuItem value={2}>Tamamlanmadı</MenuItem>
										<MenuItem value={0}>Bekleniyor</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button color="error" variant="contained" onClick={() => this.endMeeting(true)}>
							Görüşmeden Ayrıl
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default inject("store")(observer(MeetingPage));
