import { Address } from '../../model/Address'

const parseAddressData = (inputAdress) => {

    //console.log("inputAdress: ", inputAdress)
    let adress = null
    try {

        adress = Address.create({
            cityId: (inputAdress.id !== null) ? inputAdress.cityId : 0,
            cityName: (inputAdress.cityName !== null) ? inputAdress.cityName : "",
            districtId: (inputAdress.districtId !== null) ? inputAdress.districtId : 0,
            districtName: (inputAdress.districtName !== null) ? inputAdress.districtName : "",
            neighbourhoodId: (inputAdress.neighbourhoodId !== null) ? inputAdress.neighbourhoodId : 0,
            neighbourhoodName: (inputAdress?.neighbourhoodName !== null) ? inputAdress?.neighbourhoodName : "",
            address: (inputAdress.address !== null) ? inputAdress.address : ""
        })
        
    } catch (error) {
        console.log("error: ", error)
    }
    
    return adress;
} 

export default parseAddressData;

