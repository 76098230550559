import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import { toJS } from 'mobx';
import { containerStyle } from '../../utils/export-style';

import { LeftMenu } from '../../components/common/LeftMenu';

import { Box, Container, Grid } from '@mui/material';
import { TakvimMenu } from '../../components/doktor/randevu/TakvimMenu';

import getDoctorPatients from '../../utils/api/Doctor/GetPatientsforDoctor'
import getAppointmentList from '../../utils/api/Doctor/Appointment/GetAppointmentList'

class TakvimPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            hastaListesi: [],
            appointmentList: []
        }
    } 

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/panel/kullanici-girisi");
        } else {
            this.props.store.setLoadingBarStatus(false)
            let hastaListesiResponse = await getDoctorPatients(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id)
            //console.log("hastaListesiResponse: ", hastaListesiResponse)
            if (hastaListesiResponse !== undefined && hastaListesiResponse !== null) {
                if (hastaListesiResponse.status === 200) {

                    let objectedHastaListesi = toJS(hastaListesiResponse.data)
                    let tempHastaList = []
                    for (let index = 0; index < objectedHastaListesi.length; index++) {
                        let tempHasta = toJS(objectedHastaListesi[index]);
                        tempHasta.label = tempHasta.name + " " + tempHasta.surname
                        tempHastaList.push(tempHasta)
                    }
                    this.setState({
                        hastaListesi: tempHastaList
                    })
                }else if (hastaListesiResponse.status === 401) {
                    this.props.store.setUserLogout()
                    window.location.reload()
                }
            } else {
                this.props.store.setMessage("Hasta listesi getirilirken hata oluştu.", true, "error")
            }

            let appointmentListResponse = await getAppointmentList(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id)
            if(appointmentListResponse !== undefined && appointmentListResponse !== null){
                if(appointmentListResponse.status === 200){
                    //"console.log("appointmentListResponse", appointmentListResponse.data)
                    this.props.store.setRandevuListesi(appointmentListResponse.data)
                }else if (appointmentListResponse.status === 401) {
                    this.props.store.setUserLogout()
                    window.location.reload()
                }
            } 
            this.props.store.setLoadingBarStatus(true)
        }
    }

    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage={"Ajandam"} />
                <Box component="main" sx={{
                    ...containerStyle,
                    flexGrow: 1,
                    py: 8
                }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} xl={12} xs={12} style={{ padding: "0", margin: "0" }}>
                                <TakvimMenu hastaData={this.state.hastaListesi} history={this.props.history}/>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(TakvimPage));
