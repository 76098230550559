import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import { containerStyle } from '../../utils/export-style';

import { LeftMenu } from '../../components/common/LeftMenu';

import { Box, Container, Grid } from '@mui/material';
import { HastaTetkikGrafik } from '../../components/doktor/dashboard/HastaTetkikGrafik';
import { ToplamHastaSayisi } from '../../components/doktor/dashboard/ToplamHastaSayisi';
import { AylikMuayeneCirosu } from '../../components/doktor/dashboard/AylikMuayeneCirosu';
import { AylikTetkikCirosu } from '../../components/doktor/dashboard/AylikTetkikCirosu';
import { BekleyenRezervasyon } from '../../components/doktor/dashboard/BekleyenRezervasyon';
import { MuayeneTetkikCiroOranGrafik } from '../../components/doktor/dashboard/MuayeneTetkikCiroOranGrafik';

import getDashboardStatistics from '../../utils/api/Dashboard/GetDashboardStatistics'

class RaporlarPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dashboardStatics: null
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/panel/kullanici-girisi");
        } else {

            let getDashboardStatisticsResponse = await getDashboardStatistics(this.props.store.baseUrl, this.props.store.token)

            if (getDashboardStatisticsResponse !== undefined && getDashboardStatisticsResponse !== null) {
                //console.log("getDashboardStatisticsResponse: ", getDashboardStatisticsResponse)
                if (getDashboardStatisticsResponse.status === 200) {
                    this.setState({
                        dashboardStatics: getDashboardStatisticsResponse.data
                    })
                }else if (getDashboardStatisticsResponse.status === 401) {
                    this.props.store.setUserLogout()
                    window.location.reload()
                }
            }

        }
    }

    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage={"Raporlar"} />
                <Box component="main" sx={{
                    ...containerStyle,
                    flexGrow: 1,
                    py: 8
                }} >
                    <Container maxWidth={false}>
                        <Grid
                            item
                            lg={12}
                            md={12}
                            xl={12}
                            xs={12}
                        >
                            <HastaTetkikGrafik data={(this.state.dashboardStatics !== null) ? this.state.dashboardStatics.examinationRateByMonthly : []} />
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            md={6}
                            xl={3}
                            xs={12}
                        >
                            <MuayeneTetkikCiroOranGrafik sx={{ height: '100%' }} 
                                data={(this.state.dashboardStatics !== null) ? [this.state.dashboardStatics.grossAmountByAppointment, this.state.dashboardStatics.grossAmountByMedicalWorkup] : []} />
                        </Grid>
                        <Grid style={{marginTop: "0px"}}
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                lg={3}
                                sm={6}
                                xl={3}
                                xs={12}
                            >
                                <ToplamHastaSayisi sx={{ height: '100%' }} data={(this.state.dashboardStatics !== null) ? this.state.dashboardStatics.totalPatientCount : 0} />
                            </Grid>
                            <Grid
                                item
                                xl={3}
                                lg={3}
                                sm={6}
                                xs={12}
                            >

                                <AylikMuayeneCirosu data={(this.state.dashboardStatics !== null) ? this.state.dashboardStatics.grossAmountByAppointment : 0} />
                            </Grid>
                            <Grid
                                item
                                xl={3}
                                lg={3}
                                sm={6}
                                xs={12}
                            >
                                <AylikTetkikCirosu data={(this.state.dashboardStatics !== null) ? this.state.dashboardStatics.grossAmountByMedicalWorkup : 0} />
                            </Grid>
                            <Grid
                                item
                                xl={3}
                                lg={3}
                                sm={6}
                                xs={12}
                            >
                                <BekleyenRezervasyon sx={{ height: '100%' }}
                                    data={(this.state.dashboardStatics !== null) ? this.state.dashboardStatics.futureAppointmentCount : 0} />
                            </Grid>

                        </Grid>
                    </Container>
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(RaporlarPage));
