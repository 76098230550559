import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { containerStyle } from "../../utils/export-style";

import { LeftMenu } from "../../components/common/LeftMenu";
import { Box, Container, Grid } from "@mui/material";

import { KuponListesi } from "../../components/doktor/kupon/KuponListesi";

import getDoctorCouponsList from "../../utils/api/Doctor/Coupon/GetDoctorCouponsList";

class CouponsPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			couponList: [],
		};
	}

	componentDidMount = async () => {
		if (!this.props.store.isLogin) {
			this.props.store.setLoadingBarStatus(true);
			this.props.history.push("/panel/kullanici-girisi");
		} else {
			this.props.store.setLoadingBarStatus(false);

			let getDoctorCouponList = await getDoctorCouponsList(
				this.props.store.baseUrl,
				this.props.store.token,
				this.props.store.doctor[0].id
			);
			if (getDoctorCouponList !== null && getDoctorCouponList !== undefined) {
				if (getDoctorCouponList.status === 200) {
					let couponList = getDoctorCouponList.data;

					if (couponList !== null) {
						for (let index = 0; index < couponList.length; index++) {
							const coupon = couponList[index];
							coupon.id = index + 1;
						}
						this.setState({
							couponList: couponList,
						});
					}
				} else if (getDoctorCouponList.status === 401) {
					this.props.store.setUserLogout();
					window.location.reload();
				}
			}

			this.props.store.setLoadingBarStatus(true);
		}
	};

	render() {
		return (
			<div className="container mps-container">
				<LeftMenu activePage={"Kuponlarım"} />
				<Box
					component="main"
					sx={{
						...containerStyle,
						flexGrow: 1,
						py: 8,
					}}
				>
					<Container maxWidth={false}>
						<Grid container spacing={3}>
							<Grid item lg={12} md={12} xl={12} xs={12}>
								<KuponListesi
									active={this.state.couponList.filter((coupon) => coupon.codeStatus === 1)}
									expired={this.state.couponList.filter((coupon) => coupon.codeStatus === 0)}
								/>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</div>
		);
	}
}

export default inject("store")(observer(CouponsPage));
