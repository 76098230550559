import axios from "axios";

export const createOfflinePatient = async (baseUrl, token, doctorId, patient) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let postResponse = null;

	await axios
		.post(baseUrl + `/doctor/` + doctorId + "/patient/offline", patient, config)
		.then((res) => {
			postResponse = {
				status: res.data.data.status,
				result: res.data.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				postResponse = {
					status: error.response.status,
					result: error.response.status,
				};
			}
		});
	return postResponse;
};

export const updateOfflinePatient = async (baseUrl, token, doctorId, patient) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let postResponse = null;

	await axios
		.put(baseUrl + `/doctor/` + doctorId + "/patient/offline", patient, config)
		.then((res) => {
			postResponse = {
				status: res.data.data.status,
				result: res.data.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				postResponse = {
					status: error.response.status,
					result: error.response.status,
				};
			}
		});
	return postResponse;
};

export const deleteOfflinePatient = async (baseUrl, token, doctorId, patientId) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let postResponse = null;

	await axios
		.delete(baseUrl + `/doctor/` + doctorId + "/patient/offline/" + patientId, config)
		.then((res) => {
			postResponse = {
				status: res.data.data.status,
				result: res.data.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				postResponse = {
					status: error.response.status,
					result: error.response.status,
				};
			}
		});
	return postResponse;
};

export const changeDoctorPatientStatus = async (baseUrl, token, doctorId, patientId) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	let postResponse = null;

	await axios
		.put(baseUrl + "/doctor/" + doctorId + "/patient/" + patientId + "/status", patientId, config)
		.then((res) => {
			postResponse = {
				status: res.data.status,
				result: res.data.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				postResponse = {
					status: error.response.status,
					result: error.response.status,
				};
			}
		});
	return postResponse;
};
