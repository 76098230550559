import { types } from "mobx-state-tree";

export const VideoUrl = types
  .model({
    urlId: types.optional(types.number, 0),
    appointmentId: types.optional(types.integer, 0),
    doctorId: types.optional(types.number, 0),
    patientId: types.optional(types.number, 0),
    doctorName: types.optional(types.string, ""),
    patientName: types.optional(types.string, ""),
    shortUrl: types.optional(types.string, ""),
    realUrl: types.optional(types.string, ""),
    phone: types.optional(types.string, ""),
    startDate: types.optional(types.Date, new Date("0001-01-01")),
    createdDate: types.optional(types.Date, new Date("0001-01-01")),
  })
  .actions((self) => ({}));
