import React, { Component } from "react";
import { observer, inject } from "mobx-react"
import { toJS } from "mobx";

import axios from "axios";
import Dropzone from "./dropzone/dropzone";
import "./upload.css";
import Progress from "./progress/Progress";

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MapissoAlert from '../common/MapissoAlert'

import putDoctor from '../../utils/api/Doctor/PutDoctor'

class UploadAppointmentFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            uploading: false,
            uploadProgress: {},
            successfullUploaded: false
        };

        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.renderActions = this.renderActions.bind(this);
    }

    componentDidMount = async () => {
    }

    onFilesAdded(files) {
        this.setState(prevState => ({
            files: prevState.files.concat(files)
        }));
    }

    async uploadFiles() {
        this.setState({ uploadProgress: {}, uploading: true });
        const promises = [];

        this.state.files.forEach(file => {
            promises.push(this.sendRequest(file));
        });

        try {
            await Promise.all(promises);

            this.setState({ successfullUploaded: true, uploading: false });
        } catch (e) {
            // Not Production ready! Do some error handling here instead...
            this.setState({ successfullUploaded: true, uploading: false });
        }
    }

    sendRequest = async (file) => {
        let result = null;

        //console.log("file: ", file) 

        const formData = new FormData();
        formData.append("file", file);
        formData.append("filetype", this.props.fileType)
        formData.append("patientid", this.props.patientId)
        formData.append("appointmentid", this.props.appointmentId)

        // console.log("UPLOAD objectId: ", this.props.store.selectedUploadObjectId)
        //console.log("UPLOAD kyaId: ", this.props.kyaId)
        //console.log("evrak tip: ", this.props.store.selectedUploadEvrakTip)

        if (this.props.objectId !== -1) {

            let uploadReqUrl = this.props.store.baseUrl + "/doctor/" + this.props.store.doctor[0].id + "/file/upload/"
            //console.log("uploadReqUrl: ", uploadReqUrl)

            const config = {
                headers: {
                    Authorization: `Bearer ${this.props.store.token}`,
                    'Content-Type': 'multipart/form-data'
                }
            };
            //console.log("uploadReqUrl: ", uploadReqUrl)

            if (uploadReqUrl !== "") {
                this.props.store.setLoadingBarStatus(false)
                await axios.post(uploadReqUrl, formData, config).then(async (res) => {
                    result = res;
                    const copy = { ...this.state.uploadProgress }
                    copy[file.name] = { state: "done", percentage: 100 }
                    //console.log(copy)
                    //console.log(copy)
                    this.setState({ uploadProgress: copy })
                    //console.log("result: ", result.data)
                    //this.props.store.setUploadedObject(this.props.store.baseUrl + "/" + result.data.data)

                    this.props.store.setFileMessageUrl(result.data.data)
                    this.props.onUploadSuccess()

                    this.props.store.setMessage("Dosya başarıyla yüklendi", true, "success")
                }).catch((error) => {
                    //console.log("errora geldi..")
                    //console.log(error)
                    result = null
                    const copy = { ...this.state.uploadProgress };
                    copy[file.name] = { state: "error", percentage: 0 };
                    this.setState({ uploadProgress: copy });
                    this.props.store.setUploadStatus(false)
                });

                this.props.store.setLoadingBarStatus(true)
            }
        } else {
            this.props.store.setMessage("Dosya yüklemek için İmar Planı seçmelisiniz.", true, "error")
        }

        return result;
    }

    renderProgress(file) {
        const uploadProgress = this.state.uploadProgress[file.name];
        //console.log("uploadProgress")
        //console.log(uploadProgress)
        if (this.state.uploading || this.state.successfullUploaded) {
            return (
                <div className="ProgressWrapper">
                    <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
                </div>
            );
        }
    }

    deleteAllData() {
        this.setState({ files: [], successfullUploaded: false })
        //this.props.store.setUploadStatus(false)
    }

    renderActions() {
        if (this.state.successfullUploaded) {
            return (
                <Button onClick={() => this.deleteAllData()}>
                    <DeleteIcon /> <span style={{ marginRight: "5px" }}>TEMİZLE</span>
                </Button>
            );
        } else {
            return (
                <Button color="primary" variant="contained" disabled={this.state.files.length < 1 || this.state.uploading} onClick={this.uploadFiles}>
                    <CloudUploadIcon size="small" style={{ marginRight: "5px" }} /> Dosya Yükle
                </Button>
            );
        }
    }

    updateDoctorReq = async (fileUrl) => {
        this.props.store.setLoadingBarStatus(false)

        //console.log("doktor: ", this.props.store.doctor[0])

        let updatedDoktor = toJS(this.props.store.doctor[0])
        updatedDoktor.kase = fileUrl

        await putDoctor(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, updatedDoktor)
        //console.log("putDoctorResponse: ", putDoctorResponse)
        this.props.store.setLoadingBarStatus(true)
    }

    render() {
        return (
            <>
                <div className={(this.props.store.darkMode) ? "Upload upload-dark" : "Upload"} style={{ width: "100%" }}>
                    <div className="Content">
                        <div>
                            <Dropzone onFilesAdded={this.onFilesAdded} disabled={this.state.uploading || this.state.successfullUploaded} />
                        </div>
                        <div className="Files">
                            {this.state.files.map(file => {
                                return (
                                    <div key={file.name} className="Row">
                                        <span className="Filename">{file.name}</span>
                                        {this.renderProgress(file)}
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <MapissoAlert />
                </div>
                <div className="Actions">{this.renderActions()}</div>
            </>
        );
    }
}

export default inject("store")(observer(UploadAppointmentFile));