import axios from "axios";

const EnabizModelRequest = async (baseUrl, token, doctorId, appointment, patient) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let postResp = null;

	await axios
		.post(
			baseUrl + "/doctor/" + doctorId + "/appointment/" + appointment + "/e-government ",
			patient,
			config
		)
		.then((res) => {
			postResp = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				postResp = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return postResp;
};

export default EnabizModelRequest;
