import React, { useState, useEffect, useRef } from 'react';
import { observer, inject } from "mobx-react"
import PropTypes from 'prop-types';

import { DataGrid } from '@mui/x-data-grid';
import { trTR } from "@mui/x-data-grid/locales";
import { Button, Paper } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import InfoIcon from '@mui/icons-material/Info';
import { IoPersonAddSharp } from 'react-icons/io5'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import postConsultation from '../../../utils/api/Doctor/Consultation/PostConsultation'
import deleteConsultDoctor from '../../../utils/api/Doctor/Consultation/DeleteConsultDoctor'
import putConsultationApprove from '../../../utils/api/Doctor/Consultation/PutConsultationApprove'
import putConsultationReject from '../../../utils/api/Doctor/Consultation/PutConsultationReject'
import getDoctorWithTcNo from '../../../utils/api/Doctor/GetDoctorWithTcNo'
import getConsultDoctorTalepList from '../../../utils/api/Doctor/Consultation/GetConsultDoctorTalepList'
import getConsultDoctorList from '../../../utils/api/Doctor/Consultation/GetConsultDoctorList'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Paper sx={{ p: 3 }} style={{ height: "calc(100vh - 200px)" }}>
                    {children}
                </Paper>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export const KonsultantDoktorListesiMenu = inject("store")(observer((props) => {

    const [doktorTcno, setDoktorTcno] = useState(0)
    const [komisyonOrani, setKomisyonOrani] = useState(0)

    const [konsultanDoktorList, setKonsultantDoktorList] = useState([])
    const [konsultanTalepDoktorList, setKonsultantTalepDoktorList] = useState([])
    const [addConsultantDoctorMenuDisplay, setAddConsultantDoctorMenuDisplay] = useState(false)

    const [searchKonsultantDoktor, setSearchKonsultantDoktor] = useState(null)

    const [tabValue, setTabValue] = useState(0);
    const [konsultanOnayMenuDisplay, setKonsultanOnayMenuDisplay] = useState(false)

    const [konsultReddetMenuDisplay, setKonsultReddetMenuDisplay] = useState(false)

    const selectedKonsultanTalepId = useRef(0)

    useEffect(() => {
        // Update the document title using the browser API
        if (props.data.length > 0) {
            //console.log("props.data: ", props.data)
            setKonsultantDoktorList(props.data)

            //setBackupHastaListesi(props.data)
        }
    }, [props.data]);

    useEffect(() => {
        // Update the document title using the browser API
        //console.log("props.talep: ", props.talep)
        if (props.talep.length > 0) {
            setKonsultantTalepDoktorList(props.talep)
            props.store.setActiveConsultReqSize(props.talep.length)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.talep]);

    const columns = [
        {
            field: 'consultAvatar', headerName: '',
            renderCell: (params) => (
                <>
                 {
                    (params.value !== null) ? <Avatar alt={params.value} src={props.store.baseUrl + params.value} /> : 

                    <Avatar alt={params.value} src={"/assets/media/default-doctor-avatar.png"} />
                 }
                    
                </>
            )
        },
        {
            field: 'consultName', headerName: "Adı", flex: 1
        },
        { field: 'consultSurname', headerName: "Soyadı", flex: 1 },
        { field: 'consultDepartment', headerName: 'Uzmanlık Alanı', flex: 1 },
        {
            field: 'commission', headerName: 'Adil Paylaşım Oranı', flex: 1,
            renderCell: (params) => (
                <Typography>% {params.value}</Typography>
            )
        },
/*
        {
            field: 'consultAddress', headerName: 'Adres', flex: 1
        }, */
        {
            field: 'consultMobilePhone', headerName: 'GSM No', flex: 1
        },
        {
            field: 'consultEmail', headerName: 'Eposta Adresi', flex: 1
        },
        {
            field: 'consultId',
            headerName: '',
            flex: 1,
            renderCell: (params) => (
                <>
                    <Tooltip title="Sil" placement="bottom">
                        <Button color="error" variant="contained" onClick={() => deleteConsultantDoctorReq(params.row.consultDoctorId)}
                            style={{ float: "right" }}>
                            <InfoIcon style={{ marginRight: "10px" }} /> Sil
                        </Button>
                    </Tooltip>
                </>
            ),
        }
    ];

    const talepColumns = [
        {
            field: 'consultAvatar', headerName: '',
            renderCell: (params) => (
                <>
                    <Avatar alt={params.value} src={"/assets/" + params.value} />
                </>
            )
        },
        {
            field: 'consultName', headerName: "Adı", flex: 1
        },
        { field: 'consultSurname', headerName: "Soyadı", flex: 1 },
        { field: 'consultDepartment', headerName: 'Uzmanlık Alanı', flex: 1 },
        {
            field: 'commission', headerName: 'Adil Paylaşım Oranı', flex: 1,
            renderCell: (params) => (
                <Typography>% {params.value}</Typography>
            )
        },
        {
            field: 'consultCityName', headerName: 'İl', flex: 1
        },
        {
            field: 'consultDistrictName', headerName: 'İlçe', flex: 1
        },
        {
            field: 'consultMobilePhone', headerName: 'GSM No', flex: 1
        },
        {
            field: 'consultEmail', headerName: 'E-posta', flex: 1
        },
        {
            field: 'doctorId',
            headerName: '',
            flex: 1,
            renderCell: (params) => (
                <>
                    <Tooltip title="Talebi Onayla" placement="bottom">
                        <IconButton color="success" variant="contained" onClick={() => onClickOpenKonsultanTalepMenu(params.value)}
                            style={{ float: "right", marginRight: "10px" }}>
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Talebi Reddet" placement="bottom">
                        <IconButton color="error" variant="contained" onClick={() => onClickOpenRejectMenu(params.value)}
                            style={{ float: "right" }}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ];

    const onChangeTextField = async (event) => {
        setDoktorTcno(event.target.value)
    }

    const handleAddConsultantDoctorMenuOpen = () => {
        setAddConsultantDoctorMenuDisplay(true)
    }

    const handleAddConsultantDoctorMenuClose = () => {
        setAddConsultantDoctorMenuDisplay(false)
    }

    const onClickSearchDoctor = async () => {
        props.store.setLoadingBarStatus(false)

        let doctorSearchResponse = await getDoctorWithTcNo(props.store.baseUrl, props.store.token, props.store.doctor[0].id, doktorTcno)
        //console.log("doctorSearchResponse: ", doctorSearchResponse)
        if (doctorSearchResponse !== null && doctorSearchResponse !== undefined) {
            if (doctorSearchResponse.status === 200) {
                setSearchKonsultantDoktor(doctorSearchResponse.data)
            } else if (doctorSearchResponse.status === 404) {
                props.store.setMessage("Girdiğiniz T.C. numarası ile sistemizde kayıtlı doktor bulunamadı. ", true, "error")
            }
        } else {
            props.store.setMessage("Hata oluştu", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const addConsultantDoctor = async (consultDoctorId) => {
        props.store.setLoadingBarStatus(false)

        let consultantBody = {
            consultDoctorId: consultDoctorId,
            commission: komisyonOrani
        }

        let addDoctorConsultantResponse = await postConsultation(props.store.baseUrl, props.store.token, props.store.doctor[0].id, consultantBody)
        if (addDoctorConsultantResponse !== null && addDoctorConsultantResponse !== undefined) {
            if (addDoctorConsultantResponse.status === 200) {
                props.store.setMessage("Konsültant talebi başarıyla oluşturuldu.", true, "success")
            } else {
                props.store.setMessage("Konsültant talebi oluşturulurken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Konsültant talebi oluşturulurken hata oluştu.", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const deleteConsultantDoctorReq = async (consultDoctorId) => {
        props.store.setLoadingBarStatus(false)

        let deleteConsultDoctorResponse = await deleteConsultDoctor(props.store.baseUrl, props.store.token, props.store.doctor[0].id, consultDoctorId)
        if (deleteConsultDoctorResponse !== null && deleteConsultDoctorResponse !== undefined) {
            if (deleteConsultDoctorResponse.status === 200) {
                props.store.setMessage("Konsültant doktor başarıyla silindi.", true, "success")
            } else {
                props.store.setMessage("Konsültant doktoru silinirken hata oluştu.", true, "success")
            }
        } else {
            props.store.setMessage("Konsültant doktoru silinirken hata oluştu.", true, "success")
        }
        props.store.setLoadingBarStatus(true)
    }

    const handleKonsultantTabChange = (event, newValue) => {
        setTabValue(newValue)
    }

    const approveKonsultantTalep = async (consultDoctorId) => {
        props.store.setLoadingBarStatus(false)

        //console.log("consultDoctorId: ", consultDoctorId)

        let approveKonsultantResponse = await putConsultationApprove(props.store.baseUrl, props.store.token, props.store.doctor[0].id, consultDoctorId)
        if (approveKonsultantResponse !== null && approveKonsultantResponse !== undefined) {
            if (approveKonsultantResponse.status === 200) {
                setKonsultanOnayMenuDisplay(false)
                props.store.setMessage("Konsültant talebi başarıyla onaylandı.", true, "success")
                props.store.setActiveConsultReqSize(props.store.activeConsultReqSize - 1)
                await getConsultAppointmentTalepListReq();
                await getAktifConsulListReq();
            } else {
                props.store.setMessage("Konsültant talebi onaylanırken hata oluştu.", true, "success")
            }
        } else {
            props.store.setMessage("Konsültant talebi onaylanırken hata oluştu.", true, "success")
        }

        props.store.setLoadingBarStatus(true)
    }

    const rejectKonsultantTalep = async (consultDoctorId) => {
        props.store.setLoadingBarStatus(false)

        let approveKonsultantResponse = await putConsultationReject(props.store.baseUrl, props.store.token, props.store.doctor[0].id, consultDoctorId)
        if (approveKonsultantResponse !== null && approveKonsultantResponse !== undefined) {
            if (approveKonsultantResponse.status === 200) {
                setKonsultanOnayMenuDisplay(false)
                props.store.setMessage("Konsültant talebi başarıyla onaylandı.", true, "success")
                props.store.setActiveConsultReqSize(props.store.activeConsultReqSize - 1)
                await getConsultAppointmentTalepListReq()
                await getAktifConsulListReq();
            } else {
                props.store.setMessage("Konsültant talebi onaylanırken hata oluştu.", true, "success")
            }
        } else {
            props.store.setMessage("Konsültant talebi onaylanırken hata oluştu.", true, "success")
        }

        props.store.setLoadingBarStatus(true)
    }

    const onChangeKomisyonOranıTextField = (event) => {
        setKomisyonOrani(event.target.value)
    }

    const onClickOpenKonsultanTalepMenu = (davetId) => {
        setKonsultanOnayMenuDisplay(true)
        selectedKonsultanTalepId.current = davetId;
    }

    const handleCloseKonsultanTalepOnayMenu = () => {
        setKonsultanOnayMenuDisplay(false)
    }

    const getAktifConsulListReq = async () => {
        props.store.setLoadingBarStatus(false)

        let consultantDoctorListResponse = await getConsultDoctorList(props.store.baseUrl, props.store.token, props.store.doctor[0].id)
        //console.log("consultantDoctorListResponse: ", consultantDoctorListResponse)
        if (consultantDoctorListResponse !== null && consultantDoctorListResponse !== undefined) {
            if (consultantDoctorListResponse.status === 200) {
                let consultantList = consultantDoctorListResponse.data
                if (consultantList !== null) {
                    for (let index = 0; index < consultantList.length; index++) {
                        const consultantDoctor = consultantList[index];
                        consultantDoctor.id = index
                    }
                    setKonsultantDoktorList(consultantList)
                }
            }
        }
    }

    const getConsultAppointmentTalepListReq = async () => {
        props.store.setLoadingBarStatus(false)
        let consultTalepListResponse = await getConsultDoctorTalepList(props.store.baseUrl, props.store.token, props.store.doctor[0].id)
        //console.log("Konsult Talep Listesi: ", consultTalepListResponse)

        if (consultTalepListResponse !== null && consultTalepListResponse !== undefined) {
            if (consultTalepListResponse.status === 200) {
                if (consultTalepListResponse.data !== null) {

                    //console.log("consultantDoctorTalepListResponse: ", consultTalepListResponse.data)
                    let consultantList = consultTalepListResponse.data

                    if (consultantList !== null) {
                        let fixedConsultantList = []
                        for (let index = 0; index < consultantList.length; index++) {
                            const consultantDoctor = consultantList[index];
                            consultantDoctor.id = index
                            fixedConsultantList.push(consultantDoctor)
                        }
                        setKonsultantTalepDoktorList(consultantList)
                        props.store.setActiveConsultReqSize(fixedConsultantList.length)
                    }

                } else {
                    setKonsultantTalepDoktorList([])
                }
            }
        }
        props.store.setLoadingBarStatus(true)
    }

    const onClickCloseRejectMenu = () => {
        setKonsultReddetMenuDisplay(false)
    }

    const onClickOpenRejectMenu = (talepId) => {
        setKonsultReddetMenuDisplay(true)
        selectedKonsultanTalepId.current = talepId
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Typography color="secondary" variant="h5" style={{ fontWeight: "bold" }}>Konsültanlarım</Typography>
                </Grid>

                <Grid item xs={4}>
                    <Button variant='contained' color="primary" style={{ float: "right" }} onClick={handleAddConsultantDoctorMenuOpen}>
                        <IoPersonAddSharp style={{ marginRight: "10px" }} /> Konsültan Doktoru Ekle
                    </Button>
                </Grid>
            </Grid>

            <Tabs value={tabValue} onChange={handleKonsultantTabChange} aria-label="tabs">
                <Tab label="AKTİF KONSÜLTANLARIM" />
                <Tab label={(konsultanTalepDoktorList !== null) ? "GELEN KONSÜLTAN TALEPLERİ(" + konsultanTalepDoktorList.length + ")" : "GELEN KONSÜLTAN TALEPLERİ(" + 0 + ")"} />
            </Tabs>

            <TabPanel value={tabValue} index={0} >
                <DataGrid rows={(konsultanDoktorList !== null) ? konsultanDoktorList : []} columns={columns} disableSelectionOnClick
                    localeText={trTR.components.MuiDataGrid.defaultProps.localeText} color="primary" style={{ border: "0" }} />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
                <DataGrid rows={(konsultanTalepDoktorList !== null) ? konsultanTalepDoktorList : []} columns={talepColumns} disableSelectionOnClick
                    localeText={trTR.components.MuiDataGrid.defaultProps.localeText} color="primary" style={{ border: "0" }} />
            </TabPanel>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={addConsultantDoctorMenuDisplay}
                onClose={handleAddConsultantDoctorMenuClose} >
                <DialogTitle>Konsültan Ekle</DialogTitle>
                <DialogContent style={{ padding: "30px" }}>
                    <TextField value={doktorTcno} onChange={onChangeTextField} label="Doktor T.C. Kimlik No" size="small" style={{ width: "calc(100% - 100px)", marginRight: "10px" }} />
                    <Button variant='contained' color="primary" style={{ width: "90px" }} onClick={onClickSearchDoctor}>Ara</Button>
                    <List hidden={(searchKonsultantDoktor === null) ? true : false}>
                        <ListItem secondaryAction={
                            <Tooltip title="Konsültant Doktor Ekle">
                                <IconButton edge="end" aria-label="delete" onClick={() => addConsultantDoctor((searchKonsultantDoktor !== null) ? searchKonsultantDoktor.id : 0)}
                                    color="primary">
                                    <IoPersonAddSharp />
                                </IconButton>
                            </Tooltip>}>
                            <ListItemAvatar>
                                <Avatar src={(searchKonsultantDoktor !== null) ? (searchKonsultantDoktor.avatar !== null) ? props.store.baseUrl + searchKonsultantDoktor.avatar : "/assets/media/default-doctor-avatar.png" : "/assets/media/default-doctor-avatar.png"} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={(searchKonsultantDoktor?.title.titleName !== null) ? searchKonsultantDoktor?.title.titleName + " " + searchKonsultantDoktor?.professional.professionalName + " " + searchKonsultantDoktor?.name + " " + searchKonsultantDoktor?.surname
                            : searchKonsultantDoktor?.professional.professionalName + " " + searchKonsultantDoktor?.name + " " + searchKonsultantDoktor?.surname}
                            />
                            <TextField label="Adil Paylaşım Oranı" type="number" value={komisyonOrani} onChange={onChangeKomisyonOranıTextField}
                            ></TextField>
                          
                        </ListItem>
                    </List>
                    <Typography variant="body2" style={{ marginTop: "10px" }}>
                    
                        *Sayın Kullanıcı, hizmet tanımı gereği Konsültasyon, bir hasta/danışanın iki sağlık çalışanı tarafından değerlendirilmesidir.
                        Siz, konsülte ettiğiniz hasta/danışanın tıbbi yönlendirmesinde primer sorumlu kişi olduğunuz için, konsültanın önerilerinin ne
                        ölçüde uygulamaya dahil edileceğine karar veren sorumlu durumundasınız. Bu nedenle, konsültanın hasta/danışanla görüşmesi sonucu
                        alınacak kararın önemli paydaşı olarak, konsültanla mutabakatınız doğrultusunda oluşan hizmet bedelinde hak sahibi olabilirsiniz.
                        Önerimiz, konsültanlarınızla bu amaçla görüşmenizdir. Mutabakatınız doğrultusunda, Konsültanlarım sekmesinde konsültanınızın bilgi
                        satırına yüzde olarak bir hakediş tanımladığınızda, konsültanınız tarafından bu giriş onaylandığında, konsültanınızın hakedişinden
                        belirlenen oran sizin cari hesabınıza kaydolur ve hasta ödemesi buna göre pay edilir. Benzer bilgilendirme konsültanınıza da iletilecektir.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddConsultantDoctorMenuClose}>Kapat</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={konsultanOnayMenuDisplay}
                onClose={handleCloseKonsultanTalepOnayMenu} >
                <DialogTitle> Seçtiğiniz Konsültanı Onaylama Ekranı</DialogTitle>
                <DialogContent style={{ padding: "30px", paddingTop: "0" }}>

                    <Typography variant="body2" style={{ marginTop: "10px" }}>
                        *Sayın Kullanıcı, hizmet tanımı gereği Konsültasyon, bir hasta/danışanın iki sağlık çalışanı tarafından
                        değerlendirilmesidir. Size bir hasta/danışan konsülte edildiğinde, gönderen sağlık çalışanı önerilerinizin
                        ne ölçüde uygulamaya dahil edileceğinde sorumlu olmaya devam eder. Bu nedenle, konsülte edenle mutabakatınız
                        sonucu, konsültasyon hizmetinize dair hizmet bedelinizin bir kısmını konsülte edenle paylaşabilirsiniz.
                        Önerimiz, konsülte eden sağlık çalışanlarıyla bu amaçla görüşmenizdir. Mutabakatınız doğrultusunda,
                        hasta yönlendiren sağlık çalışanı Konsültanlarım sekmesinde sizin bilgi satırınıza yüzde olarak bir hakediş
                        tanımladığında, ve siz bu girişi onaylandığında, hizmet bedelinizden  belirlenen oran gönderenin cari hesabına
                        kaydolur ve hasta ödemesi buna göre pay edilir. Benzer bilgilendirme davet gönderen kullanıcıya da iletilmiştir.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="success" variant='contained' onClick={() => approveKonsultantTalep(selectedKonsultanTalepId.current)}>Onayla</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={konsultReddetMenuDisplay}
                onClose={onClickCloseRejectMenu} >
                <DialogTitle> Konsültan Reddetme Ekranı</DialogTitle>
                <DialogContent style={{ padding: "30px", paddingTop: "0" }}>
                    <Typography variant="body2" style={{ marginTop: "10px" }}>
                        Gelen konsült doktor olma talebini reddetmek istediğiniz emin misiniz?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="success" variant='contained' onClick={() => rejectKonsultantTalep(selectedKonsultanTalepId.current)}>Evet</Button>
                    <Button color="success" variant='contained' onClick={() => onClickCloseRejectMenu}>Hayır</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}))