import { types } from "mobx-state-tree"
import { DoctorPrice } from "./DoctorPrice"
import { DoctorSetting } from "./DoctorSetting"
import { DoctorWorkingDay } from "./DoctorWorkingDay"

export const DoctorAllSettings = types
    .model({
        doctorId: types.maybeNull(types.optional(types.number, 0)),
        doctorSettings: types.optional(types.array(DoctorSetting), []),
        doctorPrice: types.maybeNull(types.optional(DoctorPrice, {})),
        doctorWorkingDays: types.optional(types.array(DoctorWorkingDay), [])
    }).actions(self => ({

    }))