import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import { containerStyle } from '../../utils/export-style';

import { LeftMenu } from '../../components/common/LeftMenu';
import { Box, Container, Grid } from '@mui/material';
import { ProfileMenu } from '../../components/doktor/profile/ProfileMenu';

class ProfilePage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            clinic: null
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/panel/kullanici-girisi");
        }
    }
 
    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage={""}/>
                <Box component="main" sx={{
                    ...containerStyle,
                    flexGrow: 1,
                    py: 8
                }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                               <ProfileMenu />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(ProfilePage));
