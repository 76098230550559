import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import { containerStyle } from '../../utils/export-style';

import { LeftMenu } from '../../components/common/LeftMenu';
import { Box, Container, Grid } from '@mui/material';

import { KonsultantDoktorListesiMenu } from '../../components/doktor/konsultant/KonsultantDoktorListesiMenu';

import getConsultDoctorList from '../../utils/api/Doctor/Consultation/GetConsultDoctorList'
import getConsultDoctorTalepList from '../../utils/api/Doctor/Consultation/GetConsultDoctorTalepList'

class KlinikPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            konsultantDoktorList: [],
            konsultantDoktorTalepList: []
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/panel/kullanici-girisi");
        } else {
            this.props.store.setLoadingBarStatus(false)

            let consultantDoctorListResponse = await getConsultDoctorList(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id)
            //console.log("consultantDoctorListResponse: ", consultantDoctorListResponse)
            if (consultantDoctorListResponse !== null && consultantDoctorListResponse !== undefined) {
                if (consultantDoctorListResponse.status === 200) {

                    let consultantList = consultantDoctorListResponse.data

                    if (consultantList !== null) {

                        for (let index = 0; index < consultantList.length; index++) {
                            const consultantDoctor = consultantList[index];
                            consultantDoctor.id = index

                        }
                        this.setState({
                            konsultantDoktorList: consultantList
                        })
                    }

                }else if(consultantDoctorListResponse.status === 401){
                    this.props.store.setUserLogout()
                    window.location.reload()
                }
            }

            let consultantDoctorTalepListResponse = await getConsultDoctorTalepList(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id)
            if (consultantDoctorTalepListResponse !== null && consultantDoctorTalepListResponse !== undefined) {
                if (consultantDoctorTalepListResponse.status === 200) {

                    // console.log("consultantDoctorTalepListResponse: ", consultantDoctorTalepListResponse.data)
                    let consultantList = consultantDoctorTalepListResponse.data
                   
                    if (consultantList !== null) {
                        let fixedConsultantList = []
                        for (let index = 0; index < consultantList.length; index++) {
                            const consultantDoctor = consultantList[index];
                            consultantDoctor.id = index
                            fixedConsultantList.push(consultantDoctor)
                        }
                        // console.log("consultantList: ", consultantList)
                        this.setState({
                            konsultantDoktorTalepList: fixedConsultantList
                        })

                        this.props.store.setActiveConsultReqSize(fixedConsultantList.length)
                    }
                }
            }

            this.props.store.setLoadingBarStatus(true)
        }
    }

    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage={"Konsültanlarım"} />
                <Box component="main" sx={{
                    ...containerStyle,
                    flexGrow: 1,
                    py: 8
                }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                                <KonsultantDoktorListesiMenu data={this.state.konsultantDoktorList} talep={this.state.konsultantDoktorTalepList} />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(KlinikPage));
