import axios from "axios";
import parseNoteData from "../../../../parsers/NoteDataParser";

const getAppointmentNote = async (baseUrl, token, doctorId, appointmentId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + "/doctor/" + doctorId + "/appointment/" + appointmentId+ "/note", config)
        .then((res) => {
            let noteData = res.data.data
            if(noteData !== null){
                getResponse = {
                    status: res.status,
                    data: parseNoteData(noteData)
                }
            }else{
                getResponse = {
                    status: res.data.status,
                    data: ""
                }
            }
            
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return getResponse
}

export default getAppointmentNote;