import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"

import dayjs from 'dayjs';

import { Button, Grid, TextField, Typography, IconButton, FormGroup, Checkbox, MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel'
import Select from '@mui/material/Select';

import { DatePicker } from '@mui/x-date-pickers';

import 'dayjs/locale/tr';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import CloseIcon from '@mui/icons-material/Close';

import postInsuranceForm from '../../../utils/api/Doctor/Appointment/InsuranceForm/PostInsuranceForm'
import getAppointmentInsuranceForm from '../../../utils/api/Doctor/Appointment/InsuranceForm/GetAppointmentInsuranceForm';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const SigortaBilgiFormuMenu = inject("store")(observer((props) => {

    const [planlananYatisDate, setPlanlananYatisDate] = useState(new Date())
    const [planlananCikisDate, setPlanlananCikisDate] = useState(new Date())
    const [selectedPatientInsuranceId, setSelectedPatientInsuranceId] = useState(0)
    const [selectedInsuranceFirmName, setSelectedInsuranceFirmName] = useState("")
    const [sigortaFormAnlasmali, setSigortaFormAnlasmali] = useState(false)
    const [appointmentInsuranceForm, setAppointmentInsuranceForm] = useState(null)
    const [patientInsuranceList, setPatientInsuranceList] = useState([])

    useEffect(() => {
        // Update the document title using the browser API
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setPatientInsuranceList(props.patientInsurances)
        if (props.appointmentInsuranceForm !== null) {
            //console.log("props.appointmentInsuranceForm: ", props.appointmentInsuranceForm)
            setAppointmentInsuranceForm(props.appointmentInsuranceForm)
        }
    }, [props.appointmentInsuranceForm, props.patientInsurances]);

    const onClickSigortaBilgiMenuClose = () => {
        props.store.setSigortaBilgiFormuMenuDisplay(false)
    }

    const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    const onChangeCalendarValue = (event) => {
        //setSikayetBaslangicDate(event)
        setAppointmentInsuranceForm({ ...appointmentInsuranceForm, complaintStartdate: event })
    }

    const onChangePlanlananYatisTarih = (event) => {
        setPlanlananYatisDate(event)
    }

    const onChangePlanlananCikisTarih = (event) => {
        setPlanlananCikisDate(event)
    }

    const onChangeTextInput = (event) => {
        setAppointmentInsuranceForm({ ...appointmentInsuranceForm, previousDoctorApplied: event.target.value })
        //setDahaOncekiDoktorBasvurusu(event.target.value)
    }

    const onChangeSelectePatientInsurance = (event) => {
        //console.log("event: ", event.target.value)
        setSelectedPatientInsuranceId(Number(event.target.value))
        for (let index = 0; index < props.patientInsurances.length; index++) {
            const tempInsurance = props.patientInsurances[index];
            if (tempInsurance.insuranceId === Number(event.target.value)) {
                setSelectedInsuranceFirmName(tempInsurance.firmName)
            }
        }
    }

    const onClickSaveSigortaBilgiForm = async () => {
        props.store.setLoadingBarStatus(false)

        let treatment = ""

        for (let index = 0; index < props.tedavi.length; index++) {
            const tedavi = props.tedavi[index];

            //console.log("tedavi: ", tedavi)
            if (tedavi.actionTypeId === 1) {
                treatment = treatment + " Reçete oluşturuldu."
            } else if (tedavi.actionTypeId === 2) {
                treatment = treatment + " Rapor oluşturuldu."
            } else if (tedavi.actionTypeId === 3) {
                treatment = treatment + " Tetkik talebi oluşturuldu."
            } else if (tedavi.actionTypeId === 4) {
                treatment = treatment + " Konsültasyon istendi."
            } else if (tedavi.actionTypeId === 5) {
                treatment = treatment + " Tekrar randevu oluşturuldu."
            }
        }

        let sigortaBilgiForm = {
            insuranceId: selectedPatientInsuranceId,
            patientId: props.patient.patientId,
            previousDoctorAppliedDesc: (appointmentInsuranceForm !== null) ? appointmentInsuranceForm.previousDoctorApplied : "",
            complaintDate: (appointmentInsuranceForm !== null) ? appointmentInsuranceForm.complaintStartdate: new Date(),
            appliedDate: new Date(),
            treatment: treatment + props.tedaviAciklama,
        }

        //console.log("sigortaBilgiForm: ", sigortaBilgiForm)

        let insuranceFormSaveResponse = await postInsuranceForm(props.store.baseUrl, props.store.token, props.store.doctor[0].id,
            props.appointmentId, sigortaBilgiForm)

        //console.log("insuranceFormSaveResponse: ", insuranceFormSaveResponse)
        if (insuranceFormSaveResponse !== null && insuranceFormSaveResponse !== undefined) {
            if (insuranceFormSaveResponse.status === 200) {
                props.store.setMessage("Sigorta bilgi formu başarıyla kaydedildi.", true, "success")
                await getAppointmentInsuranceFormReq()
            } else {
                props.store.setMessage(insuranceFormSaveResponse.data.message, true, "error")
            }
        } else {
            props.store.setMessage("Sigorta bilgi formu kaydedilirken hata oluştu.", true, "error")
        }

        //console.log("sigortaBilgiForm: ", sigortaBilgiForm)


        props.store.setLoadingBarStatus(true)
    }

    const onClickUpdateSigortaBilgiForm = async () => {
        props.store.setLoadingBarStatus(false)

        let treatment = appointmentInsuranceForm.treatment

        //console.log("appointmentInsuranceForm: ", appointmentInsuranceForm)

        let sigortaBilgiForm = {
            //insuranceId: appointmentInsuranceForm.insuranceId,
            patientId: props.patient.patientId,
            previousDoctorAppliedDesc: appointmentInsuranceForm.previousDoctorApplied,
            complaintDate: new Date(appointmentInsuranceForm.complaintStartdate),
            appliedDate: new Date(),
            treatment: treatment,
        }

        let insuranceFormSaveResponse = await postInsuranceForm(props.store.baseUrl, props.store.token, props.store.doctor[0].id,
            props.appointmentId, sigortaBilgiForm)

        //console.log("insuranceFormSaveResponse: ", insuranceFormSaveResponse)
        if (insuranceFormSaveResponse !== null && insuranceFormSaveResponse !== undefined) {
            if (insuranceFormSaveResponse.status === 200) {
                props.store.setMessage("Sigorta bilgi formu başarıyla kaydedildi.", true, "success")
                await getAppointmentInsuranceFormReq()
            } else {
                props.store.setMessage("Sigorta bilgi formu kaydedilirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Sigorta bilgi formu kaydedilirken hata oluştu.", true, "error")
        }

        //console.log("sigortaBilgiForm: ", sigortaBilgiForm)


        props.store.setLoadingBarStatus(true)

    }

    const onChangeSigortaAnlasmaliAnlasmasiz = (event) => {
        if (event.target.name === "anlasmali") {
            setSigortaFormAnlasmali(true)
        } else {
            setSigortaFormAnlasmali(false)
        }
    }

    const getAppointmentInsuranceFormReq = async () => {
        props.store.setLoadingBarStatus(false)

        let getInsuranceFormRequestResponse = await getAppointmentInsuranceForm(props.store.baseUrl, props.store.token,
            props.store.doctor[0].id, props.patient.patientId)

        //console.log("getInsuranceFormRequestResponse: ", getInsuranceFormRequestResponse)

        if (getInsuranceFormRequestResponse !== null && getInsuranceFormRequestResponse !== undefined) {
            if (getInsuranceFormRequestResponse.status === 200) {
                setAppointmentInsuranceForm(getInsuranceFormRequestResponse.data)
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    return (
        <>
            <Dialog fullScreen open={props.store.sigortaBilgiFormuMenuDisplay} /*onClose={closeReceteMenuDialog}*/>
                <AppBar sx={{ position: 'relative', background: "#2196f3" }}>
                    <Toolbar>
                        <IconButton onClick={onClickSigortaBilgiMenuClose}
                            edge="start"
                            color="inherit"
                            aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" style={{ fontWeight: "bold" }}>
                            Sigorta Bilgi Formu
                        </Typography>
                        {(props.appointmentInsuranceForm === null) ?
                            <Button color="info" variant='contained' onClick={onClickSaveSigortaBilgiForm}>
                                SİGORTA BİLGİ FORMU KAYDET</Button>
                            :
                            <Button color="info" variant='contained' onClick={onClickUpdateSigortaBilgiForm}>
                                SİGORTA BİLGİ FORMU GÜNCELLE</Button>
                        }
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container spacing={0} style={{ marginTop: "0px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                        <Grid item xs={2}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Hastanın Özel Sağlık Sigortasını Seç:</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <FormControl style={{ width: "250px" }}>
                                <Select labelId="insurance-select-label"
                                    value={selectedPatientInsuranceId}
                                    id="insurance-select" style={{ background: "#fff" }}
                                    onChange={onChangeSelectePatientInsurance} MenuProps={MenuProps}>
                                    {patientInsuranceList.map((insurance, idx) => (
                                        <MenuItem key={idx} value={insurance.insuranceId}>{insurance.firmName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ border: "1px solid gray", marginTop: "20px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                        <Grid item xs={12} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>Bu form hastadan alınan bilgiler, fiziki muayene bulguları, tetkik sonuçları ve poliklinik kayıtlarındaki
                                bilgiler doğrultusunda eksiksiz ve tam doldurulmalıdır.
                            </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Sigorta Şirketi: </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'>
                                {selectedInsuranceFirmName}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Provizyon No:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2'></Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Provizyon/İrtibat Numaraları: </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Telefon Numarası: </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Faks Numarası: </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ border: "1px solid gray", marginTop: "20px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                        <Grid item xs={1} style={{ borderRight: "1px solid gray" }}>
                            <Typography variant='body2' className='vertical' style={{ fontWeight: "bold" }}>
                                Sağlık Kurumu Tarafından <br /> Doldurulacak Bölüm
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Grid container spacing={1}>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Sağlık Kuruluş Adı:</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Kurum Kodu:</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Telefon No:</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Faks No</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' >
                                        {(props.store.doctor[0] !== undefined) ?
                                            ((props.store.doctor[0].title !== null) ? props.store.doctor[0].title.titleName : "")
                                            + " " +
                                            ((props.store.doctor[0].professional !== null) ? props.store.doctor[0].professional.professionalName : "")
                                            + " " + props.store.doctor[0].name + " " + props.store.doctor[0].surname : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    {(props.store.doctor[0] !== undefined) ? formatPhoneNumber(props.store.doctor[0].mobilePhone) : ""}
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                                </Grid>
                                <Grid item xs={12} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2'><b>Sigortalının Adı-Soyadı:</b> {(props.patient.id !== 0) ? props.patient.name + " " + props.patient.surname : ""}</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2'><b>Doğum Tarihi:</b> {(props.patient.id !== 0) ? dayjs(new Date(props.patient.birthDate)).format("DD.MM.YYYY") : ""}</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2'><b>Cinsiyet:</b> {(props.patient.id !== 0) ? (props.patient.gender === 1) ? "Erkek" : (props.patient.gender === 2) ? "Kadın" : "Belirsiz" : ""}</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Poliçe No:</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2'><b>T.C. No:</b> {(props.patient.id !== 0) ? props.patient.tckno : ""}</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Adres:</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>E-posta Adresi:</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant='body2'><b>Başvuru Tarihi:</b> {dayjs(new Date()).format("DD.MM.YYYY")}</Typography>
                                </Grid>
                                <Grid item xs={2} >
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Planlanan Yatış/Çıkış Tarihi:</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ padding: "10px" }}>
                                    <FormControl fullWidth style={{ marginTop: "15px", marginBottom: "15px" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} style={{ width: "calc(100% - 100px)" }}>
                                            <DatePicker label="Planlanan Yatış Tarihi" value={planlananYatisDate} disabled
                                                onChange={(newValue) => onChangePlanlananYatisTarih(newValue)}
                                                renderInput={(params) => <TextField {...params} />} resizable
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} style={{ padding: "10px" }}>
                                    <FormControl fullWidth style={{ marginTop: "15px", marginBottom: "15px" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} style={{ width: "calc(100% - 100px)" }}>
                                            <DatePicker label="Planlanan Çıkış Tarihi" value={planlananCikisDate}
                                                onChange={(newValue) => onChangePlanlananCikisTarih(newValue)} disabled
                                                renderInput={(params) => <TextField {...params} />} resizable
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid spacing={1} container style={{ border: "1px solid gray", marginTop: "20px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                        <Grid item xs={12} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Ayakta tedaviler için talep edilen ücret bilgileri</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Doktor:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>İlaç:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Röntgen:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Sarf/Malzeme:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Laboratuvar:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Diğer/Açıklayınız:</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                        </Grid>
                        <Grid item xs={3}>

                        </Grid>
                        <Grid item xs={3}>

                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>Toplam:</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ border: "1px solid gray", marginTop: "20px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                        <Grid item xs={1} style={{ borderRight: "1px solid gray" }}>
                            <Typography variant='body2' className='vertical' style={{ fontWeight: "bold" }}>
                                Muayene Eden Hekim Tarafından <br /> Doldurulacak Bölüm
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Grid container spacing={1}>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2'><b>Hastanın Şikayeti / Öyküsü:</b> </Typography>
                                </Grid>
                                <Grid item xs={9} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2'>{((props.complaint !== undefined) ? props.complaint : "") + " " + ((props.symptom !== undefined) ? props.symptom : "")}</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Şikayetin Başlangıç Tarihi:</Typography>
                                </Grid>
                                <Grid item xs={9} style={{ borderBottom: "1px solid gray", paddingRight: "15px" }}>
                                    <FormControl fullWidth style={{ marginTop: "15px", marginBottom: "15px" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'} style={{ width: "calc(100% - 100px)" }}>
                                            <DatePicker disableFuture
                                                label="Şikayetin Başlangıç Tarihi"
                                                value={(appointmentInsuranceForm !== null) ? new Date(appointmentInsuranceForm.complaintStartdate) : new Date()}
                                                onChange={(newValue) => onChangeCalendarValue(newValue)}
                                                renderInput={(params) => <TextField {...params} />} resizable
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Daha önce aynı şikayet/hastalık nedeni ile doktor başvurusu,
                                        tetkik ve tedavi uygulandı mı? (Başvurulan sağlık kurumu  doktoru adı) :</Typography>
                                </Grid>
                                <Grid item xs={9} style={{ borderBottom: "1px solid gray", paddingRight: "15px" }}>
                                    <TextField value={(appointmentInsuranceForm !== null) ? appointmentInsuranceForm.previousDoctorApplied : ""} onChange={onChangeTextInput}
                                        label="Daha önce aynı şikayet/hastalık için yapılan işlemler"
                                        style={{ width: "100%", marginTop: "15px", marginBottom: "15px" }} multiline rows={3}></TextField>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Özgeçmiş / Kullandığı İlaçlar:</Typography>
                                </Grid>
                                <Grid item xs={9} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2'>{(appointmentInsuranceForm !== null) ? appointmentInsuranceForm.patientHistory : ""} </Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Fizik Muayene Bulguları:</Typography>
                                </Grid>
                                <Grid item xs={9} style={{ borderBottom: "1px solid gray" }}>

                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Tetkikler/Sonuçlar:</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>

                                </Grid>
                                <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>

                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Ön Tanı / Tanı:</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2'>
                                        {(props.diagnosis.diagnosisId !== 0) ?
                                            (props.diagnosis.diagnosisType === 1) ? "Kesin Tanı: " + props.diagnosis.diagnosisCode + " " + props.diagnosis.diagnosisName + " " + props.diagnosis.diagnosisDesc
                                                : (props.diagnosis.diagnosisType === 3) ? "Ön Tanı: " + props.diagnosis.diagnosisCode + " " + props.diagnosis.diagnosisName + " " + props.diagnosis.diagnosisDesc
                                                    : "" : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray", borderLeft: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>ICD 10:</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2'>{props.diagnosis.diagnosisCode}</Typography>
                                </Grid>
                                <Grid item xs={3} >
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Planlanan Tedavi / İşlem:</Typography>
                                </Grid>
                                <Grid item xs={9} >
                                    <Typography variant='body2'>
                                        {(appointmentInsuranceForm === null) ?
                                            (props.tedavi !== null) ?
                                                props.tedavi.map((tedavi, idx) => (
                                                    (tedavi.actionTypeId === 1) ?
                                                        "Reçete oluşturuldu. " : (tedavi.actionTypeId === 2) ?
                                                            "Rapor oluşturuldu. " : (tedavi.actionTypeId === 3) ?
                                                                "Tetkik talebi oluşturuldu. " : (tedavi.actionTypeId === 4) ?
                                                                    "Konsültasyon istendi. " : (tedavi.actionTypeId === 5) ?
                                                                        "Tekrar randevu oluşturuldu." : ""
                                                )) : null
                                            : appointmentInsuranceForm.treatment
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ border: "1px solid gray", marginTop: "20px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                        <Grid item xs={5}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Doktor Adı-Soyadı:</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2'> {(props.store.doctor[0] !== undefined) ?
                                        ((props.store.doctor[0].title !== null) ? props.store.doctor[0].title.titleName : "")
                                        + " " +
                                        ((props.store.doctor[0].professional !== null) ? props.store.doctor[0].professional.professionalName : "")
                                        + " " + props.store.doctor[0].name + " " + props.store.doctor[0].surname : ""}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Uzmanlık Dalı:</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2'>
                                        {(props.store.doctor[0] !== undefined) ? (props.store.doctor[0].professional !== null) ? props.store.doctor[0].professional.professionalName : "" : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>İrtibat Telefonu:</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2'>
                                        {(props.store.doctor[0] !== undefined) ? formatPhoneNumber(props.store.doctor[0].mobilePhone) : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} style={{ height: "100px" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>İmza / Kaşe:</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    {
                                        (props.store.doctor[0] !== undefined) ?
                                            <img alt="kase" src={props.store.baseUrl + props.store.doctor[0].kase} style={{ width: "200px" }}></img> : null}
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={2} style={{ borderLeft: "1px solid gray", borderRight: "1px solid gray" }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FormGroup onChange={onChangeSigortaAnlasmaliAnlasmasiz}>
                                        <FormControlLabel control={<Checkbox name="anlasmali" checked={sigortaFormAnlasmali} />} label="Anlaşmalı" />
                                        <FormControlLabel control={<Checkbox name="anlasmasiz" checked={!sigortaFormAnlasmali} />} label="Anlaşmasız" />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Operatör: </Typography>
                                </Grid>

                                <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>

                                </Grid>
                                <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Anestezi: </Typography>
                                </Grid>

                                <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>

                                </Grid>
                                <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Asistan: </Typography>
                                </Grid>

                                <Grid item xs={6} style={{ borderBottom: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}> </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body2'>
                                        *Doktor ve/ veya ekibi anlaşmasız ise sigortalının tedavisi için talep edilecek ücreti yukarıda belirtecektir.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ border: "1px solid gray", marginTop: "20px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                        <Grid item xs={12}>
                            <Typography variant='body2'>
                                Anlaşmalı sağlık kuruluşlarında yapılan her türlü tedavi / işlem anlaşması bulunmayan / anlaşmalı sağlık kurum ile Sigorta Şirketi
                                arasındaki fiyat protokolüne göre hizmet vermeyi kabul etmeyen kadrosuz bir hekim ve / veya ekibi tarafından gerçekleştirildiğinde
                                ilgili hekim ve / veya ekibine ödenecek tutar poliçe özel ve genel şartları dahilinde değerlendirileceği için sigorta şirketi ile irtibta
                                geçilmesi gerekmektedir.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} style={{ border: "1px solid gray", marginTop: "20px", marginLeft: "0", marginRight: "0", width: "100%" }}>
                        <Grid item xs={12}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>
                                Sigortalı / Kanuni Temsilcisinin Beyanı
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                Yukarıda verilen bilgilerin eksiksiz ve doğru olduğunu, sigorta şirketinin kendim ve bağımlılarım hakkında bu / diğer rahatsızlıklara ilişkin
                                tüm bilgi / belgeleri, tüm sağlık kuruluşlarından isteme hakkı olduğunu beyan / kabul ederim.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2'>
                                <b>Sigortalı / Kanuni Temsilcisinin Adı Soyadı: </b>  {(props.patient.id !== 0) ? props.patient.name + " " + props.patient.surname : ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body2'><b>Tarih: </b> {dayjs(new Date()).format("DD.MM.YYYY")} </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>İmza: </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}))