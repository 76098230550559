import axios from "axios";
import parseDoctorData from "../../parsers/DoctorDataParser";

const getNurseDoctor = async (baseUrl, token, nurseId) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let getResponse = null;

	await axios
		.get(baseUrl + "/doctor/nurse/" + nurseId, config)
		.then((res) => {
			getResponse = {
				status: res.status,
				data: parseDoctorData(res.data),
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};

export default getNurseDoctor;
