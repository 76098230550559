// Permissions
// 1: 
// 2: Diagnosis-Tanı
// 3: Examination-Tetkik
// 4: Report-Rapor
// 5: Medicine-İlaç
// 6: Supplement-Takviye
// 7: MedicalHistory-Anamnez

export const userPermissions = (doctorType) => {
	return professionals.find(professional => professional.id === doctorType).permissions;
}
export const professionName = (doctorType) => {
	return professionals.find(professional => professional.id === doctorType).name;
}

const professionals = [
	{
		id: 1,
		slug: "doktor",
		name: "Doktor",
		permissions: [2, 3, 4, 5, 6, 7],
	},
	{
		id: 3,
		slug: "diyetisyen",
		name: "Diyetisyen",
		permissions: [3, 6, 7],
	},
	{
		id: 2,
		slug: "psikolog",
		name: "Psikolog",
		permissions: [3, 6, 7],
	},
	{
		id: 4,
		slug: "fizyoterapist",
		name: "Fizyoterapist",
		permissions: [3, 6, 7],
	},
	{
		id: 5,
		slug: "antrenor",
		name: "Kişisel Antrenör",
		permissions: [6],
	},
	{
		id: 6,
		slug: "egitmen",
		name: "Eğitmen",
		permissions: [6, 7],
	},
	{
		id: 7,
		slug: "danisman",
		name: "Danışman",
		permissions: [7],
	},
	{
		id: 8,
		slug: "odyolog",
		name: "Odyolog",
		permissions: [2, 3, 4, 5, 6, 7],
	},
	{
		id: 9,
		slug: "gorusmeci",
		name: "Görüşmeci",
		permissions: [],
	},
];
