import React from "react";
const PromoCard = ({ promo, appoPrice, btnText }) => {
	appoPrice = appoPrice || promo.price / promo.count || 0;
	btnText = btnText || "Satın Al";
	return (
		<div className="promo-card">
			<div className="promo-card__count">{`${promo.count} adet randevu`}</div>
			<div className="promo-card__price">{appoPrice * promo.count + "TL"}</div>
			<div className="promo-card__discount">{`%${promo.discount} İndirim`}</div>
			<div className="promo-card__pad">
				{(appoPrice * promo.count * (100 - promo.discount)) / 100 + "TL"}
			</div>
			{promo.description && <div className="promo-card__desc">{promo.description}</div>}
			<button className="promo-card__button">{btnText}</button>
		</div>
	);
};
export default PromoCard;
