import { types } from "mobx-state-tree"
import { Address } from "../Address"

export const Clinic = types
    .model({
        clinicId: types.maybeNull(types.optional(types.number, 0)),
        doctorId: types.maybeNull(types.optional(types.number, 0)),
        name: types.maybeNull(types.optional(types.string, "")),
        adress: types.maybeNull(types.optional(types.array(Address), [])),
    }).actions(self => ({

    }))