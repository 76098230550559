import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import MoneyIcon from "@mui/icons-material/Money";
import CurrencyFormat from "react-currency-format";

export const AylikMuayeneCirosu = (props) => (
	<Card sx={{ height: "100%" }} {...props}>
		<CardContent>
			<Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
				<Grid item>
					<Typography color="textSecondary" gutterBottom variant="overline">
						{props.text ?? "AYLIK MUAYENE CİROSU"}
					</Typography>
					<Typography color="textPrimary" variant="h4">
						{props.usertype !== 1 ? (
							"**** "
						) : (
							<CurrencyFormat
								value={props.data}
								displayType="text"
								thousandSeparator={true}
							/>
						)}{" "}
						{props.unit ?? "TL"}
					</Typography>
				</Grid>
				<Grid item>
					<Avatar
						sx={{
							backgroundColor: "success.main",
							height: 56,
							width: 56,
						}}
					>
						<MoneyIcon />
					</Avatar>
				</Grid>
			</Grid>
		</CardContent>
	</Card>
);
