import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import { containerStyle } from '../../utils/export-style';

import { LeftMenu } from '../../components/common/LeftMenu';

import { Box, Container, Grid } from '@mui/material';

import getDoctorSettings from '../../utils/api/Doctor/GetDoctorSettings'
import { DoktorAyarlarMenu } from '../../components/doktor/ayarlar/DoktorAyarlarMenu';

class DoktorAyarlarPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            settings: null
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/panel/kullanici-girisi");
        }else{
            this.props.store.setLoadingBarStatus(false)
            let doctorSettingsResponse = await getDoctorSettings(this.props.store.baseUrl, this.props.store.token, this.props.store.user[0].id)
            //console.log("doctorSettingsResponse: ", doctorSettingsResponse.data)
            if(doctorSettingsResponse !== undefined && doctorSettingsResponse !== null){
                if(doctorSettingsResponse.status === 200){
                    this.setState({
                        settings: doctorSettingsResponse.data.data
                    })
                }else if (doctorSettingsResponse.status === 401) {
                    this.props.store.setUserLogout()
                    window.location.reload()
                }
            }else{
                this.props.store.setMessage("Hasta bilgisi getirilirken hata oluştu.", true, "error")
            }
            this.props.store.setLoadingBarStatus(true)
        }
    }
 
    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage={""}/>
                <Box component="main" sx={{
                    ...containerStyle,
                    flexGrow: 1,
                    py: 8
                }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                                <DoktorAyarlarMenu data={this.state.settings}/>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(DoktorAyarlarPage));
