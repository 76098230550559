import axios from "axios";

const deleteClinicProduct = async (baseUrl, token, doctorId, clinicId, productId) => {

    //doctor/{doctorid}/clinic/{clinicid}/room/{roomid}

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let deleteResponse = null

    await axios.delete(baseUrl + "/doctor/" + doctorId + "/clinic/" + clinicId + "/product?productid=" + productId, config)
        .then((res) => {
            deleteResponse = {
                status: res.data.status,
                data: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                deleteResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return deleteResponse
}

export default deleteClinicProduct;