import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";

import {
	Box,
	Container,
	Grid,
	Button,
	TextField,
	InputLabel,
	Select,
	Checkbox,
	MenuItem,
	IconButton,
	Card,
	CardActions,
	CardHeader,
	CardContent,
	Table,
	TableHead,
	TableBody,
	TableContainer,
	TableRow,
	TableCell,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControl,
	FormControlLabel,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { TopBarMenu } from "../../components/common/TopBarMenu";
import EditorComponent from "../../components/common/Editor";

import { getFieldList, getFormList, getQuestionList, insertForm, insertQuestion, getOnamList, insertOnam, deleteOnam } from "../../utils/api/Question/Question";

import { QuestionPicker } from "./QuestionPicker";

export const TemaPage = inject("store")(
	observer((props) => {
		const defaultQuestion = {
			questionText: "",
			field: undefined,
			options: [],
		};
		const defaultForm = {
			formName: "",
			description: "",
			doctorId: 0,
			questions: [],
		};
		const defaultOnam = {
			onamName: "",
			onamText: '<p align="center" ><b>ONAY FORMU</b></p><p>Bu onay formunu okudum ve onayladım.</p><p align="right"><b>#İsim Soyisim#</b></p>',
		};
		const defaultQuestions = [
			{ field: { fieldName: "text" }, questionText: "İsim Soyisim" },
			{ field: { fieldName: "text" }, questionText: "Telefon Numarası" },
		];

		const reservedWords = ["İsim Soyisim", "Telefon Numarası", "Oturum Tarihi", "Oturum Saati", "Hemşire İsmi"];

		const [fields, setFields] = useState([]);
		const [forms, setForms] = useState([]);
		const [questions, setQuestions] = useState([]);
		const [formEditDialog, setFormEditDialog] = useState(false);
		const [questionDialog, setQuestionDialog] = useState(false);
		const [newQuestion, setNewQuestion] = useState(defaultQuestion);
		const [selectedQuestion, setSelectedQuestion] = useState(null);
		const [newForm, setNewForm] = useState(defaultForm);
		const [selectedQuestions, setSelectedQuestions] = useState([]);
		const [onamDialog, setOnamDialog] = useState(0);
		const [onams, setOnams] = useState([]);
		const [newOnam, setNewOnam] = useState(null);
		const [previewForm, setPreviewForm] = useState(null);

		useEffect(() => {
			if (!props.store.isLogin) {
				props.store.setLoadingBarStatus(true);
				props.history.push("/panel/kullanici-girisi");
			} else {
				setValues();
			}
		}, []);
		const setValues = () => {
			getFieldList(props.store.baseUrl, props.store.token).then((res) => {
				if (res.status === 200) {
					setFields(res.data.data);
				} else if (res.status === 401) {
					props.store.setUserLogout();
					window.location.reload();
				}
			});

			getFormList(props.store.baseUrl, props.store.token).then((res) => {
				if (res.status === 200) {
					setForms(res.data.data);
				}
			});

			getQuestionList(props.store.baseUrl, props.store.token).then((res) => {
				if (res.status === 200) {
					setQuestions(res.data.data);
				}
			});
		};

		const handleFormEdit = async (formId) => {
			if (formEditDialog) {
				setFormEditDialog(false);
				setNewForm(defaultForm);
			} else {
				if (typeof formId === "number") {
					// await getFormQuestions(formId);
				} else {
					// setFormQuestions([]);
				}
				setFormEditDialog(true);
			}
		};

		const clearQuestion = () => {
			setNewQuestion(defaultQuestion);
		};

		const handleQuestionDialog = () => {
			setNewQuestion(defaultQuestion);
			setQuestionDialog(!questionDialog);
		};

		const handleOnamDialog = async (formid = 0) => {
			if (onamDialog > 0) {
				setOnamDialog(0);
			} else {
				await getOnamList(props.store.baseUrl, props.store.token, formid).then((res) => {
					if (res.status === 200) {
						setOnams(res.data.data);
						setOnamDialog(formid);
					} else {
						props.store.setMessage("Bir sorunla karşılaşıldı.", true, "error");
					}
				});
			}
		};

		const submitQuestion = async () => {
			if (reservedWords.includes(newQuestion.questionText)) {
				return props.store.setMessage("Bu isimle bir soru ekleyemezsiniz. (" + reservedWords.join(", ") + ")", true, "error");
			}

			if (newQuestion.field.fieldName !== "select") {
				newQuestion.options = [];
			}

			await insertQuestion(props.store.baseUrl, props.store.token, newQuestion).then((res) => {
				if (res.status === 200) {
					props.store.setMessage("Soru başarıyla eklendi.", true, "success");
					handleQuestionDialog();
					setValues();
				} else {
					props.store.setMessage("Bir sorunla karşılaşıldı.", true, "error");
				}
			});
		};

		const submitForm = async () => {
			newForm.questions = selectedQuestions?.map((question) => {
				return {
					fieldId: question.field.fieldId,
					questionId: question.questionId,
					required: question.required ?? false,
				};
			});

			await insertForm(props.store.baseUrl, props.store.token, newForm).then((res) => {
				if (res.status === 200) {
					props.store.setMessage("Form başarıyla eklendi.", true, "success");
					handleFormEdit();
					setValues();
				} else {
					props.store.setMessage("Bir sorunla karşılaşıldı.", true, "error");
				}
			});
		};

		const submitOnam = async () => {
			if (newOnam) {
				newOnam.onamText = document?.querySelector(".rsw-ce")?.innerHTML;
				newOnam.formId = onamDialog;
				await insertOnam(props.store.baseUrl, props.store.token, newOnam).then((res) => {
					if (res.status === 200) {
						props.store.setMessage("Onam formu başarıyla eklendi.", true, "success");
						setNewOnam(null);
					} else {
						props.store.setMessage("Bir sorunla karşılaşıldı.", true, "error");
					}
				});
				await getOnamList(props.store.baseUrl, props.store.token, onamDialog).then((res) => {
					if (res.status === 200) {
						setOnams(res.data.data);
					}
				});
			}
		};

		const handleDeleteOnam = async (onamId) => {
			await deleteOnam(props.store.baseUrl, props.store.token, onamId).then((res) => {
				if (res.status === 200) {
					props.store.setMessage("Onam formu başarıyla silindi.", true, "success");
					setNewOnam(null);
				} else {
					props.store.setMessage("Bir sorunla karşılaşıldı.", true, "error");
				}
			});
			await getOnamList(props.store.baseUrl, props.store.token, onamDialog).then((res) => {
				if (res.status === 200) {
					setOnams(res.data.data);
				}
			});
		};

		return (
			<div className="container mps-container">
				<TopBarMenu meeting />
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						pt: 9,
					}}
				>
					<Container maxWidth={false}>
						<Grid container spacing={1}>
							<Grid item style={{ height: "100%", width: "100%" }}>
								<Card>
									<CardHeader title="Tema Yönetimi" />
								</Card>
							</Grid>
							<Grid item style={{ height: "100%", width: "100%" }}>
								<Card>
									<CardActions sx={{ flexWrap: "wrap" }}>
										<Button onClick={handleFormEdit} variant={"contained"} style={{ margin: "6px" }}>
											Yeni Tema Ekle
										</Button>
										<Button onClick={handleQuestionDialog} variant={"contained"} style={{ margin: "6px" }}>
											Yeni Soru Ekle
										</Button>
									</CardActions>
								</Card>
							</Grid>
							<Grid item style={{ height: "100%", width: "100%" }}>
								<Card>
									<CardHeader title="Temalarım" style={{ paddingBottom: "10px" }} />
									<CardContent style={{ paddingTop: "0", height: "100%" }}>
										<TableContainer style={{ marginTop: "0px" }}>
											<Table sx={{ minWidth: 650 }} aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell>#</TableCell>
														<TableCell>TEMA ADI</TableCell>
														<TableCell>AÇIKLAMA</TableCell>
														<TableCell style={{ textAlign: "end" }}>İŞLEMLER</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{forms?.map((form, idx) => (
														<TableRow
															key={form.formId}
															sx={{
																"&:last-child td, &:last-child th": { border: 0 },
															}}
														>
															<TableCell component="th" scope="row">
																{idx + 1}
															</TableCell>
															<TableCell component="th" scope="row">
																{form.formName}
															</TableCell>
															<TableCell component="th" scope="row">
																{form.description}
															</TableCell>
															<TableCell component="th" scope="row" style={{ textAlign: "end" }}>
																<Button onClick={() => setPreviewForm(form)} variant={"contained"} style={{ margin: "6px" }}>
																	Temayı İncele
																</Button>
																<Button onClick={() => handleOnamDialog(form.formId)} variant={"contained"} style={{ margin: "6px" }}>
																	Onam Formları
																</Button>
																{/* <Button onClick={() => console.log("form", form)} color="error" variant={"contained"} style={{ margin: "6px" }}>
																	Sil
																</Button> */}
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</CardContent>
								</Card>
							</Grid>
							<Grid item style={{ height: "100%", width: "100%" }}>
								<Card>
									<CardHeader title="Sorularım" style={{ paddingBottom: "10px" }} />
									<CardContent style={{ paddingTop: "0", height: "100%" }}>
										<TableContainer style={{ marginTop: "0px" }}>
											<Table sx={{ minWidth: 650 }} aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell>#</TableCell>
														<TableCell>SORU ADI</TableCell>
														<TableCell>ÖRNEK</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{questions?.map((question, idx) => (
														<TableRow
															key={question.questionId}
															sx={{
																"&:last-child td, &:last-child th": { border: 0 },
															}}
														>
															<TableCell component="th" scope="row">
																{idx + 1}
															</TableCell>
															<TableCell component="th" scope="row">
																{question.questionText}
															</TableCell>
															<TableCell component="th" scope="row">
																<QuestionPicker question={question} />
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</Container>
				</Box>
				<Dialog fullWidth open={formEditDialog} onClose={handleFormEdit}>
					<DialogTitle>Tema Oluştur</DialogTitle>
					<DialogContent key={0}>
						<Grid container>
							<Grid item xs={12}>
								<TextField
									name="name"
									variant="outlined"
									label="Tema Adı*"
									onChange={(e) => setNewForm({ ...newForm, formName: e.target.value })}
									style={{ width: "100%", marginTop: "15px" }}
								></TextField>
							</Grid>
							<Grid item xs={12}>
								<TextField
									name="name"
									variant="outlined"
									label="Açıklama (opsiyonel)"
									onChange={(e) => setNewForm({ ...newForm, description: e.target.value })}
									style={{ width: "100%", marginTop: "15px" }}
								></TextField>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
									<Grid container>
										<Grid item xs={9}>
											<FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
												<InputLabel>Soru Seç</InputLabel>
												<Select value={selectedQuestion} id="questionSelect" label="Soru Seç" onChange={(e) => setSelectedQuestion(e.target.value)}>
													{questions?.map((question) => (
														<MenuItem key={question.questionId} value={question}>
															{question.questionText}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={3} sx={{ marginTop: "15px", paddingInlineStart: "15px" }}>
											<Button
												sx={{ width: "100%", height: "100%" }}
												variant="contained"
												onClick={() => {
													selectedQuestions.push(selectedQuestion);
													setSelectedQuestions([...selectedQuestions]);
													setSelectedQuestion(null);
												}}
											>
												Ekle +
											</Button>
										</Grid>
									</Grid>
									<br />
									<span style={{ fontWeight: "bold" }}>Temanız bu şekilde görünecek:</span>
									{defaultQuestions.map((question, idx) => (
										<QuestionPicker question={question} key={idx} />
									))}
									{selectedQuestions?.map((question, idx) => (
										<Grid container alignItems={"center"} key={idx}>
											<Grid item xs={8}>
												<QuestionPicker question={question} field={fields} />
											</Grid>
											<Grid item xs={3}>
												<FormControlLabel
													control={<Checkbox />}
													label="Zorunlu"
													onChange={(e) => {
														selectedQuestions[idx].required = e.target.checked;
														setSelectedQuestions([...selectedQuestions]);
													}}
													sx={{ marginTop: "15px", marginInlineStart: "10px", marginInlineEnd: "-10px" }}
												/>
											</Grid>
											<Grid item xs={1} sx={{ marginTop: "15px", paddingInlineStart: "15px" }}>
												<Button
													sx={{ width: "100%", height: "100%", padding: "8px", minWidth: "0px" }}
													color="error"
													variant="contained"
													onClick={() => {
														selectedQuestions.splice(idx, 1);
														setSelectedQuestions([...selectedQuestions]);
													}}
												>
													-
												</Button>
											</Grid>
										</Grid>
									))}
								</FormControl>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleFormEdit}>Kapat</Button>
						<Button disabled={!newForm.formName || selectedQuestions.length === 0} onClick={submitForm} variant="contained">
							Oluştur
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog fullWidth open={questionDialog} onClose={handleQuestionDialog}>
					<DialogTitle>
						Soru Ekle
						<div style={{ fontWeight: "normal" }}>"İsim Soyisim" ve "Telefon Numarası" soruları bütün temalarda yer almaktadır. Ayrıca eklemek gerekmemektedir.</div>
					</DialogTitle>
					<DialogContent>
						<Grid container>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									label="Soru*"
									style={{ width: "100%", marginTop: "15px" }}
									value={newQuestion.questionText}
									onChange={(e) => setNewQuestion({ ...newQuestion, questionText: e.target.value })}
								></TextField>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
									<InputLabel>Soru Tipi*</InputLabel>
									<Select label="Soru Tipi*" value={newQuestion.field?.fieldId} onChange={(e) => setNewQuestion({ ...newQuestion, field: e.target.value })}>
										{fields?.map((field) => (
											<MenuItem key={field.fieldId} value={field}>
												{field.description}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							{newQuestion?.field?.fieldName === "select" && (
								<Grid item xs={12}>
									<FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
										<Grid container>
											<Grid item xs={9}>
												<TextField id="optionBox" name="option" variant="outlined" label="Seçenek Ekle" style={{ width: "100%", marginTop: "15px" }}></TextField>
											</Grid>
											<Grid item xs={3} sx={{ marginTop: "15px", paddingInlineStart: "15px" }}>
												<Button
													sx={{ width: "100%", height: "100%" }}
													variant="contained"
													onClick={() => {
														newQuestion.options?.push({ optionText: document.getElementById("optionBox").value });
														setNewQuestion({ ...newQuestion, options: newQuestion.options });
														document.getElementById("optionBox").value = "";
													}}
												>
													Ekle +
												</Button>
											</Grid>
										</Grid>
									</FormControl>
									{newQuestion.options?.length > 0 && (
										<Grid container sx={{ marginTop: "10px" }}>
											{newQuestion?.options?.map((option, idx) => (
												<span style={{ padding: "6px", backgroundColor: "#f3f3f3", marginInlineEnd: "6px", borderRadius: "8px", display: "inline" }} key={idx}>
													{option.optionText}
												</span>
											))}
										</Grid>
									)}
								</Grid>
							)}
							{newQuestion?.field && newQuestion?.questionText && (
								<Grid item xs={12}>
									<Box style={{ width: "100%", marginTop: "15px", backgroundColor: "#f3f3f3", padding: "10px", borderRadius: "8px" }}>
										<span style={{ fontWeight: "bold" }}>Sorunuz bu şekilde görünecek:</span>
										<QuestionPicker question={newQuestion} fields={fields} />
									</Box>
								</Grid>
							)}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button color="error" onClick={clearQuestion}>
							Temizle
						</Button>
						<Button onClick={handleQuestionDialog}>Kapat</Button>
						<Button onClick={submitQuestion} disabled={!newQuestion.field || !newQuestion.questionText} variant="contained">
							Ekle
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog fullWidth open={onamDialog > 0} onClose={() => handleOnamDialog()}>
					<DialogTitle>Onam Formları</DialogTitle>
					<DialogContent>
						<Grid container>
							{onams.length > 0 && (
								<Grid item xs={12}>
									{onams?.map((onam, idx) => (
										<Grid container key={idx} sx={{ marginBottom: "20px" }}>
											<Grid item xs={12}>
												<Accordion key={idx}>
													<AccordionSummary
														sx={{ background: "#F3F3F3" }}
														expandIcon={<ArrowDropDownIcon />}
														aria-controls={"content-" + onam.onamId}
														id={"header-" + onam.onamId}
													>
														<Typography>{onam.onamName}</Typography>
													</AccordionSummary>
													<AccordionDetails>
														<div dangerouslySetInnerHTML={{ __html: onam.onamText }}></div>
													</AccordionDetails>
												</Accordion>
											</Grid>
											<Grid container>
												<Grid item xs={6}>
													<Button onClick={() => setNewOnam(onam)} color="warning" sx={{ width: "100%" }} variant="contained">
														Güncelle
													</Button>
												</Grid>
												<Grid item xs={6}>
													<Button onClick={() => handleDeleteOnam(onam.onamId)} color="error" sx={{ width: "100%" }} variant="contained">
														Sil
													</Button>
												</Grid>
											</Grid>
										</Grid>
									))}
								</Grid>
							)}
							<Grid item xs={12}>
								<Button onClick={() => setNewOnam(defaultOnam)} variant="contained" sx={{ width: "100%" }}>
									Onam Formu Ekle
								</Button>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleOnamDialog}>Kapat</Button>
						<Button onClick={submitQuestion} variant="contained">
							Ekle
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={onamDialog > 0 && !!newOnam} fullScreen onClose={() => setNewOnam(null)}>
					<DialogTitle id="alert-dialog-title">
						<span style={{ fontWeight: "bold" }}>Editör</span>
						<IconButton
							aria-label="close"
							onClick={() => setNewOnam(null)}
							sx={{
								position: "absolute",
								right: 8,
								top: 8,
							}}
						>
							<CloseIcon />
						</IconButton>
						{forms?.find((form) => form.formId === onamDialog)?.questions.length > 0 && (
							<div style={{ fontWeight: "normal" }}>"Yerleştir" menüsünden katılımcı formundaki alanları metin alanına ekleyebilirsiniz.</div>
						)}
					</DialogTitle>
					<DialogContent>
						<TextField
							name="onamName"
							value={newOnam?.onamName}
							onChange={(e) => setNewOnam({ ...newOnam, onamName: e.target.value })}
							variant="outlined"
							label="Onam Form İsmi"
							style={{ width: "100%", marginTop: "15px", marginBottom: "15px" }}
						/>
						{questions && forms && newOnam && (
							<EditorComponent allQuestions={questions} questions={forms?.find((form) => form.formId === onamDialog)?.questions} value={newOnam?.onamText} />
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setNewOnam(null)}>Kapat</Button>
						<Button disabled={!newOnam?.onamName} onClick={submitOnam} variant="contained">
							Kaydet
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={!!previewForm} onClose={() => setPreviewForm(null)}>
					<DialogTitle id="alert-dialog-title">
						<span style={{ fontWeight: "bold" }}>Temayı İncele</span>
						<IconButton
							aria-label="close"
							onClick={() => setPreviewForm(null)}
							sx={{
								position: "absolute",
								right: 8,
								top: 8,
							}}
						>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<Grid container>
							{defaultQuestions.map((question, idx) => (
								<QuestionPicker question={question} key={idx} />
							))}
							{previewForm?.questions?.map((question) => (
								<QuestionPicker key={question.questionId} question={questions} field={fields} onForm={question} />
							))}
						</Grid>
					</DialogContent>
				</Dialog>
			</div>
		);
	})
);
