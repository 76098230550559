import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';

export const ClosePageComponent = () => {

    const [isPrompt, setIsPrompt] = useState(false)

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', handleEndConcert)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', handleEndConcert)
            handleEndConcert()
        }
    }, [])
    const alertUser = e => {
        e.preventDefault()
        e.returnValue = ''
    }
    const handleEndConcert = async () => {
        setIsPrompt(true)
    }
    return (
        <Container>
            <Prompt
                when={isPrompt}
                message={() => 'Are you sure you want to leave this page?'}
            />
        </Container>
    )
}