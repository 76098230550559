import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { PersonelPage } from "./PersonelPage";

class PersonelContainer extends Component {
	constructor(props) {
		super(props);
	}
	componentDidMount = async () => {
		if (!this.props.store.isLogin) {
			this.props.store.setLoadingBarStatus(true);
			this.props.history.push("/panel/kullanici-girisi");
		}
	};
	render() {
		return <PersonelPage />;
	}
}

export default inject("store")(observer(PersonelContainer));
