import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import { containerStyle } from '../../utils/export-style';

import { LeftMenu } from '../../components/common/LeftMenu';

import { Box, Container, Grid } from '@mui/material';

import getDoctorPatientInfo from '../../utils/api/Doctor/GetDoctorPatientInfo'
import { HastaBilgiMenu } from '../../components/doktor/hasta/HastaBilgiMenu';

class HastalarPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            hasta: null,
            patientId: 0
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.store.setLoadingBarStatus(true)
            this.props.history.push("/panel/kullanici-girisi");
        }else{
            this.props.store.setLoadingBarStatus(false)

            this.setState({
                patientId: parseInt(this.props.match.params.id)
            })

            let hastaInfoResponse = await getDoctorPatientInfo(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, parseInt(this.props.match.params.id))
            //console.log("hastaInfoResponse: ", hastaInfoResponse.data)
            if(hastaInfoResponse !== undefined && hastaInfoResponse !== null){
                if(hastaInfoResponse.status === 200){
                    this.setState({
                        hasta: hastaInfoResponse.data
                    })
                }else if(hastaInfoResponse.status === 401){
                    this.props.store.setUserLogout()
                    window.location.reload()
                }
            }else{
                this.props.store.setMessage("Hasta bilgisi getirilirken hata oluştu.", true, "error")
            }
            this.props.store.setLoadingBarStatus(true)
        }
    }
 
    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage={"Hastalar"}/>
                <Box component="main" sx={{
                    ...containerStyle,
                    flexGrow: 1,
                    py: 8
                }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                                <HastaBilgiMenu data={this.state.hasta} patientId={this.state.patientId} history={this.props.history}/>
                            </Grid>
                        </Grid>
                    </Container> 
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(HastalarPage));
