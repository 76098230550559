import axios from "axios";

const userLogin = async (baseUrl, tcKimlikNo, password) => {
    let userResponse = null

    const body = {
        tckno: Number(tcKimlikNo),
        password: password
    }
    //console.log("body: ", body)
    await axios.put(baseUrl + `/doctor/login/code`, body)
        .then((res) => {
            if(res.data.status === 200){
                userResponse = {
                    status: res.status,
                    data: res.data.data
                }
            }else {
                userResponse = {
                    status: res.data.status,
                    data: res.data.message
                }
            }
            
        }).catch((error) => {
            //console.log("error: ", error)
            if (error.response !== undefined) {
                userResponse = {
                    status: error.response.status
                }
            } 
        });
    return userResponse
}

export default userLogin;
