import React from "react";
import { observer, inject } from "mobx-react";
import dayjs from "dayjs";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, Typography } from "@mui/material";

export const RecetePage = inject("store")(
	observer((props) => {
		return (
			<div style={{ padding: "20px" }}>
				<Grid container>
					<Grid item xs={6}>
						<Typography style={{ fontWeight: "bold" }}>REÇETE</Typography>
						<Typography variant="body2">
							WholeMedics AŞ'ye Ait MedScreenWM <br /> Platformunda İmzalanmıştır. SB Tescil No.********
						</Typography>
					</Grid>
					<Grid item xs={6} style={{ textAlign: "right" }}>
						{/* <QRCode value={"/danisan/recete/" + props.receteId} size={100} style={{ marginRight: "10px" }} /> */}
						<Typography variant="body2">Tarih: {dayjs(new Date(props.appointment?.appointmentDate)).format("DD.MM.YYYY")}</Typography>
					</Grid>
				</Grid>
				<Grid container style={{ width: "100%", border: "1px solid gray", padding: "10px" }}>
					<Grid item xs={5}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography variant="body1" style={{ fontWeight: "bold" }}>
									Doktor Bilgileri
								</Typography>
							</Grid>
							<Grid item xs={5}>
								<Typography variant="body2" style={{ fontWeight: "bold" }}>
									Dr. Adı Soyadı:
								</Typography>
							</Grid>
							<Grid item xs={7}>
								<Typography variant="body2">
									{(props.store.doctor[0].title?.titleName ? props.store.doctor[0].title.titleName + " " : "") +
										props.store.doctor[0].professional.professionalName +
										" " +
										props.store.doctor[0].name +
										" " +
										props.store.doctor[0].surname}
								</Typography>
							</Grid>
							<Grid item xs={5}>
								<Typography variant="body2" style={{ fontWeight: "bold" }}>
									İletişim Bilgileri:
								</Typography>
							</Grid>
							<Grid item xs={7}>
								<Typography variant="body2">
									{props.store.doctor[0].address.address +
										" " +
										props.store.doctor[0].address.neighbourhoodName +
										" " +
										props.store.doctor[0].address.districtName +
										" " +
										props.store.doctor[0].address.cityName}{" "}
									<br />
									{props.store.doctor[0].email} <br />
									{props.store.doctor[0].mobilePhone}
								</Typography>
							</Grid>
							<Grid item xs={5}>
								<Typography variant="body2" style={{ fontWeight: "bold" }}>
									Uzmanlık Bilgisi:
								</Typography>
							</Grid>
							<Grid item xs={7}>
								<Typography variant="body2">{props.store.doctor[0].departments?.name}</Typography>
							</Grid>
							<Grid item xs={5}>
								<Typography variant="body2" style={{ fontWeight: "bold" }}>
									Diploma No:
								</Typography>
							</Grid>
							<Grid item xs={7}>
								<Typography variant="body2">{props.store.doctor[0].diploma?.diplomaNo}</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={3}></Grid>
					<Grid item xs={4}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography variant="body1" style={{ fontWeight: "bold" }}>
									Hasta Bilgileri
								</Typography>
							</Grid>
							<Grid item xs={5}>
								<Typography variant="body2" style={{ fontWeight: "bold" }}>
									T.C. No:
								</Typography>
							</Grid>
							<Grid item xs={7}>
								<Typography variant="body2">{props.patient.tckno}</Typography>
							</Grid>
							<Grid item xs={5}>
								<Typography variant="body2" style={{ fontWeight: "bold" }}>
									Hasta Adı Soyadı:
								</Typography>
							</Grid>
							<Grid item xs={7}>
								<Typography variant="body2">{props.patient.name + " " + props.patient.surname}</Typography>
							</Grid>
							<Grid item xs={5}>
								<Typography variant="body2" style={{ fontWeight: "bold" }}>
									Doğum Tarihi:
								</Typography>
							</Grid>
							<Grid item xs={7}>
								<Typography variant="body2">{dayjs(new Date(props.patient.birthDate)).format("DD.MM.YYYY")}</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container spacing={0} style={{ width: "100%", marginTop: "15px", border: "1px solid gray", padding: "10px" }}>
					<Grid item xs={12}>
						<Typography variant="body2">
							<b>Tanı: </b>
							{props.tani?.diagnosisMainCode + " " + props.tani?.diagnosisName}
						</Typography>
					</Grid>
				</Grid>

				<Grid container spacing={0} style={{ width: "100%", marginTop: "15px", border: "1px solid gray", padding: "10px" }}>
					<Grid item xs={12}>
						<Typography variant="body2">
							<b>İlaç Listesi</b>
						</Typography>
					</Grid>
					<Grid item xs={12} style={{ display: props.prescription === null ? "block" : "none" }}></Grid>
					<Grid item xs={12}>
						<TableContainer>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell align="left" style={{ paddingLeft: "50px" }}>
											İLAÇ ADI
										</TableCell>
										<TableCell align="left">DOZ</TableCell>
										<TableCell align="left">PERİYOT</TableCell>
										<TableCell align="left">KUTU</TableCell>
										<TableCell align="left">KULLANIM ŞEKLİ</TableCell>
										<TableCell align="left"></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{props.recete?.map((medicine, idx) => (
										<TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
											<TableCell align="left" style={{ paddingLeft: "50px" }}>
												<Typography>{medicine.medicineName}</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography>{medicine.dose}</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography>{medicine.medicinePeriod} </Typography>
											</TableCell>
											<TableCell align="left">
												<Typography>{medicine.count}</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography>{medicine.usageComment}</Typography>
											</TableCell>
											<TableCell align="left"></TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</div>
		);
	})
);
