import axios from "axios";

const postAppointmentAlcohol = async (baseUrl, token, doctorId, appointmentId, alcohol) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    var bodyFormData = new FormData();
    bodyFormData.append('alcohol', (alcohol === true) ? 1 : 0);

    await axios.post(baseUrl + "/doctor/" + doctorId + "/appointment/" + appointmentId + "/alcohol", bodyFormData, config)
        .then((res) => {
            putResponse = {
                status: res.data.status,
                data: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return putResponse
}

export default postAppointmentAlcohol;