import axios from "axios";
const getAppointmentListByPatientId = async (baseUrl, token, doctorId, patientId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + "/doctor/" + doctorId + "/appointment/patient/" + patientId, config)
        .then((res) => {
            let appointmentList = res.data.data

            let fixedAppointmentList = []
            for (let index = 0; index < appointmentList.length; index++) {
                const tempAppointment = appointmentList[index];
                tempAppointment.id = index
                fixedAppointmentList.push(tempAppointment)
            }
            getResponse = {
                status: res.status,
                data: fixedAppointmentList
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return getResponse
}

export default getAppointmentListByPatientId;