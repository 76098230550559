import axios from "axios";

const putClinicExpenseCategory = async (baseUrl, token, doctorId, clinicId, expenseCategory) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    await axios.put(baseUrl + "/doctor/" + doctorId + "/clinic/" + clinicId + "/expense/category", expenseCategory, config)
        .then((res) => {
            putResponse = {
                status: res.data.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return putResponse
}

export default putClinicExpenseCategory;