import React, { useRef } from "react";
import generatePDF from "react-to-pdf";
import { useReactToPrint } from "react-to-print";

export const PrintButton = (props) => {
	const componentRef = useRef();

	const options = {
		filename: "recete.pdf",
		method: "save",
		resolution: 1,
		page: {
			format: "letter",
			orientation: "portrait",
		},
		canvas: {
			mimeType: "image/jpeg",
		},
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const downloadPdf = () => generatePDF(componentRef, options);

	return (
		<span className="print-document" onClick={props.pdf ? downloadPdf : handlePrint}>
			<div style={{ position: "absolute", left: "-9999px" }}>
				<div ref={componentRef}>{props.content}</div>
			</div>
			{props.children}
		</span>
	);
};
