import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	TextField,
	IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { Button, Card, CardActions, CardContent, CardHeader, Tooltip } from "@mui/material";

import { TopBarMenu } from "../../components/common/TopBarMenu";

import { Box, Container, Grid } from "@mui/material";
import { HiVideoCamera, HiClipboardList, HiOutlinePrinter, HiSelector, HiPlay } from "react-icons/hi";
// import { HiOutlinePencilAlt } from "react-icons/hi";
import { FaPhone, FaRegCopy } from "react-icons/fa";

import { getMeetingList, upsertMeeting, getParticipantList, getAllParticipantList, inviteMeeting, changeMeetingStatus, getRecording } from "../../utils/api/Dashboard/Meeting";
import { getQuestionList, getFormList, getFieldList, getAllResponses } from "../../utils/api/Question/Question";
import { ToplamHastaSayisi } from "../../components/doktor/dashboard/ToplamHastaSayisi";
import { BekleyenRezervasyon } from "../../components/doktor/dashboard/BekleyenRezervasyon";
import { AylikMuayeneCirosu } from "../../components/doktor/dashboard/AylikMuayeneCirosu";

import dayjs from "dayjs";
import { PrintButton } from "../../components/common/PrintButton";
import { TakvimBox } from "../../components/doktor/dashboard/TakvimBox";
import { ReportComponent } from "../../components/common/ReportComponent";

import { CalendarComponent } from "../../components/common/CalendarComponent";
import EditorComponent from "../../components/common/Editor";
import { QuestionPicker } from "./QuestionPicker";

// import isBefore from "date-fns/isBefore";

class MeetingDashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			meetings: undefined,
			meetingMenu: false,
			addParticipantMenu: false,
			participantsMenu: false,
			fullName: "",
			mobilePhone: [],
			duration: 60,
			meetingDate: new Date(),
			participantList: undefined,
			inviteMeeting: undefined,
			isCalendar: false,
			openEditor: false,
			forms: [],
			fields: [],
			questions: [],
			selectedForm: null,
			participantDialog: null,
			reportDialog: null,
			responses: [],
			isReady: false,
			meetingId: null,
			statusDialog: false,
			meetingAudio: "",
		};
		this.tableRef = React.createRef();
	}
	isMeeting = this.props.store.doctorType === 9;
	isSupervisor = this.props.store.doctor[0].userType === 1;

	meetingStatus = ["Bekleniyor", "Tamamlandı", "Tamamlanmadı", "İptal Edildi", "Ertelendi"];

	componentDidMount = async () => {
		if (!this.props.store.isLogin) {
			this.props.store.setLoadingBarStatus(true);
			this.props.history.push("/panel/kullanici-girisi");
		} else {
			this.getMeetingList();
		}
	};

	getMeetingList = async () => {
		this.props.store.setLoadingBarStatus(false);
		let getMeetingListReq = await getMeetingList(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id);
		if (getMeetingListReq !== undefined && getMeetingListReq !== null) {
			if (getMeetingListReq.status === 200) {
				this.setState({
					meetings: getMeetingListReq.data.data,
				});
				const formsReq = await getFormList(this.props.store.baseUrl, this.props.store.token);
				if (formsReq !== undefined && formsReq !== null) {
					if (formsReq.status === 200) {
						this.setState({
							forms: formsReq.data.data,
						});
					}
				}
				const fieldsReq = await getFieldList(this.props.store.baseUrl, this.props.store.token);
				if (fieldsReq !== undefined && fieldsReq !== null) {
					if (fieldsReq.status === 200) {
						this.setState({
							fields: fieldsReq.data.data,
						});
					}
				}
				const questionsReq = await getQuestionList(this.props.store.baseUrl, this.props.store.token);
				if (questionsReq !== undefined && questionsReq !== null) {
					if (questionsReq.status === 200) {
						this.setState({
							questions: questionsReq.data.data,
						});
					}
				}
			} else if (getMeetingListReq.status === 401) {
				this.props.store.setUserLogout();
				window.location.reload();
			}
		}
		this.props.store.setLoadingBarStatus(true);
		this.setState({ isReady: true });
	};

	handleMeetingMenu = () => {
		this.setState({
			meetingMenu: !this.state.meetingMenu,
			mobilePhone: [],
			selectedForm: null,
			fullName: "",
			meetingDate: new Date(),
			duration: 60,
		});
	};

	handleAddParticipantMenu = (meeting) => {
		if (meeting) {
			const selectedForm = this.state.forms.find((f) => f.formId === meeting.formId);
			this.setState({
				addParticipantMenu: true,
				mobilePhone: [],
				selectedForm,
				fullName: "",
				meetingId: meeting.meetingId,
			});
		} else {
			this.setState({
				addParticipantMenu: false,
				mobilePhone: [],
				selectedForm: null,
				fullName: "",
				meetingId: null,
			});
		}
	};

	handleParticipantsMenu = () => {
		this.setState({
			meetingMenu: !this.state.participantsMenu,
		});
	};

	insertMeeting = async () => {
		if (this.state.mobilePhone.length === 0) return this.props.store.setMessage("Telefon numarası ekleyin", true, "error");
		if (this.state.fullName === "") return this.props.store.setMessage("İsim alanı boş olamaz", true, "error");

		const utcDiff = new Date().getTimezoneOffset();
		const meetingDate = new Date(this.state.meetingDate.setMinutes(this.state.meetingDate.getMinutes() - utcDiff));

		const meet = {
			doctorId: this.props.store.doctor[0].id,
			fullName: this.state.fullName,
			responses: this.state.mobilePhone,
			meetingDate,
			duration: this.state.duration,
			formId: this.state.selectedForm?.formId,
		};
		this.props.store.setLoadingBarStatus(false);
		let upsertMeetingReq = await upsertMeeting(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, meet);
		if (upsertMeetingReq !== undefined && upsertMeetingReq !== null) {
			if (upsertMeetingReq.status === 200) {
				this.props.store.setMessage("Görüşme işlemi başarılı", true, "success");
				this.setState({ meetingMenu: false });
				this.getMeetingList();
			} else {
				this.props.store.setMessage("Görüşme işlemi sırasında hata oluştu", true, "error");
			}
		}
		this.props.store.setLoadingBarStatus(true);
	};

	addParticipants = async () => {
		if (this.state.mobilePhone.length === 0) return this.props.store.setMessage("Telefon numarası ekleyin", true, "error");

		const meet = {
			doctorId: this.props.store.doctor[0].id,
			responses: this.state.mobilePhone,
			meetingId: this.state.meetingId,
			formId: this.state.selectedForm?.formId,
		};
		this.props.store.setLoadingBarStatus(false);
		let upsertMeetingReq = await upsertMeeting(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, meet, true);
		if (upsertMeetingReq !== undefined && upsertMeetingReq !== null) {
			if (upsertMeetingReq.status === 200) {
				this.props.store.setMessage("Ekleme işlemi başarılı", true, "success");
				this.handleAddParticipantMenu();
				this.getMeetingList();
			} else {
				this.props.store.setMessage("Ekleme işlemi sırasında hata oluştu", true, "error");
			}
		}
		this.props.store.setLoadingBarStatus(true);
	};

	startVideoCall = async (meeting) => {
		this.props.history.push("/panel/gorusme/" + meeting.meetingId);
	};

	checkPatients = async (meeting) => {
		this.props.store.setLoadingBarStatus(false);
		let participantList = undefined;
		if (meeting) {
			participantList = await getParticipantList(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, meeting.meetingId);
		} else {
			participantList = await getAllParticipantList(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id);
		}
		if (participantList !== null && participantList?.status === 200) {
			if (participantList.data.data.length === 0) {
				this.props.store.setMessage("Kayıtlı katılımcı bulunamadı", true, "info");
			} else {
				this.setState({ participantList: participantList.data.data, participantDialog: true });
			}
		}
		this.props.store.setLoadingBarStatus(true);
	};

	changeMeetingStatus = async () => {
		this.props.store.setLoadingBarStatus(false);
		const changeMeetingStatusRes = await changeMeetingStatus(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, this.state.meetingId, this.state.statusDialog);
		if (changeMeetingStatusRes !== null && changeMeetingStatusRes?.status === 200) {
			this.setState({ statusDialog: false, meetingId: null });
			this.props.store.setMessage("Durum değiştirildi", true, "success");
			this.getMeetingList();
		} else this.props.store.setMessage("Durum değiştirilemedi", true, "error");
		this.props.store.setLoadingBarStatus(true);
	};

	checkAllPatients = async () => {};

	onChangeFullname = (event) => {
		this.setState({
			fullName: event.target.value,
		});
	};

	addPhoneNumber = () => {
		let mobilePhone = document.getElementById("mobilePhone").value;
		let name = document.getElementById("name").value;
		if (name === "") {
			this.props.store.setMessage("İsim alanı boş olamaz", true, "error");
		} else if (this.state.mobilePhone.findIndex((r) => Number(r.mobilePhone) === Number(mobilePhone)) === -1) {
			if (Number(mobilePhone)?.toString().startsWith("5") && Number(mobilePhone).toString().length === 10) {
				const answ = this.getAnswers();
				if (answ?.includes(undefined)) {
					return this.props.store.setMessage("Zorunlu alan boş bırakılamaz", true, "error");
				}
				this.setState((prevState) => ({
					mobilePhone: [...prevState.mobilePhone, { response: answ, mobilePhone: Number(mobilePhone), name }],
				}));
				document.getElementById("mobilePhone").value = "";
				document.getElementById("name").value = "";
			} else {
				this.props.store.setMessage("Geçersiz numara", true, "error");
			}
		} else {
			this.props.store.setMessage("Bu telefon numarası zaten ekli", true, "error");
		}
	};

	formatPhoneNumber = (phoneNumber) => {
		const formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{7})/, "0($1)$2");
		return formattedPhoneNumber;
	};

	onChangeDuration = (event) => {
		if (Number(event.target.value) > 0 && Number(event.target.value) <= 720)
			this.setState({
				duration: Number(event.target.value),
			});
	};
	onChangeMeetingDate = (event) => {
		this.setState({
			meetingDate: new Date(event),
		});
	};

	handleInviteDialog = (meeting) => {
		if (meeting) {
			meeting.inviteLink = window.location.origin + "/meet/" + meeting.videoUrl.split("/")[3].split("?")[0];
			this.setState({
				inviteMeeting: meeting,
			});
		} else {
			this.setState({
				inviteMeeting: undefined,
			});
		}
	};

	copyInviteLink = (link) => {
		navigator.clipboard.writeText(link);
		this.props.store.setMessage("Davet adresi kopyalandı", true, "success");
		this.handleInviteDialog();
	};

	inviteNumber = async () => {
		const phone = document.getElementById("invitePhone").value;
		if (Number(phone)?.toString().startsWith("5") && Number(phone).toString().length === 10) {
			this.props.store.setLoadingBarStatus(false);
			const sendInviteReq = await inviteMeeting(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id, {
				mobilePhone: phone,
				meetingId: this.state.inviteMeeting.meetingId,
			});

			if (sendInviteReq !== undefined && sendInviteReq !== null) {
				if (sendInviteReq.status === 200) {
					this.props.store.setMessage("Davet gönderildi", true, "success");
					document.getElementById("invitePhone").value = "";
				} else {
					this.props.store.setMessage("Davet sırasında sorun oluştu", true, "error");
				}
			} else this.props.store.setMessage("Davet sırasında sorun oluştu", true, "error");
			this.props.store.setLoadingBarStatus(true);
		} else {
			this.props.store.setMessage("Geçersiz numara", true, "error");
		}
	};

	tableRow = (meeting, idx, active = false) => (
		<TableRow
			key={idx + (active ? "-active" : "-passive")}
			sx={{
				"&:last-child td, &:last-child th": { border: 0 },
			}}
			className={meeting.status > 1 ? "meeting-denied" : ""}
		>
			<TableCell component="th" scope="row">
				{meeting.meetingId}
			</TableCell>
			<TableCell component="th" scope="row">
				{meeting.fullName}
			</TableCell>
			<TableCell component="th" scope="row">
				{meeting.participantCount}
			</TableCell>
			<TableCell component="th" scope="row">
				{dayjs(new Date(meeting.startDate ?? meeting.meetingDate)).format("DD.MM.YYYY HH:mm")}
			</TableCell>
			<TableCell component="th" scope="row">
				{meeting.duration} dk
			</TableCell>
			{this.props.store.userType === 1 && (
				<TableCell component="th" scope="row">
					{meeting.doctorName}
				</TableCell>
			)}
			<TableCell component="th" scope="row">
				{this.state.forms?.find((form) => form.formId === meeting.formId)?.formName}
			</TableCell>
			<TableCell component="th" scope="row">
				{this.meetingStatus[meeting.status]}
			</TableCell>
			<TableCell component="th" scope="row" style={{ textAlign: "end" }}>
				{active && (
					<>
						<Tooltip title="Görüşme Odasına Gir">
							<Button variant={"contained"} onClick={() => this.startVideoCall(meeting)} style={{ marginInlineEnd: "8px" }}>
								<HiVideoCamera
									style={{
										fontSize: "20px",
									}}
								/>
							</Button>
						</Tooltip>
						{/* <Tooltip title="Davet Et">
							<Button variant={"contained"} onClick={() => this.handleInviteDialog(meeting)} style={{ marginInlineEnd: "8px" }}>
								<FaPhone style={{ fontSize: "20px" }} />
							</Button>
						</Tooltip> */}
					</>
				)}
				<Tooltip title="Katılımcı Ekle">
					<Button variant={"contained"} onClick={() => this.handleAddParticipantMenu(meeting)}>
						<PersonOutlineIcon
							style={{
								fontSize: "20px",
							}}
						/>
					</Button>
				</Tooltip>
				<Tooltip title="Katılımcı Listesine Bak">
					<Button
						variant={"contained"}
						onClick={() => this.checkPatients(meeting)}
						style={{
							marginInlineStart: "8px",
						}}
					>
						<HiClipboardList
							style={{
								fontSize: "20px",
							}}
						/>
					</Button>
				</Tooltip>
				<Tooltip title="Durumu Değiştir">
					<Button
						variant={"contained"}
						onClick={() => this.setState({ statusDialog: meeting.status.toString(), meetingId: meeting.meetingId })}
						style={{
							marginInlineStart: "8px",
						}}
					>
						<HiSelector
							style={{
								fontSize: "20px",
							}}
						/>
					</Button>
				</Tooltip>
				{!active && this.isSupervisor && (
					<Tooltip title="Kaydı Dinle">
						<Button
							variant={"contained"}
							onClick={() => this.getRecordingAsync(meeting.videoUrl)}
							style={{
								marginInlineStart: "8px",
							}}
						>
							<HiPlay
								style={{
									fontSize: "20px",
								}}
							/>
						</Button>
					</Tooltip>
				)}
			</TableCell>
		</TableRow>
	);

	handleCalendar = (isCalendar) => {
		this.setState({
			isCalendar: isCalendar,
		});
	};

	handleEditor = () => {
		this.setState({
			openEditor: !this.state.openEditor,
		});
	};

	getAnswers = () => {
		if (this.state.selectedForm?.questions)
			return this.state.selectedForm?.questions.map((q) => {
				const el = document.getElementById("qpicker-" + q.questionId);
				const field = this.state.fields.find((f) => f.fieldId === q.fieldId);
				switch (field.fieldName) {
					case "text":
					case "number":
					case "date":
						if (q.required && el.value === "") {
							return undefined;
						}
						return { responseValue: el.value, formQuestionId: q.formQuestionId, formId: this.state.selectedForm.formId, questionId: q.questionId };

					case "checkbox":
						const checkboxValue = el.checked.toString();
						if (q.required && checkboxValue === "false") {
							return undefined;
						}
						return { responseValue: checkboxValue, formQuestionId: q.formQuestionId, formId: this.state.selectedForm.formId, questionId: q.questionId };

					case "select":
						const selectValue = el.innerHTML.includes("span") ? "" : el.innerHTML;
						if (q.required && selectValue === "") {
							return undefined;
						}
						return { responseValue: selectValue, formQuestionId: q.formQuestionId, formId: this.state.selectedForm.formId, questionId: q.questionId };

					default:
						if (q.required && el.value === "") {
							return undefined;
						}
						return { responseValue: el.value, formQuestionId: q.formQuestionId, formId: this.state.selectedForm.formId, questionId: q.questionId };
				}
			});
	};

	handleReport = async () => {
		this.props.store.setLoadingBarStatus(false);
		const allParticipantList = await getAllParticipantList(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id);
		const getAllResponsesReq = await getAllResponses(this.props.store.baseUrl, this.props.store.token);
		if (getAllResponsesReq !== null && getAllResponsesReq?.status === 200) {
			this.setState({ responses: getAllResponsesReq.data.data, participantList: allParticipantList?.data?.data, reportDialog: true });
		}
		this.props.store.setLoadingBarStatus(true);
	};

	getRecordingAsync = async (meetingid) => {
		this.props.store.setLoadingBarStatus(false);
		const getRecordingRes = await getRecording(this.props.store.baseUrl, this.props.store.token, meetingid);
		if (getRecordingRes !== null && getRecordingRes?.status === 200) {
			this.setState({ meetingAudio: getRecordingRes.data.data });
		} else {
			this.props.store.setMessage("Kayıt bulunamadı", true, "error");
		}
		this.props.store.setLoadingBarStatus(true);
	};

	render() {
		const totalParticipants = this.state.meetings?.reduce((sum, meeting) => sum + meeting.participantCount, 0) ?? 0;
		const totalDuration = this.state.meetings?.reduce((sum, meeting) => sum + meeting.duration, 0) ?? 0;

		let activeMeetings = [];
		let pastMeetings = [];
		this.state.meetings?.forEach((meeting, idx) => {
			if (!meeting.endDate && new Date(meeting.meetingDate).setMinutes(new Date(meeting.meetingDate).getMinutes() + meeting.duration) > Date.now() && meeting.status === 0) {
				activeMeetings.push(this.tableRow(meeting, idx, true));
			} else {
				pastMeetings.push(this.tableRow(meeting, idx));
			}
		});

		return (
			<div className="container mps-container">
				<TopBarMenu meeting />
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						pt: 9,
					}}
				>
					<Container maxWidth={false}>
						<Grid container spacing={3} sx={{ marginBottom: "12px" }}>
							<Grid item xl={3} lg={3} sm={6} xs={12}>
								<ToplamHastaSayisi sx={{ height: "100%" }} data={this.state.meetings?.length ?? 0} text="TOPLAM GÖRÜŞME SAYISI" />
							</Grid>
							<Grid item xl={3} lg={3} sm={6} xs={12}>
								<BekleyenRezervasyon sx={{ height: "100%" }} data={totalParticipants} text="TOPLAM KATILIMCI SAYISI" />
							</Grid>
							<Grid item xl={3} lg={3} sm={6} xs={12}>
								<AylikMuayeneCirosu data={totalDuration} usertype={1} text="TOPLAM GÖRÜŞME SÜRESİ" unit={"dakika"} />
							</Grid>
							<Grid item xl={3} lg={3} sm={6} xs={12}>
								<TakvimBox handleiscalendar={this.handleCalendar} />
							</Grid>
						</Grid>
						<Grid container spacing={3} sx={{ marginBottom: "12px" }}>
							<Grid item xs={12}>
								<Card>
									<CardActions sx={{ flexWrap: "wrap" }}>
										<Button onClick={() => this.checkPatients()} variant={"contained"} style={{ margin: "6px" }}>
											Katılımcı Listesi
										</Button>
										<Button onClick={this.handleMeetingMenu} variant={"contained"} style={{ margin: "6px" }}>
											Yeni Görüşme Ekle
										</Button>
										{this.state.isReady && this.isSupervisor && (
											<>
												<Button onClick={() => this.props.history.push("/panel/personel")} variant={"contained"} style={{ margin: "6px" }}>
													Personeller
												</Button>
												<Button onClick={() => this.props.history.push("/panel/tema")} variant={"contained"} style={{ margin: "6px" }}>
													Temalar
												</Button>
												<Button onClick={this.handleReport} variant={"contained"} style={{ margin: "6px" }}>
													Raporlar
												</Button>
											</>
										)}
									</CardActions>
								</Card>
							</Grid>
						</Grid>
						{!this.state.isCalendar ? (
							<Grid container spacing={3}>
								<Grid item style={{ height: "100%", width: "100%" }}>
									<Card>
										<CardHeader title="Aktif Görüşmeleriniz" style={{ paddingBottom: "10px" }} />
										<CardContent style={{ paddingTop: "0", height: "100%" }}>
											<TableContainer style={{ marginTop: "0px" }}>
												<Table sx={{ minWidth: 650 }} aria-label="simple table">
													<TableHead>
														<TableRow>
															<TableCell>ID</TableCell>
															<TableCell>OTURUM ADI</TableCell>
															<TableCell>KATILIMCI SAYISI</TableCell>
															<TableCell>TARİH/SAAT</TableCell>
															<TableCell>SÜRESİ</TableCell>
															{this.props.store.userType === 1 && <TableCell>GÖRÜŞME SAHİBİ</TableCell>}
															<TableCell>TEMA</TableCell>
															<TableCell>DURUMU</TableCell>
															<TableCell style={{ textAlign: "end" }}>İŞLEMLER</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>{activeMeetings}</TableBody>
												</Table>
											</TableContainer>
										</CardContent>
									</Card>
								</Grid>

								{pastMeetings.length > 0 && (
									<Grid item style={{ height: "100%", width: "100%" }}>
										<Card>
											<CardHeader title="Geçmiş Görüşmeleriniz" style={{ paddingBottom: "10px" }} />
											<CardContent style={{ paddingTop: "0", height: "100%" }}>
												<TableContainer style={{ marginTop: "0px" }}>
													<Table sx={{ minWidth: 650 }} aria-label="simple table">
														<TableHead>
															<TableRow>
																<TableCell>ID</TableCell>
																<TableCell>OTURUM ADI</TableCell>
																<TableCell>KATILIMCI SAYISI</TableCell>
																<TableCell>TARİH/SAAT</TableCell>
																<TableCell>SÜRESİ</TableCell>
																{this.props.store.userType === 1 && <TableCell>GÖRÜŞME SAHİBİ</TableCell>}
																<TableCell>TEMA</TableCell>
																<TableCell>DURUMU</TableCell>
																<TableCell style={{ textAlign: "end" }}>İŞLEMLER</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>{pastMeetings}</TableBody>
													</Table>
												</TableContainer>
											</CardContent>
										</Card>
									</Grid>
								)}
							</Grid>
						) : (
							<Grid container>
								<Card style={{ width: "100%", padding: "18px" }}>
									<CalendarComponent list={this.state.meetings} />
								</Card>
							</Grid>
						)}
					</Container>
					<Dialog open={!!this.state.meetingMenu} onClose={this.handleMeetingMenu}>
						<DialogTitle>Yeni Görüşme Oluştur</DialogTitle>
						<DialogContent key={0}>
							<Grid container>
								<Grid item xs={12}>
									<TextField
										name="name"
										error={this.state.fullName === ""}
										value={this.state.fullName}
										variant="outlined"
										label="Görüşme Adı"
										onChange={this.onChangeFullname}
										style={{ width: "100%", marginTop: "15px" }}
									></TextField>
								</Grid>
								<Grid item xs={6} sx={{ marginTop: "15px" }}>
									<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"tr"}>
										<DateTimePicker
											label="Görüşme Tarihi"
											disablePast
											value={this.state.meetingDate}
											onChange={this.onChangeMeetingDate}
											renderInput={(params) => <TextField {...params} />}
											resizable
										/>
									</LocalizationProvider>
								</Grid>
								<Grid item xs={6}>
									<TextField
										name="duration"
										type="number"
										value={this.state.duration}
										variant="outlined"
										label="Süre (Dakika)"
										onChange={this.onChangeDuration}
										style={{ width: "100%", marginTop: "15px" }}
									></TextField>
								</Grid>
								<Grid item xs={12}>
									<FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
										<InputLabel>Tema Seçiniz</InputLabel>
										<Select
											label="Tema Seçiniz"
											value={this.state.selectedForm}
											onChange={(e) => {
												this.setState({ selectedForm: e.target.value });
											}}
										>
											{this.state.forms?.map((form) => (
												<MenuItem key={form.formId + "-form"} value={form}>
													{form.formName}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								{this.state.selectedForm && (
									<Grid item xs={12}>
										<Grid item xs={12}>
											<TextField id="name" name="name" variant="outlined" label="İsim Soyisim*" autoComplete="off" style={{ width: "100%", marginTop: "15px" }} />
										</Grid>
										<Grid item xs={12}>
											<TextField
												id="mobilePhone"
												name="mobilePhone"
												variant="outlined"
												label="Telefon Numarası*"
												autoComplete="off"
												style={{ width: "100%", marginTop: "15px" }}
											/>
										</Grid>
										{this.state.selectedForm?.questions.map((fq) => (
											<Grid item xs={12} key={fq.formQuestionId + "-selected"}>
												<QuestionPicker question={this.state.questions} field={this.state.fields} onForm={fq} />
											</Grid>
										))}
										<Grid item xs={12} sx={{ marginTop: "15px" }}>
											<Button sx={{ width: "100%", height: "100%" }} onClick={this.addPhoneNumber} variant="contained">
												Kişi Ekle +
											</Button>
										</Grid>
									</Grid>
								)}
								{this.state.mobilePhone.map((r, idx) => (
									<React.Fragment key={idx + r.phoneNumber}>
										<Grid item xs={9}>
											<div style={{ width: "100%", padding: "15px" }} variant="outlined" label="Telefon Numarası">
												{r.name + " - " + this.formatPhoneNumber(r.mobilePhone.toString())}
											</div>
										</Grid>
										<Grid item xs={3} sx={{ marginTop: "15px", paddingInlineStart: "15px" }}>
											<Button
												sx={{ width: "100%", height: "100%" }}
												onClick={() => {
													const newState = this.state.mobilePhone.filter((ra) => ra.mobilePhone !== r.mobilePhone);
													this.setState({ mobilePhone: newState });
												}}
												variant="contained"
												color="error"
											>
												Sil -
											</Button>
										</Grid>
									</React.Fragment>
								))}
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleMeetingMenu}>Kapat</Button>
							<Button onClick={this.insertMeeting} disabled={!this.state.selectedForm} variant="contained">
								Oluştur
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog open={!!this.state.addParticipantMenu} onClose={() => this.handleAddParticipantMenu()}>
						<DialogTitle>Katılımclı Ekle</DialogTitle>
						<DialogContent key={0}>
							<Grid container minWidth={320}>
								<Grid item xs={12}>
									<Grid item xs={12}>
										<TextField id="name" name="name" variant="outlined" label="İsim Soyisim*" style={{ width: "100%", marginTop: "15px" }} />
									</Grid>
									<Grid item xs={12}>
										<TextField id="mobilePhone" name="mobilePhone" variant="outlined" label="Telefon Numarası*" style={{ width: "100%", marginTop: "15px" }} />
									</Grid>
									{this.state.selectedForm?.questions.map((fq) => (
										<Grid item xs={12} key={fq.formQuestionId + "-selected"}>
											<QuestionPicker question={this.state.questions} field={this.state.fields} onForm={fq} />
										</Grid>
									))}
									<Grid item xs={12} sx={{ marginTop: "15px" }}>
										<Button sx={{ width: "100%", height: "100%" }} onClick={this.addPhoneNumber} variant="contained">
											Kişi Ekle +
										</Button>
									</Grid>
								</Grid>
								{this.state.mobilePhone.map((r, idx) => (
									<React.Fragment key={idx + r.phoneNumber}>
										<Grid item xs={9}>
											<div style={{ width: "100%", padding: "15px" }} variant="outlined" label="Telefon Numarası">
												{r.name + " - " + this.formatPhoneNumber(r.mobilePhone.toString())}
											</div>
										</Grid>
										<Grid item xs={3} sx={{ marginTop: "15px", paddingInlineStart: "15px" }}>
											<Button
												sx={{ width: "100%", height: "100%" }}
												onClick={() => {
													const newState = this.state.mobilePhone.filter((ra) => ra.mobilePhone !== r.mobilePhone);
													this.setState({ mobilePhone: newState });
												}}
												variant="contained"
												color="error"
											>
												Sil -
											</Button>
										</Grid>
									</React.Fragment>
								))}
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => this.handleAddParticipantMenu()}>Kapat</Button>
							<Button onClick={this.addParticipants} disabled={this.state.mobilePhone.length === 0} variant="contained">
								Ekle
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog open={!!this.state.participantsMenu} onClose={this.handleParticipantsMenu}>
						<DialogTitle>Görüşme Katılımcıları</DialogTitle>
						<DialogContent key={1}>
							<Grid container>
								<Grid item xs={12}>
									<TextField
										name="name"
										error={this.state.fullName === ""}
										value={this.state.fullName}
										variant="outlined"
										label="Görüşme Adı"
										onChange={this.onChangeFullname}
										style={{ width: "100%", marginTop: "15px" }}
									></TextField>
								</Grid>
								<Grid item xs={6} sx={{ marginTop: "15px" }}>
									<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"tr"}>
										<DateTimePicker
											label="Görüşme Tarihi"
											disablePast
											value={this.state.meetingDate}
											onChange={this.onChangeMeetingDate}
											renderInput={(params) => <TextField {...params} />}
											resizable
										/>
									</LocalizationProvider>
								</Grid>
								<Grid item xs={6}>
									<Table sx={{ minWidth: 650 }} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>ID</TableCell>
												<TableCell>OTURUM ADI</TableCell>
												<TableCell>KATILIMCI SAYISI</TableCell>
												<TableCell>TARİH/SAAT</TableCell>
												<TableCell>SÜRESİ</TableCell>
												<TableCell>İŞLEMLER</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>{activeMeetings}</TableBody>
									</Table>
								</Grid>
								<Grid item xs={12}>
									<DialogContentText style={{ padding: "15px" }}>Telefon Numaraları</DialogContentText>
								</Grid>
								{this.state.mobilePhone.map((resp, idx) => (
									<React.Fragment key={idx + resp.mobilePhone}>
										<Grid item xs={9}>
											<div style={{ width: "100%", padding: "15px" }} variant="outlined" label="Telefon Numarası">
												{resp.name + "- " + this.formatPhoneNumber(resp.mobilePhone.toString())}
											</div>
										</Grid>
										<Grid item xs={3} sx={{ marginTop: "15px", paddingInlineStart: "15px" }}>
											<Button
												sx={{ width: "100%", height: "100%" }}
												onClick={() => {
													this.setState((prevState) => ({
														mobilePhone: prevState.mobilePhone.filter((r) => r.mobilePhone !== resp.mobilePhone),
													}));
												}}
												variant="contained"
												color="error"
											>
												Sil -
											</Button>
										</Grid>
									</React.Fragment>
								))}
								<Grid item xs={9}>
									<TextField id="mobilePhone" name="mobilePhone" variant="outlined" label="Telefon Numarası" style={{ width: "100%", marginTop: "15px" }}></TextField>
								</Grid>
								<Grid item xs={3} sx={{ marginTop: "15px", paddingInlineStart: "15px" }}>
									<Button sx={{ width: "100%", height: "100%" }} onClick={this.addPhoneNumber} variant="contained">
										Ekle +
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleMeetingMenu}>Kapat</Button>
							<Button onClick={this.insertMeeting} variant="contained">
								Oluştur
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog open={!!this.state.inviteMeeting} onClose={() => this.handleInviteDialog()}>
						<DialogTitle id="alert-dialog-title">
							<span style={{ fontWeight: "bold" }}>Davet</span>
							<IconButton
								aria-label="close"
								onClick={() => this.handleInviteDialog()}
								sx={{
									position: "absolute",
									right: 8,
									top: 8,
								}}
							>
								<CloseIcon />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<Grid container>
								<Grid item xs={12}>
									<span style={{ textAlign: "center" }}>
										<b>Davet Adresi: </b>
										{this.state.inviteMeeting?.inviteLink}
									</span>
								</Grid>
								<Grid item xs={12}>
									<Button variant="contained" style={{ width: "100%" }} onClick={() => this.copyInviteLink(this.state.inviteMeeting?.inviteLink)}>
										Davet Adresini Kopyala
										<FaRegCopy style={{ marginInlineStart: "5px" }} />
									</Button>
								</Grid>
								<Grid item xs={12}>
									<TextField id="invitePhone" name="invitePhone" variant="outlined" label="Telefon Numarası" style={{ width: "100%", marginTop: "15px" }}></TextField>
								</Grid>
								<Grid item xs={12}>
									<Button variant="contained" style={{ width: "100%", marginTop: "15px" }} onClick={this.inviteNumber}>
										Davet Gönder
										<FaPhone style={{ marginInlineStart: "5px" }} />
									</Button>
								</Grid>
							</Grid>
						</DialogContent>
					</Dialog>
					<Dialog fullScreen open={!!this.state.participantDialog} onClose={() => this.setState({ participantDialog: false })}>
						<DialogTitle id="alert-dialog-title">
							<span style={{ fontWeight: "bold" }}>Kayıtlı Listesi</span>
							<IconButton
								aria-label="close"
								onClick={() => this.setState({ participantDialog: false })}
								sx={{
									position: "absolute",
									right: 8,
									top: 8,
								}}
							>
								<CloseIcon />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>ID</TableCell>
										<TableCell>KATILIMCI ADI</TableCell>
										<TableCell>KAYIT TARİHİ</TableCell>
										<TableCell>SÜRESİ</TableCell>
										{/* <TableCell>İŞLEMLER</TableCell> */}
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.participantList?.map((participant, idx) => (
										<TableRow
											key={participant.participantId + "-part"}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}
										>
											<TableCell component="th" scope="row">
												{participant.participantId}
											</TableCell>
											<TableCell component="th" scope="row">
												{participant.name}
											</TableCell>
											<TableCell component="th" scope="row">
												{dayjs(new Date(participant.createdDate)).format("DD.MM.YYYY HH:mm")}
											</TableCell>
											<TableCell component="th" scope="row">
												{participant.duration} dk
											</TableCell>
											{/* <TableCell component="th" scope="row">
												<PrintButton
													content={
														<MeetingAgreement
															var={{
																patientName: participant.name,
																doctorName: this.props.store.doctor[0].name + " " + this.props.store.doctor[0].surname,
																date: new Date(participant.createdDate),
															}}
														/>
													}
												>
													<Tooltip title="Onam Formunu Yazdır">
														<Button variant={"contained"}>
															<HiOutlinePencilAlt
																style={{
																	fontSize: "20px",
																}}
															/>
														</Button>
													</Tooltip>
												</PrintButton>
											</TableCell> */}
										</TableRow>
									))}
								</TableBody>
							</Table>
							<PrintButton
								content={
									<Table aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>ID</TableCell>
												<TableCell>KATILIMCI ADI</TableCell>
												<TableCell>KAYIT TARİHİ</TableCell>
												<TableCell>SÜRESİ</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.participantList?.map((participant, idx) => (
												<TableRow
													key={participant.participantId + "-participant"}
													sx={{
														"&:last-child td, &:last-child th": {
															border: 0,
														},
													}}
												>
													<TableCell component="th" scope="row">
														{participant.participantId}
													</TableCell>
													<TableCell component="th" scope="row">
														{participant.name}
													</TableCell>
													<TableCell component="th" scope="row">
														{dayjs(new Date(participant.createdDate)).format("DD.MM.YYYY HH:mm")}
													</TableCell>
													<TableCell component="th" scope="row">
														{participant.duration} dk
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								}
							>
								<Button variant="contained">
									Yazdır
									<HiOutlinePrinter
										style={{
											marginInlineStart: "5px",
											fontSize: "20px",
										}}
									/>
								</Button>
							</PrintButton>
						</DialogContent>
					</Dialog>
					<Dialog open={!!this.state.openEditor} fullScreen onClose={() => this.setState({ openEditor: false })}>
						<DialogTitle id="alert-dialog-title">
							<span style={{ fontWeight: "bold" }}>Editör</span>
							<IconButton
								aria-label="close"
								onClick={() => this.setState({ openEditor: false })}
								sx={{
									position: "absolute",
									right: 8,
									top: 8,
								}}
							>
								<CloseIcon />
							</IconButton>
							<div style={{ fontWeight: "normal" }}>"Yerleştir" menüsünden katılımcı formundaki alanları metin alanına ekleyebilirsiniz.</div>
						</DialogTitle>
						<DialogContent>
							<EditorComponent />
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleEditor}>Kapat</Button>
							<Button onClick={this.handleEditor} variant="contained">
								Oluştur
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog open={!!this.state.reportDialog} fullScreen onClose={() => this.setState({ reportDialog: false })}>
						<DialogTitle id="alert-dialog-title">
							<span style={{ fontWeight: "bold" }}>Raporlar</span>
							<IconButton
								aria-label="close"
								onClick={() => this.setState({ reportDialog: false })}
								sx={{
									position: "absolute",
									right: 8,
									top: 8,
								}}
							>
								<CloseIcon />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<ReportComponent
								refs={this.tableRef}
								forms={this.state.forms}
								questions={this.state.questions}
								responses={this.state.responses}
								participants={this.state.participantList}
								meetings={this.state.meetings}
								fileName={this.props.store.doctor[0].name[0] + this.props.store.doctor[0].surname[0] + " Oturum Raporları"}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => this.setState({ reportDialog: false })} variant="contained">
								Kapat
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog open={!!this.state.statusDialog} onClose={() => this.setState({ statusDialog: false })}>
						<DialogTitle id="alert-dialog-title" sx={{ minWidth: "300px" }}>
							<span style={{ fontWeight: "bold" }}>Durumu Değiştir</span>
							<IconButton
								aria-label="close"
								onClick={() => this.setState({ statusDialog: false })}
								sx={{
									position: "absolute",
									right: 8,
									top: 8,
								}}
							>
								<CloseIcon />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
								<InputLabel>Durum Seçiniz</InputLabel>
								<Select label="Durum Seçiniz" value={this.state.statusDialog} onChange={(value) => this.setState({ statusDialog: value.target.value })}>
									{this.meetingStatus.map((status, idx) => (
										<MenuItem key={"status-" + idx} value={idx.toString()}>
											{status}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => this.setState({ statusDialog: false, meetingId: 0 })}>Kapat</Button>
							<Button onClick={this.changeMeetingStatus} variant="contained">
								Değiştir
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog open={!!this.state.meetingAudio} onClose={() => this.setState({ meetingAudio: "" })}>
						<DialogTitle id="alert-dialog-title" sx={{ minWidth: "300px" }}>
							<span style={{ fontWeight: "bold" }}>Görüşme Kaydı</span>
							<IconButton
								aria-label="close"
								onClick={() => this.setState({ meetingAudio: "" })}
								sx={{
									position: "absolute",
									right: 8,
									top: 8,
								}}
							>
								<CloseIcon />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<audio controls>
								<source src={this.state.meetingAudio} type="video/mp4" />
							</audio>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => this.setState({ meetingAudio: "" })}>Kapat</Button>
						</DialogActions>
					</Dialog>
				</Box>
			</div>
		);
	}
}

export default inject("store")(observer(MeetingDashboard));
