import axios from "axios";
import parseDashboardStatisticsData from '../../parsers/DashboardStatisticsDataParser'

const getDashboardStatistics = async (baseUrl, token, doctorId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + `/Dashboard/` + doctorId, config)
        .then((res) => {
            //console.log("res.data: ", res.data)
            getResponse = {
                status: res.data.status,
                data: parseDashboardStatisticsData(res.data)
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return getResponse
}

export default getDashboardStatistics;