import axios from "axios";

export const getFieldList = async (baseUrl, token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let getResponse = null;

	await axios
		.get(baseUrl + "/question/fields", config)
		.then((res) => {
			getResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};

export const getQuestionList = async (baseUrl, token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let getResponse = null;

	await axios
		.get(baseUrl + "/question", config)
		.then((res) => {
			getResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};

export const insertQuestion = async (baseUrl, token, question) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	let postResponse = null;

	await axios
		.post(baseUrl + "/question", question, config)
		.then((res) => {
			postResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				postResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return postResponse;
};

export const getFormList = async (baseUrl, token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let getResponse = null;

	await axios
		.get(baseUrl + "/question/forms", config)
		.then((res) => {
			getResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};

export const insertForm = async (baseUrl, token, form) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	let postResponse = null;
	await axios
		.post(baseUrl + "/question/form", form, config)
		.then((res) => {
			postResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				postResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return postResponse;
};

export const getOnamList = async (baseUrl, token, formid) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	let getResponse = null;

	await axios
		.get(baseUrl + "/question/onam/" + formid, config)
		.then((res) => {
			getResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};

export const insertOnam = async (baseUrl, token, onam) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	let putResponse = null;
	await axios
		.put(baseUrl + "/question/onam", onam, config)
		.then((res) => {
			putResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				putResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return putResponse;
};

export const deleteOnam = async (baseUrl, token, onamid) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	let deleteResponse = null;
	await axios
		.delete(baseUrl + "/question/onam/" + onamid, config)
		.then((res) => {
			deleteResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				deleteResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return deleteResponse;
};

export const getAllResponses = async (baseUrl, token) => {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	let getResponse = null;
	await axios
		.get(baseUrl + "/question/responses", config)
		.then((res) => {
			getResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};

export const getResponses = async (baseUrl, doctorid, meetingid, participantid) => {
	let getResponse = null;
	await axios
		.get(baseUrl + "/question/" + doctorid + "/participant/" + meetingid + "/" + participantid)
		.then((res) => {
			getResponse = {
				status: res.data.status,
				data: res.data,
			};
		})
		.catch((error) => {
			if (error.response !== undefined) {
				getResponse = {
					status: error.response.status,
					data: error.response.status,
				};
			}
		});
	return getResponse;
};
