import axios from "axios";

const putAppointmentCancel = async (baseUrl, token, doctorId, appointmentId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    await axios.put(baseUrl + "/doctor/" + doctorId + "/appointment/" + appointmentId + "/cancel",  {}, config)
        .then((res) => {
            if(res.data.status === 200){
                putResponse = {
                    status: res.data.status,
                    data: res.data
                }
            }else{
                // console.log("res.data.message: ", res.data.message)
                putResponse = {
                    status: res.data.status,
                    data: res.data.message
                }
            }
            
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return putResponse
}

export default putAppointmentCancel;