import axios from "axios";

export const getConsumables = async (baseUrl, token, doctorId, clinicId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + "/doctor/" + doctorId + "/clinic/" + clinicId +  `/consumable/`, config)
        .then((res) => {
            getResponse = {
                status: res.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return getResponse
}

export const putConsumable = async (baseUrl, token, doctorId, clinicId, consumable) => {

    const config = { 
        headers: { Authorization: `Bearer ${token}` }
    };

    let postResponse = null

    await axios.put(baseUrl + "/doctor/" + doctorId + "/clinic/" + clinicId +  "/consumable", consumable, config)
        .then((res) => {
            postResponse = {
                status: res.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                postResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return postResponse
}

export const deleteConsumable = async (baseUrl, token, doctorId, clinicId, consumableId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let deleteResponse = null

    await axios.delete(baseUrl + "/doctor/" + doctorId + "/clinic/" + clinicId + "/consumable/" + consumableId, config)
        .then((res) => {
            deleteResponse = {
                status: res.data.status,
                data: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                deleteResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return deleteResponse
}

export const getProductConsumables = async (baseUrl, token, doctorId, clinicId, productId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + "/doctor/" + doctorId + "/clinic/" + clinicId +  "/consumable/" + productId, config)
        .then((res) => {
            getResponse = {
                status: res.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return getResponse
}

export const getConsumableLogs = async (baseUrl, token, doctorId, clinicId, consumableId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + "/doctor/" + doctorId + "/clinic/" + clinicId +  "/consumable/" + consumableId + "/log", config)
        .then((res) => {
            getResponse = {
                status: res.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return getResponse
}
