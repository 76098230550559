import { types } from "mobx-state-tree"
import { DoctorWorkingHour } from "./DoctorWorkingHour"

export const DoctorWorkingDay = types
    .model({
        id: types.maybeNull(types.optional(types.number, 0)),
        doctorId: types.maybeNull(types.optional(types.number, 0)),
        day: types.maybeNull(types.optional(types.number, 0)),
        dayId: types.maybeNull(types.optional(types.number, 0)),
        dayName: types.maybeNull(types.optional(types.string, "")),
        isActive: types.maybeNull(types.optional(types.boolean, false)),
        hourList: types.maybeNull(types.optional(types.array(types.string), [])),
        hours: types.maybeNull(types.optional(types.array(DoctorWorkingHour), []))
    }).actions(self => ({

    }))
