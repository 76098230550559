import React from "react";
import { Grid, Table, TableHead, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import dayjs from "dayjs";

export const DekontBilgi = ({ store, patient, data }) => {
	const calculateTotal = (data) => {
		return data.reduce((total, pay) => total + (pay?.price || 0), 0);
	};

	const doctor = store.userType !== 1 ? store.doctorAssistantAccount[0] : store.doctor[0];

	if (!doctor || !patient || !data) return null;

	return (
		<Grid id="printContent">
			<Grid container>
				<Grid item xs={6}>
					<Typography style={{ fontWeight: "bold" }}>DEKONT</Typography>
				</Grid>
				<Grid item xs={6} style={{ textAlign: "right" }}>
					<Typography variant="body2">
						Tarih: {dayjs(new Date()).format("DD.MM.YYYY")}
					</Typography>
				</Grid>
			</Grid>
			<Grid container style={{ width: "100%", border: "1px solid gray", padding: "10px" }}>
				<Grid item xs={5}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Typography variant="body1" style={{ fontWeight: "bold" }}>
								Doktor Bilgileri
							</Typography>
						</Grid>
						<Grid item xs={5}>
							<Typography variant="body2" style={{ fontWeight: "bold" }}>
								Adı Soyadı:
							</Typography>
						</Grid>
						<Grid item xs={7}>
							<Typography variant="body2">
								{(doctor?.title?.titleName ? doctor.title.titleName + " " : "") +
									doctor.professional?.professionalName +
									(doctor.professional?.professionalName
										? doctor.professional.professionalName + " "
										: "") +
									doctor.name +
									" " +
									doctor.surname}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={1}></Grid>
				<Grid item xs={6}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Typography variant="body1" style={{ fontWeight: "bold" }}>
								Hasta Bilgileri
							</Typography>
						</Grid>
						<Grid item xs={5}>
							<Typography variant="body2" style={{ fontWeight: "bold" }}>
								Hasta Adı Soyadı:
							</Typography>
						</Grid>
						<Grid item xs={7}>
							<Typography variant="body2">
								{patient.name + " " + patient.surname}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				spacing={0}
				style={{
					width: "100%",
					marginTop: "15px",
					border: "1px solid gray",
					padding: "10px",
				}}
			>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Ödeme Tarihi</TableCell>
							<TableCell>Ödeme</TableCell>
							<TableCell>Ödeme Tipi</TableCell>
							<TableCell>Durum</TableCell>
							<TableCell>Ödemeyi Alan</TableCell>
							<TableCell>Ödeme Notu</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data && data.length > 0 && (
							<>
								{data.map((pay, idx) => (
									<TableRow key={idx}>
										<TableCell>
											{dayjs(pay?.paymentDate).isBefore(dayjs(1971))
												? undefined
												: dayjs(pay?.paymentDate).format(
														"DD.MM.YYYY HH:mm:ss"
												  )}
										</TableCell>
										<TableCell>{pay?.price}TL</TableCell>
										<TableCell>{pay?.type}</TableCell>
										<TableCell>
											{pay?.completed ? "Ödeme Tamamlandı" : ""}
										</TableCell>
										<TableCell>
											{(pay?.name ?? "") + " " + (pay?.surname ?? "")}
										</TableCell>
										<TableCell>{pay?.note}</TableCell>
									</TableRow>
								))}
								{calculateTotal(data) > 0 && (
									<TableRow>
										<TableCell colSpan={6}>
											<strong>Toplam: {calculateTotal(data)}TL</strong>
										</TableCell>
									</TableRow>
								)}
							</>
						)}
					</TableBody>
				</Table>
			</Grid>
		</Grid>
	);
};
