import { types } from "mobx-state-tree"

export const DashboardStatistics = types
    .model({
        totalPatientCount: types.optional(types.number, 0),
        grossAmountByOfflineAppointment: types.optional(types.number, 0),
        grossAmountByOnlineAppointment: types.optional(types.number, 0),
        grossAmountByMedicalWorkup: types.optional(types.number, 0),
        futureAppointmentCount: types.optional(types.number, 0),
        serviceAmount: types.optional(types.number, 0),
        commissionAmount: types.optional(types.number, 0)
    }).actions(self => ({

    }))

