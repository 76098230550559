import axios from "axios";

const deleteConsultDoctor = async (baseUrl, token, doctorId, consultDoctorId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let deleteResponse = null

    await axios.delete(baseUrl + "/doctor/" + doctorId + "/consult/" + consultDoctorId, config)
        .then((res) => {
            deleteResponse = {
                status: res.status,
                data: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                deleteResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return deleteResponse
}

export default deleteConsultDoctor;