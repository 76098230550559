import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";

const DashboardInfoBox = (props) => (
	<Card {...props}>
		<CardContent>
			<Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
				<Grid item xs={12}>
					<Typography color="textSecondary" gutterBottom variant="overline">
						{props.text}
					</Typography>
					<Typography color="textPrimary" variant="h4">
						{props.data ? props.data + " TL" : "0"}
					</Typography>
				</Grid>
				<Grid item>
					<Avatar sx={{ backgroundColor: props.color, height: 56, width: 56 }}>
						{props.image}
					</Avatar>
				</Grid>
			</Grid>
		</CardContent>
	</Card>
);

export default DashboardInfoBox;
