import { types } from "mobx-state-tree"

export const Note = types
    .model({
        noteId: types.optional(types.number, 0),
        patientId: types.optional(types.number, 0),
        doctorId: types.optional(types.number, 0),
        appointmentId: types.optional(types.number, 0),
        documentUrl: types.optional(types.string, ""),
        complaint: types.optional(types.string, ""),
        history: types.optional(types.string, ""),
        symptom: types.optional(types.string, ""),
        alcohol: types.optional(types.number, 0),
        smooking: types.optional(types.number, 0),
        familyIllness: types.optional(types.string, ""),
        doctorSpecialNote: types.optional(types.string, "")
    }).actions(self => ({
    }))