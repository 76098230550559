import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import { containerStyle } from '../../utils/export-style';

import { LeftMenu } from '../../components/common/LeftMenu';

import { Box, Container, Grid } from '@mui/material';
import { HastaListesiMenu } from '../../components/doktor/hasta/HastaListesiMenu';

import patientsGetForDoctor from '../../utils/api/Doctor/GetPatientsforDoctor'

class HastalarPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            hastaListesi: []
        }
    }

    componentDidMount = async () => {
        if (!this.props.store.isLogin) {
            this.props.history.push("/panel/kullanici-girisi");
        }else{
            this.props.store.setLoadingBarStatus(false)
            let hastaListesiResponse = await patientsGetForDoctor(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id)
            //console.log("hastaListesiResponse: ", hastaListesiResponse)
            if(hastaListesiResponse !== undefined && hastaListesiResponse !== null){
                if(hastaListesiResponse.status === 200){
                    this.setState({
                        hastaListesi: hastaListesiResponse.data
                    })
                }else if(hastaListesiResponse.status === 401){
                    this.props.store.setUserLogout()
                    window.location.reload()
                }
            }else{
                this.props.store.setMessage("Hasta listesi getirilirken hata oluştu.", true, "error")
            }
            this.props.store.setLoadingBarStatus(true)
        }
    }
 
    render() {

        return (
            <div className="container mps-container">
                <LeftMenu activePage={"Hastalar"}/>
                <Box component="main" sx={{
                    ...containerStyle,
                    flexGrow: 1,
                    py: 8
                }} >
                    <Container maxWidth={false}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                                <HastaListesiMenu data={this.state.hastaListesi} />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </div>
        )
    }
}

export default inject("store")(observer(HastalarPage));
