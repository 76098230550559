import axios from "axios";

const postAppointmentActions = async (baseUrl, token, doctorId, appointmentId, actions) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    await axios.post(baseUrl + "/doctor/" + doctorId + "/appointment/" + appointmentId + "/actions", actions, config)
        .then((res) => {
            putResponse = {
                status: res.data.status,
                data: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return putResponse
}

export default postAppointmentActions;