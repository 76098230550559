import { Appointment } from '../../model/Appointment'

const parseAppointmentData = (inputAppointment) => {

    let appointment = null
    try {
        appointment = Appointment.create({
            id: (inputAppointment.appointmentId !== null) ? inputAppointment.appointmentId : 0,
            appointmentId: (inputAppointment.appointmentId !== null) ? inputAppointment.appointmentId : 0,
            doctorId: (inputAppointment.doctorId !== null) ? inputAppointment.doctorId : 0,
            patientId: (inputAppointment.patientId !== null) ? inputAppointment.patientId : 0,
            title: (inputAppointment.title !== null) ? inputAppointment.title : "",
            description: (inputAppointment.description !== null) ? inputAppointment.description : "",
            videoUrl: (inputAppointment.videoUrl !== null) ? inputAppointment.videoUrl : "",
            status: (inputAppointment.status !== null) ? inputAppointment.status : 0,
            appointmentDate: (inputAppointment.appointmentDate !== null) ? new Date(inputAppointment.appointmentDate) : new Date('0001-01-01'),
            appointmentEndDate: (inputAppointment.appointmentEndDate !== null) ? new Date(inputAppointment.appointmentEndDate) : new Date('0001-01-01'),
            appointmentType: (inputAppointment.appointmentType !== null) ? inputAppointment.appointmentType : 0,
            roomId: (inputAppointment.roomId !== null && inputAppointment.roomId !== undefined) ? inputAppointment.roomId : 0,
            clinicId: (inputAppointment.clinicId !== null && inputAppointment.clinicId !== undefined) ? inputAppointment.clinicId : 0,
            priceOffline: (inputAppointment.priceOffline !== null && inputAppointment.priceOffline !== undefined) ? inputAppointment.priceOffline: 0,
            productsOffline: (inputAppointment.productsOffline !== null && inputAppointment.productsOffline !== undefined) ? inputAppointment.productsOffline: [],
            examinationPrice: (inputAppointment.examinationPrice !== null && inputAppointment.examinationPrice !== undefined) ? inputAppointment.examinationPrice: 0,
            consultDoctorId: (inputAppointment.consultDoctorId !== null  && inputAppointment.consultDoctorId !== undefined) ? inputAppointment.consultDoctorId : 0,
            duration: (inputAppointment.duration !== null && inputAppointment.duration !== undefined) ? inputAppointment.duration : 0,
            startDate: (inputAppointment.startDate !== null && inputAppointment.startDate !== undefined) ? new Date(inputAppointment.startDate): new Date('0001-01-01'),
            expireDate: (inputAppointment.expireDate !== null && inputAppointment.expireDate !== undefined) ? new Date(inputAppointment.expireDate): new Date('0001-01-01'),
            barcode: (inputAppointment.barcode !== null) ? inputAppointment.barcode: "",
            sysTakip: (inputAppointment.sysTakip !== null) ? inputAppointment.sysTakip : "",
        })
    } catch (error) {
        console.log("error: ", error)
    }

    return appointment;
}

export default parseAppointmentData;