import axios from "axios";

export const getDoctorPromos = async (baseUrl, token, doctorId) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null;

    await axios.get(baseUrl + "/doctor/" + doctorId + "/promos", config)
        .then((res) => {
            getResponse = {
                status: res.status,
                data: res.data.data
            };
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        });

    return getResponse;
};
export const insertPromo = async (baseUrl, token, doctorId, promoData) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null;

    await axios.post(baseUrl + "/doctor/" + doctorId + "/promo", promoData, config)
        .then((res) => {
            getResponse = {
                status: res.status,
                data: res.data.data
            };
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        });

    return getResponse;
};
export const deletePromo = async (baseUrl, token, doctorId, promoId) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null;

    await axios.delete(baseUrl + "/doctor/" + doctorId + "/promo/" + promoId, config)
        .then((res) => {
            getResponse = {
                status: res.status,
                data: res.data.data
            };
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        });

    return getResponse;
};
export const insertPatientPromo = async (baseUrl, token, doctorId, patientId, promoId) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null;

    await axios.post(baseUrl + "/doctor/" + doctorId + "/promo/" + promoId + "/insert/" + patientId, {}, config)
        .then((res) => {
            getResponse = {
                status: res.status,
                data: res.data.data
            };
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        });

    return getResponse;
};
