import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";

import { Grid, Checkbox, TextField, InputLabel, Select, MenuItem, FormControl, FormControlLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export const QuestionPicker = inject("store")(
	observer((props) => {
		const [dateValue, setDateValue] = useState(Date.now());
		let selectedQ = props.question;

		if (props.onForm !== undefined) {
			selectedQ = props.question?.find((q) => q.questionId === props.onForm.questionId);
		}

		switch (selectedQ ? selectedQ?.field?.fieldName : props?.question?.field?.fieldName) {
			case "text":
				return (
					<Grid item xs={12}>
						<TextField
							id={"qpicker-" + selectedQ.questionId}
							variant="outlined"
							label={props.onForm?.required ? selectedQ.questionText + "*" : selectedQ.questionText}
							style={{ width: "100%", marginTop: "15px" }}
						/>
					</Grid>
				);
			case "number":
				return (
					<Grid item xs={12}>
						<TextField
							id={"qpicker-" + selectedQ.questionId}
							type="number"
							variant="outlined"
							label={props.onForm?.required ? selectedQ.questionText + "*" : selectedQ.questionText}
							style={{ width: "100%", marginTop: "15px" }}
						/>
					</Grid>
				);
			case "date":
				return (
					<Grid item xs={12}>
						<FormControl fullWidth style={{ marginTop: "15px" }}>
							<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"tr"} style={{ width: "calc(100% - 100px)" }}>
								<DatePicker
									label={props.onForm?.required ? selectedQ.questionText + "*" : selectedQ.questionText}
									value={dateValue}
									onChange={(date) => setDateValue(new Date(date))}
									renderInput={(params) => <TextField id={"qpicker-" + selectedQ.questionId} {...params} />}
								/>
							</LocalizationProvider>
						</FormControl>
					</Grid>
				);
			case "checkbox":
				return (
					<Grid item xs={12}>
						<FormControlLabel control={<Checkbox id={"qpicker-" + selectedQ.questionId ?? 0} />} label={props.onForm?.required ? selectedQ.questionText + "*" : selectedQ.questionText} />
					</Grid>
				);
			case "select":
				return (
					<Grid item xs={12}>
						<FormControl fullWidth style={{ width: "100%", marginTop: "15px" }}>
							<InputLabel>{props.onForm?.required ? selectedQ.questionText + "*" : selectedQ.questionText}</InputLabel>
							<Select id={"qpicker-" + selectedQ.questionId} label={props.onForm?.required ? selectedQ.questionText + "*" : selectedQ.questionText}>
								{selectedQ.options.map((option) => (
									<MenuItem key={option.optionId} value={option.optionText}>
										{option.optionText}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				);
			default:
				return null;
		}
	})
);
