import { DoctorDiploma } from '../../model/Doctor/DoctorDiploma'

const parseDoctorDiplomaData = (inputDoctorDiploma) => {
    //console.log("inputDoctorDiploma: ", inputDoctorDiploma)
    let doctorDiploma = null
    try {
        if (inputDoctorDiploma !== null) {
            doctorDiploma = DoctorDiploma.create({
                doctorId: (inputDoctorDiploma.doctorId !== null) ? inputDoctorDiploma.doctorId : 0,
                diplomaNo: (inputDoctorDiploma.diplomaNo !== null) ? inputDoctorDiploma.diplomaNo : "",
                diplomaImage: (inputDoctorDiploma.diplomaImage !== null) ? inputDoctorDiploma.diplomaImage : "",
                diplomaDate: (inputDoctorDiploma.diplomaDate !== null) ? new Date(inputDoctorDiploma.diplomaDate) : new Date(),
                univercityId: (inputDoctorDiploma.univercityId !== null) ? inputDoctorDiploma.univercityId : 0
            })
        }
    } catch (error) {
        console.log("error: ", error)
    }

    return doctorDiploma;
}

export default parseDoctorDiplomaData;

