import { DoctorUniversity } from '../../model/Doctor/DoctorUniversity'

const parseDoctorUniversityData = (inputDoctorUniversity) => {
    //console.log("inputDoctorUniversity: ", inputDoctorUniversity)
    let doctorUniversity = null
    try {

        doctorUniversity = DoctorUniversity.create({
            univercityid: (inputDoctorUniversity.univercityid !== null) ?  inputDoctorUniversity.univercityid : 0,
            univercityName: (inputDoctorUniversity.univercityName !== null) ? inputDoctorUniversity.univercityName : ""
        })
      
    } catch (error) {
        console.log("error: ", error)
    }
    
    return doctorUniversity;
} 

export default parseDoctorUniversityData;

