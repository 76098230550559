import axios from "axios";

const postClinicExpense = async (baseUrl, token, doctorId, clinicId, expense) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let postResponse = null

    await axios.post(baseUrl + "/doctor/" + doctorId + "/clinic/" + clinicId + "/expense", expense, config)
        .then((res) => {
            postResponse = {
                status: res.data.status,
                data: res.data.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                postResponse = {
                    status: error.response.status,
                    data: error.response.status
                }
            }
        });
    return postResponse
}

export default postClinicExpense;