import React, { useState, useEffect } from 'react';
import { observer, inject } from "mobx-react"

import { Button, Grid, Typography, IconButton, LinearProgress/*, MenuItem, InputLabel */} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
//import FormControl from '@mui/material/FormControl';
//import Select from '@mui/material/Select';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import { ImLab } from 'react-icons/im'


import CloseIcon from '@mui/icons-material/Close';

import getExaminationCategoryList from '../../../utils/api/Doctor/Examination/GetExaminationCategoryList'
import getExaminationProductList from '../../../utils/api/Doctor/Examination/GetExaminationProductList'
import postExamination from '../../../utils/api/Doctor/Examination/PostExamination'

/*const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};*/

export const TetkikMenu = inject("store")(observer((props) => {

    const selectedFirmId = 1
    const [examinationCategoryList, setExaminationCategoryList] = useState([])
    const [examinationProductList, setExaminationProductList] = useState([])
    const [selectedProductList, setSelectedProductList] = useState([])
    const [isSended, setIsSended] = useState(false)
    //const [selectedCategoryName, setSelectedCategoryName] = useState("")
    const [selectedCategoryColor, setSelectedCategoryColor] = useState("")

    useEffect(() => {
        // Update the document title using the browser API
        //console.log("props.examinations: ", props.examinations)
        getExaminationCategoryReq()
        if (props.examinations !== null) {

            let examinationList = props.examinations

            let fixedProductList = []
            for (let index = 0; index < examinationList.length; index++) {
                const examination = examinationList[index];

                let product = {
                    productName: examination.examinationDesc,
                    productId: examination.productId,
                    examinationId: examination.examinationId
                }

                fixedProductList.push(product)

            }

            setSelectedProductList(fixedProductList)
            //{product.categoryName + " - " + product.productName} 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.examinations]);

    const onClickCloseTetkikDialogMenu = () => {
        props.store.setTetkikDialogMenuDisplay(false)
    }

    const getExaminationCategoryReq = async() => {
        props.store.setLoadingBarStatus(false)

        let examinationListCategoryListResponse = await getExaminationCategoryList(props.store.baseUrl,
            props.store.token, props.store.doctor[0].id, selectedFirmId)

        if (examinationListCategoryListResponse !== null && examinationListCategoryListResponse !== undefined) {
            if (examinationListCategoryListResponse.status === 200) {
                //console.log("examinationListCategoryListResponse: ", examinationListCategoryListResponse)
                setExaminationCategoryList(examinationListCategoryListResponse.data)
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    /*const onChangeExaminationFirmListSelectMenu = async (event) => {
        props.store.setLoadingBarStatus(false)

        if (event.target.value !== selectedFirmId) {
            //setSelectedProductList([])
        }

        setSelectedFirmId(event.target.value)

        let examinationListCategoryListResponse = await getExaminationCategoryList(props.store.baseUrl,
            props.store.token, props.store.doctor[0].id, event.target.value)

        if (examinationListCategoryListResponse !== null && examinationListCategoryListResponse !== undefined) {
            if (examinationListCategoryListResponse.status === 200) {
                console.log("examinationListCategoryListResponse: ", examinationListCategoryListResponse)
                setExaminationCategoryList(examinationListCategoryListResponse.data)
            }
        }
        props.store.setLoadingBarStatus(true)
    }*/

    const onClickExaminationCategoryButton = async (categoryId) => {
        props.store.setLoadingBarStatus(false)

        let examinationProductListResponse = await getExaminationProductList(props.store.baseUrl, props.store.token,
            props.store.doctor[0].id, selectedFirmId, categoryId)

        for (let index = 0; index < examinationCategoryList.length; index++) {
            const examinationCategory = examinationCategoryList[index];
            if (examinationCategory.categoryId === categoryId) {
                //setSelectedCategoryName(examinationCategory.categoryName)
                setSelectedCategoryColor(examinationCategory.color)
            }
        }

        if (examinationProductListResponse !== null && examinationProductListResponse !== undefined) {
            if (examinationProductListResponse.status === 200) {
                //console.log("examinationProductListResponse: ", examinationProductListResponse)
                if (examinationProductListResponse.data !== null) {
                    setExaminationProductList(examinationProductListResponse.data)
                } else {
                    setExaminationProductList([])
                }
            }
        }

        props.store.setLoadingBarStatus(true)
    }

    const checkProductSelectedAlready = (product) => {
        let productExist = false
        for (let i = 0; i < selectedProductList.length; i++) {
            const tempSelectedProduct = selectedProductList[i];
            if (tempSelectedProduct.productId === product.productId) {
                productExist = true
            }
        }
        return productExist;
    }

    const onClickSelectProduct = (product) => {
        let productExist = checkProductSelectedAlready(product)
        if (productExist) {
            setSelectedProductList(selectedProductList.filter(a =>
                a.productId !== product.productId
            ))
        } else {
            product.color = selectedCategoryColor
            setSelectedProductList([...selectedProductList, product])
        }
    }

    const onClickDeleteSelectedProduct = (product) => {
        setSelectedProductList(selectedProductList.filter(a =>
            a.productId !== product.productId
        ))
    }

    const onClickSaveExaminations = async () => {
        props.store.setLoadingBarStatus(false)
        //console.log("examination: ", selectedProductList)
        let fixedTetkikListesi = []
        for (let index = 0; index < selectedProductList.length; index++) {
            const tempProduct = selectedProductList[index];
            let tetkik = {
                labProductId: tempProduct.productId,
                examinationDesc: tempProduct.productName,
                patientId: props.patient.patientId,
                firmId: selectedFirmId
            }
            fixedTetkikListesi.push(tetkik)
        }

        let postExaminationListResponse = await postExamination(props.store.baseUrl, props.store.token, props.store.doctor[0].id, props.appointmentId, fixedTetkikListesi)
        if (postExaminationListResponse !== null && postExaminationListResponse !== undefined) {
            if (postExaminationListResponse.status === 200) {
                setIsSended(true)
                props.store.setMessage("Tetkikler başarıyla gönderildi.", true, "success")
            } else {
                props.store.setMessage("Tetkikler gönderilirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Tetkikler gönderilirken hata oluştu.", true, "error")
        }
        //console.log("postExaminationListResponse: ", postExaminationListResponse)
        //console.log("fixedTetkikListesi: ", fixedTetkikListesi)
        props.store.setLoadingBarStatus(true)
    }

    const onClickUpdateExaminations = async() => {
        props.store.setLoadingBarStatus(false)
        //console.log("examination: ", selectedProductList)
        let fixedTetkikListesi = []
        for (let index = 0; index < selectedProductList.length; index++) {
            const tempProduct = selectedProductList[index];
            let tetkik = {
                labProductId: tempProduct.productId,
                examinationDesc: tempProduct.productName,
                patientId: props.patient.patientId,
                firmId: selectedFirmId,
                examinationId: (tempProduct.examinationId !== undefined) ? tempProduct.examinationId : 0
            }
            fixedTetkikListesi.push(tetkik)
        }

        let postExaminationListResponse = await postExamination(props.store.baseUrl, props.store.token, props.store.doctor[0].id, props.appointmentId, fixedTetkikListesi)
        if (postExaminationListResponse !== null && postExaminationListResponse !== undefined) {
            if (postExaminationListResponse.status === 200) {
                setIsSended(true)
                props.store.setMessage("Tetkikler başarıyla gönderildi.", true, "success")
            } else {
                props.store.setMessage("Tetkikler gönderilirken hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Tetkikler gönderilirken hata oluştu.", true, "error")
        }
        //console.log("postExaminationListResponse: ", postExaminationListResponse)
        //console.log("fixedTetkikListesi: ", fixedTetkikListesi)
        props.store.setLoadingBarStatus(true)

    }

    return (
        <>
            <Dialog fullScreen open={props.store.tetkikDialogMenuDisplay} /*onClose={this.closeReceteMenuDialog}*/>
                <AppBar sx={{ position: 'relative', background: "#2196f3" }}>
                    <Toolbar>
                        <IconButton onClick={onClickCloseTetkikDialogMenu}
                            edge="start"
                            color="inherit"
                            aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Tetkik Paneli
                        </Typography>
                        {
                            (isSended || props.examinations !== null) ?
                                <Button color="info" variant='contained' onClick={onClickUpdateExaminations}>
                                    TETKİK TALEBİNİ GÜNCELLE
                                </Button> :

                                <Button color="info" variant='contained' onClick={onClickSaveExaminations}>
                                    TETKİK TALEBİNİ KAYDET</Button>
                        }
                    </Toolbar>
                    <div style={{ position: "absolute", height: "5px", width: "100%", zIndex: "5000" }}>
                        <LinearProgress style={{ display: (props.store.loadingBarStatus) ? "none" : "block" }} color="primary" />
                    </div>
                </AppBar>
                <DialogContent>
                    {
                        /**
                         <FormControl style={{ width: "250px" }}>
                            <InputLabel>Labaratuar Seçiniz</InputLabel>
                            <Select labelId="insurance-select-label"
                                value={selectedFirmId} label="Labaratuar Seçiniz"
                                id="insurance-select" style={{ background: "#fff" }}
                                onChange={onChangeExaminationFirmListSelectMenu} MenuProps={MenuProps}>
                                {(props.examinationListFirm !== null) ? props.examinationListFirm.map((examinationFirm, idx) => (
                                    <MenuItem key={idx} value={examinationFirm.firmid}>{examinationFirm.firmName}</MenuItem>
                                )) : null}
                            </Select>
                        </FormControl>
                         */
                    }
                    
                    <Grid container spacing={0} style={{
                        width: "100%", marginTop: "15px", border: "1px solid #e0e0e0", height: "calc(100vh - 200px)",
                        maxHeight: "calc(100vh - 200px)", overflowY: "auto", borderRadius: "10px"}}>
                        <Grid item xs={3} style={{ padding: "10px", borderRight: "1px solid #e0e0e0", height: "calc(100vh - 200px)", maxHeight: "calc(100vh - 205px)" }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>
                                Tetkik Kategori Listesi
                            </Typography>

                            <List sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 'calc(100vh - 250px)',
                                '& ul': { padding: 0 },
                            }}>
                                {
                                    examinationCategoryList.map((category, idx) => (
                                        <ListItem key={idx} disablePadding>
                                            <ListItemButton onClick={() => onClickExaminationCategoryButton(category.categoryId)} >
                                                <ListItemAvatar>
                                                    <Avatar style={{ background: category.color }}>
                                                        <ImLab />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <Typography style={{ paddingTop: "4px", paddingBottom: "4px", fontSize: "13px" }}>
                                                    {category.categoryName}
                                                </Typography>
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Grid>
                        <Grid item xs={5} style={{
                            padding: "10px", borderRight: "1px solid #e0e0e0", overflowY: 'auto',
                            maxHeight: 'calc(100vh - 205px)',
                        }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>
                                Seçtiğinin Kategoriye Bağlı Tetkik Listesi
                            </Typography>
                            <FormGroup sx={{
                                width: '100%',
                                '& ul': { padding: 0 },
                            }}>
                                {
                                    (examinationProductList.length !== 0) ?
                                        examinationProductList.map((product, idx) => (
                                            <FormControlLabel key={idx} control={
                                                <Checkbox checked={checkProductSelectedAlready(product)}
                                                    onChange={() => onClickSelectProduct(product)} />}
                                                label={product.productName} style={{ fontSize: "12px" }} />
                                        ))
                                        : <Typography variant='body2'>Henüz kategori seçmediniz ya da seçtiğiniz kategoride tanımlı tetkik bulunmamaktadır.</Typography>
                                }
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4} style={{ padding: "10px", }}>
                            <Typography variant='body2' style={{ fontWeight: "bold" }}>
                                Seçilen Tetkik Listesi
                            </Typography>
                            {
                                selectedProductList.map((product, idx) => (
                                    <ListItem key={idx}
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete" onClick={() => onClickDeleteSelectedProduct(product)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        } >
                                        <ListItemAvatar>
                                            <Avatar style={{ background: product.color }}>
                                                <ImLab />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Typography style={{ paddingTop: "4px", paddingBottom: "4px", fontSize: "13px" }}>
                                            {(product.categoryName !== undefined) ? product.categoryName + " - " + product.productName : product.productName}
                                        </Typography>
                                    </ListItem>
                                ))
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}))