import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import ReCAPTCHA from "react-google-recaptcha";

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '@mui/material/FormGroup';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton'
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import DialogActions from '@mui/material/DialogActions';

import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import userLogin from '../../utils/api/Doctor/Auth/UserLogin'
import userLoginWithSmsCode from '../../utils/api/Doctor/Auth/UserLoginWithSmsCode'
import doctorGet from '../../utils/api/Doctor/GetDoctor'
import getNurseDoctor from '../../utils/api/Doctor/GetNurseDoctor'
import getAppointmentList from '../../utils/api/Doctor/Appointment/GetAppointmentList'

import { CountDown } from '../../components/common/CountDown';
import MapissoAlert from '../../components/common/MapissoAlert'
import putPatientForgotPasswordUpdate from '../../utils/api/Doctor/PutDoctorForgotPasswordUpdate';
import putDoctorForgot from '../../utils/api/Doctor/PutDoctorForgot';
import getConsultDoctorTalepList from '../../utils/api/Doctor/Consultation/GetConsultDoctorTalepList';
import UserAgreement from '../../utils/UserAgreement';

class LoginPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tcKimlikNo: "",
            password: "",
            smsCode: "",
            errorCount: 0,
            captchaValue: "",
            loginStep: 0,
            countInputDate: new Date(),
            code: "",
            parolamiUnuttumDialogDisplay: false,
            parolamiUnuttumDisplay: true,
            parolamiUnuttumTckno: "",
            newPassword: "",
            reNewpassword: "",
            passwordHelperTitle: "",
            passwordErrorStatus: false,
            repasswordHelperTitle: "",
            repasswordErrorStatus: false,
            repasswordShowStatus: false,
            passwordShowStatus: false,
            parolamiUnuttumCode: "",
            parolamiUnuttumNewPassword: "",
            parolamiUnuttumReNewpassword: "",
            showAgreement: "",
        }
    }

    componentDidMount = () => {
        this.props.store.setLoadingBarStatus(true)
    }

    onTcKimlikNoTextFieldChange = (event) => {
        this.setState({
            tcKimlikNo: event.target.value
        })
    }

    onPasswordTextFieldChange = (event) => {
        this.setState({
            password: event.target.value
        })
    }

    onKeyPressHandler = (target) => {
        if (target.charCode === 13) {
            this.onLogin()
        }
    }

    onChangeRecaptchaKey = (value) => {
        //console.log("Captcha value:", value);
        this.setState({
            captchaValue: value
        })
    }

    onSmsCodeTextInputChange = (event) => {
        this.setState({
            smsCode: event.target.value
        })
    }

    onClickLoginFirstStep = async () => {
        this.props.store.setLoadingBarStatus(false)

        if (this.state.tcKimlikNo !== "" && this.state.password !== "") {

            let queryable = false

            if (this.state.errorCount > 1) {
                if (this.state.captchaValue !== "") {
                    queryable = true
                } else {
                    queryable = false
                    this.props.store.setMessage("Robot değilim kontrolünü tamamlamalısınız.", true, "error")
                }
            } else {
                queryable = true
            }

            if (queryable) {
                await this.loginRequest()
            }

        } else {
            this.props.store.setMessage("Giriş yapmak için kullanıcı bilgilerinizi eksiksiz giriniz.", true)
        }
        this.props.store.setLoadingBarStatus(true)
    }

    loginRequest = async () => {
        let response = await userLogin(this.props.store.baseUrl, this.state.tcKimlikNo, this.state.password)
        if (response != null) {
            if (response.status === 200) {
                this.setState({
                    countInputDate: new Date(),
                    loginStep: 1,
                    smsCode: response.data?.code,
                    showAgreement: (this.state.showAgreement === "") ? !response.data?.pastLogin : this.state.showAgreement
                })
            } else {
                this.props.store.setToken("")
                this.setState({
                    errorCount: this.state.errorCount + 1
                })
                this.props.store.setMessage(response.data, true, "error")
            }
        } else {
            this.props.store.setMessage("Servis altyapısında problem oluştu", true)
        }
    }

    onClickLoginSecondStep = async () => {
        this.props.store.setLoadingBarStatus(false)

        if (this.state.smsCode !== "") {

            let response = await userLoginWithSmsCode(this.props.store.baseUrl, this.state.tcKimlikNo, this.state.password, this.state.smsCode)

            if (response != null) {
                if (response.status === 200) {

                    let user = response.data

                    this.props.store.setUser(user)
                    this.props.store.setToken(user.token)
                    this.props.store.setUserLogged()
                    

                    let doctorGetResponse = await doctorGet(this.props.store.baseUrl, this.props.store.token, user.id)
                    //console.log("doctorGetResponse: ", doctorGetResponse)
                    if (doctorGetResponse !== null) {
                        if (doctorGetResponse.status === 200) {
                            if (doctorGetResponse.data !== null) {
                                this.props.store.setDoctor(doctorGetResponse.data)
                                this.props.store.setUserType(doctorGetResponse.data.userType)
                                if(doctorGetResponse.data.userType !== 1){
                                    let doctorAssistantGetResponse = await getNurseDoctor(this.props.store.baseUrl, this.props.store.token, doctorGetResponse.data.id)
                                    if (doctorGetResponse !== null) {
                                        if (doctorGetResponse.status === 200) {
                                            if (doctorAssistantGetResponse.data !== null) {
                                                this.props.store.setDoctorAssistantAccount(doctorAssistantGetResponse.data)
                                            }
                                        
                                        }
                                    }
                                }

                                if(doctorGetResponse.data !== null){
                                    let professional = doctorGetResponse.data.professional
                                    if(professional !== null){
                                        let doctorType = professional.professionalId
                                        this.props.store.setDoctorType(doctorType)
                                    }
                                }

                                let consultantDoctorTalepListResponse = await getConsultDoctorTalepList(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id)
                                if (consultantDoctorTalepListResponse !== null && consultantDoctorTalepListResponse !== undefined) {
                                    if (consultantDoctorTalepListResponse.status === 200) {
                                        if (consultantDoctorTalepListResponse.data !== null) {
                                            //console.log("consultantDoctorTalepListResponse: ", consultantDoctorTalepListResponse.data)
                                            let consultantListSize = consultantDoctorTalepListResponse.data.length;
                                            this.props.store.setActiveConsultReqSize(consultantListSize)
                                        } else {
                                            this.props.store.setActiveConsultReqSize(0)
                                        }   

                                    }
                                }

                                let appointmentListResponse = await getAppointmentList(this.props.store.baseUrl, this.props.store.token, this.props.store.doctor[0].id)
                                if (appointmentListResponse !== undefined && appointmentListResponse !== null) {
                                    if (appointmentListResponse.status === 200) {
                                        //console.log("appointmentListResponse", appointmentListResponse.data)
                                        this.props.store.setRandevuListesi(appointmentListResponse.data)
                                    }
                                }
                            }
                        }
                    }

                    this.props.store.setLoadingBarStatus(true)
                    this.props.history.push("/panel");

                } else {
                    this.props.store.setToken("")
                    this.props.store.setMessage(response.data, true, "error")
                }
            } else {
                this.props.store.setMessage("Servis altyapısında problem oluştu", true, "error")
            }
        } else {
            this.props.store.setMessage("SMS kodunu girmeniz gerekmektedir..", true, "error")
        }
        this.props.store.setLoadingBarStatus(true)
    }

    onClickOpenParolamiUnuttum = () => {
        this.setState({
            parolamiUnuttumDialogDisplay: true
        })
    }

    handleCloseParolamiUnuttum = () => {
        this.setState({
            parolamiUnuttumDialogDisplay: false
        })
    }

    handleCloseAgreement = () => {
        this.setState({
            showAgreement: false
        })
    }

    onChangeParolamiUnuttumTcknoTextfield = (event) => {
        this.setState({
            parolamiUnuttumTckno: event.target.value
        })
    }

    onClickForgotPassword = async () => {
        this.props.store.setLoadingBarStatus(false)
        let passwordForgotResponse = await putDoctorForgot(this.props.store.baseUrl, this.props.store.token, this.state.parolamiUnuttumTckno)
        // console.log("passwordForgotResponse: ", passwordForgotResponse)
        if (passwordForgotResponse !== null && passwordForgotResponse !== undefined) {
            if (passwordForgotResponse.status === 200) {
                this.setState({
                    parolamiUnuttumDisplay: false,
                    countInputDate: new Date()
                })
            }
        }

        this.setState({
            parolamiUnuttumDisplay: false,
            countInputDate: new Date()
        })

        this.props.store.setLoadingBarStatus(true)
    }

    onNewPasswordTextFieldTextChange = (event) => {
        let passwordText = event.target.value
        if (passwordText.length < 8 && !this.isUpper(passwordText)) {
            this.setState({

                passwordHelperTitle: "*Şifreniz en az 8 karakter uzunluğunda olmalıdır. En az 1 tane büyük harf içermelidir.",
                passwordErrorStatus: true
            })
        } else {
            this.setState({
                passwordHelperTitle: "",
                passwordErrorStatus: false
            })
        }
        this.setState({
            newPassword: event.target.value
        })
    }

    isUpper(str) {
        return !/[a-z]/.test(str) && /[A-Z]/.test(str);
    }

    onRegisterRePasswordTextFieldTextChange = (event) => {
        let passwordText = event.target.value
        if (passwordText !== this.state.newPassword) {
            this.setState({
                repasswordHelperTitle: "*Şifreler uyuşmuyor.",
                repasswordErrorStatus: true
            })
        } else {
            this.setState({
                repasswordHelperTitle: "",
                repasswordErrorStatus: false
            })
        }

        this.setState({
            reNewpassword: event.target.value
        })
    }

    onClickPasswordUpdate = async () => {
        this.props.store.setLoadingBarStatus(false)

        if (!this.state.repasswordErrorStatus) {
            let passwordUpdateResponse = await putPatientForgotPasswordUpdate(this.props.store.baseUrl, this.props.store.token,
                this.state.parolamiUnuttumCode, this.state.newPassword)

            if (passwordUpdateResponse !== null && passwordUpdateResponse !== undefined) {
                if (passwordUpdateResponse.status === 200) {
                    this.props.store.setMessage("Şifreniz başarıyla güncellendi.", true, "success")
                    this.setState({
                        parolamiUnuttumDisplay: false
                    })
                }
            }
            // console.log("passwordUpdateResponse: ", passwordUpdateResponse)
        } else {
            this.props.store.setMessage("Şifreler uyuşmadığı için güncelleme işlemi yapamazsınız.", true, "error")
        }

        this.props.store.setLoadingBarStatus(true)
    }

    handleMouseDownPassword = () => {
        this.setState({
            passwordShowStatus: false
        })
    }

    handleClickShowPassword = () => {
        this.setState({
            passwordShowStatus: true
        })
    }

    handleClickShowRepassword = () => {
        this.setState({
            repasswordShowStatus: true
        })
    }

    handleMouseDownRepassword = () => {
        this.setState({
            repasswordShowStatus: false
        })
    }

    onParolamiUnuttumCodeTextChange = (event) => {
        this.setState({
            parolamiUnuttumCode: event.target.value
        })
    }

    render() {
        return (
            <div className="container mps-container bg-image">
                <Grid container style={{ width: "100%" }}>
                    <Grid item xs={3} className="height100vh" sx={{ padding: {xs: '25px', sm: "50px"}, background: "#fff", minWidth: {xs: '100%' , sm: "500px"}, paddingRight:{ sm:"100px !important" }}}>
                        <img src='/assets/images/wholemedic-logo.png' alt="wholemedics" style={{ width: "250px" }}></img>

                        <Box component='div' sx={{ paddingLeft: {xs: "unset", sm: "50px"}, display: (this.state.loginStep === 0) ? "block" : "none" }} >
                            <Typography variant="h4" style={{ textAlign: "left", fontWeight: "4bold00", marginTop: "75px", marginBottom: "30px" }}>Sağlık Çalışanı paneline, hoş geldin!</Typography>

                            <TextField id="outlined-basic1" variant="outlined" style={{ width: "100%", marginBottom: "10px", background: "#ffece2", borderRadius: "10px" }}
                                label="Kimlik Numarası" onChange={this.onTcKimlikNoTextFieldChange} onKeyPress={this.onKeyPressHandler} 
                                autoComplete="off"/>
                            <TextField id="3dtiles-input4" variant="outlined" type="password" label="Parola" onKeyPress={this.onKeyPressHandler}
                                style={{ width: "100%", marginBottom: "20px", background: "#ffece2", borderRadius: "10px", border: "0" }} onChange={this.onPasswordTextFieldChange} />

                            {(this.state.errorCount > 1) ?
                                <ReCAPTCHA style={{ marginBottom: "15px" }} hl="tr"
                                    sitekey="6Ld9ghgjAAAAADIr4XcEN-aWyBxlzniwhJbujQWG"
                                    onChange={this.onChangeRecaptchaKey}
                                /> : null
                            }

                            <FormGroup style={{ marginTop: "10px", marginBottom: "10px" }}>
                                <Typography variant='subtitle2' color="default" style={{ marginTop: "10px", fontSize: "13px" }}>
                                    Kişisel verilerinizin işlenmesi hakkında detaylı bilgi için
                                    <a href="https://saglikcim.net/kisisel-verilerin-korunmasi" style={{ color: "inherit", textDecoration: "underline", fontWeight: "bold", marginLeft: "5px" }}
                                        target="_blank" rel="noreferrer">
                                        Kişisel Verilerin İşlenmesi Hakkındaki Aydınlatma Metnini</a>'ni okuyabilirsiniz</Typography>
                            </FormGroup>

                            <Button variant="contained" color="primary" onClick={this.onClickLoginFirstStep}
                                style={{ width: "100%", height: "50px", fontWeight: "bold" }}>
                                GİRİŞ YAP
                            </Button>
                            <LinearProgress style={{ display: (this.props.store.loadingBarStatus) ? "none" : "block", width: "calc(100% - 18px)", marginLeft: "9px" }} />
                        </Box>
                        <Box component="div" sx={{ paddingLeft: {xs: "unset", sm: "50px"}, display: (this.state.loginStep === 1) ? "block" : "none" }}>
                            <Typography style={{ marginTop: "50px", fontWeight: "bold" }}>SMS Kodu Doğrulama</Typography>
                            <Typography variant='body1' >Cep telefonu numaranıza gönderilen sms kodunu giriniz.</Typography>
                            <TextField id="outlined-basic2" variant="outlined" name="tckno" style={{ width: "100%", marginBottom: "10px", borderRadius: "10px", marginTop: "10px" }}
                                value={this.state.smsCode}
                                label="SMS Kodu*" onChange={this.onSmsCodeTextInputChange}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <Button color='primary' variant='contained' onClick={this.onClickLoginFirstStep}>
                                                Tekrar Gönder
                                            </Button>
                                        </InputAdornment>
                                }}
                                autoComplete='one-time-code'
                            />
                            <CountDown date={this.state.countInputDate} time={180000} />

                            <Button variant="contained" color="primary" onClick={this.onClickLoginSecondStep}
                                style={{ width: "100%", height: "50px", fontWeight: "bold", marginTop: "10px" }}>
                                GİRİŞ YAP
                            </Button>
                            <LinearProgress style={{ display: (this.props.store.loadingBarStatus) ? "none" : "block", width: "calc(100% - 18px)", marginLeft: "9px" }} />

                        </Box>
                        <Button style={{ fontSize: "14px", textDecoration: "none", color: "#2196f3", fontWeight: "bold", float: "right", marginTop: "10px" }}
                            onClick={this.onClickOpenParolamiUnuttum}>
                            Parolamı unuttum
                        </Button>

                    </Grid>
                </Grid>
                <MapissoAlert />
                <Dialog open={this.state.parolamiUnuttumDialogDisplay} maxWidth={"sm"} fullWidth>
                    <AppBar sx={{ position: 'relative', background: "#2196f3" }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.handleCloseParolamiUnuttum}
                                aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Parolamı Unuttum
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div style={{ position: "absolute", height: "5px", width: "100%", zIndex: "5000" }}>
                        <LinearProgress style={{ display: (this.props.store.loadingBarStatus) ? "none" : "block" }} color="primary" />
                    </div>
                    <DialogContent style={{ padding: "30px" }}>
                        <Paper elevation={0} style={{ width: "100%", minHeight: "100px", display: (this.state.parolamiUnuttumDisplay) ? "block" : "none" }}>
                            <Grid container spacing={3} style={{ width: "100%" }}>
                                <Grid item xs={12}>
                                    <TextField value={this.state.parolamiUnuttumTckno} onChange={this.onChangeParolamiUnuttumTcknoTextfield}
                                        label="T.C. Kimlik Numarası" style={{ width: "100%" }} />
                                </Grid>
                            </Grid>
                        </Paper>

                        <Paper elevation={0} style={{ width: "100%", minHeight: "100px", display: (!this.state.parolamiUnuttumDisplay) ? "block" : "none" }}>
                            <Grid item xs={12}>
                                <CountDown date={this.state.countInputDate} time={180000} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="outlined-basic3" variant="outlined" name="password"
                                    type="text" value={this.state.parolamiUnuttumCode}
                                    style={{ width: "100%", marginBottom: "10px", borderRadius: "10px", marginTop: "10px" }}
                                    label="SMS Kodu" onChange={this.onParolamiUnuttumCodeTextChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="outlined-basic4" variant="outlined" name="password"
                                    type={this.state.passwordShowStatus ? "text" : "password"}
                                    value={this.state.newPassword}
                                    style={{ width: "100%", marginBottom: "10px", borderRadius: "10px", marginTop: "10px" }}
                                    label="Şifreniz*" onChange={this.onNewPasswordTextFieldTextChange} helperText={this.state.passwordHelperTitle} error={this.state.passwordErrorStatus}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    onMouseDown={this.handleMouseDownPassword}
                                                    edge="end">
                                                    {this.state.passwordShowStatus ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                    }} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="outlined-basic5" variant="outlined" name="repassword"
                                    type={this.state.repasswordShowStatus ? 'text' : 'password'}
                                    value={this.state.reNewpassword}
                                    style={{ width: "100%", marginBottom: "10px", borderRadius: "10px", marginTop: "10px" }}
                                    label="Şifreniz (tekrar)*" onChange={this.onRegisterRePasswordTextFieldTextChange} helperText={this.state.repasswordHelperTitle} error={this.state.repasswordErrorStatus}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowRepassword}
                                                    onMouseDown={this.handleMouseDownRepassword}
                                                    edge="end">
                                                    {this.state.repasswordShowStatus ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                    }} />
                            </Grid>
                        </Paper>

                    </DialogContent>
                    <DialogActions>
                        {(this.state.parolamiUnuttumDisplay) ?
                            <Button variant='contained' color="primary" onClick={this.onClickForgotPassword}
                                style={{ height: "55px", float: "right" }}>
                                Parolamı Sıfırla
                            </Button> : <Button variant='contained' color="primary" onClick={this.onClickPasswordUpdate}>
                                Parolamı Değiştir
                        </Button>}
                    </DialogActions>
                </Dialog>
                <Dialog open={!!this.state.showAgreement} maxWidth={"md"} fullWidth>
                    <AppBar sx={{ position: 'relative', background: "#2196f3" }}>
                        <Toolbar>
                            <Typography variant="h6" component="div">
                                WHOLEMEDICS SAĞLIK ÇALIŞANI SÖZLEŞME
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div style={{ position: "absolute", height: "5px", width: "100%", zIndex: "5000" }}>
                        <LinearProgress style={{ display: (this.props.store.loadingBarStatus) ? "none" : "block" }} color="primary" />
                    </div>
                    <DialogContent style={{ padding: "30px" }}>
                        <Paper elevation={0} style={{ width: "100%", minHeight: "100px", display: (this.state.parolamiUnuttumDisplay) ? "block" : "none" }}>
                            <Grid container spacing={3} style={{ width: "100%" }}>
                                {UserAgreement}
                            </Grid>
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' color="primary" onClick={this.handleCloseAgreement}
                            style={{ height: "55px", float: "right" }}>
                            Kabul Et
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default inject("store")(observer(LoginPage));
