import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { TemaPage } from "./TemaPage";

class TemaContainer extends Component {
	
	componentDidMount = async () => {
		if (!this.props.store.isLogin) {
			this.props.store.setLoadingBarStatus(true);
			this.props.history.push("/panel/kullanici-girisi");
		}
	};
	render() {
		return <TemaPage />;
	}
}

export default inject("store")(observer(TemaContainer));
