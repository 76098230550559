import axios from "axios";

export const getEnabizAccount = async (baseUrl, token, doctorId) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let getResponse = null

    await axios.get(baseUrl + `/doctor/account/`+ doctorId, config)
        .then((res) => {
            getResponse = {
                status: res.status,
                data: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                getResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return getResponse
}

export const putEnabizAccount = async (baseUrl, token, doctorId, accountInfo) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    let putResponse = null

    await axios.put(baseUrl + "/doctor/account/" + doctorId , accountInfo, config)
        .then((res) => {
            putResponse = {
                status: res.data.status,
                data: res.data
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                putResponse = {
                    status: error.response.status,
                    data:  error.response.status
                }
            }
        });
    return putResponse
}